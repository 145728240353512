import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../css_variables';

export const RateCardModalContainer = Styled.div`
    display: flex;
    .date-picker-button {
        &.locked {
			background-color: ${CSS_CONSTANTS.v2_input_disabled_grey};
			color: ${CSS_CONSTANTS.v2_text_light_gray};
        }
    }
`;

export const VersionSection = Styled.div`
    background-color: ${CSS_CONSTANTS.background_ghost_white};
    width: 220px;
    height: 100%;
    padding-top: 32px;
`;

export const RateCardSection = Styled.div`
    padding-top: 50px;
    width: 100%;
`;

export const VersionHeader = Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 16px 0 24px;
`;

export const VersionHeaderText = Styled.div`
    font-size: 13px;
    line-height: 20px;
`;

export const RateCardModalHeadline = Styled.div`
    font-size: 20px;
    margin-bottom: 32px;
    font-family: Urbane;
    padding: 0 16px 0 24px;
    height: 24px;
`;

export const VersionListHeader = Styled.div`
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #A5A5AE;
    padding: 0 16px 0 24px;
    margin-bottom: 8px;
`;

export const VersionListItem = Styled.div`
    display: flex;
    padding: 7px 24px;
    cursor: pointer;
    height: 36px;
    &.highlighted, :hover {
        background: #F0E6FD;
    }
    .actions {
        position: absolute;
        top: -4px;
        right: -15px;
    }
`;

export const VersionListItemContent = Styled.div`
    display: flex;
    flex-grow: 1;
`;

export const VersionDate = Styled.div`
    font-size: 12px;
    line-height: 22px;
    color: #4C4C58;
`;

export const VersionActiveToday = Styled.div`
    font-size: 9px;
    color: #A5A5AE;
    line-height: 20px;
    border: 1px #A5A5AE solid;
    border-radius: 4px;
    margin-left: 8px;
    width: 52px;
    text-align: center
`;

export const RateCardSettingsRow = Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const RateCardSettingsInput = Styled.div`
    width: 50%; 
    &:last-child {
        margin-left: 16px
    }
`;

export const RateCardSettingsContainer = Styled.div`
    margin-bottom: 24px;
`;
