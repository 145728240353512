import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {Button, SIZE, Table, XeroIcon} from 'web-components';
import {withRouter} from 'react-router-dom';

import * as tracking from '../../../tracking';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import CreateXeroVerificationKeyMutation from '../../../mutations/create_xero_verification_key_mutation';
import Util from '../../../forecast-app/shared/util/util';
import {BUTTON_STYLE, BUTTON_COLOR, ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import Warning from '../../../components/warning';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';
import {ButtonWrapper, HelpButtonWrapper, AddTenantButtonWrapper} from './AppXeroStyled';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import DirectApi from '../../../directApi';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {INVOICE_INTEGRATIONS} from '../../../components/settings-finance/InvoiceIntegrationsUtils';

class appXero extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fetching: props.viewer.company.newXeroEnabled,
			xeroOrgs: [],
		};

		this.getXeroOrgs();

		this.superPropertyChecksum = trackPage('App Catalog - Xero');
	}

	componentDidMount() {
		document.title = 'Xero - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-xero');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/4943407588113');
	}

	getXeroOrgs() {
		if (!this.props.viewer.company.newXeroEnabled) return;

		DirectApi.Fetch_Raw('xero/organizations').then(result => {
			if (result.status === 403) {
				this.setState({
					fetching: false,
				});
			}

			result.json().then(resp => {
				this.setState({
					fetching: false,
					xeroOrgs: resp.orgs || [],
				});
			});
		});
	}

	removeTenant(tenantId) {
		const {formatMessage} = this.props.intl;

		const callbackPositive = () => {
			const headers = new Headers();
			headers.append('Content-Type', 'application/json');
			const init = {headers: headers, credentials: 'include', method: 'DELETE'};

			fetch(DirectApi.graphqlServerEndpoint(`xero/settings/${tenantId}`), init).then(() => window.location.reload());
		};

		if (hasFeatureFlag('invoicing_pathways')) {
			showModal({
				type: MODAL_TYPE.DISABLE_INTEGRATION_MODAL,
				integration: INVOICE_INTEGRATIONS.XERO,
				removeCallback: callbackPositive,
			});
		} else {
			showModal({
				type: MODAL_TYPE.GENERIC,
				content: (
					<div>
						<Warning messageId={'settings.app_catalog.xero.remove_tenant_message_warning'} />
						<div className="warning-part-2">
							{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}
						</div>
					</div>
				),
				className: 'default-warning-modal',
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.remove'}),
						callback: callbackPositive,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
					},
				],
			});
		}
	}

	addTenant() {
		const onSuccess = ({createXeroVerificationKey}) => {
			tracking.trackEvent('Xero integration enabled');
			trackEvent('Xero Integration', 'Enabled');

			window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${
				process.env.XERO_INTEGRATION_APP_CLIENT_ID
			}&redirect_uri=${
				process.env.API_URL + '/xero/oauth'
			}&scope=${'offline_access accounting.transactions accounting.contacts accounting.settings'}&state=${
				createXeroVerificationKey.key
			}`;
		};

		Util.CommitMutation(CreateXeroVerificationKeyMutation, {}, onSuccess);
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;

		return (
			<div className="section-content settings-app settings-app-page settings-app-xero">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<XeroIcon size={SIZE.EXTRA_BIG} />
									<div className="title-description-wrapper">
										<div className="description">
											{formatMessage({id: 'settings.app_catalog.xero_description'})}
										</div>
									</div>
								</div>

								{this.state.fetching ? (
									<InlineLoader />
								) : (
									[
										<Table>
											<Table.Header>
												<Table.HeaderColumn flex={1} usePadding visible width="LARGE" align="left">
													<Table.HeaderColumn.Title>
														<FormattedMessage id={'common.organization'} />
													</Table.HeaderColumn.Title>
												</Table.HeaderColumn>

												<Table.HeaderColumn usePadding visible align="right" />

												<Table.HeaderColumn usePadding visible align="right" />
											</Table.Header>
											<Table.Rows data={{rows: this.state.xeroOrgs}} canExpand={false}>
												{({rowData, tableColumnsProps}) => (
													<Table.Row {...tableColumnsProps}>
														<Table.Column>{rowData.organization}</Table.Column>

														<Table.Column>
															{rowData.inaccessible ? (
																<Button
																	size={Button.SIZE.STANDARD}
																	variant={Button.VARIANT.WHITE}
																	onClick={() => this.addTenant()}
																>
																	<FormattedMessage id={'common.reconnect'} />
																</Button>
															) : (
																''
															)}
														</Table.Column>

														<Table.Column>
															<Button
																size={Button.SIZE.STANDARD}
																variant={Button.VARIANT.RED_OUTLINE}
																onClick={() => this.removeTenant(rowData.tenantId)}
															>
																<FormattedMessage id={'common.remove'} />
															</Button>
														</Table.Column>
													</Table.Row>
												)}
											</Table.Rows>
										</Table>,
										<ButtonWrapper>
											<AddTenantButtonWrapper>
												<Button
													size={Button.SIZE.STANDARD}
													variant={Button.VARIANT.GREEN_FILLED}
													onClick={() => this.addTenant()}
												>
													<FormattedMessage id={'common.add'} />
												</Button>
											</AddTenantButtonWrapper>

											<HelpButtonWrapper>
												<Button
													size={Button.SIZE.STANDARD}
													variant={Button.VARIANT.WHITE}
													onClick={() => this.openHelpCenter()}
												>
													<FormattedMessage id={'settings.app_catalog.help_button'} />
												</Button>
											</HelpButtonWrapper>
										</ButtonWrapper>,
									]
								)}
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appXeroQuery = graphql`
	query appXero_Query {
		viewer {
			actualPersonId
			component(name: "app_xero")
			...appXero_viewer
		}
	}
`;

export {appXeroQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(appXero, {
			viewer: graphql`
				fragment appXero_viewer on Viewer {
					id
					availableFeatureFlags {
						key
					}
					company {
						id
						xeroEnabled
						newXeroEnabled
					}
				}
			`,
		})
	)
);
