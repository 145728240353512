import React from 'react';
import {ModalText} from '../../modal/modal_styled';

export const TimeApprovalIncompatibleContent = ({integrationName}) => {
	return (
		<>
			<ModalText bold>{`The ${integrationName} integration is not compatible with Time Approval`}</ModalText>
			<ModalText>{`Time Approval locks time registrations, which means that the ${integrationName} integration will not be able to synchronize changes correctly.`}</ModalText>
			<ModalText>{`If you wish to use this integration, please deactivate Time Approval from the Time Management page`}</ModalText>
		</>
	);
};
