import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import Styled from 'styled-components/macro';
import ActionsMenu from '../../../forecast-app/shared/components/action-menu/actions_menu';
import {BasicTextInput, DragHandleIcon} from 'web-components';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';

const PriorityLevel = Styled.div`
  background-color:  ${props => (props.isDragging || props.hover ? props.theme.colors.forecastPurpleLight : 'none')};
  display:flex;
  height: 44px;
  width: 500px;
  filter:  ${props => (props.isDragging ? 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12))' : 'none')};
`;

const Name = Styled.div`
  width: 420px;
  height: 44px;
	padding: 7px;
  overflow: hidden;
  & input[type='text']:not(:hover) {
  border: 1px solid transparent
  }
`;

const UsageCount = Styled.div`
  width: 30px;
  height: 44px;
  line-height: 44px;
  color:  ${props => (props.disabled ? props.theme.colors.mediumLightGray : props.theme.colors.textLightGray)}
`;

const ActionMenuWrapper = Styled.div`
 padding-top: 6px;
 padding-right: 4px;
`;

const DragHandle = ({hover, isDragging}) => {
	return (
		<div style={{padding: '14px', width: '34px'}}>
			{hover || isDragging ? <DragHandleIcon size={DragHandleIcon.SIZE.STANDARD} color="#4a4a4a" /> : <></>}
		</div>
	);
};

const deletePriorityLevel = (priorityLevel, deleteCallback) => {
	if (priorityLevel.useCount) {
		showModal({
			type: MODAL_TYPE.DELETE_PRIORITY_LEVEL,
			deleteCallback,
			priorityLevelToDelete: priorityLevel,
		});
	} else {
		deleteCallback();
	}
};

const PriorityLevelRow = ({priorityLevel, isDragging, disableCallback, deleteCallback, updateNameCallback}) => {
	const {formatMessage} = useIntl();
	const [hover, setHover] = useState(false);
	const [name, setName] = useState(priorityLevel.name);
	const handleSetName = name => {
		if (name.length > 100) {
			name = name.substring(0, 100);
		}
		setName(name);
	};

	const getActionsMenuOptions = () => {
		return [
			{
				text: formatMessage({id: priorityLevel.disabled ? 'common.enable' : 'common.disable'}),
				cy: 'disable',
				onClick: () => {
					disableCallback(priorityLevel.id, !priorityLevel.disabled);
				},
			},
			{
				text: formatMessage({id: 'common.delete'}),
				cy: 'delete',
				onClick: () => {
					deletePriorityLevel(priorityLevel, deleteCallback);
				},
			},
		];
	};

	return (
		<PriorityLevel
			isDragging={isDragging}
			hover={hover}
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}
		>
			<DragHandle hover={hover} isDragging={isDragging} />
			<Name>
				<BasicTextInput
					onChange={handleSetName}
					value={name}
					callback={value => updateNameCallback(priorityLevel.id, value)}
					disabled={priorityLevel.disabled}
				/>
			</Name>
			<UsageCount disabled={priorityLevel.disabled}>
				{priorityLevel.useCount ? '(' + priorityLevel.useCount + ')' : ''}
			</UsageCount>
			<ActionMenuWrapper>
				<ActionsMenu whiteInner options={getActionsMenuOptions()} />
			</ActionMenuWrapper>
		</PriorityLevel>
	);
};

export default PriorityLevelRow;
