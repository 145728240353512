import {useIntl} from 'react-intl';
import ForecastTooltip from '../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import InformationIcon from '../../../../images/information-icon';
import Button from '../../../../forecast-app/shared/components/buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import InlineLoader from '../../../../forecast-app/shared/components/inline-loader/inline_loader';
import React, {useState} from 'react';
import {BasicTextInput, CheckmarkIcon, WarningIcon} from 'web-components';
import Util from '../../../../forecast-app/shared/util/util';
import {createToast} from '../../../../forecast-app/shared/components/toasts/toast';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import CreateTempoVerificationKeyMutation from '../../../../mutations/CreateTempoVerificationKeyMutation';
import {createFragmentContainer, graphql} from 'react-relay';
import VerifyTempoTokenMutation from '../../../../mutations/VerifyTempoTokenMutation';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {FlexRow, Text} from '@forecast-it/design-system';
import UpdateCompanyMutation from '../../../../mutations/update_company_mutation.modern';
import DeleteTempoIntegrationMutation from '../../../../mutations/DeleteTempoIntegrationMutation';

const verifyTempoOauthToken = ({
	setTempoOauthTokenValid,
	setVerifyingTempoOauthToken,
	setShowOauthTokenValidMarking,
	formatMessage,
	setWorklogCount,
}) => {
	setShowOauthTokenValidMarking(false);

	const onSuccess = result => {
		if (result && result.verifyTempoToken) {
			if (result.verifyTempoToken.statusCode === 200) {
				setTempoOauthTokenValid(true);
				setVerifyingTempoOauthToken(false);
				createToast({
					duration: 5000,
					message: `Oauth Token is valid with ${result.verifyTempoToken.worklogCount} worklogs`,
				});
				setWorklogCount(result.verifyTempoToken.worklogCount);
				return;
			} else if (result.verifyTempoToken.statusCode === 401) {
				createToast({
					duration: 5000,
					message: 'Oauth Token is not valid, please refresh',
				});
			} else {
				createToast({
					duration: 5000,
					message: formatMessage({
						id: 'app_jira.integrate_page.tempo.token_check.error',
					}),
				});
			}
		}
		setTempoOauthTokenValid(false);
		setVerifyingTempoOauthToken(false);
	};

	const onError = () => {
		setTempoOauthTokenValid(false);
		setVerifyingTempoOauthToken(false);
		createToast({
			duration: 5000,
			message: formatMessage({
				id: 'app_jira.integrate_page.tempo.token_check.error',
			}),
		});
	};
	Util.CommitMutation(VerifyTempoTokenMutation, null, onSuccess, true, null, onError);

	setVerifyingTempoOauthToken(true);
	setShowOauthTokenValidMarking(true);
};

const unlinkTempo = (setClientId, setClientSecret) => {
	const onMutationSuccess = () => {
		trackEvent('Tempo Integration Oauth', 'Unlinked');
		setClientId('');
		setClientSecret('');
	};

	const onUserConfirm = () => {
		Util.CommitMutation(DeleteTempoIntegrationMutation, null, onMutationSuccess);
	};
	showModal({
		type: MODAL_TYPE.CONFIRM_GENERIC,
		hideWarningTitle: true,
		title: 'Unlink Tempo Oauth Token',
		warningMessage: 'Clicking this button will unlink the company from Tempo, by removing the Tempo stored Tempo tokens.',
		labelText: "To continue with this operation, please type 'confirm' in the text field below.",
		confirmText: 'confirm',
		onConfirm: onUserConfirm,
		placeholderText: "type 'confirm'",
	});
};

const createOrReauthorizeTempoOauthToken = (company, jiraBaseUrl) => {
	const onMutationSuccess = ({createTempoVerificationKey}) => {
		trackEvent('Tempo Integration Oauth', 'Refreshed');

		const clientId = company.tempoOauthClientId;
		const redirectUri = process.env.TEMPO_OAUTH_REDIRECT_URI;

		window.location.replace(
			`${jiraBaseUrl}/plugins/servlet/ac/io.tempo.jira/oauth-authorize/` +
				`?client_id=${clientId}&redirect_uri=${redirectUri}?key=${createTempoVerificationKey.key}`
		);
	};

	const onUserConfirm = () => {
		Util.CommitMutation(CreateTempoVerificationKeyMutation, {}, onMutationSuccess);
	};
	showModal({
		type: MODAL_TYPE.CONFIRM_GENERIC,
		hideWarningTitle: true,
		title: 'Refresh Tempo Oauth Token',
		warningMessage:
			'Clicking this button will redirect to the Atlassian website to refresh the Tempo Oauth Token. \n' +
			'It is important that you login using an account that has access to all Tempo data.',
		labelText: "To continue to the Atlassian website, please type 'confirm' in the text field below.",
		confirmText: 'confirm',
		onConfirm: onUserConfirm,
		placeholderText: "type 'confirm'",
	});
};

const updateCompany = ({company, tempoOauthClientId, tempoOauthClientSecret}) => {
	Util.CommitMutation(
		UpdateCompanyMutation,
		{
			id: company.id,
			tempoOauthClientId: tempoOauthClientId,
			tempoOauthClientSecret: tempoOauthClientSecret,
		},
		() => {
			createToast({
				duration: 5000,
				message: `Client credentials saved`,
			});
		}
	);
};

const JiraTempoOauthTokenSection = ({company}) => {
	const {jiraBaseUrl, tempoOauthTokenApplied, tempoOauthClientId, tempoOauthClientSecret} = company;
	const {formatMessage} = useIntl();
	const [clientId, setClientId] = useState(tempoOauthClientId);
	const [clientSecret, setClientSecret] = useState(tempoOauthClientSecret);
	const [tempoOauthTokenValid, setTempoOauthTokenValid] = useState(true);
	const [verifyingTempoOauthToken, setVerifyingTempoOauthToken] = useState(false);
	const [showOauthTokenValidMarking, setShowOauthTokenValidMarking] = useState(false);
	const [worklogCount, setWorklogCount] = useState(null);

	const enableAuthorizeButton =
		tempoOauthClientId !== null &&
		tempoOauthClientId !== '' &&
		tempoOauthClientSecret !== null &&
		tempoOauthClientSecret !== '' &&
		clientId === tempoOauthClientId &&
		clientSecret === tempoOauthClientSecret;

	const enableSaveCredentialsButton =
		clientId !== null &&
		clientId !== '' &&
		clientSecret !== null &&
		clientSecret !== '' &&
		(clientId !== tempoOauthClientId || clientSecret !== tempoOauthClientSecret);

	return (
		<div className="settings-app-jira-section live">
			<span className="settings-app-jira-section-title standard">Tempo Timesheets</span>
			<div className="settings-app-jira-section-body">
				<div className="current-settings">
					<div className="settings-title-container">
						<div className="current-settings-title">Oauth token</div>
						<ForecastTooltip
							content={
								tempoOauthTokenApplied && tempoOauthTokenValid
									? 'Check and refresh the tempo oauth token'
									: 'Authorize the Forecast app to access your tempo data. This will redirect to the atlassian website.'
							}
							delay={0}
						>
							<InformationIcon />
						</ForecastTooltip>
					</div>
					<div style={{display: 'flex', columnGap: '12px', maxHeight: '35px'}}>
						{tempoOauthTokenApplied && tempoOauthTokenValid ? (
							<>
								<Button
									className="settings-button"
									buttonStyle={BUTTON_STYLE.OUTLINE}
									colorTheme={BUTTON_COLOR.WHITE}
									text={formatMessage({id: 'common.check'})}
									onClick={() =>
										verifyTempoOauthToken({
											setTempoOauthTokenValid: setTempoOauthTokenValid,
											setVerifyingTempoOauthToken: setVerifyingTempoOauthToken,
											setShowOauthTokenValidMarking: setShowOauthTokenValidMarking,
											formatMessage: formatMessage,
											setWorklogCount: setWorklogCount,
										})
									}
								/>
								{showOauthTokenValidMarking && (
									<FlexRow>
										{tempoOauthTokenValid ? (
											<>
												<CheckmarkIcon />
												{worklogCount ? (
													<Text color={'medium'} size={'2'}>
														({worklogCount} worklogs)
													</Text>
												) : null}
											</>
										) : (
											<WarningIcon updated color={'#E01212'} />
										)}
									</FlexRow>
								)}
								{verifyingTempoOauthToken ? <InlineLoader /> : null}
							</>
						) : null}
					</div>
					<div style={{display: 'flex', columnGap: '8px'}}>
						<Button
							className="settings-button"
							buttonStyle={BUTTON_STYLE.OUTLINE}
							colorTheme={BUTTON_COLOR.WHITE}
							text={tempoOauthTokenApplied ? 'Reauthorize' : 'Authorize'}
							isDisabled={!enableAuthorizeButton}
							onClick={() => createOrReauthorizeTempoOauthToken(company, jiraBaseUrl)}
						/>
						<Button
							className="settings-button"
							buttonStyle={BUTTON_STYLE.OUTLINE}
							colorTheme={BUTTON_COLOR.RED}
							text={'Unlink'}
							isDisabled={false}
							onClick={() => unlinkTempo(setClientId, setClientSecret)}
						/>
					</div>
					<div style={{display: 'flex', columnGap: '8px', alignItems: 'self-end'}}>
						<BasicTextInput value={clientId} width={'300px'} placeholder={'Client ID'} onChange={setClientId} />
						<BasicTextInput
							type="password"
							value={clientSecret}
							label={'Client secret:'}
							width={'300px'}
							placeholder={'Client secret'}
							onChange={setClientSecret}
						/>
						<Button
							className="settings-button"
							buttonStyle={BUTTON_STYLE.OUTLINE}
							colorTheme={BUTTON_COLOR.WHITE}
							text={'Save client credentials'}
							isDisabled={!enableSaveCredentialsButton}
							onClick={() =>
								updateCompany({
									company,
									tempoOauthClientId: clientId,
									tempoOauthClientSecret: clientSecret,
								})
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default createFragmentContainer(JiraTempoOauthTokenSection, {
	company: graphql`
		fragment jiraTempoOauthTokenSection_company on Company {
			tempoOauthTokenApplied
			jiraBaseUrl
			tempoOauthClientId
			tempoOauthClientSecret
		}
	`,
});
