import React, {useEffect, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import styled from 'styled-components';
import {sortBy} from 'lodash';
import MissionControlHeader from '../mission_control_header';
import {ModuleModal} from './module_modal';
import {Module} from './module';
import {MODULE_TYPES} from '../../../constants';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import DirectApi from '../../../directApi';
import Util from '../../../forecast-app/shared/util/util';

const CompaniesWithModulesStyle = styled.div`
	max-height: 100vh;
	overflow: hidden;
`;

const ButtonStyle = styled.button`
	white-space: nowrap;
`;

const TableHeader = styled.thead`
	th {
		background-color: white;
		position: sticky;
		top: 0;
	}
`;

const TableBody = styled.tbody``;

const SortableTableHeaderCellStyle = styled.th`
	text-decoration: underline;
	width: 1%;
	white-space: nowrap;
`;

const SortableTableHeaderCell = ({label, property, sortValue, onClick}) => {
	const sortDirection = sortValue.property === property ? (sortValue.desc ? '▼' : '▲') : null;

	return (
		<SortableTableHeaderCellStyle onClick={() => onClick(property)}>
			{label}
			{sortDirection}
		</SortableTableHeaderCellStyle>
	);
};

const CompaniesWithModules = props => {
	const {
		viewer: {
			missionControlData: {companiesWithModules},
		},
	} = props;

	const [selectedModule, setSelectedModule] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [sortValue, setSortValue] = useState({property: 'companyId', desc: false});
	const [companySisenseStats, setCompanySisenseStats] = useState({});

	const sortByProperty = property =>
		setSortValue({property, desc: property !== sortValue.property ? false : !sortValue.desc});

	const filteredCompaniesWithModules = sortBy(
		companiesWithModules.filter(companyWithModules => {
			return companyWithModules.companyName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
		}),
		[sortValue.property]
	);

	if (sortValue.desc) {
		filteredCompaniesWithModules.reverse();
	}

	const GetCompanySisenseStats = () => {
		if (process.env.CIRCLE_BRANCH === 'production' || process.env.CIRCLE_BRANCH === 'master') {
			DirectApi.Fetch_Raw('sisense/check_users').then(result => {
				result.json().then(resp => {
					setCompanySisenseStats(resp);
				});
			});
		}
	};

	useEffect(() => {
		GetCompanySisenseStats();
	}, []);

	return (
		<CompaniesWithModulesStyle>
			<div className="mission-control-list">
				<MissionControlHeader viewer={props.viewer} />
				<h1>Modules</h1>
				<div>
					<input
						type="search"
						placeholder="Search"
						value={searchValue}
						onChange={event => setSearchValue(event.target.value)}
					/>
				</div>
				<div style={{maxHeight: '90vh', overflowY: 'scroll'}}>
					<table>
						<TableHeader>
							<tr>
								<SortableTableHeaderCell
									sortValue={sortValue}
									onClick={sortByProperty}
									label="ID"
									property="companyId"
								/>
								<SortableTableHeaderCell
									sortValue={sortValue}
									onClick={sortByProperty}
									label="Name"
									property="companyName"
								/>
								<SortableTableHeaderCell
									sortValue={sortValue}
									onClick={sortByProperty}
									label="Tier"
									property="tier"
								/>
								{hasFeatureFlag('get_missioncontrol_data_from_us') && <th>Datacenter</th>}
								<th>Modules</th>
								<th>&nbsp;</th>
								<th
									style={{
										whiteSpace: 'nowrap',
									}}
								>
									AvA Viewers
								</th>
								<th
									style={{
										whiteSpace: 'nowrap',
									}}
								>
									AvA Designers
								</th>
							</tr>
						</TableHeader>
						<TableBody>
							{filteredCompaniesWithModules.map(companyWithModules => {
								const companyModules = companyWithModules.modules.edges.map(edge => edge.node);
								const existingModuleTypes = companyModules.map(module => module.moduleType);
								const sortedCompanyModules = sortBy(companyModules, ['enabledByTier', 'moduleType']);

								const availableModuleTypes = Object.keys(MODULE_TYPES).filter(
									moduleType =>
										existingModuleTypes.indexOf(moduleType) === -1 || moduleType === module.moduleType
								);
								const sisenseGroup = companySisenseStats.sisenseGroups?.find(
									group => group.name === `company_${companyWithModules.companyId}`
								);

								const numOfSisenseViewers = sisenseGroup?.users.filter(
									user => user.roleId === companySisenseStats?.sisenseViewerId
								).length;
								const numOfSisenseDesigners = sisenseGroup?.users.filter(
									user => user.roleId === companySisenseStats?.sisenseDesignerId
								).length;

								return (
									<tr key={companyWithModules.id}>
										<td
											style={{
												verticalAlign: 'middle',
												width: '1%',
											}}
										>
											{companyWithModules.companyId}
										</td>
										<td
											style={{
												verticalAlign: 'middle',
												width: '1%',
												whiteSpace: 'nowrap',
											}}
										>
											{companyWithModules.companyName.replace(/ *\([^)]*\) */g, '')}
										</td>
										<td
											style={{
												verticalAlign: 'middle',
												width: '1%',
												whiteSpace: 'nowrap',
											}}
										>
											{Util.getTiersMessage(companyWithModules.tier)}
										</td>
										{hasFeatureFlag('get_missioncontrol_data_from_us') && (
											<td>{companyWithModules.datacenter}</td>
										)}
										<td
											style={{
												verticalAlign: 'middle',
												width: '1%',
												whiteSpace: 'nowrap',
												paddingTop: 0,
												paddingBottom: 0,
											}}
										>
											<div style={{display: 'flex', width: '50vw', flexWrap: 'wrap'}}>
												{sortedCompanyModules.map(module => (
													<Module
														key={module.id}
														module={module}
														onModuleClicked={() =>
															setSelectedModule({
																...module,
																datacenter: companyWithModules.datacenter,
															})
														}
													/>
												))}
											</div>
										</td>
										<td style={{verticalAlign: 'middle'}}>
											{availableModuleTypes.length > 0 ? (
												<ButtonStyle
													onClick={() =>
														setSelectedModule({
															companyId: companyWithModules.id,
															datacenter: companyWithModules.datacenter,
														})
													}
												>
													Add modules
												</ButtonStyle>
											) : null}
										</td>
										<td
											style={{
												verticalAlign: 'middle',
												width: '1%',
											}}
										>
											{numOfSisenseViewers}/{companyWithModules.advancedAnalyticsViewerSeats}
										</td>
										<td
											style={{
												verticalAlign: 'middle',
												width: '1%',
											}}
										>
											{numOfSisenseDesigners}/{companyWithModules.advancedAnalyticsDesignerSeats}
										</td>
									</tr>
								);
							})}
						</TableBody>
					</table>
				</div>
				{selectedModule ? (
					<ModuleModal
						companiesWithModules={companiesWithModules}
						module={selectedModule}
						closeModal={() => setSelectedModule(null)}
					/>
				) : null}
			</div>
		</CompaniesWithModulesStyle>
	);
};

const companiesWithModulesQuery = graphql`
	query companiesWithModules_Query {
		viewer {
			actualPersonId
			component(name: "control_companies_with_modules")
			...companiesWithModules_viewer
		}
	}
`;

export {companiesWithModulesQuery};

export default createFragmentContainer(CompaniesWithModules, {
	viewer: graphql`
		fragment companiesWithModules_viewer on Viewer {
			id
			...missionControlHeader_viewer
			missionControlData {
				companiesWithModules {
					id
					companyId
					companyName
					tier
					advancedAnalyticsViewerSeats
					advancedAnalyticsDesignerSeats
					datacenter
					modules(first: 10000) @connection(key: "companiesWithModules_modules") {
						edges {
							node {
								id
								companyId
								moduleType
								enabledByTier
								startYear
								startMonth
								startDay
								endYear
								endMonth
								endDay
							}
						}
					}
				}
			}
		}
	`,
});
