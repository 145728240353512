import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GenericModal from './generic_modal';
import Warning from '../../components/warning';

class WarningModal extends Component {
	render() {
		const content = (
			<div className={'default-warning-modal'}>
				<Warning
					messageId={this.props.warningMessageId}
					message={this.props.message}
					important={this.props.irreversible}
					useInfoIcon={this.props.useInfoIcon}
				/>
				{this.props.warningInformation ? (
					<div className="warning-part-2">
						{this.props.warningInformation.map((info, index) => (
							<div key={index}>{info}</div>
						))}
					</div>
				) : null}
			</div>
		);
		return <GenericModal closeModal={this.props.closeModal} buttons={this.props.buttons} content={content} />;
	}
}

WarningModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	irreversible: PropTypes.bool,
	label: PropTypes.string,
	useInfoIcon: PropTypes.bool,
};
export default WarningModal;
