import React from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import styled from 'styled-components';
import {Button, CrossIcon, Dropdown, ProjectIndicator, Switch} from 'web-components';
import {CSS_CONSTANTS} from '../../css_variables';
import DirectApi from '../../directApi';
import ProjectDropdown from '../../forecast-app/shared/components/dropdowns/project-dropdown-b/Project_dropdown';
import {createToast} from '../../forecast-app/shared/components/toasts/toast';
import Util from '../../forecast-app/shared/util/util';
import CreatePersonDismissedProjectNotificationMutation from '../../mutations/CreatePersonDismissedProjectNotificationMutation';
import DeletePersonDismissedProjectNotificationMutation from '../../mutations/DeletePersonDismissedProjectNotificationMutation';
import UpdateViewerNotificationSettingsMutation from '../../mutations/UpdateViewerNotificationSettingsMutation';
import {
	DropdownLabel,
	DropdownSection,
	DropdownWrapper,
	InputWrapper,
	SwitchLabel,
} from '../settings/settings_company_notifications.styled';

const SettingsContainer = styled.div`
	margin: 16px 24px;
	padding: 16px 24px;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	width: 512px;
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

const SectionLabel = styled.div`
	font-weight: 500;
	margin-top: 32px;
`;

const ProjectRow = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 6px;
	border-bottom: 1px solid #e6e6e6;
	margin-bottom: 8px;
`;

const IndicatorNameWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const NameWrapper = styled.div`
	margin-left: 4px;
`;

const InfoTextContainer = styled.div`
	margin-top: 4px;
`;

const InfoText = styled.div`
	color: ${CSS_CONSTANTS.v2_text_light_gray};
`;

const DropdownContainer = styled.div`
	margin: 16px 0;
`;

const BetaTooltipWrapper = styled.div`
	display: flex;
`;

const toggleProjectStatusEmail = (projectStatusEmailEnabled, id) => {
	Util.CommitMutation(UpdateViewerNotificationSettingsMutation, {
		id: id,
		projectStatusEmailEnabled: !projectStatusEmailEnabled,
	});
};

const handleChangeDay = (day, id) => {
	Util.CommitMutation(UpdateViewerNotificationSettingsMutation, {
		id: id,
		projectStatusEmailDay: day,
	});
};

const handleChangeHour = (hour, id) => {
	Util.CommitMutation(UpdateViewerNotificationSettingsMutation, {
		id: id,
		projectStatusEmailHour: hour,
	});
};

const days_options = [
	{value: 'monday', label: 'Weekly on Monday'},
	{value: 'tuesday', label: 'Weekly on Tuesday'},
	{value: 'wednesday', label: 'Weekly on Wednesday'},
	{value: 'thursday', label: 'Weekly on Thursday'},
	{value: 'friday', label: 'Weekly on Friday'},
	{value: 'saturday', label: 'Weekly on Saturday'},
	{value: 'sunday', label: 'Weekly on Sunday'},
	{value: 'daily', label: 'Daily'},
];

const hours_options = () => {
	const hours = [];
	for (let i = 0; i < 24; i++) {
		hours.push({value: i, label: i + ':00'});
	}
	return hours;
};

const addDismissedProject = ids => {
	if (ids) {
		const onSuccess = () => {
			createToast({duration: 5000, message: 'Project Muted'});
		};
		Util.CommitMutation(CreatePersonDismissedProjectNotificationMutation, {projectId: ids[0]}, onSuccess);
	}
};

const removeDismissedProject = id => {
	const onSuccess = () => {
		createToast({duration: 5000, message: 'Project Unmuted'});
	};
	Util.CommitMutation(DeletePersonDismissedProjectNotificationMutation, {projectId: id}, onSuccess);
};

const sendDemoEmailRequest = () => {
	DirectApi.Fetch_Raw('project_status_digest_test');
};

const ProfileProjectStatusUpdates = ({viewer}) => {
	const {formatMessage} = useIntl();
	const {id, projectStatusEmailEnabled, projectStatusEmailDay, projectStatusEmailHour} = viewer.personNotificationSettings;

	const ownedIncludedProjects = viewer.projects.edges.filter(
		project => project.node.userIsProjectOwner && !viewer.personDismissedProjectNotificationIds.includes(project.node.id)
	);

	const ownedExcludedProjects = viewer.projects.edges
		.filter(
			project => project.node.userIsProjectOwner && viewer.personDismissedProjectNotificationIds.includes(project.node.id)
		)
		.sort((a, b) => Util.sortAlphabetically(a.node.name, b.node.name));

	return (
		<SettingsContainer>
			<BetaTooltipWrapper>
				<InputWrapper>
					<Switch
						checked={projectStatusEmailEnabled}
						onChange={() => toggleProjectStatusEmail(projectStatusEmailEnabled, id)}
					></Switch>
					<SwitchLabel>{'Project status email updates'}</SwitchLabel>
				</InputWrapper>
			</BetaTooltipWrapper>
			<InfoTextContainer>
				<InfoText>{'Stay in the loop with your ongoing projects and their suggested status'}</InfoText>
				<InfoText>
					You only receive updates for projects where you are set as owner or where you have previously updated the
					project status.
				</InfoText>
			</InfoTextContainer>
			<DropdownContainer>
				<DropdownSection>
					<DropdownLabel>{formatMessage({id: 'common.when_colon'})}</DropdownLabel>
					<DropdownWrapper>
						<Dropdown
							disabled={!projectStatusEmailEnabled}
							name={formatMessage({id: 'overview_time.day'})}
							selectedItems={[projectStatusEmailDay]}
							onSelect={day => handleChangeDay(day[0], id)}
						>
							{days_options.map(option => (
								<Dropdown.SingleText key={option.value} value={option.value} searchString={option.label}>
									{option.label}
								</Dropdown.SingleText>
							))}
						</Dropdown>
					</DropdownWrapper>
				</DropdownSection>
				<DropdownSection>
					<DropdownLabel>{formatMessage({id: 'common.time_colon'})}</DropdownLabel>
					<DropdownWrapper>
						<Dropdown
							disabled={!projectStatusEmailEnabled}
							name={formatMessage({id: 'common.hour'})}
							selectedItems={[projectStatusEmailHour]}
							onSelect={hour => handleChangeHour(hour[0], id)}
						>
							{hours_options().map(option => (
								<Dropdown.SingleText key={option.value} value={option.value} searchString={option.label}>
									{option.label}
								</Dropdown.SingleText>
							))}
						</Dropdown>
					</DropdownWrapper>
				</DropdownSection>
			</DropdownContainer>
			<SectionLabel>{'Mute updates on specific projects'}</SectionLabel>
			<InfoTextContainer>
				<InfoText>{'You will not be updated about project you mute.'}</InfoText>
				<InfoText>{'This is visible only to you.'}</InfoText>
			</InfoTextContainer>
			<DropdownContainer>
				<DropdownWrapper>
					<ProjectDropdown
						projects={ownedIncludedProjects}
						isClearable
						optionsName={'Owned projects'}
						selectedItems={[]}
						onSelect={addDismissedProject}
						showSecondaryIcon
						usePortal
						maxHeight={255}
						width={360}
						name={'Select project to mute'}
					/>
				</DropdownWrapper>
			</DropdownContainer>
			{ownedExcludedProjects.map(project => {
				return (
					<ProjectRow key={project.node.id}>
						<IndicatorNameWrapper>
							<ProjectIndicator
								name={project.node.name}
								id={project.node.companyProjectId}
								color={project.node.projectColor}
							/>
							<NameWrapper>{project.node.name}</NameWrapper>
						</IndicatorNameWrapper>
						<CrossIcon size={CrossIcon.SIZE.SMALL} onClick={() => removeDismissedProject(project.node.id)} />
					</ProjectRow>
				);
			})}
			{viewer.email.includes('@forecast') ? (
				<div style={{marginTop: '48px'}}>
					<Button variant={Button.VARIANT.PURPLE_FILLED} onClick={sendDemoEmailRequest}>
						Send email test for company
					</Button>
				</div>
			) : null}
		</SettingsContainer>
	);
};

export default createFragmentContainer(ProfileProjectStatusUpdates, {
	viewer: graphql`
		fragment ProfileProjectStatusUpdates_viewer on Viewer {
			id
			email
			personDismissedProjectNotificationIds
			personNotificationSettings {
				id
				projectStatusEmailEnabled
				projectStatusEmailDay
				projectStatusEmailHour
			}
			projects(first: 100000, excludeDoneOrHalted: true) {
				edges {
					...ProjectDropdown_projects
					node {
						id
						name
						companyProjectId
						projectColor
						userIsProjectOwner
					}
				}
			}
		}
	`,
});
