import React from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import * as tracking from '../../../tracking';
import zapierLogo from '../../../images/integrations/zapier-logo.png';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class AppZapier extends React.Component {
	constructor(props) {
		super(props);

		this.superPropertyChecksum = trackPage('App Catalog - Zapier');
	}

	componentDidMount() {
		document.title = 'Zapier - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-zapier');

		const script = document.createElement('script');
		script.src = 'https://zapier.com/apps/embed/widget.js?services=forecast&html_id=templates_zapier&limit=10';
		script.async = true;

		document.body.appendChild(script);
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5563301843985');
	}

	handleCalenderButtonClick() {
		window.open('https://zapier.com/apps/forecast/integrations');
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<img width={100} height={100} alt="zapier logo" src={zapierLogo} />
									<div className="title-description-wrapper">
										<div className="description">
											<FormattedMessage id="settings.app_catalog.zapier.description" />
											<br />
											<FormattedHTMLMessage id="settings.app_catalog.zapier.beta" />
											<br />
											<a
												href="https://zapier.com/apps/forecast/integrations"
												target="_blank"
												rel="noopener noreferrer"
											>
												https://zapier.com/apps/forecast/integrations
											</a>
											<div id="templates_zapier" />
										</div>
									</div>
								</div>
								<div>
									<button className="submit-button" onClick={this.handleCalenderButtonClick.bind(this)}>
										<FormattedMessage id="settings.app_catalog.zapier.button_text" />
									</button>
									<button className="help-button" onClick={this.openHelpCenter.bind(this)}>
										<FormattedMessage id="settings.app_catalog.help_button" />
									</button>
								</div>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

AppZapier.propTypes = {};

export default injectIntl(withRouter(AppZapier));
