import React from 'react';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import * as tracking from '../../../tracking';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import CreateEconomicVerificationKeyMutation from '../../../mutations/create_economic_verification_key_mutation';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import Util from '../../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_STYLE, BUTTON_COLOR, ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {EconomicIcon, SIZE} from 'web-components';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {INVOICE_INTEGRATIONS} from '../../../components/settings-finance/InvoiceIntegrationsUtils';

class appEconomic extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			verifying: false,
			verifyingSave: false,
		};

		this.superPropertyChecksum = trackPage('App Catalog - Economic');
	}

	componentDidMount() {
		document.title = 'Economic - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-economic');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	disableEconomic() {
		const {formatMessage} = this.props.intl;
		const deactivateForReal = () => {
			Util.CommitMutation(UpdateCompanyMutation, {
				id: this.props.viewer.company.id,
				economicDeactivate: true,
			});
		};

		if (hasFeatureFlag('invoicing_pathways')) {
			showModal({
				type: MODAL_TYPE.DISABLE_INTEGRATION_MODAL,
				integration: INVOICE_INTEGRATIONS.ECONOMIC,
				removeCallback: deactivateForReal,
			});
		} else {
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'warning-modal.edit-warning-title',
				warningInformation: [formatMessage({id: 'settings.app_catalog.economic.deactivate_warning'})],
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.deactivate'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: deactivateForReal,
					},
				],
			});
		}
		this.setState({verifying: false});
	}

	enableEconomic() {
		const onSuccess = ({createEconomicVerificationKey}) => {
			tracking.trackEvent('Economic integration enabled');
			trackEvent('Economic Integration', 'Enabled');
			const redirectUri = encodeURI(
				process.env.ECONOMIC_REDIRECT_URI + '?access_key=' + createEconomicVerificationKey.key
			);
			window.location.href = `https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=YhbcMtIdEOT9uAkBDsvWUxJ99gYK5wGQ9Wj2hb3l1l01&redirectUrl=${redirectUri}`;
		};

		Util.CommitMutation(CreateEconomicVerificationKeyMutation, null, onSuccess);
	}

	openHelpCenterInvoicing() {
		window.open('https://support.forecast.app/hc/en-us/articles/5112725520273');
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<EconomicIcon size={SIZE.EXTRA_BIG} />
									<div className="title-description-wrapper">
										<div className="description">
											<FormattedHTMLMessage id="settings.app_catalog.economic.description" />
										</div>
									</div>
								</div>
								<div className="input-fields">
									{this.props.viewer.company.economicEnabled ? (
										<button
											key="economic-child-2"
											className="submit-button"
											onClick={this.disableEconomic.bind(this)}
										>
											{formatMessage({id: 'integration.disable'})}
										</button>
									) : (
										<button className="submit-button" onClick={this.enableEconomic.bind(this)}>
											{formatMessage({id: 'integration.enable'})}
										</button>
									)}
									<button className="help-button" onClick={this.openHelpCenterInvoicing.bind(this)}>
										{formatMessage({id: 'settings.app_catalog.help_economic_invoice'})}
									</button>
								</div>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appEconomicQuery = graphql`
	query appEconomic_Query {
		viewer {
			actualPersonId
			component(name: "app_economic")
			...appEconomic_viewer
		}
	}
`;

export {appEconomicQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(appEconomic, {
			viewer: graphql`
				fragment appEconomic_viewer on Viewer {
					id
					company {
						id
						economicEnabled
					}
				}
			`,
		})
	)
);
