import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../css_variables';

export const SettingsCompanyNotificationsPageWrapper = Styled.div`
   font-size: 13px;
   color: ${CSS_CONSTANTS.v2_text_gray};
   display:flex;
   flex-direction:column;
   flex-grow: 1;

`;

export const MainContentWrapper = Styled.div`
    display: flex;
    flex-direction:column;
	flex-grow: 1;
    width:566px;
    margin: 0 0 0 24px;
`;

export const NotificationSectionWrapper = Styled.div`
    display: flex;
    flex-direction:column;
    border-top : solid 1px ${CSS_CONSTANTS.app_border_color};
    
    margin: 0 0 16px 0 ;
    padding:24px 0 0 0;
`;

export const PeriodWrapper = Styled.div`
    margin: 16px 0 24px;
`;

export const InputWrapper = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 8px;
`;

export const RadioWrapper = Styled.div`
    display:flex;
    flex-direction:row;
    align-items: center;
    margin-top: 16px;
    &:first-of-type {
        margin-top: 0;
    }
`;

export const SwitchLabel = Styled.div`
    font-weight:500;
    margin-left:13px;
`;

export const Label = Styled.label`
    margin-left:13px;
    color: #4C4C58;
`;

export const InfoText = Styled.div`
    margin:16px 0 ;
    color:${CSS_CONSTANTS.v2_text_light_gray};
`;

export const DropdownSection = Styled.div`
    display:flex;
    flex-direction:row;
    align-items:baseline;
    margin: 8px 0;
    
`;

export const DropdownWrapper = Styled.div`
    width: 176px;
`;

export const DropdownLabel = Styled.div`
    text-transform:capitalize;
    width:60px;
`;

export const SubSection = Styled.div`
    border-bottom:solid 1px ${CSS_CONSTANTS.app_border_color};
`;

export const RoleRow = Styled.div`
    display:flex;
    align-items:center;
    height:48px;
    border-top:solid 1px ${CSS_CONSTANTS.app_border_color};
    padding: 0 16px;
    &:hover{
       background-color: ${props => props.hoverEffect && CSS_CONSTANTS.v2_branding_purple + '06'};
    }
`;

export const RoleName = Styled.div`
    flex-grow:1;
`;

export const SubSectionHeader = Styled.div`
    margin:16px 0 0 0 ;
    font-weight: 500;
`;

export const RoleDropdownWrapper = Styled.div`
    width:250px;
`;
