/* global Trello */
import React from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import loadScript from 'load-script';
import * as tracking from '../../../tracking';
import MigrateTrelloDataMutation from '../../../mutations/migrate_trello_data_mutation';
import trelloLogo from '../../../images/integrations/trello-logo.png';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import UploadingOverlay from '../../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import Util from '../../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../../constants';
import {ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

let scriptLoadingStarted = false;

class appTrello extends React.Component {
	constructor(props) {
		super(props);

		this.clickCounter = 0;

		this.onApiLoad = this.onApiLoad.bind(this);

		this.state = {
			verifying: false,
			apiLoaded: false,
		};

		this.superPropertyChecksum = trackPage('App Catalog - Trello');
	}

	componentDidMount() {
		document.title = 'Trello - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-trello');

		if (this.isTrelloReady()) {
			// google api is already exists
			// init immediately
			this.onApiLoad();
		} else if (!scriptLoadingStarted) {
			// load google api and the init
			scriptLoadingStarted = true;
			loadScript('https://code.jquery.com/jquery-1.7.1.min.js', () => {
				loadScript('https://trello.com/1/client.js?key=f72864a52a0ce665e3696ff198334c29', this.onApiLoad);
			});
		} else {
			// is loading
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	onApiLoad() {
		this.setState({apiLoaded: true});
	}

	isTrelloReady() {
		return !!window.Trello;
	}

	trello() {
		const {formatMessage} = this.props.intl;
		localStorage.removeItem('trello_token');
		const authenticationSuccess = () => {
			this.clickCounter++;
			if (this.clickCounter === 1) {
				const token = localStorage.getItem('trello_token');
				this.clickCounter = 0;

				this.setState({verifying: true});
				const onSuccess = response => {
					this.setState({verifying: false});
					if (response.migrateTrelloData.success) {
						tracking.trackEvent('Trello migration completed');
						trackEvent('Trello Migration', 'Completed');
						showModal({
							type: MODAL_TYPE.WARNING,
							warningMessageId: 'trello.migration_started',
							warningInformation: [formatMessage({id: 'trello.migration_started_description'})],
							useInfoIcon: true,
							buttons: [
								{
									text: formatMessage({id: 'common.ok'}),
									style: BUTTON_STYLE.FILLED,
									color: BUTTON_COLOR.WHITE,
								},
							],
						});
					} else {
						showModal({
							type: MODAL_TYPE.WARNING,
							warningMessageId: 'trello.migration_failed',
							warningInformation: [formatMessage({id: 'trello.migration_failed_description'})],
							buttons: [
								{
									text: formatMessage({id: 'common.ok'}),
									style: BUTTON_STYLE.FILLED,
									color: BUTTON_COLOR.WHITE,
								},
							],
						});
					}
				};

				Util.CommitMutation(MigrateTrelloDataMutation, {token}, onSuccess);
			} else {
				// eslint-disable-next-line no-console
				console.log('this.clickCounter', this.clickCounter);
			}
		};
		const authenticationFailure = function () {
			// eslint-disable-next-line no-console
			console.log('Failed authentication');
		};

		Trello.authorize({
			type: 'popup',
			name: 'Forecast',
			scope: {
				read: true,
				write: false,
				account: true,
			},
			expiration: 'never',
			success: authenticationSuccess,
			error: authenticationFailure,
		});
	}

	openHelpSection() {
		window.open('https://support.forecast.app/hc/en-us/articles/5400736970385');
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{this.state.verifying ? <UploadingOverlay /> : null}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<img width={100} height={100} alt="trello logo" src={trelloLogo} />
									<div className="title-description-wrapper">
										<div className="description">
											<FormattedHTMLMessage id="settings.app_catalog.trello.description" />
										</div>
									</div>
								</div>
								<div className="input-fields">
									<button
										disabled={this.state.verifying || !this.state.apiLoaded}
										className="submit-button"
										onClick={this.trello.bind(this)}
									>
										{formatMessage({id: 'settings.app_catalog.trello.migrate_button'})}
									</button>
									<button onClick={this.openHelpSection.bind(this)} className="help-button">
										<FormattedMessage id="settings.app_catalog.help_button" />
									</button>
								</div>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appTrelloQuery = graphql`
	query appTrello_Query {
		viewer {
			actualPersonId
			component(name: "app_trello")
			...appTrello_viewer
		}
	}
`;

export {appTrelloQuery};

export default createFragmentContainer(injectIntl(withRouter(appTrello)), {
	viewer: graphql`
		fragment appTrello_viewer on Viewer {
			id
		}
	`,
});
