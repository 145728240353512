import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import UpdateViewerMutation from '../../mutations/update_viewer_mutation.modern';
import * as tracking from '../../tracking';
import Util from '../../forecast-app/shared/util/util';
import Switch from '../../forecast-app/shared/components/switch/switch';
import RadioButton from '../../components/new-ui/radio_button';
import InputField from '../../components/new-ui/input_field';
import SetIntegrationNotificationSettingMutation from '../../mutations/SetIntegrationNotificationSettingMutation';
import {hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import SetSlackNotificationSettingMutation from '../../mutations/slack_integration/SetSlackNotificationSettingMutation';
import UpdateViewerNotificationSettingsMutation from '../../mutations/UpdateViewerNotificationSettingsMutation';
import styled from 'styled-components';
import {unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import ForecastTooltip from '../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {Checkbox} from 'web-components';
import {ForecastTooltipFormulaRenderer} from '../../components/ForecastTooltipFormulaRenderer';
import {hasFeatureFlag, hasTimeApproval} from '../../forecast-app/shared/util/FeatureUtil';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../constants';

const NOTIFICATION_PREFIX = {
	NOTIFY_IN_APP_ON: 'notifyInAppOn',
	NOTIFY_ON: 'notifyOn',
};

const NOTIFICATION_FREQUENCY = {
	INSTANT: 0,
	DAILY: 1,
	WEEKLY: 2,
};

const FrequencySection = styled.div`
	display: flex;
	margin-bottom: 8px;
	align-items: center;
`;

const RadioButtonContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: 16px;
`;

class ProfileNotificationsSettings extends Component {
	constructor(props) {
		super(props);

		this.inputs = {};
		this.state = {
			isAdvancedVisible: false,
			isInvoiceAdvancedVisible: false,
			projectDeadlineNotificationPeriod: this.props.viewer.projectDeadlineNotificationPeriod,
			taskDeadlineNotificationPeriod: this.props.viewer.taskDeadlineNotificationPeriod,
			invoiceNotificationDaysOverdue: this.props.viewer.invoiceNotificationDaysOverdue,
			invoiceEnableAllInAppChildren: this.getToggleInvoiceChildrenState(true),
			invoiceEnableAllChildren: this.getToggleInvoiceChildrenState(false),
			invoiceEnableAllChildrenTeams: this.getToggleInvoiceChildrenStateSlackTeams('teamsNotificationSettings'),
			invoiceEnableAllChildrenSlack: this.getToggleInvoiceChildrenStateSlackTeams('slackNotificationSettings'),
			invoiceEnableAllChildrenIsOnlyOwned: this.getToggleInvoiceChildrenStateIsOnlyOwned(),
		};

		this.hasInvoiceNotificationsFeature = hasFeatureFlag('invoice_notifications');
	}

	componentDidMount() {
		document.title = 'Notifications - My profile - Forecast';

		tracking.trackPage('profile-notifications');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleToggle(notification) {
		const params = {};
		params[notification] = !this.props.viewer[notification];
		Util.CommitMutation(UpdateViewerMutation, params);
	}

	getToggleInvoiceChildrenStateSlackTeams(notificationSettings) {
		const {
			notifyOnInvoiceDueDate,
			notifyOnInvoiceOverdue,
			notifyOnInvoiceDaysOverdue,
			notifyOnInvoiceCreatedOrDeleted,
			notifyOnInvoiceStatusChange,
			notifyOnInvoiceDateReached,
			notifyOnInvoicePayment,
			notifyOnInvoiceDateChanged,
			notifyOnInvoiceDueDateChanged,
		} = this.props.viewer[notificationSettings];
		return (
			notifyOnInvoiceDueDate &&
			notifyOnInvoiceOverdue &&
			notifyOnInvoiceDaysOverdue &&
			notifyOnInvoiceCreatedOrDeleted &&
			notifyOnInvoiceStatusChange &&
			notifyOnInvoiceDateReached &&
			notifyOnInvoicePayment &&
			notifyOnInvoiceDateChanged &&
			notifyOnInvoiceDueDateChanged
		);
	}

	getToggleInvoiceChildrenStateIsOnlyOwned() {
		const {
			invoiceDueDateOnlyOwned,
			invoiceOverdueOnlyOwned,
			invoiceDaysOverdueOnlyOwned,
			invoiceCreatedOrDeletedOnlyOwned,
			invoiceStatusChangeOnlyOwned,
			invoiceDateReachedOnlyOwned,
			invoicePaymentOnlyOwned,
			invoiceDateChangedOnlyOwned,
			invoiceDueDateChangedOnlyOwned,
		} = this.props.viewer.personNotificationSettings;
		return (
			invoiceDueDateOnlyOwned &&
			invoiceOverdueOnlyOwned &&
			invoiceDaysOverdueOnlyOwned &&
			invoiceCreatedOrDeletedOnlyOwned &&
			invoiceStatusChangeOnlyOwned &&
			invoiceDateReachedOnlyOwned &&
			invoicePaymentOnlyOwned &&
			invoiceDateChangedOnlyOwned &&
			invoiceDueDateChangedOnlyOwned
		);
	}

	getToggleInvoiceChildrenState(inApp) {
		const {
			notifyInAppOnInvoiceDueDate,
			notifyInAppOnInvoiceOverdue,
			notifyInAppOnInvoiceDaysOverdue,
			notifyInAppOnInvoiceCreatedOrDeleted,
			notifyInAppOnInvoiceStatusChange,
			notifyInAppOnInvoiceDateReached,
			notifyInAppOnInvoicePayment,
			notifyInAppOnInvoiceDateChanged,
			notifyInAppOnInvoiceDueDateChanged,
			notifyOnInvoiceDueDate,
			notifyOnInvoiceOverdue,
			notifyOnInvoiceDaysOverdue,
			notifyOnInvoiceCreatedOrDeleted,
			notifyOnInvoiceStatusChange,
			notifyOnInvoiceDateReached,
			notifyOnInvoicePayment,
			notifyOnInvoiceDateChanged,
			notifyOnInvoiceDueDateChanged,
		} = this.props.viewer;

		if (inApp) {
			return (
				notifyInAppOnInvoiceDueDate &&
				notifyInAppOnInvoiceOverdue &&
				notifyInAppOnInvoiceDaysOverdue &&
				notifyInAppOnInvoiceCreatedOrDeleted &&
				notifyInAppOnInvoiceStatusChange &&
				notifyInAppOnInvoiceDateReached &&
				notifyInAppOnInvoicePayment &&
				notifyInAppOnInvoiceDateChanged &&
				notifyInAppOnInvoiceDueDateChanged
			);
		} else {
			return (
				notifyOnInvoiceDueDate &&
				notifyOnInvoiceOverdue &&
				notifyOnInvoiceDaysOverdue &&
				notifyOnInvoiceCreatedOrDeleted &&
				notifyOnInvoiceStatusChange &&
				notifyOnInvoiceDateReached &&
				notifyOnInvoicePayment &&
				notifyOnInvoiceDateChanged &&
				notifyOnInvoiceDueDateChanged
			);
		}
	}

	handleToggleInvoiceChildren(advancedColumnData, inApp) {
		const notifyOn = inApp ? NOTIFICATION_PREFIX.NOTIFY_IN_APP_ON : NOTIFICATION_PREFIX.NOTIFY_ON;
		const params = {};

		advancedColumnData.forEach(data => {
			const notifToChange = notifyOn + data.type;
			params[notifToChange] = inApp ? !this.state.invoiceEnableAllInAppChildren : !this.state.invoiceEnableAllChildren;
		});

		if (inApp) {
			this.setState({invoiceEnableAllInAppChildren: !this.state.invoiceEnableAllInAppChildren});
		} else {
			this.setState({invoiceEnableAllChildren: !this.state.invoiceEnableAllChildren});
		}

		Util.CommitMutation(UpdateViewerMutation, params);
	}

	handleTeamsToggleAll(advancedColumnData) {
		advancedColumnData.forEach(data => {
			const notifToChange = NOTIFICATION_PREFIX.NOTIFY_ON + data.type;
			this.handleTeamsToggle(notifToChange, !this.state.invoiceEnableAllChildrenTeams);
		});

		this.setState({invoiceEnableAllChildrenTeams: !this.state.invoiceEnableAllChildrenTeams});
	}

	handleTeamsToggle(teamNotification, valueOverride) {
		let value = !this.props.viewer.teamsNotificationSettings[teamNotification];
		// When called from the toggle all switch, we are not toggling this value, but
		// setting it to the value of the parent switch.
		if (valueOverride !== undefined) {
			value = valueOverride;
		}
		Util.CommitMutation(
			SetIntegrationNotificationSettingMutation,
			{
				teamSetting: `msteams_${teamNotification}`,
				personId: this.props.viewer.actualPersonId,
				value: value,
			},
			() => this.props.retry()
		);
	}

	handleSlackToggleAll(advancedColumnData) {
		advancedColumnData.forEach(data => {
			const notifToChange = NOTIFICATION_PREFIX.NOTIFY_ON + data.type;
			this.handleSlackToggle(notifToChange, !this.state.invoiceEnableAllChildrenSlack);
		});

		this.setState({invoiceEnableAllChildrenSlack: !this.state.invoiceEnableAllChildrenSlack});
	}

	handleSlackToggle(slackNotification, valueOverride) {
		let value = !this.props.viewer.slackNotificationSettings[slackNotification];
		if (valueOverride !== undefined) {
			value = valueOverride;
		}

		Util.CommitMutation(
			SetSlackNotificationSettingMutation,
			{
				setting: `slack_${slackNotification}`,
				personId: this.props.viewer.actualPersonId,
				value: value,
			},
			() => this.props.retry()
		);
	}

	handleIsOnlyOwnedToggleAll(advancedColumnData) {
		const params = {};

		advancedColumnData.forEach(data => {
			params[data.typeIsOnlyOwned] = !this.state.invoiceEnableAllChildrenIsOnlyOwned;
		});

		this.setState({invoiceEnableAllChildrenIsOnlyOwned: !this.state.invoiceEnableAllChildrenIsOnlyOwned});
		Util.CommitMutation(UpdateViewerNotificationSettingsMutation, params);
	}

	handleAdvancedToggle(notification, inApp) {
		const notifyOn = inApp ? NOTIFICATION_PREFIX.NOTIFY_IN_APP_ON : NOTIFICATION_PREFIX.NOTIFY_ON;
		const params = {};
		const notifToChange = notifyOn + notification;
		params[notifToChange] = !this.props.viewer[notifToChange];
		Util.CommitMutation(UpdateViewerMutation, params);
	}

	handleInvoiceAdvancedToggle(notification, inApp) {
		const notifyOn = inApp ? NOTIFICATION_PREFIX.NOTIFY_IN_APP_ON : NOTIFICATION_PREFIX.NOTIFY_ON;
		const params = {};
		const notifToChange = notifyOn + notification;
		params[notifToChange] = !this.props.viewer[notifToChange];
		Util.CommitMutation(UpdateViewerMutation, params, () => {
			this.setState({
				invoiceEnableAllInAppChildren: this.getToggleInvoiceChildrenState(true),
				invoiceEnableAllChildren: this.getToggleInvoiceChildrenState(false),
				invoiceEnableAllChildrenTeams: this.getToggleInvoiceChildrenStateSlackTeams('teamsNotificationSettings'),
				invoiceEnableAllChildrenSlack: this.getToggleInvoiceChildrenStateSlackTeams('slackNotificationSettings'),
				invoiceEnableAllChildrenIsOnlyOwned: this.getToggleInvoiceChildrenStateIsOnlyOwned(),
			});
		});
	}

	toggleAdvancedSettings() {
		this.setState({isAdvancedVisible: !this.state.isAdvancedVisible});
	}

	toggleInvoiceAdvancedSettings() {
		this.setState({isInvoiceAdvancedVisible: !this.state.isInvoiceAdvancedVisible});
	}

	handleNotificationValueChange(type, value) {
		this.setState({[type]: value});
	}

	handleNotificationValueBlur(type) {
		//Clamp value between 0 and 99 because lower than 0 makes no sense and 99+ looks ugly
		let inputValue = parseFloat(this.state[type]);
		if (isNaN(inputValue)) {
			inputValue = 0;
		} else if (inputValue < 0) {
			inputValue = 0;
		} else if (inputValue > 99) {
			inputValue = 99;
		} else {
			inputValue = Math.floor(inputValue);
		}
		this.setState({[type]: inputValue});
		const mutationParams = {
			[type]: inputValue,
		};
		Util.CommitMutation(UpdateViewerMutation, mutationParams);
	}

	handleNotificationFrequencyChange(input) {
		this.setState({notificationFrequency: input.value});
		const mutationParams = {
			notificationFrequency: input.value,
		};
		Util.CommitMutation(UpdateViewerMutation, mutationParams);
	}

	handleStageChangedOnlyAssignedToggle() {
		Util.CommitMutation(UpdateViewerNotificationSettingsMutation, {
			stateChangedOnlyAssignedProjects: !this.props.viewer.personNotificationSettings.stateChangedOnlyAssignedProjects,
		});
	}

	handleProjectDeadlineOnlyAssignedToggle() {
		Util.CommitMutation(UpdateViewerNotificationSettingsMutation, {
			projectDeadlineOnlyAssignedProjects:
				!this.props.viewer.personNotificationSettings.projectDeadlineOnlyAssignedProjects,
		});
	}

	handleInvoiceOnlyOwnedToggle(type) {
		const params = {};
		params[type] = !this.props.viewer.personNotificationSettings[type];

		Util.CommitMutation(UpdateViewerNotificationSettingsMutation, params, () => {
			this.setState({
				invoiceEnableAllChildrenIsOnlyOwned: this.getToggleInvoiceChildrenStateIsOnlyOwned(),
			});
		});
	}

	getTopHeader() {
		const {formatMessage} = this.props.intl;
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={formatMessage({id: 'header.notifications'})} />
			</TopHeaderBarWrapper>
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const viewer = this.props.viewer;
		const notificationFrequencyOptions = [
			{
				value: NOTIFICATION_FREQUENCY.INSTANT,
				label: formatMessage({id: 'profile.notifications.frequency_instant'}),
			},
			{value: NOTIFICATION_FREQUENCY.DAILY, label: formatMessage({id: 'profile.notifications.frequency_daily'})},
			{
				value: NOTIFICATION_FREQUENCY.WEEKLY,
				label: formatMessage({id: 'profile.notifications.frequency_weekly'}),
			},
		];
		const hasReadAllProjects = hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL);
		const isSageRestricted = hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);

		const taskPart1 = formatMessage({id: 'profile.notifications.notify_on_task_deadline_part_1'});
		const projectPart1 = formatMessage({id: 'profile.notifications.notify_on_project_deadline_part_1'});

		let firstSectionData = [
			{
				type: 'notifyOnProjectStatusChange',
				typeInApp: 'notifyInAppOnProjectStatusChange',
				description: formatMessage({id: 'profile.notifications.notify_on_project_status_change'}),
				isOnlyAssigned: viewer.personNotificationSettings.stateChangedOnlyAssignedProjects,
				onlyAssignedHandler: hasReadAllProjects ? this.handleStageChangedOnlyAssignedToggle.bind(this) : null,
			},
			{
				type: 'notifyOnAssignedProject',
				typeInApp: 'notifyInAppOnAssignedProject',
				description: formatMessage({id: 'profile.notifications.notify_on_assigned_to_project'}),
			},
			{
				type: 'notifyOnProjectDeadline',
				typeInApp: 'notifyInAppOnProjectDeadline',
				description: (
					<div className={'input-section'}>
						{projectPart1.length > 1 ? <div className={'first-part'}>{projectPart1}</div> : null}
						<InputField
							id={'project'}
							hideLabel={true}
							ref={input => (this.inputs['project'] = input)}
							type="number"
							value={this.state.projectDeadlineNotificationPeriod}
							disabled={viewer.disableNotifications || !viewer.notifyOnProjectDeadline}
							onChange={this.handleNotificationValueChange.bind(this, 'projectDeadlineNotificationPeriod')}
							onBlur={this.handleNotificationValueBlur.bind(this, 'projectDeadlineNotificationPeriod')}
						/>
						<div className={'second-part'}>
							{formatMessage({id: 'profile.notifications.notify_on_project_deadline_part_2'})}
						</div>
					</div>
				),
				isOnlyAssigned: viewer.personNotificationSettings.projectDeadlineOnlyAssignedProjects,
				onlyAssignedHandler: hasReadAllProjects ? this.handleProjectDeadlineOnlyAssignedToggle.bind(this) : null,
			},
			{
				type: 'notifyOnTaskDeadline',
				typeInApp: 'notifyInAppOnTaskDeadline',
				description: (
					<div className={'input-section'}>
						{taskPart1.length > 1 ? <div className={'first-part'}>{taskPart1}</div> : null}
						<InputField
							id={'task'}
							hideLabel={true}
							ref={input => (this.inputs['task'] = input)}
							type="number"
							value={this.state.taskDeadlineNotificationPeriod}
							disabled={viewer.disableNotifications || !viewer.notifyOnProjectDeadline}
							onChange={this.handleNotificationValueChange.bind(this, 'taskDeadlineNotificationPeriod')}
							onBlur={this.handleNotificationValueBlur.bind(this, 'taskDeadlineNotificationPeriod')}
						/>
						<div className={'second-part'}>
							{formatMessage({id: 'profile.notifications.notify_on_task_deadline_part_2'})}
						</div>
					</div>
				),
			},
			{
				type: 'notifyOnMention',
				typeInApp: 'notifyInAppOnMention',
				description: formatMessage({id: 'profile.notifications.notify_on_mention'}),
			},
			{
				type: 'notifyOnAssignedTask',
				typeInApp: 'notifyInAppOnAssignedTask',
				description: formatMessage({id: 'profile.notifications.notify_on_assigned_to_task'}),
			},
		];

		if (hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS)) {
			firstSectionData.unshift({
				type: 'notifyOnPersonJoin',
				adminOnly: true,
				typeInApp: 'notifyInAppOnPersonJoin',
				description: formatMessage({id: 'profile.notifications.notify_on_person_join_company'}),
			});
		}

		const useTimeOffApproval = this.props.viewer.company.useTimeOffApproval;
		if (
			hasFeatureFlag('pto_timesheet_allocation_linking') &&
			hasTimeApproval() &&
			hasPermission(PERMISSION_TYPE.TIME_REGISTRATION_CREATE_ALL)
		) {
			firstSectionData.push({
				type: 'notifyOnTimeOffManager',
				typeInApp: 'notifyInAppOnTimeOffManager',
				description: formatMessage({
					id: useTimeOffApproval
						? 'profile.notifications.notify_on_request_time_off'
						: 'profile.notifications.notify_on_register_time_off',
				}),
			});
		}

		if (hasFeatureFlag('pto_timesheet_allocation_linking') && hasTimeApproval()) {
			firstSectionData.push({
				type: 'notifyOnTimeOffOwner',
				typeInApp: 'notifyInAppOnTimeOffOwner',
				description: formatMessage({
					id: 'profile.notifications.notify_on_time_off_request_status_change',
				}),
			});
		}

		const teamsEnabled = this.props.viewer.company.teamsEnabled;

		const slackEnabled = this.props.viewer.company.newSlackEnabled && this.props.viewer.slackId;

		const advancedTaskColumnData = [
			{
				type: 'TaskDescriptionChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_description_change'}),
			},
			{
				type: 'TaskTitleChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_title_change'}),
			},
			{
				type: 'TaskEstimateChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_estimate_change'}),
			},
			{
				type: 'TaskStartDateChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_start_date_change'}),
			},
			{
				type: 'TaskEndDateChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_end_date_change'}),
			},
			{
				type: 'TaskSprintChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_sprint_change'}),
			},
			{
				type: 'TaskPhaseChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_phase_change'}),
			},
			{
				type: 'TaskStatusColumnChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_status_column_change'}),
			},
			{
				type: 'TaskProjectChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_project_change'}),
			},
			{
				type: 'TaskSubtaskChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_subtask_change'}),
			},
			{
				type: 'TaskSubtaskEstimateChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_subtask_estimate_change'}),
			},
			{
				type: 'TaskCommentChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_comment_change'}),
			},
			{
				type: 'TaskFileChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_file_change'}),
			},
			{type: 'TaskBugChange', description: formatMessage({id: 'profile.notifications.notify_on_task_bug_change'})},
			{
				type: 'TaskBlockedChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_blocked_change'}),
			},
			{
				type: 'TaskRepeatingChange',
				description: formatMessage({id: 'profile.notifications.notify_on_task_repeating_change'}),
			},
		];

		const advancedInvoiceColumnData = [
			{
				type: 'InvoiceDueDate',
				description: 'When the due date on an invoice is reached',
				isOnlyOwned: viewer.personNotificationSettings.invoiceDueDateOnlyOwned,
				typeIsOnlyOwned: 'invoiceDueDateOnlyOwned',
				onlyOwnedHandler: hasReadAllProjects
					? this.handleInvoiceOnlyOwnedToggle.bind(this, 'invoiceDueDateOnlyOwned')
					: null,
			},
			{
				type: 'InvoiceOverdue',
				description: 'When an invoice becomes overdue',
				isOnlyOwned: viewer.personNotificationSettings.invoiceOverdueOnlyOwned,
				typeIsOnlyOwned: 'invoiceOverdueOnlyOwned',
				onlyOwnedHandler: hasReadAllProjects
					? this.handleInvoiceOnlyOwnedToggle.bind(this, 'invoiceOverdueOnlyOwned')
					: null,
			},
			{
				type: 'InvoiceDaysOverdue',
				description: (
					<div className={'input-section'}>
						<div className={'first-part'}>When an invoice is</div>
						<InputField
							id={'project'}
							hideLabel={true}
							ref={input => (this.inputs['project'] = input)}
							type="number"
							value={this.state.invoiceNotificationDaysOverdue}
							disabled={viewer.disableNotifications || !viewer.notifyOnProjectDeadline}
							onChange={this.handleNotificationValueChange.bind(this, 'invoiceNotificationDaysOverdue')}
							onBlur={this.handleNotificationValueBlur.bind(this, 'invoiceNotificationDaysOverdue')}
						/>
						<div className={'second-part'}> days overdue</div>
					</div>
				),
				isOnlyOwned: viewer.personNotificationSettings.invoiceDaysOverdueOnlyOwned,
				typeIsOnlyOwned: 'invoiceDaysOverdueOnlyOwned',
				onlyOwnedHandler: hasReadAllProjects
					? this.handleInvoiceOnlyOwnedToggle.bind(this, 'invoiceDaysOverdueOnlyOwned')
					: null,
			},
			{
				type: 'InvoiceCreatedOrDeleted',
				description: 'When an invoice is created or deleted',
				isOnlyOwned: viewer.personNotificationSettings.invoiceCreatedOrDeletedOnlyOwned,
				typeIsOnlyOwned: 'invoiceCreatedOrDeletedOnlyOwned',
				onlyOwnedHandler: hasReadAllProjects
					? this.handleInvoiceOnlyOwnedToggle.bind(this, 'invoiceCreatedOrDeletedOnlyOwned')
					: null,
			},
			{
				type: 'InvoiceStatusChange',
				description: 'When an invoice status changes',
				isOnlyOwned: viewer.personNotificationSettings.invoiceStatusChangeOnlyOwned,
				typeIsOnlyOwned: 'invoiceStatusChangeOnlyOwned',
				onlyOwnedHandler: hasReadAllProjects
					? this.handleInvoiceOnlyOwnedToggle.bind(this, 'invoiceStatusChangeOnlyOwned')
					: null,
			},
			{
				type: 'InvoiceDateReached',
				description: 'When the invoice date is reached for an invoice',
				isOnlyOwned: viewer.personNotificationSettings.invoiceDateReachedOnlyOwned,
				typeIsOnlyOwned: 'invoiceDateReachedOnlyOwned',
				onlyOwnedHandler: hasReadAllProjects
					? this.handleInvoiceOnlyOwnedToggle.bind(this, 'invoiceDateReachedOnlyOwned')
					: null,
			},
			{
				type: 'InvoicePayment',
				description: 'When a payment is made on an invoice',
				isOnlyOwned: viewer.personNotificationSettings.invoicePaymentOnlyOwned,
				typeIsOnlyOwned: 'invoicePaymentOnlyOwned',
				onlyOwnedHandler: hasReadAllProjects
					? this.handleInvoiceOnlyOwnedToggle.bind(this, 'invoicePaymentOnlyOwned')
					: null,
			},
			{
				type: 'InvoiceDateChanged',
				description: 'When an invoice date is changed',
				isOnlyOwned: viewer.personNotificationSettings.invoiceDateChangedOnlyOwned,
				typeIsOnlyOwned: 'invoiceDateChangedOnlyOwned',
				onlyOwnedHandler: hasReadAllProjects
					? this.handleInvoiceOnlyOwnedToggle.bind(this, 'invoiceDateChangedOnlyOwned')
					: null,
			},
			{
				type: 'InvoiceDueDateChanged',
				description: 'When an invoice due date is changed',
				isOnlyOwned: viewer.personNotificationSettings.invoiceDueDateChangedOnlyOwned,
				typeIsOnlyOwned: 'invoiceDueDateChangedOnlyOwned',
				onlyOwnedHandler: hasReadAllProjects
					? this.handleInvoiceOnlyOwnedToggle.bind(this, 'invoiceDueDateChangedOnlyOwned')
					: null,
			},
		];

		return (
			<div className="settings-notifications v2" data-cy={'notification-settings-page'}>
				{this.props.children}
				<div className="master-section">
					<div className="section-header">
						<div className="column-description">
							{formatMessage({id: 'profile.notifications.notification_types'})}
						</div>
					</div>
					<div className="slider-container">
						<div className="description">
							{formatMessage({id: 'profile.notifications.enable_app_notifications'})}
						</div>
						<Switch
							onChange={this.handleToggle.bind(this, 'disableInAppNotifications')}
							checked={!viewer.disableInAppNotifications}
							sliderWidth={38}
							sliderHeight={20}
						/>
					</div>
					{!isSageRestricted ? (
						<div className="slider-container">
							<div className="description">
								{formatMessage({id: 'profile.notifications.enable_email_notifications'})}
							</div>
							<Switch
								onChange={this.handleToggle.bind(this, 'disableNotifications')}
								checked={!viewer.disableNotifications}
								sliderWidth={38}
								sliderHeight={20}
							/>
						</div>
					) : (
						''
					)}
					{teamsEnabled ? (
						<div className="slider-container">
							<div className="description">
								{formatMessage({id: 'profile.notifications.enable_teams_notifications'})}
							</div>
							<Switch
								onChange={() => this.handleTeamsToggle('disableNotifications')}
								checked={!viewer.teamsNotificationSettings.disableNotifications}
								sliderWidth={38}
								sliderHeight={20}
							/>
						</div>
					) : (
						''
					)}
					{slackEnabled ? (
						<div className="slider-container">
							<div className="description">
								{formatMessage({id: 'profile.notifications.enable_slack_notifications'})}
							</div>
							<Switch
								onChange={() => this.handleSlackToggle('disableNotifications')}
								checked={!viewer.slackNotificationSettings.disableNotifications}
								sliderWidth={38}
								sliderHeight={20}
							/>
						</div>
					) : (
						''
					)}
				</div>
				<div className="second-section">
					<div className="section-header">
						<div className="column-description">{formatMessage({id: 'profile.notifications.notify_me'})}</div>
						<div className="column-app">{formatMessage({id: 'common.app'})}</div>
						{!isSageRestricted ? <div className="column-email">{formatMessage({id: 'common.email'})}</div> : ''}
						{teamsEnabled ? (
							<div className="column-email">{formatMessage({id: 'common.microsoft_teams'})}</div>
						) : (
							''
						)}
						{slackEnabled ? <div className="column-email">Slack</div> : ''}
						{hasReadAllProjects ? (
							<div className="column-assigned-projects">
								<ForecastTooltip
									content={
										<ForecastTooltipFormulaRenderer
											items={[
												{
													description: formatMessage({
														id: 'profile.notifications.only_assigned_tooltip_desc_1',
													}),
												},
												{
													description: formatMessage({
														id: 'profile.notifications.only_assigned_tooltip_desc_2',
													}),
												},
											]}
											translatedMessage={true}
										/>
									}
								>
									<div>Only assigned projects</div>
								</ForecastTooltip>
							</div>
						) : null}
					</div>
					{firstSectionData.map((item, index) => {
						return (
							<div key={'first-section-item-' + index} className="slider-container">
								<div className="column-description">{item.description}</div>
								<div className="column-app">
									<Switch
										locked={viewer.disableInAppNotifications}
										onChange={this.handleToggle.bind(this, item.typeInApp)}
										checked={viewer[item.typeInApp] && !viewer.disableInAppNotifications}
										sliderWidth={38}
										sliderHeight={20}
									/>
								</div>
								{!isSageRestricted ? (
									<div className="column-email">
										<Switch
											locked={viewer.disableNotifications}
											onChange={this.handleToggle.bind(this, item.type)}
											checked={viewer[item.type] && !viewer.disableNotifications}
											sliderWidth={38}
											sliderHeight={20}
										/>
									</div>
								) : (
									''
								)}
								{teamsEnabled ? (
									<div className="column-email">
										<Switch
											locked={viewer.teamsNotificationSettings.disableNotifications}
											onChange={this.handleTeamsToggle.bind(this, item.type)}
											checked={
												viewer.teamsNotificationSettings[item.type] &&
												!viewer.teamsNotificationSettings.disableNotifications
											}
											sliderWidth={38}
											sliderHeight={20}
										/>
									</div>
								) : (
									''
								)}
								{slackEnabled ? (
									<div className="column-email">
										<Switch
											locked={viewer.slackNotificationSettings.disableNotifications}
											onChange={this.handleSlackToggle.bind(this, item.type)}
											checked={
												viewer.slackNotificationSettings[item.type] &&
												!viewer.slackNotificationSettings.disableNotifications
											}
											sliderWidth={38}
											sliderHeight={20}
										/>
									</div>
								) : (
									''
								)}
								{item.onlyAssignedHandler ? (
									<div className="column-assigned-projects">
										<ForecastTooltip
											content={
												<ForecastTooltipFormulaRenderer
													items={[
														{
															description: formatMessage({
																id: 'profile.notifications.only_assigned_tooltip_desc_1',
															}),
														},
														{
															description: formatMessage({
																id: 'profile.notifications.only_assigned_tooltip_desc_2',
															}),
														},
													]}
													translatedMessage={true}
												/>
											}
										>
											<Checkbox checked={item.isOnlyAssigned} onChange={item.onlyAssignedHandler} />
										</ForecastTooltip>
									</div>
								) : (
									<div className="column-assigned-projects" />
								)}
							</div>
						);
					})}
				</div>
				<div className="advanced-section">
					<div className="section-header">
						<div className="column-description">Task change updates</div>
						<div className="column-app">{formatMessage({id: 'common.app'})}</div>
						{!isSageRestricted ? <div className="column-email">{formatMessage({id: 'common.email'})}</div> : ''}
						{teamsEnabled ? (
							<div className="column-email">{formatMessage({id: 'common.microsoft_teams'})}</div>
						) : (
							''
						)}
						{slackEnabled ? <div className="column-email">Slack</div> : ''}
					</div>
					<div className="first-advanced-section">
						<div className={'slider-section'}>
							<div className="column-description">
								{formatMessage({id: 'profile.notifications.notify_on_task_update'})}
							</div>
							<div className="column-app">
								<Switch
									locked={viewer.disableInAppNotifications}
									onChange={this.handleToggle.bind(this, 'notifyInAppOnAssignedTaskUpdate')}
									checked={viewer.notifyInAppOnAssignedTaskUpdate && !viewer.disableInAppNotifications}
									sliderWidth={38}
									sliderHeight={20}
								/>
							</div>
							{!isSageRestricted ? (
								<div className="column-email">
									<Switch
										locked={viewer.disableNotifications}
										onChange={this.handleToggle.bind(this, 'notifyOnAssignedTaskUpdate')}
										checked={viewer.notifyOnAssignedTaskUpdate && !viewer.disableNotifications}
										sliderWidth={38}
										sliderHeight={20}
									/>
								</div>
							) : (
								''
							)}
							{teamsEnabled ? (
								<div className="column-email">
									<Switch
										locked={viewer.teamsNotificationSettings.disableNotifications}
										onChange={this.handleTeamsToggle.bind(this, 'notifyOnAssignedTaskUpdate')}
										checked={
											viewer.teamsNotificationSettings.notifyOnAssignedTaskUpdate &&
											!viewer.teamsNotificationSettings.disableNotifications
										}
										sliderWidth={38}
										sliderHeight={20}
									/>
								</div>
							) : (
								''
							)}
							{slackEnabled ? (
								<div className="column-email">
									<Switch
										locked={viewer.slackNotificationSettings.disableNotifications}
										onChange={this.handleSlackToggle.bind(this, 'notifyOnAssignedTaskUpdate')}
										checked={
											viewer.slackNotificationSettings.notifyOnAssignedTaskUpdate &&
											!viewer.slackNotificationSettings.disableNotifications
										}
										sliderWidth={38}
										sliderHeight={20}
									/>
								</div>
							) : (
								''
							)}
						</div>
					</div>
					{viewer.notifyOnAssignedTaskUpdate && !viewer.disableNotifications ? (
						<FrequencySection>
							<div style={{width: '350px'}}>Task change email frequency</div>
							{notificationFrequencyOptions.map((item, index) => {
								return (
									<RadioButtonContainer>
										<div style={{marginRight: '4px'}}>
											<RadioButton
												onSelected={this.handleNotificationFrequencyChange.bind(this, item)}
												stateValue={this.props.viewer.notificationFrequency}
												value={item.value}
											/>
										</div>
										<div>{item.label}</div>
									</RadioButtonContainer>
								);
							})}
						</FrequencySection>
					) : null}
					<button
						className={'show-advanced-settings-button ' + (this.state.isAdvancedVisible ? 'expanded' : 'collapsed')}
						onClick={this.toggleAdvancedSettings.bind(this)}
					>
						{this.state.isAdvancedVisible
							? formatMessage({id: 'profile.notifications.hide_advanced_settings'})
							: formatMessage({id: 'profile.notifications.show_advanced_settings'})}
					</button>

					{this.state.isAdvancedVisible ? (
						<div className="second-advanced-section">
							<div className="advanced-settings-description">
								{formatMessage({id: 'profile.notifications.selected_task_notification_settings'})}
							</div>
							{advancedTaskColumnData.map((item, index) => (
								<div key={'first-column-item-task-' + index} className="slider-container">
									<div className="column-description">{item.description}</div>
									<div className="column-app">
										<Switch
											locked={!viewer.notifyInAppOnAssignedTaskUpdate || viewer.disableInAppNotifications}
											onChange={this.handleAdvancedToggle.bind(this, item.type, true)}
											checked={
												viewer.notifyInAppOnAssignedTaskUpdate &&
												viewer[NOTIFICATION_PREFIX.NOTIFY_IN_APP_ON + item.type] &&
												!viewer.disableInAppNotifications
											}
											sliderWidth={38}
											sliderHeight={20}
										/>
									</div>
									{!isSageRestricted ? (
										<div className="column-email">
											<Switch
												locked={!viewer.notifyOnAssignedTaskUpdate || viewer.disableNotifications}
												onChange={this.handleAdvancedToggle.bind(this, item.type, false)}
												checked={
													viewer.notifyOnAssignedTaskUpdate &&
													viewer[NOTIFICATION_PREFIX.NOTIFY_ON + item.type] &&
													!viewer.disableNotifications
												}
												sliderWidth={38}
												sliderHeight={20}
											/>
										</div>
									) : (
										''
									)}
									{teamsEnabled ? (
										<div className="column-email">
											<Switch
												locked={
													!viewer.teamsNotificationSettings.notifyOnAssignedTaskUpdate ||
													viewer.teamsNotificationSettings.disableNotifications
												}
												onChange={this.handleTeamsToggle.bind(
													this,
													NOTIFICATION_PREFIX.NOTIFY_ON + item.type
												)}
												checked={
													viewer.teamsNotificationSettings.notifyOnAssignedTaskUpdate &&
													viewer.teamsNotificationSettings[
														NOTIFICATION_PREFIX.NOTIFY_ON + item.type
													] &&
													!viewer.teamsNotificationSettings.disableNotifications
												}
												sliderWidth={38}
												sliderHeight={20}
											/>
										</div>
									) : (
										''
									)}
									{slackEnabled ? (
										<div className="column-email">
											<Switch
												locked={
													!viewer.slackNotificationSettings.notifyOnAssignedTaskUpdate ||
													viewer.slackNotificationSettings.disableNotifications
												}
												onChange={this.handleSlackToggle.bind(
													this,
													NOTIFICATION_PREFIX.NOTIFY_ON + item.type,
													false
												)}
												checked={
													viewer.slackNotificationSettings.notifyOnAssignedTaskUpdate &&
													viewer.slackNotificationSettings[
														NOTIFICATION_PREFIX.NOTIFY_ON + item.type
													] &&
													!viewer.slackNotificationSettings.disableNotifications
												}
												sliderWidth={38}
												sliderHeight={20}
											/>
										</div>
									) : (
										''
									)}
								</div>
							))}
						</div>
					) : (
						<div className="second-advanced-section" />
					)}
				</div>
				{this.hasInvoiceNotificationsFeature && hasPermission(PERMISSION_TYPE.INVOICE_READ) && (
					<div className="advanced-section">
						<div className="section-header">
							<div className="column-description">
								{formatMessage({id: 'profile.notifications.financial_section'})}
							</div>
							<div className="column-app">{formatMessage({id: 'common.app'})}</div>
							{!isSageRestricted ? <div className="column-email">{formatMessage({id: 'common.email'})}</div> : ''}
							{teamsEnabled ? (
								<div className="column-email">{formatMessage({id: 'common.microsoft_teams'})}</div>
							) : (
								''
							)}
							{slackEnabled ? <div className="column-email">Slack</div> : ''}

							{this.hasInvoiceNotificationsFeature && hasReadAllProjects ? (
								<div className="column-owned-projects">
									<ForecastTooltip
										content={
											<ForecastTooltipFormulaRenderer
												items={[
													{
														description: formatMessage({
															id: 'profile.notifications.only_owned_tooltip_desc_1',
														}),
													},
													{
														description: formatMessage({
															id: 'profile.notifications.only_owned_tooltip_desc_2',
														}),
													},
												]}
												translatedMessage={true}
											/>
										}
									>
										<div>Only projects I own</div>
									</ForecastTooltip>
								</div>
							) : null}
						</div>
						<div className="first-advanced-section">
							<div className={'slider-section'}>
								<div className="column-description">
									{formatMessage({id: 'profile.notifications.notify_on_invoice_update'})}
								</div>
								<div className="column-app">
									<Switch
										locked={viewer.disableInAppNotifications}
										onChange={this.handleToggleInvoiceChildren.bind(this, advancedInvoiceColumnData, true)}
										checked={this.state.invoiceEnableAllInAppChildren}
										sliderWidth={38}
										sliderHeight={20}
									/>
								</div>

								{!isSageRestricted ? (
									<div className="column-email">
										<Switch
											locked={viewer.disableNotifications}
											onChange={this.handleToggleInvoiceChildren.bind(
												this,
												advancedInvoiceColumnData,
												false
											)}
											checked={this.state.invoiceEnableAllChildren}
											sliderWidth={38}
											sliderHeight={20}
										/>
									</div>
								) : (
									''
								)}
								{teamsEnabled ? (
									<div className="column-email">
										<Switch
											locked={viewer.teamsNotificationSettings.disableNotifications}
											onChange={this.handleTeamsToggleAll.bind(this, advancedInvoiceColumnData)}
											checked={this.state.invoiceEnableAllChildrenTeams}
											sliderWidth={38}
											sliderHeight={20}
										/>
									</div>
								) : (
									''
								)}
								{slackEnabled ? (
									<div className="column-email">
										<Switch
											locked={viewer.slackNotificationSettings.disableNotifications}
											onChange={this.handleSlackToggleAll.bind(this, advancedInvoiceColumnData)}
											checked={this.state.invoiceEnableAllChildrenSlack}
											sliderWidth={38}
											sliderHeight={20}
										/>
									</div>
								) : (
									''
								)}

								{this.hasInvoiceNotificationsFeature && hasReadAllProjects ? (
									<div className="column-owned-projects">
										<Checkbox
											onChange={this.handleIsOnlyOwnedToggleAll.bind(this, advancedInvoiceColumnData)}
											checked={this.state.invoiceEnableAllChildrenIsOnlyOwned}
										/>
									</div>
								) : (
									''
								)}
							</div>
						</div>

						<button
							className={
								'show-advanced-settings-button ' +
								(this.state.isInvoiceAdvancedVisible ? 'expanded' : 'collapsed')
							}
							onClick={this.toggleInvoiceAdvancedSettings.bind(this)}
						>
							{this.state.isInvoiceAdvancedVisible
								? formatMessage({id: 'profile.notifications.hide_advanced_settings'})
								: formatMessage({id: 'profile.notifications.show_advanced_settings'})}
						</button>

						{this.state.isInvoiceAdvancedVisible ? (
							<div className="second-advanced-section">
								<div className="advanced-settings-description">
									{formatMessage({id: 'profile.notifications.selected_invoice_notification_settings'})}
								</div>
								{advancedInvoiceColumnData.map((item, index) => (
									<div key={'first-column-item-invoice-' + index} className="slider-container">
										<div className="column-description">{item.description}</div>
										<div className="column-app">
											<Switch
												locked={viewer.disableInAppNotifications}
												onChange={this.handleInvoiceAdvancedToggle.bind(this, item.type, true)}
												checked={
													viewer[NOTIFICATION_PREFIX.NOTIFY_IN_APP_ON + item.type] &&
													!viewer.disableInAppNotifications
												}
												sliderWidth={38}
												sliderHeight={20}
											/>
										</div>
										{!isSageRestricted ? (
											<div className="column-email">
												<Switch
													locked={viewer.disableNotifications}
													onChange={this.handleInvoiceAdvancedToggle.bind(this, item.type, false)}
													checked={
														viewer[NOTIFICATION_PREFIX.NOTIFY_ON + item.type] &&
														!viewer.disableNotifications
													}
													sliderWidth={38}
													sliderHeight={20}
												/>
											</div>
										) : (
											''
										)}
										{teamsEnabled ? (
											<div className="column-email">
												<Switch
													locked={viewer.teamsNotificationSettings.disableNotifications}
													onChange={this.handleTeamsToggle.bind(
														this,
														NOTIFICATION_PREFIX.NOTIFY_ON + item.type
													)}
													checked={
														viewer.teamsNotificationSettings[
															NOTIFICATION_PREFIX.NOTIFY_ON + item.type
														] && !viewer.teamsNotificationSettings.disableNotifications
													}
													sliderWidth={38}
													sliderHeight={20}
												/>
											</div>
										) : (
											''
										)}
										{slackEnabled ? (
											<div className="column-email">
												<Switch
													locked={viewer.slackNotificationSettings.disableNotifications}
													onChange={this.handleSlackToggle.bind(
														this,
														NOTIFICATION_PREFIX.NOTIFY_ON + item.type
													)}
													checked={
														viewer.slackNotificationSettings[
															NOTIFICATION_PREFIX.NOTIFY_ON + item.type
														] && !viewer.slackNotificationSettings.disableNotifications
													}
													sliderWidth={38}
													sliderHeight={20}
												/>
											</div>
										) : (
											''
										)}
										{item.onlyOwnedHandler ? (
											<div className="column-owned-projects">
												<ForecastTooltip
													content={
														<ForecastTooltipFormulaRenderer
															items={[
																{
																	description: formatMessage({
																		id: 'profile.notifications.only_owned_tooltip_desc_1',
																	}),
																},
																{
																	description: formatMessage({
																		id: 'profile.notifications.only_owned_tooltip_desc_2',
																	}),
																},
															]}
															translatedMessage={true}
														/>
													}
												>
													<div>
														<Checkbox checked={item.isOnlyOwned} onChange={item.onlyOwnedHandler} />
													</div>
												</ForecastTooltip>
											</div>
										) : (
											''
										)}
									</div>
								))}
							</div>
						) : (
							<div className="second-advanced-section" />
						)}
					</div>
				)}
			</div>
		);
	}
}

const profileNotificationsSettingsQuery = graphql`
	query profileNotificationsSettings_Query {
		viewer {
			actualPersonId
			component(name: "settings_notifications")
			...profileNotificationsSettings_viewer
		}
	}
`;

export {profileNotificationsSettingsQuery};

export default createFragmentContainer(injectIntl(ProfileNotificationsSettings), {
	viewer: graphql`
		fragment profileNotificationsSettings_viewer on Viewer {
			id
			slackId
			availableFeatureFlags {
				key
			}
			company {
				teamsEnabled
				newSlackEnabled
				useTimeOffApproval
			}
			actualPersonId
			notificationFrequency
			notifyOnProjectStatusChange
			notifyOnAssignedProject
			notifyOnPersonJoin
			notifyOnProjectDeadline
			notifyOnAssignedTask
			notifyOnAssignedTaskUpdate
			notifyOnTaskDeadline
			notifyOnMention
			disableNotifications
			projectDeadlineNotificationPeriod
			taskDeadlineNotificationPeriod
			invoiceNotificationDaysOverdue
			notifyOnTaskDescriptionChange
			notifyOnTaskTitleChange
			notifyOnTaskEstimateChange
			notifyOnTaskStartDateChange
			notifyOnTaskEndDateChange
			notifyOnTaskSprintChange
			notifyOnTaskPhaseChange
			notifyOnTaskStatusColumnChange
			notifyOnTaskProjectChange
			notifyOnTaskSubtaskChange
			notifyOnTaskSubtaskEstimateChange
			notifyOnTaskCommentChange
			notifyOnTaskFileChange
			notifyOnTaskBugChange
			notifyOnTaskBlockedChange
			notifyOnTaskRepeatingChange
			notifyOnInvoiceDueDate
			notifyOnInvoiceOverdue
			notifyOnInvoiceDaysOverdue
			notifyOnInvoiceCreatedOrDeleted
			notifyOnInvoiceStatusChange
			notifyOnInvoiceDateReached
			notifyOnInvoicePayment
			notifyOnInvoiceDateChanged
			notifyOnInvoiceDueDateChanged
			notifyOnTimeOffManager
			notifyOnTimeOffOwner
			notifyInAppOnProjectStatusChange
			notifyInAppOnAssignedProject
			notifyInAppOnPersonJoin
			notifyInAppOnProjectDeadline
			notifyInAppOnAssignedTask
			notifyInAppOnAssignedTaskUpdate
			notifyInAppOnTaskDeadline
			notifyInAppOnMention
			disableInAppNotifications
			notifyInAppOnTaskDescriptionChange
			notifyInAppOnTaskTitleChange
			notifyInAppOnTaskEstimateChange
			notifyInAppOnTaskStartDateChange
			notifyInAppOnTaskEndDateChange
			notifyInAppOnTaskSprintChange
			notifyInAppOnTaskPhaseChange
			notifyInAppOnTaskStatusColumnChange
			notifyInAppOnTaskProjectChange
			notifyInAppOnTaskSubtaskChange
			notifyInAppOnTaskSubtaskEstimateChange
			notifyInAppOnTaskCommentChange
			notifyInAppOnTaskFileChange
			notifyInAppOnTaskBugChange
			notifyInAppOnTaskBlockedChange
			notifyInAppOnTaskRepeatingChange
			notifyInAppOnInvoiceDueDate
			notifyInAppOnInvoiceOverdue
			notifyInAppOnInvoiceDaysOverdue
			notifyInAppOnInvoiceCreatedOrDeleted
			notifyInAppOnInvoiceStatusChange
			notifyInAppOnInvoiceDateReached
			notifyInAppOnInvoicePayment
			notifyInAppOnInvoiceDateChanged
			notifyInAppOnInvoiceDueDateChanged
			notifyInAppOnTimeOffManager
			notifyInAppOnTimeOffOwner

			personNotificationSettings {
				id
				projectDeadlineOnlyAssignedProjects
				stateChangedOnlyAssignedProjects
				invoiceDueDateOnlyOwned
				invoiceOverdueOnlyOwned
				invoiceDaysOverdueOnlyOwned
				invoiceCreatedOrDeletedOnlyOwned
				invoiceStatusChangeOnlyOwned
				invoiceDateReachedOnlyOwned
				invoicePaymentOnlyOwned
				invoiceDateChangedOnlyOwned
				invoiceDueDateChangedOnlyOwned
			}
			teamsNotificationSettings {
				disableNotifications
				notifyOnProjectStatusChange
				notifyOnAssignedProject
				notifyOnPersonJoin
				notifyOnProjectDeadline
				notifyOnAssignedTask
				notifyOnAssignedTaskUpdate
				notifyOnTaskDeadline
				notifyOnMention
				notifyOnTaskDescriptionChange
				notifyOnTaskTitleChange
				notifyOnTaskEstimateChange
				notifyOnTaskStartDateChange
				notifyOnTaskEndDateChange
				notifyOnTaskSprintChange
				notifyOnTaskPhaseChange
				notifyOnTaskStatusColumnChange
				notifyOnTaskProjectChange
				notifyOnTaskSubtaskChange
				notifyOnTaskSubtaskEstimateChange
				notifyOnTaskCommentChange
				notifyOnTaskFileChange
				notifyOnTaskBugChange
				notifyOnTaskBlockedChange
				notifyOnTaskRepeatingChange
				notifyOnInvoiceDueDate
				notifyOnInvoiceOverdue
				notifyOnInvoiceDaysOverdue
				notifyOnInvoiceCreatedOrDeleted
				notifyOnInvoiceStatusChange
				notifyOnInvoiceDateReached
				notifyOnInvoicePayment
				notifyOnInvoiceDateChanged
				notifyOnInvoiceDueDateChanged
			}
			slackNotificationSettings {
				disableNotifications
				notifyOnProjectStatusChange
				notifyOnAssignedProject
				notifyOnPersonJoin
				notifyOnProjectDeadline
				notifyOnAssignedTask
				notifyOnAssignedTaskUpdate
				notifyOnTaskDeadline
				notifyOnMention
				notifyOnTaskDescriptionChange
				notifyOnTaskTitleChange
				notifyOnTaskEstimateChange
				notifyOnTaskStartDateChange
				notifyOnTaskEndDateChange
				notifyOnTaskSprintChange
				notifyOnTaskPhaseChange
				notifyOnTaskStatusColumnChange
				notifyOnTaskProjectChange
				notifyOnTaskSubtaskChange
				notifyOnTaskSubtaskEstimateChange
				notifyOnTaskCommentChange
				notifyOnTaskFileChange
				notifyOnTaskBugChange
				notifyOnTaskBlockedChange
				notifyOnTaskRepeatingChange
				notifyOnInvoiceDueDate
				notifyOnInvoiceOverdue
				notifyOnInvoiceDaysOverdue
				notifyOnInvoiceCreatedOrDeleted
				notifyOnInvoiceStatusChange
				notifyOnInvoiceDateReached
				notifyOnInvoicePayment
				notifyOnInvoiceDateChanged
				notifyOnInvoiceDueDateChanged
			}
		}
	`,
});
