import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import AutosizeInput from 'react-input-autosize';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import * as tracking from '../../../tracking';
import oktaLogo from '../../../images/integrations/okta-logo-square.png';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Util from '../../../forecast-app/shared/util/util';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import ToggleSSOMutation from '../../../mutations/ToggleSSOMutation';
import {ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class appOkta extends React.Component {
	constructor(props) {
		super(props);

		const company = props.viewer.company;
		this.state = {
			oktaUrl: company.oktaUrl ? company.oktaUrl : '',
			oktaClientId: company.oktaClientId ? company.oktaClientId : '',
			oktaClientSecret: company.oktaClientSecret ? company.oktaClientSecret : '',
			verifying: false,
		};

		this.superPropertyChecksum = trackPage('App Catalog - Okta');
	}

	componentDidMount() {
		document.title = 'Okta - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-okta');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleChange(property, e) {
		this.setState({[property]: e.target.value});
	}

	enableDisable() {
		this.setState({verifying: true});

		const onSuccess = () => {
			this.setState({verifying: false});
		};

		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: this.props.viewer.company.id,
				oktaUrl: this.state.oktaUrl,
				oktaClientId: this.state.oktaClientId,
				oktaClientSecret: this.state.oktaClientSecret,
			},
			onSuccess
		);
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5088970352529 ');
	}

	activateSSO() {
		Util.CommitMutation(ToggleSSOMutation, {
			activateOkta: true,
		});
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<img width={100} height={100} alt="Okta" src={oktaLogo} />
									<div className="title-description-wrapper">
										<div className="description">
											<FormattedMessage id="settings.app_catalog.okta_description" />
										</div>
									</div>
								</div>

								{!this.props.viewer.company.oktaEnabled ? (
									<button
										disabled={
											this.props.viewer.company.oneloginEnabled || this.props.viewer.company.AADEnabled
										}
										className="submit-button"
										onClick={this.activateSSO.bind(this)}
									>
										<FormattedMessage id="common.activate" />
									</button>
								) : (
									''
								)}

								{this.props.viewer.company.oktaEnabled ? (
									<div className="input-fields">
										<label htmlFor="okta_url">
											<span>
												<strong>Okta account URL</strong>
											</span>
											https://
											<AutosizeInput
												id="okta_url"
												type="text"
												className="okta-account-name"
												placeholder={formatMessage({
													id: 'settings.app_catalog.harvest.account_name_placeholder',
												})}
												value={this.state.oktaUrl}
												onChange={this.handleChange.bind(this, 'oktaUrl')}
											/>
											.okta.com
										</label>
										<label htmlFor="okta_client_id">
											<strong>Application client id</strong>
											<input
												id="okta_client_id"
												type="text"
												placeholder="Client id"
												value={this.state.oktaClientId}
												onChange={this.handleChange.bind(this, 'oktaClientId')}
											/>
										</label>
										<label htmlFor="okta_client_secret">
											<strong>Application client secret</strong>
											<input
												id="okta_client_secret"
												type="password"
												placeholder="Client secret"
												value={this.state.oktaClientSecret}
												onChange={this.handleChange.bind(this, 'oktaClientSecret')}
											/>
										</label>
										{this.props.viewer.company.scimUsernamePassword ? (
											<label htmlFor="scim_username">
												<strong>SCIM username</strong>
												<input
													id="scim_username"
													type="text"
													value={this.props.viewer.company.scimUsernamePassword.split(':')[0]}
												/>
											</label>
										) : null}
										{this.props.viewer.company.scimUsernamePassword ? (
											<label htmlFor="scim_password">
												<strong>SCIM password</strong>
												<input
													id="scim_password"
													type="text"
													value={this.props.viewer.company.scimUsernamePassword.split(':')[1]}
												/>
											</label>
										) : null}
										<button
											disabled={this.state.verifying}
											className="submit-button"
											onClick={this.enableDisable.bind(this)}
										>
											<FormattedMessage id="common.save" />
										</button>
										<button className="help-button" onClick={this.openHelpCenter.bind(this)}>
											<FormattedMessage id="settings.app_catalog.help_button" />
										</button>
									</div>
								) : (
									<button className="help-button" onClick={this.openHelpCenter.bind(this)}>
										<FormattedMessage id="settings.app_catalog.help_button" />
									</button>
								)}
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appOktaQuery = graphql`
	query appOkta_Query {
		viewer {
			actualPersonId
			component(name: "app_okta")
			...appOkta_viewer
		}
	}
`;

export {appOktaQuery};

export default createFragmentContainer(injectIntl(withRouter(appOkta)), {
	viewer: graphql`
		fragment appOkta_viewer on Viewer {
			id
			company {
				id
				oktaEnabled
				AADEnabled
				oneloginEnabled
				scimUsernamePassword
				oktaClientId
				oktaClientSecret
				oktaUrl
			}
		}
	`,
});
