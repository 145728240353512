import React, {useState} from 'react';
import {BambooHRIcon, BasicTextInput, Button, CaretIcon} from 'web-components';
import {FakeHeaderContainer, GoBackContainer, GoBackText} from './app_salesforce_style';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {FormattedMessage, useIntl} from 'react-intl';
import {graphql} from 'graphql';
import {withRouter} from 'react-router-dom';
import {createFragmentContainer} from 'react-relay';
import {ButtonsWrapper, DescriptionWrapper, IconAndDescriptionWrapper} from './app_hubspot_style';
import {
	InputLineWrapper,
	MainSectionWrapper,
	PageWrapper,
	SubSection,
} from '../../project/project_settings/ProjectSettings_styled';
import Util from '../../../forecast-app/shared/util/util';
import ActivateBambooHRMutation from '../../../mutations/ActivateBambooHRMutation';
import DeactivateBambooHRMutation from '../../../mutations/DeactivateBambooHRMutation';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../../components/warning';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import DirectApi from '../../../directApi';
import {usingTimeOffApproval} from '../../../forecast-app/shared/util/FeatureUtil';
import {TimeOffApprovalIncompatibleContent} from './TimeOffApprovalIncompatibleContent';

const AppBambooHR = ({history, viewer}) => {
	const intl = useIntl();

	const [apiKey, setApiKey] = useState('');
	const [domain, setDomain] = useState('');

	const useTimeOffApproval = usingTimeOffApproval(viewer.company.useTimeOffApproval);
	const bambooHREnabled = viewer.company.bambooHREnabled;

	if (bambooHREnabled && !apiKey && !domain) {
		DirectApi.Fetch('bamboohr/settings').then(json => {
			setApiKey(json.apiKey);
			setDomain(json.subdomain);
		});
	}

	const openHelpCenter = () => {
		window.open('https://support.forecast.app/hc/en-us/articles/5090907794833');
	};

	const goBackToIntegrationsPage = () => {
		history.push('/admin/integrations');
	};

	const onActivateBamboo = resp => {
		createToast({
			duration: 5000,
			message: intl.formatMessage({
				id: resp.activateBambooHR.company.bambooHREnabled
					? 'settings.app_catalog.bamboohr.activation_successful'
					: 'settings.app_catalog.bamboohr.activation_failed',
			}),
		});
	};

	const activateBambooHR = () => {
		Util.CommitMutation(ActivateBambooHRMutation, {bambooApiKey: apiKey, bambooDomain: domain}, onActivateBamboo);
	};

	const deactivateBambooHR = () => {
		const {formatMessage} = intl;

		const callbackPositive = () => {
			setApiKey('');
			setDomain('');
			Util.CommitMutation(DeactivateBambooHRMutation, null);
		};

		let info = 'settings.app_catalog.bamboohr.disable_message_warning';

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId={info} />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			className: 'default-warning-modal',
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.deactivate'}),
					callback: callbackPositive,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
				},
			],
		});
	};

	const actionButton = bambooHREnabled ? (
		<Button variant={Button.VARIANT.RED_OUTLINE} onClick={deactivateBambooHR}>
			<FormattedMessage id="common.deactivate" />
		</Button>
	) : (
		<Button variant={Button.VARIANT.PURPLE_OUTLINE} onClick={activateBambooHR}>
			<FormattedMessage id="common.activate" />
		</Button>
	);

	const content = (
		<PageWrapper>
			<MainSectionWrapper width={1250}>
				<FakeHeaderContainer onClick={goBackToIntegrationsPage}>
					<GoBackContainer>
						<CaretIcon size={CaretIcon.SIZE.STANDARD} color={'#535353'} direction={CaretIcon.DIRECTION.LEFT} />
						<GoBackText>
							{intl.formatMessage({id: 'app_salesforce.salesforce_configuration.go_back_to_integrations'})}
						</GoBackText>
					</GoBackContainer>
				</FakeHeaderContainer>

				<CustomScrollDiv>
					<SubSection>
						<IconAndDescriptionWrapper>
							<BambooHRIcon size={BambooHRIcon.SIZE.EXTRA_BIG} />
							<DescriptionWrapper>
								<FormattedMessage id="settings.app_catalog.bamboohr.description" />
							</DescriptionWrapper>
						</IconAndDescriptionWrapper>

						<InputLineWrapper>
							<BasicTextInput
								value={domain}
								onChange={option => setDomain(option)}
								placeholder={'Domain'}
								maxLength={160}
							/>
						</InputLineWrapper>

						<InputLineWrapper>
							<BasicTextInput
								value={apiKey}
								onChange={option => setApiKey(option)}
								placeholder={'API key'}
								maxLength={160}
							/>
						</InputLineWrapper>

						<ButtonsWrapper>
							{actionButton}
							<Button variant={Button.VARIANT.STANDARD} onClick={openHelpCenter}>
								<FormattedMessage id="settings.app_catalog.help_button" />
							</Button>
						</ButtonsWrapper>
					</SubSection>
				</CustomScrollDiv>
			</MainSectionWrapper>
		</PageWrapper>
	);

	const incompatibleContent = (
		<PageWrapper>
			<MainSectionWrapper width={1250}>
				<FakeHeaderContainer onClick={goBackToIntegrationsPage}>
					<GoBackContainer>
						<CaretIcon size={CaretIcon.SIZE.STANDARD} color={'#535353'} direction={CaretIcon.DIRECTION.LEFT} />
						<GoBackText>
							{intl.formatMessage({id: 'app_salesforce.salesforce_configuration.go_back_to_integrations'})}
						</GoBackText>
					</GoBackContainer>
				</FakeHeaderContainer>
				<CustomScrollDiv>
					<SubSection>
						<TimeOffApprovalIncompatibleContent integrationName={'BambooHR'} />
					</SubSection>
				</CustomScrollDiv>
			</MainSectionWrapper>
		</PageWrapper>
	);

	return useTimeOffApproval ? incompatibleContent : content;
};

const appBambooHRQuery = graphql`
	query AppBambooHR_Query {
		viewer {
			actualPersonId
			component(name: "app_bamboohr")
			...AppBambooHR_viewer
		}
	}
`;

export {appBambooHRQuery};

export default withRouter(
	createFragmentContainer(AppBambooHR, {
		viewer: graphql`
			fragment AppBambooHR_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				company {
					id
					useTimeOffApproval
					bambooHREnabled
				}
			}
		`,
	})
);
