import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {useIntl} from 'react-intl';
import {Section} from './Styles';
import {FlexRow, DeprecatedText as Text} from '@forecast-it/design-system';
import CheckBox from '../../../components/new-ui/check_box';
import React from 'react';

const showTrackClientsConfirmationModal = company => {
	showModal({
		type: MODAL_TYPE.SETTINGS_SETUP_CONFIRM_TOGGLE_CLIENTS,
		company: company,
	});
};

export const ToggleClients = ({company}) => {
	const {formatMessage} = useIntl();
	const {revenueEnabled, clientsEnabled} = company;
	return (
		<>
			<Section>
				<Text type={'heading'} variant={'m'}>
					{formatMessage({id: 'settings.setup.clients.title'})}
				</Text>
				<FlexRow gap={'l'}>
					<CheckBox
						isDisabled={revenueEnabled}
						isChecked={clientsEnabled}
						onChange={() => showTrackClientsConfirmationModal(company)}
					/>
					<Text type={'label'}>{formatMessage({id: 'settings.setup.clients.selector_label'})}</Text>
				</FlexRow>
				<Text>{formatMessage({id: 'settings.setup.clients.description'})}</Text>
			</Section>
		</>
	);
};
