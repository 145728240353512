/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type profileNotificationsSettings_viewer$ref: FragmentReference;
declare export opaque type profileNotificationsSettings_viewer$fragmentType: profileNotificationsSettings_viewer$ref;
export type profileNotificationsSettings_viewer = {|
  +id: string,
  +slackId: ?string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +company: ?{|
    +teamsEnabled: ?boolean,
    +newSlackEnabled: ?boolean,
    +useTimeOffApproval: ?boolean,
  |},
  +actualPersonId: ?string,
  +notificationFrequency: ?number,
  +notifyOnProjectStatusChange: ?boolean,
  +notifyOnAssignedProject: ?boolean,
  +notifyOnPersonJoin: ?boolean,
  +notifyOnProjectDeadline: ?boolean,
  +notifyOnAssignedTask: ?boolean,
  +notifyOnAssignedTaskUpdate: ?boolean,
  +notifyOnTaskDeadline: ?boolean,
  +notifyOnMention: ?boolean,
  +disableNotifications: ?boolean,
  +projectDeadlineNotificationPeriod: ?number,
  +taskDeadlineNotificationPeriod: ?number,
  +invoiceNotificationDaysOverdue: ?number,
  +notifyOnTaskDescriptionChange: ?boolean,
  +notifyOnTaskTitleChange: ?boolean,
  +notifyOnTaskEstimateChange: ?boolean,
  +notifyOnTaskStartDateChange: ?boolean,
  +notifyOnTaskEndDateChange: ?boolean,
  +notifyOnTaskSprintChange: ?boolean,
  +notifyOnTaskPhaseChange: ?boolean,
  +notifyOnTaskStatusColumnChange: ?boolean,
  +notifyOnTaskProjectChange: ?boolean,
  +notifyOnTaskSubtaskChange: ?boolean,
  +notifyOnTaskSubtaskEstimateChange: ?boolean,
  +notifyOnTaskCommentChange: ?boolean,
  +notifyOnTaskFileChange: ?boolean,
  +notifyOnTaskBugChange: ?boolean,
  +notifyOnTaskBlockedChange: ?boolean,
  +notifyOnTaskRepeatingChange: ?boolean,
  +notifyOnInvoiceDueDate: ?boolean,
  +notifyOnInvoiceOverdue: ?boolean,
  +notifyOnInvoiceDaysOverdue: ?boolean,
  +notifyOnInvoiceCreatedOrDeleted: ?boolean,
  +notifyOnInvoiceStatusChange: ?boolean,
  +notifyOnInvoiceDateReached: ?boolean,
  +notifyOnInvoicePayment: ?boolean,
  +notifyOnInvoiceDateChanged: ?boolean,
  +notifyOnInvoiceDueDateChanged: ?boolean,
  +notifyOnTimeOffManager: ?boolean,
  +notifyOnTimeOffOwner: ?boolean,
  +notifyInAppOnProjectStatusChange: ?boolean,
  +notifyInAppOnAssignedProject: ?boolean,
  +notifyInAppOnPersonJoin: ?boolean,
  +notifyInAppOnProjectDeadline: ?boolean,
  +notifyInAppOnAssignedTask: ?boolean,
  +notifyInAppOnAssignedTaskUpdate: ?boolean,
  +notifyInAppOnTaskDeadline: ?boolean,
  +notifyInAppOnMention: ?boolean,
  +disableInAppNotifications: ?boolean,
  +notifyInAppOnTaskDescriptionChange: ?boolean,
  +notifyInAppOnTaskTitleChange: ?boolean,
  +notifyInAppOnTaskEstimateChange: ?boolean,
  +notifyInAppOnTaskStartDateChange: ?boolean,
  +notifyInAppOnTaskEndDateChange: ?boolean,
  +notifyInAppOnTaskSprintChange: ?boolean,
  +notifyInAppOnTaskPhaseChange: ?boolean,
  +notifyInAppOnTaskStatusColumnChange: ?boolean,
  +notifyInAppOnTaskProjectChange: ?boolean,
  +notifyInAppOnTaskSubtaskChange: ?boolean,
  +notifyInAppOnTaskSubtaskEstimateChange: ?boolean,
  +notifyInAppOnTaskCommentChange: ?boolean,
  +notifyInAppOnTaskFileChange: ?boolean,
  +notifyInAppOnTaskBugChange: ?boolean,
  +notifyInAppOnTaskBlockedChange: ?boolean,
  +notifyInAppOnTaskRepeatingChange: ?boolean,
  +notifyInAppOnInvoiceDueDate: ?boolean,
  +notifyInAppOnInvoiceOverdue: ?boolean,
  +notifyInAppOnInvoiceDaysOverdue: ?boolean,
  +notifyInAppOnInvoiceCreatedOrDeleted: ?boolean,
  +notifyInAppOnInvoiceStatusChange: ?boolean,
  +notifyInAppOnInvoiceDateReached: ?boolean,
  +notifyInAppOnInvoicePayment: ?boolean,
  +notifyInAppOnInvoiceDateChanged: ?boolean,
  +notifyInAppOnInvoiceDueDateChanged: ?boolean,
  +notifyInAppOnTimeOffManager: ?boolean,
  +notifyInAppOnTimeOffOwner: ?boolean,
  +personNotificationSettings: ?{|
    +id: string,
    +projectDeadlineOnlyAssignedProjects: ?boolean,
    +stateChangedOnlyAssignedProjects: ?boolean,
    +invoiceDueDateOnlyOwned: ?boolean,
    +invoiceOverdueOnlyOwned: ?boolean,
    +invoiceDaysOverdueOnlyOwned: ?boolean,
    +invoiceCreatedOrDeletedOnlyOwned: ?boolean,
    +invoiceStatusChangeOnlyOwned: ?boolean,
    +invoiceDateReachedOnlyOwned: ?boolean,
    +invoicePaymentOnlyOwned: ?boolean,
    +invoiceDateChangedOnlyOwned: ?boolean,
    +invoiceDueDateChangedOnlyOwned: ?boolean,
  |},
  +teamsNotificationSettings: ?{|
    +disableNotifications: ?boolean,
    +notifyOnProjectStatusChange: ?boolean,
    +notifyOnAssignedProject: ?boolean,
    +notifyOnPersonJoin: ?boolean,
    +notifyOnProjectDeadline: ?boolean,
    +notifyOnAssignedTask: ?boolean,
    +notifyOnAssignedTaskUpdate: ?boolean,
    +notifyOnTaskDeadline: ?boolean,
    +notifyOnMention: ?boolean,
    +notifyOnTaskDescriptionChange: ?boolean,
    +notifyOnTaskTitleChange: ?boolean,
    +notifyOnTaskEstimateChange: ?boolean,
    +notifyOnTaskStartDateChange: ?boolean,
    +notifyOnTaskEndDateChange: ?boolean,
    +notifyOnTaskSprintChange: ?boolean,
    +notifyOnTaskPhaseChange: ?boolean,
    +notifyOnTaskStatusColumnChange: ?boolean,
    +notifyOnTaskProjectChange: ?boolean,
    +notifyOnTaskSubtaskChange: ?boolean,
    +notifyOnTaskSubtaskEstimateChange: ?boolean,
    +notifyOnTaskCommentChange: ?boolean,
    +notifyOnTaskFileChange: ?boolean,
    +notifyOnTaskBugChange: ?boolean,
    +notifyOnTaskBlockedChange: ?boolean,
    +notifyOnTaskRepeatingChange: ?boolean,
    +notifyOnInvoiceDueDate: ?boolean,
    +notifyOnInvoiceOverdue: ?boolean,
    +notifyOnInvoiceDaysOverdue: ?boolean,
    +notifyOnInvoiceCreatedOrDeleted: ?boolean,
    +notifyOnInvoiceStatusChange: ?boolean,
    +notifyOnInvoiceDateReached: ?boolean,
    +notifyOnInvoicePayment: ?boolean,
    +notifyOnInvoiceDateChanged: ?boolean,
    +notifyOnInvoiceDueDateChanged: ?boolean,
  |},
  +slackNotificationSettings: ?{|
    +disableNotifications: ?boolean,
    +notifyOnProjectStatusChange: ?boolean,
    +notifyOnAssignedProject: ?boolean,
    +notifyOnPersonJoin: ?boolean,
    +notifyOnProjectDeadline: ?boolean,
    +notifyOnAssignedTask: ?boolean,
    +notifyOnAssignedTaskUpdate: ?boolean,
    +notifyOnTaskDeadline: ?boolean,
    +notifyOnMention: ?boolean,
    +notifyOnTaskDescriptionChange: ?boolean,
    +notifyOnTaskTitleChange: ?boolean,
    +notifyOnTaskEstimateChange: ?boolean,
    +notifyOnTaskStartDateChange: ?boolean,
    +notifyOnTaskEndDateChange: ?boolean,
    +notifyOnTaskSprintChange: ?boolean,
    +notifyOnTaskPhaseChange: ?boolean,
    +notifyOnTaskStatusColumnChange: ?boolean,
    +notifyOnTaskProjectChange: ?boolean,
    +notifyOnTaskSubtaskChange: ?boolean,
    +notifyOnTaskSubtaskEstimateChange: ?boolean,
    +notifyOnTaskCommentChange: ?boolean,
    +notifyOnTaskFileChange: ?boolean,
    +notifyOnTaskBugChange: ?boolean,
    +notifyOnTaskBlockedChange: ?boolean,
    +notifyOnTaskRepeatingChange: ?boolean,
    +notifyOnInvoiceDueDate: ?boolean,
    +notifyOnInvoiceOverdue: ?boolean,
    +notifyOnInvoiceDaysOverdue: ?boolean,
    +notifyOnInvoiceCreatedOrDeleted: ?boolean,
    +notifyOnInvoiceStatusChange: ?boolean,
    +notifyOnInvoiceDateReached: ?boolean,
    +notifyOnInvoicePayment: ?boolean,
    +notifyOnInvoiceDateChanged: ?boolean,
    +notifyOnInvoiceDueDateChanged: ?boolean,
  |},
  +$refType: profileNotificationsSettings_viewer$ref,
|};
export type profileNotificationsSettings_viewer$data = profileNotificationsSettings_viewer;
export type profileNotificationsSettings_viewer$key = {
  +$data?: profileNotificationsSettings_viewer$data,
  +$fragmentRefs: profileNotificationsSettings_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnProjectStatusChange",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnAssignedProject",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnPersonJoin",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnProjectDeadline",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnAssignedTask",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnAssignedTaskUpdate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskDeadline",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnMention",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "disableNotifications",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskDescriptionChange",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskTitleChange",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskEstimateChange",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskStartDateChange",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskEndDateChange",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskSprintChange",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskPhaseChange",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskStatusColumnChange",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskProjectChange",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskSubtaskChange",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskSubtaskEstimateChange",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskCommentChange",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskFileChange",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskBugChange",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskBlockedChange",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnTaskRepeatingChange",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnInvoiceDueDate",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnInvoiceOverdue",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnInvoiceDaysOverdue",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnInvoiceCreatedOrDeleted",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnInvoiceStatusChange",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnInvoiceDateReached",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnInvoicePayment",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnInvoiceDateChanged",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifyOnInvoiceDueDateChanged",
  "storageKey": null
},
v35 = [
  (v9/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/),
  (v21/*: any*/),
  (v22/*: any*/),
  (v23/*: any*/),
  (v24/*: any*/),
  (v25/*: any*/),
  (v26/*: any*/),
  (v27/*: any*/),
  (v28/*: any*/),
  (v29/*: any*/),
  (v30/*: any*/),
  (v31/*: any*/),
  (v32/*: any*/),
  (v33/*: any*/),
  (v34/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "profileNotificationsSettings_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slackId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teamsEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newSlackEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeOffApproval",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationFrequency",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectDeadlineNotificationPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taskDeadlineNotificationPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceNotificationDaysOverdue",
      "storageKey": null
    },
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/),
    (v19/*: any*/),
    (v20/*: any*/),
    (v21/*: any*/),
    (v22/*: any*/),
    (v23/*: any*/),
    (v24/*: any*/),
    (v25/*: any*/),
    (v26/*: any*/),
    (v27/*: any*/),
    (v28/*: any*/),
    (v29/*: any*/),
    (v30/*: any*/),
    (v31/*: any*/),
    (v32/*: any*/),
    (v33/*: any*/),
    (v34/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyOnTimeOffManager",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyOnTimeOffOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnProjectStatusChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnAssignedProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnPersonJoin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnProjectDeadline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnAssignedTask",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnAssignedTaskUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskDeadline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnMention",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disableInAppNotifications",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskDescriptionChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskTitleChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskEstimateChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskStartDateChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskEndDateChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskSprintChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskPhaseChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskStatusColumnChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskProjectChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskSubtaskChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskSubtaskEstimateChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskCommentChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskFileChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskBugChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskBlockedChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskRepeatingChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceDueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceOverdue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceDaysOverdue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceCreatedOrDeleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceStatusChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceDateReached",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoicePayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceDateChanged",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceDueDateChanged",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTimeOffManager",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTimeOffOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonNotificationSettingsType",
      "kind": "LinkedField",
      "name": "personNotificationSettings",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectDeadlineOnlyAssignedProjects",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stateChangedOnlyAssignedProjects",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceDueDateOnlyOwned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceOverdueOnlyOwned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceDaysOverdueOnlyOwned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceCreatedOrDeletedOnlyOwned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceStatusChangeOnlyOwned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceDateReachedOnlyOwned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoicePaymentOnlyOwned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceDateChangedOnlyOwned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceDueDateChangedOnlyOwned",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationSettingsType",
      "kind": "LinkedField",
      "name": "teamsNotificationSettings",
      "plural": false,
      "selections": (v35/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationSettingsType",
      "kind": "LinkedField",
      "name": "slackNotificationSettings",
      "plural": false,
      "selections": (v35/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd12ed0f815006cf1c973027b23f114f5';

module.exports = node;
