import React, {useState} from 'react';
import {DeprecatedText as Text} from '@forecast-it/design-system';
import {DeprecatedCreativeModal as CreativeModal} from '@forecast-it/design-system';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import {COMPANY_SETUP, setCompanySetting} from '../../../forecast-app/shared/util/CompanySetupUtil';

const ToggleTimesheetsModal = ({closeModal, company}) => {
	const {formatMessage} = useIntl();
	const [disabled, setDisabled] = useState(false);
	const wantToEnable = !company.timesheetsEnabled;

	const persistTimesheetsEnabledSetting = (value, controlRef) => {
		setDisabled(true);
		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: company.id,
				timesheetsEnabled: wantToEnable,
			},
			() => {
				setCompanySetting(COMPANY_SETUP.TIME_SHEETS_ENABLED, wantToEnable);
				closeModal();
			}
		);
	};

	return (
		<CreativeModal
			disabled={disabled}
			closeModal={closeModal}
			creativeButtonText={formatMessage({id: wantToEnable ? 'common.turn_on_feature' : 'common.turn_off_feature'})}
			onCreateHandler={() => persistTimesheetsEnabledSetting(false, this)}
			title={formatMessage({id: wantToEnable ? 'common.turn_on_this_feature' : 'common.turn_off_this_feature'})}
		>
			<Text>
				<FormattedHTMLMessage
					id={
						wantToEnable
							? 'settings.setup.time_tracking.enable_confirmation_text'
							: 'settings.setup.time_tracking.disable_confirmation_text'
					}
				/>
			</Text>
		</CreativeModal>
	);
};

export default ToggleTimesheetsModal;
