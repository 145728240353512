import React, {useState} from 'react';
import _ from 'lodash';
import {useIntl} from 'react-intl';
import GenericModal from '../generic_modal';
import InvoiceEntriesList from '../create_invoice_modal_v2/steps/invoice_entries_list';
import {ContentWrapper, EditInvoiceModalTabContent, HeaderContainer} from '../../../styles/v2/invoice_modals_v2_styled';
import {
	PageTabsContainer,
	TabStyled,
	UneditableMessageBox,
	MessageContainer,
	PadLockContainer,
} from '../../../styles/sections/insights/invoicing/invoicing_page_styled';
import {BUTTON_COLOR} from '../../../constants';
import InvoiceDetailsForm from '../create_invoice_modal_v2/components/invoice_details_form';
import Util from '../../../forecast-app/shared/util/util';
import UpdateInvoiceMutation from '../../../mutations/update_invoice_mutation';
import InvoicePaymentsList from './invoice_payments_list';
import InvoiceTimeRegsList from './invoice_time_reg_list';
import CSVButton from '../../../forecast-app/shared/components/headers/header-bar/csv_button';
import {FormattedHTMLMessage} from 'react-intl';
import {LockIcon} from 'web-components';
import {CSS_CONSTANTS} from '../../../css_variables';
import {invoiceLineConverter} from '../create_invoice_modal_v2/create_invoice_modal_v2';

const getInvoiceLineTotal = line => {
	const standardPrice = Util.roundToNDecimals(line.unitPrice * line.quantity, 2);
	const discount = Util.roundToNDecimals((line.discount * standardPrice) / 100, 2);
	const grossAmount = standardPrice - discount;
	const tax = Util.roundToNDecimals((line.tax * grossAmount) / 100, 2);
	const netAmount = grossAmount + tax;
	return netAmount;
};

const EditInvoiceModal = ({
	closeModal,
	invoice: initialInvoice,
	projects,
	refetch,
	company,
	isProjectInvoicing,
	project,
	language,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {entries} = initialInvoice;
	const mappedProjects = projects.edges.map(p => ({...p.node}));
	const clientProjects = mappedProjects.filter(project =>
		project.client && initialInvoice.client ? project.client.id === initialInvoice.client.id : false
	);
	const initialInvoiceLines = entries.edges.map(e => ({
		...e.node,
		projectId: e.node.project ? e.node.project.id : null,
		quantity: e.node.quantityText,
	}));

	const [invoiceLines, setInvoiceLines] = useState(initialInvoiceLines);
	const tabs = {
		DETAILS: {key: 'DETAILS', translation: formatMessage({id: 'common.details'})},
		ENTRIES: {key: 'ENTRIES', translation: formatMessage({id: 'invoice.entries'}, {x: invoiceLines.length})},
		PAYMENTS: {
			key: 'PAYMENTS',
			translation: formatMessage({id: 'invoice.payments'}, {x: initialInvoice.payments.edges.length}),
		},
		TIME_REGS: {
			key: 'TIME_REGS',
			translation: `${formatMessage({id: 'common.time_registrations'})} (${
				initialInvoice.timeRegistrations.edges.length
			})`,
		},
	};
	const [selectedTab, setSelectedTab] = useState(tabs.DETAILS);

	const projectIds = _.uniq(invoiceLines.filter(line => line.project).map(line => line.project.id));
	const [invoice, setInvoice] = useState({
		companyInvoiceId: initialInvoice.companyInvoiceId,
		name: initialInvoice.name,
		date: Util.CreateNonUtcMomentDate(initialInvoice.createdYear, initialInvoice.createdMonth, initialInvoice.createdDay),
		dueDate: Util.CreateNonUtcMomentDate(initialInvoice.dueYear, initialInvoice.dueMonth, initialInvoice.dueDay),
		currency: initialInvoice.currency,
		notes: initialInvoice.notes,
		status: initialInvoice.status,
		client: initialInvoice.client,
		ProjectsIds: projectIds,
		reference: initialInvoice.invoiceReference,
		isExported: !!(initialInvoice.quickbooksId || initialInvoice.xeroId || initialInvoice.economicId),
	});
	const [payments, setPayments] = useState(initialInvoice.payments.edges.map(payment => ({...payment.node})));
	const [hasError, setHasError] = useState(false);

	const invoiceTotal = invoiceLines.reduce((acc, line) => {
		return acc + getInvoiceLineTotal(line);
	}, 0);

	const canNotEditMessageId = invoice.isExported ? 'invoicing.cannot_edit_warning_exported' : 'invoicing.cannot_edit_warning';
	const invoiceStatus = invoice.status;

	const getInvoiceLineCSVData = entry => {
		let lineData = {
			invoiceId: invoice.companyInvoiceId,
			invoiceName: invoice.name,
			projectId: entry.project ? entry.project.companyProjectId : '',
			projectName: entry.project ? entry.project.name : '',
			description: entry.description,
			quantity: entry.quantity && Util.formatLocalDecimals(Number(entry.quantity), language),
			unitPrice: entry.unitPrice && Util.getFormattedCurrencyNumber(entry.unitPrice, intl),
			discount: entry.discount + '%',
			tax: entry.tax + '%',
			totalPrice: Util.getFormattedCurrencyNumber(getInvoiceLineTotal(entry), intl),
			currency: invoice.currency,
		};

		return lineData;
	};

	const getTimeRegLineCSVData = entry => {
		let lineData = {
			invoiceId: invoice.companyInvoiceId,
			invoiceName: invoice.name,
			date: Util.CreateMomentDate(entry.year, entry.month, entry.day).format('D MMM YYYY') || '',
			projectId: entry.project ? entry.project.companyProjectId : '',
			projectName: entry.project ? entry.project.name : '',
			name: entry.person && entry.person.fullName,
			notes: entry.notes || '',
			quantity: entry.minutesRegistered && Util.formatLocalDecimals(entry.minutesRegistered / 60, language),
			unitPrice: entry.rate && Util.getFormattedCurrencyNumber(entry.rate, intl),
			totalPrice: entry.price && Util.getFormattedCurrencyNumber(entry.price, intl),
			currency: invoice.currency,
		};
		return lineData;
	};

	const getCSVData = key => {
		let csvData = [];
		if (key === 'TIME_REGS') {
			csvData.push({
				invoiceId: 'Invoice Id',
				invoiceName: 'Invoice Name',
				date: 'Date',
				projectId: 'Project Id',
				projectName: 'Project Name',
				name: 'Name',
				notes: 'Notes',
				quantity: 'Quantity',
				unitPrice: 'Unit Price',
				totalPrice: 'Total Price',
				currency: 'Currency',
			});
			const timeregs = initialInvoice.timeRegistrations.edges.map(timeReg => ({...timeReg.node}));

			timeregs.forEach(entry => {
				const project = clientProjects.find(project => {
					if (entry.project) return project.id === entry.project.id;
					return project.id === entry.task.project.id;
				});
				entry.project = project;
				csvData.push(getTimeRegLineCSVData(entry));
			});
			return csvData;
		} else if (key === 'ENTRIES') {
			csvData.push({
				invoiceId: 'Invoice Id',
				invoiceName: 'Invoice Name',
				projectId: 'Project Id',
				projectName: 'Project Name',
				description: 'Description',
				quantity: 'Quantity',
				unitPrice: 'Unit Price',
				discount: 'Discount',
				tax: 'Tax',
				totalPrice: 'Total Price',
				currency: 'Currency',
			});
			invoiceLines.forEach(entry => {
				csvData.push(getInvoiceLineCSVData(entry));
			});
			return csvData;
		}
	};

	const handleCsvDownloadPress = key => {
		const csvData = getCSVData(key);
		const exportData = Util.JSONToCSV(csvData);
		const fileName = `${invoice.name}_${key}.csv`.toLowerCase();
		Util.exportToCSV(exportData, fileName);
	};

	const content = (
		<ContentWrapper>
			<HeaderContainer>
				<PageTabsContainer noMargins className={'edit-invoice-modal-tabs'}>
					{Object.keys(tabs).map(tab => (
						<TabStyled
							onClick={() => setSelectedTab(tabs[tab])}
							isActive={tabs[tab].key === selectedTab.key}
							data-userpilot={'edit-invoice-tab-' + tab.toLowerCase()}
						>
							{tabs[tab].translation}
						</TabStyled>
					))}
				</PageTabsContainer>
				{(selectedTab.key === 'ENTRIES' || selectedTab.key === 'TIME_REGS') && (
					<CSVButton key={'csv-button'} onClick={() => handleCsvDownloadPress(selectedTab.key)} />
				)}
			</HeaderContainer>
			{selectedTab.key === tabs.ENTRIES.key && (invoice.status !== 'DRAFT' || invoice.isExported) && (
				<UneditableMessageBox>
					<PadLockContainer>
						<LockIcon size={LockIcon.SIZE.LARGE} color={CSS_CONSTANTS.v2_text_gray} />
					</PadLockContainer>
					<MessageContainer>
						<FormattedHTMLMessage key={0} id={canNotEditMessageId} values={{invoiceStatus}} />
					</MessageContainer>
				</UneditableMessageBox>
			)}
			<EditInvoiceModalTabContent>
				{selectedTab.key === tabs.DETAILS.key && (
					<InvoiceDetailsForm
						isEditing
						cantEditInvoice={invoice.status !== 'DRAFT' || invoice.isExported}
						setInvoice={setInvoice}
						invoice={invoice}
						currency={invoice.currency}
						currenciesDropdownOptions={[{value: invoice.currency, label: invoice.currency}]}
						selectedProjectsIds={projectIds}
						setHasError={setHasError}
						projects={mappedProjects}
					/>
				)}
				{selectedTab.key === tabs.ENTRIES.key && (
					<InvoiceEntriesList
						canEditInvoice={invoice.status === 'DRAFT'}
						invoiceExported={invoice.isExported}
						lines={invoiceLines}
						projects={mappedProjects}
						clientProjects={clientProjects}
						currency={invoice.currency}
						setInvoiceLines={setInvoiceLines}
						setHasError={setHasError}
						company={company}
						invoice={invoice}
						isProjectInvoicing={isProjectInvoicing}
						project={project}
					/>
				)}
				{selectedTab.key === tabs.PAYMENTS.key && (
					<InvoicePaymentsList
						intl={intl}
						payments={payments}
						setPayments={setPayments}
						currency={invoice.currency}
						invoiceTotal={invoiceTotal}
						canEditPayments={!invoice.isExported}
					/>
				)}
				{selectedTab.key === tabs.TIME_REGS.key && (
					<InvoiceTimeRegsList
						intl={intl}
						timeRegs={initialInvoice.timeRegistrations.edges.map(timeReg => ({...timeReg.node}))}
						clientProjects={clientProjects}
						currency={invoice.currency}
					/>
				)}
			</EditInvoiceModalTabContent>
		</ContentWrapper>
	);

	const onInvoiceSave = () => {
		const onSuccess = () => {
			closeModal();
			// update state
			if (refetch) refetch();
		};

		const updatedInvoiceData = {
			id: initialInvoice.id,
			name: invoice.name,
			currency: invoice.currency,
			invoiceReference: invoice.reference,
			status: invoice.status,
			dueDay: invoice.dueDate.date(),
			dueMonth: invoice.dueDate.month() + 1,
			dueYear: invoice.dueDate.year(),
			createdDay: invoice.date.date(),
			createdMonth: invoice.date.month() + 1,
			createdYear: invoice.date.year(),
			notes: invoice.notes,
			entries: invoiceLines.map(invoiceLineConverter),
			payments: payments.map(payment => {
				const newPayment = {...payment};
				delete newPayment.createdAt;
				delete newPayment.createdBy;
				return newPayment;
			}),
		};

		Util.CommitMutation(UpdateInvoiceMutation, updatedInvoiceData, onSuccess);
	};

	const modalButtons = [
		{
			text: formatMessage({id: 'common.cancel'}),
			callback: closeModal.bind(this),
			color: BUTTON_COLOR.WHITE,
		},
		{
			text: formatMessage({id: 'common.save'}),
			preventDefaultClose: true,
			callback: onInvoiceSave,
			disabled: hasError,
			userpilot: 'create-invoice-save-button',
		},
	];

	let modalTitle = `${invoice.companyInvoiceId} - ${invoice.name}`;

	if (invoice.status !== 'DRAFT')
		modalTitle = `${modalTitle} - ${formatMessage({
			id: invoice.isExported ? 'common.exported' : `common.${invoice.status.toLowerCase()}`,
		})}`;

	return <GenericModal buttons={modalButtons} headerText={modalTitle} closeModal={closeModal} content={content} />;
};

export default EditInvoiceModal;
