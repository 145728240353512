import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createFragmentContainer, graphql} from 'react-relay';
import DropdownV2 from '../../../forecast-app/shared/components/dropdowns/dropdown';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import {LabelCheckboxheaderStyled} from '../labels/label_checkbox.styled';

const DeletePriorityLevelModal = ({company, closeModal, priorityLevelToDelete, deleteCallback}) => {
	const {formatMessage} = useIntl();
	const [selectedPriorityLevel, setSelectedPriorityLevel] = useState(null);

	const performDeleteCallback = () => {
		deleteCallback(selectedPriorityLevel.value);
	};

	const priorityLevelOptions = company.priorityLevels.edges
		.map(edge => edge.node)
		.filter(priorityLevel => priorityLevel.id !== priorityLevelToDelete.id)
		.map(priorityLevel => ({
			value: priorityLevel.id,
			label: priorityLevel.name,
		}));

	priorityLevelOptions.push({value: null, label: formatMessage({id: 'common.no_priority'})});

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({id: 'settings_priority.delete_priority_level_header'})}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.delete'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: performDeleteCallback,
					disabled: !selectedPriorityLevel,
					cy: 'button-move',
				},
			]}
			content={
				<ModalBody>
					<LabelCheckboxheaderStyled>
						<span>{formatMessage({id: 'settings_priority.delete_priority_level_description'})}</span>
					</LabelCheckboxheaderStyled>
					<Field>
						<DropdownV2
							options={priorityLevelOptions}
							onChange={setSelectedPriorityLevel}
							value={selectedPriorityLevel?.value}
							label={formatMessage({id: 'common.priority'})}
							placeholder={formatMessage({id: 'settings_priority.delete_priority_level_placeholder'})}
							inputName={'priority-level-input'}
							inputCy={'priority-level-input'}
							listDataCy={'priority-level'}
						/>
					</Field>
				</ModalBody>
			}
		/>
	);
};

const deletePriorityLevelModalQuery = graphql`
	query DeletePriorityLevelModal_Query {
		viewer {
			actualPersonId
			component(name: "delete_priority_level_modal")
			company {
				...DeletePriorityLevelModal_company
			}
		}
	}
`;

export {deletePriorityLevelModalQuery};

export default createFragmentContainer(DeletePriorityLevelModal, {
	company: graphql`
		fragment DeletePriorityLevelModal_company on Company {
			id
			priorityLevels(first: 1000) {
				edges {
					node {
						id
						name
						weight
					}
				}
			}
		}
	`,
});
