import React from 'react';
import { FlexRow, FlexItem, TextField, Label, FlexColumn, DropdownList, ButtonDropdownField, DateRangePicker, Text, } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import { HIDDEN_FEATURES } from '../../../constants';
import ColorsPicker, { ColorsPickerDesignSystemSizeWrapper } from '../../../components/colors_picker';
export function ProjectMainInfo(props) {
    var _a;
    const { formatMessage } = useIntl();
    return (React.createElement(FlexColumn, { gap: 'l' },
        React.createElement(FlexRow, { alignItems: 'start' },
            React.createElement(TextField, { "data-cy": 'new-project-name', label: props.label, onValueChange: props.onProjectNameChange, onBlur: props.onProjectNameBlur, value: props.projectName, placeholder: formatMessage({ id: 'new_project_modal.project_name_placeholder' }), errorText: props.projectNameErrorMessage, maxLength: 191 })),
        React.createElement(FlexColumn, { gap: 'xs' },
            React.createElement(FlexRow, null,
                React.createElement(FlexItem, null,
                    React.createElement(TextField, { "data-cy": 'new-project-customId', label: formatMessage({ id: 'new_project_modal.project_id' }), onValueChange: props.onCustomProjectIdChange, onBlur: props.onCustomProjectIdBlur, hasError: !!props.customProjectIdError, value: props.customProjectId, maxLength: 6, sizeInCharacters: 6, placeholder: `P${props.lastCompanyProjectId + 1}` })),
                props.useDuplication && props.duplicateColor ? null : (React.createElement(FlexColumn, null,
                    React.createElement(Label, null, formatMessage({ id: 'common.color' })),
                    React.createElement(ColorsPickerDesignSystemSizeWrapper, null,
                        React.createElement(ColorsPicker, { value: props.projectColor, rectangle: true, onColorSelect: props.onColorSelect, projectLocked: undefined, usePortal: true, cy: 'new-project-color' }))))),
            props.customProjectIdError ? (React.createElement(FlexRow, null,
                React.createElement(Text, { color: "error", noWrap: true, "data-cy": 'new-project-customId-error' }, props.customProjectIdError))) : null),
        React.createElement(FlexRow, null,
            React.createElement(FlexItem, null,
                React.createElement(DateRangePicker, { "data-cy": 'new-project-dates', label: formatMessage({ id: 'common.dates' }), disabled: props.isProjectLocked, startDate: props.startDate, endDate: props.endDate, onChange: props.handleDateRangeChange })),
            !Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT) ? (React.createElement(FlexColumn, null,
                React.createElement(Label, null, formatMessage({ id: 'common.client' })),
                React.createElement(DropdownList, null,
                    React.createElement(DropdownList.Trigger, { "data-cy": 'settings-project-stage' },
                        React.createElement(ButtonDropdownField, null,
                            React.createElement(Text, null, ((_a = props.clientOptions.find(o => o.value === props.client)) === null || _a === void 0 ? void 0 : _a.label) || 'No Client'))),
                    React.createElement(DropdownList.Content, { align: 'start' }, props.clientOptions.map(client => (React.createElement(DropdownList.Item, { key: client.value, "data-cy": client.cy, onClick: () => props.onClientChange(client) },
                        React.createElement(FlexRow, null,
                            React.createElement(Text, null, client.label))))))))) : null)));
}
