import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {Hexagon} from 'web-components';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {TABS} from './ProjectSettingsUtil';
import {MainSectionWrapper, PageWrapper} from './ProjectSettings_styled';
import ProjectSettingsGeneral from './ProjectSettingsGeneral';
import Util from '../../../forecast-app/shared/util/util';
import ProjectSettingsStatus from './ProjectSettingsStatus';
import ProjectSettingsTasks, {TASK_MONITORING} from './ProjectSettingsTasks';
import ProjectSettingsFinancials from './ProjectSettingsFinancials';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import ProjectSettingsTeam from './ProjectSettingsTeam';
import ProjectSettingsIntegrations from './ProjectSettingsIntegrations';
import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../constants';
import * as tracking from '../../../tracking';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {hasSomePermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {getLocalStorageSilent, setLocalStorageSilent} from '../../../forecast-app/shared/util/LocalStorageUtil';
import ProjectUtil from '../../../forecast-app/shared/util/project_util';
import ProjectProgress from './ProjectProgress';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {trackComplexEvent, trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {withSocketHandling} from '../../../socket/withSocketHandling';
import ProjectHeader from '../../../forecast-app/project-tab/projects/shared/ProjectHeader';
import {doGetProgramBudgetInfo} from '../../../forecast-app/project-tab/programs/hooks/useProjectProgramFinancials';
import {profilePicSrc} from '../../../directApi';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';

const ProjectSettingsPage = ({viewer, retry, history, setSocketConfig}) => {
	const intl = useIntl();
	const [selectedTab, setSelectedTab] = useState(getLocalStorageSilent('project_settings_tab') || TABS.GENERAL.key);
	useEffect(() => {
		setLocalStorageSilent('project_settings_tab', selectedTab);
	}, [selectedTab]);

	const {project, company} = viewer;
	const projectDoneOrHalted = project?.status === 'DONE' || project?.status === 'HALTED';

	useTrackPage('Project Settings');

	useEffect(() => {
		tracking.trackPage('project-settings', null, null, {newSettings: true});

		if (history.location.hash) {
			if (history.location.hash.replace('#', '').toLowerCase() === 'update-status') {
				setSelectedTab(TABS.STATUS.key);
				tracking.trackPageAction('Status Modal triggered by Status Digest');
				trackComplexEvent('Status Modal', 'Triggered', {triggerLocation: 'From Status Digest'});

				if (!projectDoneOrHalted) {
					showModal({
						type: MODAL_TYPE.PROJECT_STATUS_V2,
						projectId: project.id,
						triggeredByStatusDigest: true,
					});
				}
			} else {
				const tab = history.location.hash.replace('#', '').toUpperCase().split('+')[0];
				setSelectedTab(tab);
			}
		} else {
			if (selectedTab) {
				history.push(`${location.pathname}#${selectedTab.toLowerCase()}`);
			}
		}
	}, [history.location.hash]);

	// catch if project is deleted but still in cache
	if (!project) {
		history.replace('/not-found');
		return null;
	}

	const projectStartDate = project.projectStartYear
		? Util.CreateNonUtcMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay)
		: null;
	const projectEndDate = project.projectEndYear
		? Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay)
		: null;
	const hasOpportunityAccess = Util.hasOpportunityAccess(company.modules);
	const stages = Util.getProjectStageForDropdown(intl, hasOpportunityAccess);
	const hasFinancialAccess = hasSomePermission([
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
	]);
	const hasBaselineAccess = Util.hasBaselineModule(company.modules);

	const socketProjectIds = [Util.getIdFromBase64String(project.id)];
	const socketEvents = [
		{
			type: SOCKET_EVENT_TYPE.PROJECT_PERSON,
			action: SOCKET_ACTION.CREATE,
			projectIds: socketProjectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT_PERSON,
			action: SOCKET_ACTION.DELETE,
			projectIds: socketProjectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT_PERSON,
			action: SOCKET_ACTION.UPDATE,
			projectIds: socketProjectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT,
			action: SOCKET_ACTION.UPDATE,
			projectIds: socketProjectIds,
		},
	];

	const {
		program,
		programBudget,
		programAllocation,
		canManageProgram,
		isInFixedPriceProgram,
		isInCappedRevenueProgram,
		isProgramRevenueLocked,
		programRemainingBudget,
		revenueSetting,
	} = doGetProgramBudgetInfo(
		viewer.actualPersonId,
		project.id,
		project.program,
		project.programBudgetType,
		project.isProgramRevenueLocked,
		project.programRemainingBudget
	);

	setSocketConfig(socketEvents);

	const showNotOwnerCannotChangeBudgetTypeModal = () => {
		trackEvent('Change Project Budget Type Button', 'Clicked', {error: 'Not Owner'});
		showModal({
			type: MODAL_TYPE.NOT_PERMITTED_PROJECT_BUDGET_CHANGE,
			program: program,
			isInFixedPriceProgram: isInFixedPriceProgram,
			messageTranslationTag: 'project_settings.not_permitted_modal_message',
		});
	};

	const showChangeBudgetTypeModal = () => {
		const errorFixedPriceNoOwner = isInFixedPriceProgram && (!canManageProgram || !hasFinancialAccess);

		if (errorFixedPriceNoOwner) {
			showNotOwnerCannotChangeBudgetTypeModal();
		} else {
			showModal({
				type: MODAL_TYPE.PROJECT_BUDGET_CHANGE,
				isInFixedPriceProgram: isInFixedPriceProgram,
				isInCappedRevenueProgram: isInCappedRevenueProgram,
				programBudget: programBudget,
				programAllocation: programAllocation,
				programRemainingBudget: programRemainingBudget,
				isProgramRevenueLocked: isProgramRevenueLocked,
				canManageProgram: canManageProgram,
				program: program,
				project: project,
				revenueSetting: revenueSetting,
				hasInvoice: viewer.project.hasInvoices,
				titleText: 'Change Budget Type',
				confirmText: intl.formatMessage({id: 'common.change'}),
				handleConfirm: () => {},
				useRetainers: Util.shouldUseRetainers(company.modules),
				hasFinance: CompanySetupUtil.hasFinance(company.modules),
				currency: project.rateCard ? project.rateCard.currency : company.currency,
				retry: retry,
				availableFeatureFlags: viewer.availableFeatureFlags,
			});
		}
	};

	const showUpdateFixedPriceModal = () => {
		showModal({
			type: MODAL_TYPE.UPDATE_FIXED_PRICE,
			project,
			callback: function () {
				createToast({
					duration: 10000,
					message: intl.formatMessage({id: 'project_settings.project_type_updating'}),
				});
			},
		});

		tracking.trackElementClicked('Update Fix Price button');
		trackEvent('Update Fixed Price Button', 'Clicked');
	};

	const projectPersonsName = project.projectPersons.edges
		.filter(projectPerson => {
			return projectPerson.node.person.active;
		})
		.map(projectPerson => {
			return {
				name: projectPerson.node.person.firstName + ' ' + projectPerson.node.person.lastName,
			};
		});

	const getSettingsSection = () => {
		if (TABS[selectedTab] && TABS[selectedTab].isVisible && !TABS[selectedTab].isVisible(project)) {
			setSelectedTab(TABS.GENERAL.key);
		}

		switch (selectedTab) {
			case TABS.GENERAL.key:
				return (
					<ProjectSettingsGeneral
						intl={intl}
						history={history}
						projectId={project.id}
						customProjectId={project.customProjectId}
						companyProjectId={project.companyProjectId}
						projectGroupId={project.projectGroupId}
						projectName={project.name}
						projectDescription={project.description}
						projectStartDate={projectStartDate}
						projectEndDate={projectEndDate}
						customFieldValues={project.customFieldValues}
						companyClients={company.clients.edges}
						projectClient={project.client && project.client.id}
						companyLabels={company.labels.edges.map(edge => edge.node)}
						projectLabels={project.projectLabels.edges}
						projectPersonsName={projectPersonsName}
						projectColor={project.projectColor}
						projectDoneOrHalted={projectDoneOrHalted}
						hasLockedTime={project.hasLockedTime}
						hasInvoice={project.hasInvoices}
						budgetType={project.budgetType}
						pipedriveCompanyDomain={company.pipedriveCompanyDomain}
						hubspotAccountId={company.hubspotAccountId}
						userCanDeleteProject={project.userCanDeleteProject}
						userCantDeleteProjectReason={project.userCantDeleteProjectReason}
						isUsingProjectAllocation={company.isUsingProjectAllocation}
						isSageProject={project.sageProject.sageProjectId != null}
					/>
				);
			case TABS.STATUS.key:
				const currentProjectStatusDate = project.currentProjectStatus?.year
					? Util.CreateNonUtcMomentDate(
							project.currentProjectStatus.year,
							project.currentProjectStatus.month,
							project.currentProjectStatus.day
					  ).format('ll')
					: null;
				const currentProjectStatusPerson = project.currentProjectStatus?.person;
				const currentProjectStatusPersonFullName =
					currentProjectStatusPerson?.firstName + ' ' + currentProjectStatusPerson?.lastName;
				const currentProjectStatusPersonImage = (
					<Hexagon
						text={currentProjectStatusPersonFullName}
						imgUrl={profilePicSrc(currentProjectStatusPerson?.profilePictureId)}
					/>
				);
				return (
					<ProjectSettingsStatus
						intl={intl}
						viewverId={viewer.id}
						projectId={project.id}
						project={project}
						company={company}
						projectStage={project.status}
						stages={stages}
						hasOpportunityAccess={hasOpportunityAccess}
						baselineWinChance={project.baselineWinChance}
						companyProjectId={project.companyProjectId}
						projectColor={project.projectColor}
						projectTasks={project.tasks.edges}
						projectColumns={project.statusColumnsV2.edges}
						currentProjectStatus={project.currentProjectStatus}
						projectStatuses={project.projectStatuses}
						currentProjectStatusColor={project.currentProjectStatus && project.currentProjectStatus.color}
						currentProjectStatusDate={currentProjectStatusDate}
						currentProjectStatusPersonImage={currentProjectStatusPersonImage}
						currentProjectStatusDescription={project.currentProjectStatus?.description}
						currentProjectStatusPersonFullName={currentProjectStatusPersonFullName}
						projectDoneOrHalted={projectDoneOrHalted}
						budgetType={project.budgetType}
					/>
				);
			case TABS.TASKS.key:
				return (
					<ProjectSettingsTasks
						intl={intl}
						projectId={project.id}
						useSprint={project.sprintTimeBox}
						sprintLength={project.sprintLengthInDays}
						taskSubLevel={project.taskLevels}
						taskMonitoring={
							project.useTaskOwner
								? TASK_MONITORING.USE_OWNER
								: project.useTaskFollowers
								? TASK_MONITORING.USE_FOLLOWERS
								: TASK_MONITORING.NO_TASK_MONITORING
						}
						estimationUnit={project.estimationUnit}
						hoursPrStoryPoint={project.minutesPerEstimationPoint / 60 || ''}
						remainingAutoCalculated={project.remainingAutoCalculated}
						linkToPublicCreateTaskPage={project.linkToPublicCreateTaskPage}
						publicCreateTaskPageStatusColumn={project.publicCreateTaskPageStatusColumn}
						projectColumns={project.statusColumnsV2.edges}
						projectDoneOrHalted={projectDoneOrHalted}
						isJiraProject={project.isJiraProject}
						isADOProject={project.vstsProject}
						isInProjectGroup={project.isInProjectGroup}
						noTimeRegProject={ProjectUtil.projectUsesManualProgress(project)}
						projectStatus={project.status}
						useTaskHierarchy={project.useTaskHierarchy}
						useDeliverables={project.useDeliverables}
						usePublicCreateTaskPassword={project.usePublicCreateTaskPassword}
						publicCreateTaskPassword={project.publicCreateTaskPassword}
						jiraSyncRemaining={project.jiraSyncRemaining}
					/>
				);
			case TABS.FINANCIALS.key:
				if (hasFinancialAccess) {
					return (
						<ProjectSettingsFinancials
							intl={intl}
							projectId={project.id}
							useBaseline={project.useBaseline}
							showChangeBudgetTypeModal={showChangeBudgetTypeModal}
							hasFinanceAccess={CompanySetupUtil.hasFinance()}
							hasBaselineAccess={hasBaselineAccess}
							taskCostCalculationType={project.taskCostCalculationType}
							unassignedTaskHourlyCost={project.unassignedTaskHourlyCost}
							currency={viewer.project.rateCard ? viewer.project.rateCard.currency : viewer.company.currency}
							projectDoneOrHalted={projectDoneOrHalted}
							hasLockedPeriod={project.hasLockedPeriod}
							budgetType={project.budgetType}
							showUpdateFixedPriceModal={showUpdateFixedPriceModal}
							rateCard={project.rateCard}
							company={company}
							project={project}
							projectStartDate={projectStartDate}
							projectEndDate={projectEndDate}
							availableFeatureFlags={viewer.availableFeatureFlags}
						/>
					);
				} else {
					setSelectedTab(TABS.GENERAL.key);
					break;
				}
			case TABS.TEAM.key:
				return (
					<ProjectSettingsTeam viewer={viewer} intl={intl} history={history} projectId={project.id} retry={retry} />
				);
			case TABS.INTEGRATIONS.key:
				return <ProjectSettingsIntegrations viewer={viewer} history={history} intl={intl} />;
			case TABS.PROJECT_PROGRESS.key:
				return <ProjectProgress viewer={viewer} project={project} intl={intl} />;
			default:
				//this can happen if you add a wrong parameter to the url
				setSelectedTab(TABS.GENERAL.key);
		}
	};

	const getHeaderName = () => {
		switch (selectedTab) {
			case TABS.GENERAL.key:
				return intl.formatMessage({id: 'project_settings.page_header_general'});
			case TABS.STATUS.key:
				return intl.formatMessage({id: 'project_settings.page_header_status'});
			case TABS.TASKS.key:
				return intl.formatMessage({id: 'project_settings.page_header_tasks'});
			case TABS.FINANCIALS.key:
				return intl.formatMessage({id: 'project_settings.page_header_financials'});
			case TABS.TEAM.key:
				return intl.formatMessage({id: 'project_settings.page_header_team'});
			case TABS.INTEGRATIONS.key:
				return intl.formatMessage({id: 'project_settings.page_header_integrations'});
			case TABS.PROJECT_PROGRESS.key:
				return intl.formatMessage({id: 'project_settings.page_header_progress'});
			default:
				return intl.formatMessage({id: 'project_settings.page_title'});
		}
	};

	return (
		<PageWrapper data-cy={'project-settings-page'}>
			<ProjectHeader title={getHeaderName()} project={viewer.project || null} psProject={viewer.psProject || null} />
			<CustomScrollDiv>
				<MainSectionWrapper noWidth={selectedTab === TABS.TEAM.key} fullHeight={selectedTab === TABS.INTEGRATIONS.key}>
					{getSettingsSection()}
				</MainSectionWrapper>
			</CustomScrollDiv>
		</PageWrapper>
	);
};

const ProjectSettingsPageQuery = graphql`
	query ProjectSettingsPage_Query($projectId: String, $loadCustomFields: Boolean!) {
		viewer {
			actualPersonId
			component(name: "project_settings")
			project(id: $projectId) {
				id
			}
			...ProjectSettingsPage_viewer @arguments(projectId: $projectId, loadCustomFields: $loadCustomFields)
		}
	}
`;

graphql`
	fragment ProjectSettingsPage_project on ProjectType {
		id
		...ProjectPriorityDropdown_project
		...ProjectManualProgressConfiguration_project
		...ProjectHeader_project
		...SecondaryNavigation_project
		...ValueCalculations_project
		companyProjectId
		customProjectId
		isInProjectGroup
		projectGroupId
		name
		budgetType
		budget
		useBaseline
		defaultPeriodPeriodicity
		defaultPeriodLength
		defaultPeriodBudgetType
		defaultPeriodHoursAmount
		defaultPeriodPriceAmount
		defaultPeriodSettingSubtractValue
		defaultPeriodSettingRollValue
		githubRepositoryIds
		useManualAllocations
		taskCostCalculationType
		unassignedTaskHourlyCost
		linkToPublicCreateTaskPage
		usePublicCreateTaskPassword
		publicCreateTaskPassword
		publicCreateTaskPageStatusColumn {
			id
			name
		}
		manualProgressOnProjectEnabled
		manualProgressOnPhasesEnabled
		manualProgressOnTasksEnabled
		revenueMarginLow
		revenueMarginHigh
		hasInvoices
		hasLockedPeriod
		hasLockedTime
		userCanDeleteProject
		userCantDeleteProjectReason
		baselineWinChance
		teamsId
		sageProject {
			id
			sageProjectId
			sageProjectName
		}
		currentProjectStatus {
			id
			color
			description
			day
			month
			year
			person {
				id
				firstName
				lastName
				profilePictureId
			}
			...ProjectStatus_projectStatus
		}
		projectStatuses(first: 10000) @connection(key: "Project_projectStatuses") {
			edges {
				node {
					id
					year
					month
					day
					hour
					minute
					second
					...ProjectStatus_projectStatus
				}
			}
		}
		manualProgressOnProjectEnabled
		progressDetails {
			id
			progress
			createdAt
			createdBy {
				firstName
				lastName
				profilePictureId
			}
		}
		progressHistory {
			id
			progress
			createdAt
			createdBy {
				firstName
				lastName
				profilePictureId
			}
		}
		isProgramRevenueLocked
		programBudgetType
		programRemainingBudget
		program {
			name
			prefix
			totalAllocation
			budgetValue
			revenueSetting
			members {
				edges {
					node {
						role
						person {
							id
						}
					}
				}
			}
		}
		harvestProject {
			id
		}
		harvestProjectId
		jiraSyncRemaining

		jiraCloudProject {
			id
			name
		}
		...JiraIntegrationSection_project
		jiraServerProject {
			id
			name
		}
		isJiraProject
		slackChannelId
		slackChannel {
			id
		}
		salesforceOpportunity
		unit4Project {
			id
			name
		}
		hubspotDeal
		pipedriveDeal
		vstsProject
		vstsAccount
		vstsArea
		vstsSyncBugs
		vstsTwoWaySync
		vstsUseEpics
		vstsUseNewSubtasks
		gitlabProjectId
		gitlabProjectName
		gitlabServerProjectId
		gitlabServerProjectName
		gitHubSyncTarget
		status
		projectColor
		statusColumnsV2(first: 1000000) {
			edges {
				node {
					id
					category
					name
				}
			}
		}
		projectLabels(first: 10000) {
			edges {
				node {
					id
					label {
						id
						name
						color
					}
				}
			}
		}
		description
		estimationUnit
		sprintTimeBox
		sprintLengthInDays
		projectStartYear
		projectStartMonth
		projectStartDay
		projectEndYear
		projectEndMonth
		projectEndDay
		taskLevels
		useTaskHierarchy
		useDeliverables
		minutesPerEstimationPoint
		billable
		remainingAutoCalculated
		useTaskOwner
		useTaskFollowers
		retainerPeriods(first: 1) {
			edges {
				node {
					id
				}
			}
		}
		projectPersons(first: 10000, includeInactive: true) @connection(key: "Project_projectPersons", filters: []) {
			edges {
				node {
					id
					isContactPerson
					role {
						id
						name
					}
					person {
						id
						firstName
						lastName
						fullName
						initials
						profiles(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
						permissions
						email
						profilePictureId
						profilePictureDefaultId
						active
						isViewer
						harvestUser
						role {
							id
							name
						}
						personLabels(first: 1000) {
							edges {
								node {
									id
									label {
										id
										name
									}
								}
							}
						}
					}
					relationType
				}
			}
		}
		rateCard {
			id
			currency
			disabledRoles {
				id
			}
		}
		client {
			id
			clientBillTos(first: 10000) @connection(key: "BillFrom_clientBillTos", filters: []) {
				edges {
					node {
						id
						clientId
						billTo {
							id
						}
						billFrom {
							id
						}
					}
				}
			}
		}
		tasks(first: 100000) {
			edges {
				node {
					id
					name
					companyTaskId
					statusColumnV2 {
						name
						category
					}
					parentTaskId
					sageIntacctId
				}
			}
		}
		projectBillTos(first: 10000) @connection(key: "Project_projectBillTos", filters: []) {
			edges {
				node {
					id
					clientBillToId
					projectId
					clientId
					invoiced
					billTo {
						id
						name
					}
					billFrom {
						id
						name
						integration
					}
				}
			}
		}
	}
`;

export {ProjectSettingsPageQuery};

export default withSocketHandling(
	withRouter(
		createFragmentContainer(ProjectSettingsPage, {
			viewer: graphql`
				fragment ProjectSettingsPage_viewer on Viewer
				@argumentDefinitions(projectId: {type: "String"}, loadCustomFields: {type: "Boolean!"}) {
					actualPersonId
					id
					component(name: "project_settings")
					language
					githubConnected
					availableFeatureFlags {
						key
					}
					company {
						id
						...ProjectPriorityDropdown_company
						createdAt ##new companies should not see the hex code input
						tier
						forecastDemo
						modules {
							moduleType
						}
						profiles(first: 100000) {
							edges {
								node {
									id
									name
								}
							}
						}
						sageIntacctTimeRegsFromForecast
						isChargebeeCustomer
						characterLimit
						salesforceEnabled
						newSalesforceEnabled
						hubspotAccountId
						salesforceUrl
						githubEnabled
						githubInstallationId
						vstsEnabled
						vstsAccount
						unit4Enabled
						currency
						harvestEnabled
						slackEnabled
						newSlackEnabled
						jiraCloudEnabled
						jiraServerEnabled
						gitlabEnabled
						gitlabServerEnabled
						gitlabServerUrl
						teamsEnabled
						sageIntacctConnectionEnabled
						pipedriveCompanyDomain
						isUsingSchedulingPlanMode
						isUsingProjectAllocation
						isUsingMixedAllocation
						labels(first: 10000, labelType: PROJECT) {
							edges {
								node {
									...LabelDropdown_labels
								}
							}
						}
						clients {
							edges {
								node {
									id
									name
									logoId
									logoDefaultId
								}
							}
						}
						rateCards {
							edges {
								...RateCardDropdown_rateCards
								node {
									id
									disabledRoles {
										id
									}
								}
							}
						}
						exchangeRates(first: 10000) {
							edges {
								node {
									id
									currency
									rate
								}
							}
						}
						## team section start ##
						roles(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
						allPersons(first: 10000, onlyActive: true, excludeClientUsers: true)
							@connection(key: "Company_allPersons", filters: []) {
							edges {
								node {
									id
									firstName
									lastName
									fullName
									initials
									active
									profilePictureId
									profilePictureDefaultId
									email
									monday
									tuesday
									wednesday
									thursday
									friday
									saturday
									sunday
									harvestUser
									role {
										id
										name
									}
								}
							}
						}
						teams(first: 1000) {
							edges {
								node {
									id
									name
									teamPersons(first: 1000) {
										edges {
											node {
												id
												person {
													id
													active
													firstName
													lastName
													fullName
													initials
													profilePictureId
													profilePictureDefaultId
													email
													monday
													tuesday
													wednesday
													thursday
													friday
													saturday
													sunday
													harvestUser
													role {
														id
														name
													}
												}
											}
										}
									}
								}
							}
						}
						## team section end ##
					}
					project(id: $projectId) {
						customFieldValues @include(if: $loadCustomFields) {
							edges {
								node {
									id
									key
									value
									displayName
									readOnly
								}
							}
						}
						...ProjectSettingsPage_project @relay(mask: false)
					}
					psProject(companyProjectId: $projectId) {
						...ProjectHeader_psProject
					}
				}
			`,
		})
	)
);
