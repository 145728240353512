import React from 'react';
import moment from 'moment';

import {
	InvoiceLinesTableContainer,
	InvoiceLinesDetailsSection,
	AddLineButton,
	InvoiceDetails,
	DetailLabelColumn,
	DetailValueColumn,
	DetailLabel,
	DetailValue,
} from '../../../styles/sections/insights/invoicing/invoicing_page_styled';
import {InvoiceRowHeader, BasicTextInput, CurrencyInput} from 'web-components';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import InvoiceRow from '../../../components/invoice-row/Invoice_row';
import Util from '../../../forecast-app/shared/util/util';
import AddButton from '../../../components/add_button';
import DatePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import {DATE_PICKER_STYLE} from '../../../constants';

const InvoicePaymentsList = ({intl, intl: {formatMessage}, payments, setPayments, currency, invoiceTotal, canEditPayments}) => {
	const columns = {
		datePicker: true,
		notes: true,
		paidInput: true,
		hasContextMenu: true,
	};

	const duplicatePayment = index => {
		const newPayment = {...payments[index]};
		newPayment.id = null;

		const newPayments = [...payments];
		newPayments.splice(index, 0, newPayment);

		setPayments(newPayments);
	};

	const deletePayment = index => {
		const newPayments = [...payments];
		newPayments.splice(index, 1);
		setPayments(newPayments);
	};

	const getContextMenuOptions = index => {
		return [
			{
				text: formatMessage({id: 'common.duplicate'}),
				onClick: () => duplicatePayment(index),
				disabled: !canEditPayments,
			},
			{
				text: formatMessage({id: 'common.delete'}),
				onClick: () => deletePayment(index),
				disabled: !canEditPayments,
			},
		];
	};

	const addPayment = () => {
		const newPaymentsList = [
			{
				amount: null,
				day: moment().date(),
				month: moment().month() + 1,
				year: moment().year(),
				notes: null,
			},
			...payments,
		];

		setPayments(newPaymentsList);
	};

	const updatePayment = (paymentIndex, field, value) => {
		let paymentToUpdate = {...payments[paymentIndex]};

		switch (field) {
			case 'date':
				paymentToUpdate.day = value.date();
				paymentToUpdate.month = value.month() + 1;
				paymentToUpdate.year = value.year();
				break;
			case 'notes':
				paymentToUpdate.notes = value;
				break;
			case 'amount':
				paymentToUpdate.amount = +value;
				break;
			default:
				break;
		}

		let updatedPaymentsList = [...payments];
		updatedPaymentsList.splice(paymentIndex, 1, paymentToUpdate);

		setPayments(updatedPaymentsList);
	};

	const getDateField = (payment, index) => {
		return (
			<DatePicker
				useCompactStyle
				startDate={Util.CreateNonUtcMomentDate(payment.year, payment.month, payment.day)}
				handleDateRangeChange={d => updatePayment(index, 'date', d)}
				datePickerStyle={DATE_PICKER_STYLE.SLIM}
				isSingleDatePicker={true}
				disabled={!canEditPayments}
			/>
		);
	};

	const getNotesField = (payment, index) => {
		return (
			<BasicTextInput
				width={'450px'}
				value={payment.notes || ''}
				onChange={value => updatePayment(index, 'notes', value)}
				disabled={!canEditPayments}
			/>
		);
	};

	const getAmountsField = (payment, index) => {
		return {
			paidAmount: (
				<CurrencyInput
					width={'82px'}
					value={payment.amount || ''}
					callback={amount => updatePayment(index, 'amount', amount)}
					currencySymbole={Util.GetCurrencySymbol(currency)}
					disabled={!canEditPayments}
					intl={intl}
				/>
			),
		};
	};

	const totalPaid = payments.reduce((acc, payment) => acc + payment.amount, 0);
	const unPaid = invoiceTotal - totalPaid;

	return (
		<>
			<InvoiceLinesTableContainer data-userpilot={'edit-payments-table'}>
				<InvoiceRowHeader intl={intl} columns={columns} />

				<CustomScrollDiv autoHeightMin={100} autoHeightMax={400}>
					{payments.map((payment, index) => {
						return (
							<InvoiceRow
								key={index}
								columns={columns}
								datePickerDate={getDateField(payment, index)}
								notes={getNotesField(payment, index)}
								amounts={getAmountsField(payment, index)}
								contextMenuOptions={getContextMenuOptions(index)}
							/>
						);
					})}
				</CustomScrollDiv>
			</InvoiceLinesTableContainer>
			<InvoiceLinesDetailsSection>
				{canEditPayments ? (
					<AddLineButton data-userpilot={'add-payment-button'}>
						<AddButton onClick={addPayment} />
					</AddLineButton>
				) : null}
				<InvoiceDetails>
					<DetailLabelColumn>
						<DetailLabel>{formatMessage({id: 'common.total_paid'})}</DetailLabel>
						<DetailLabel isTotal>{formatMessage({id: 'settings_subscription.unpaid'})}</DetailLabel>
					</DetailLabelColumn>
					<DetailValueColumn>
						<DetailValue>
							{Util.getFormattedNumberWithCurrency(Util.GetCurrencySymbol(currency), totalPaid, intl)}
						</DetailValue>
						<DetailValue isTotal>
							{Util.getFormattedNumberWithCurrency(Util.GetCurrencySymbol(currency), unPaid, intl)}
						</DetailValue>
					</DetailValueColumn>
				</InvoiceDetails>
			</InvoiceLinesDetailsSection>
		</>
	);
};

export default InvoicePaymentsList;
