import {createRefetchContainer, graphql} from 'react-relay';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {FlexColumn} from '@forecast-it/design-system';
import ProjectInvoicingTotals from './ProjectInvoicingTotals';
import ProjectInvoicingTableSection from './ProjectInvoicingTableSection';
import ProjectInvoicingUninvoicedItemsTableSection from './ProjectInvoicingUninvoicedItemsTableSection';
import Util from '../../../../forecast-app/shared/util/util';
import ClientSelector from '../../../../components/client_selector';
import {hasPermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import UpdateProjectMutation from '../../../../mutations/update_project_mutation.modern';
import GenericEmptyState from '../../empty_states/generic_empty_state';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {cloneDeep} from 'lodash';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {Route, useLocation, useRouteMatch} from 'react-router-dom';
import StandaloneEditInvoiceModalWrapper from '../../../modal/edit_invoice_modal/StandaloneEditInvoiceModalWrapper';
import {BUDGET_TYPE} from '../../../../constants';
import {dateFragmentPropsFromIntervalInIsoFormat, dateObjectToIsoDate} from '../../../../forecast-app/shared/util/DateUtil';
import moment from 'moment';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';

const ProjectInvoicingPage = ({viewer, relay: {refetch}}) => {
	useTrackPage('Project Financials Invoicing', null, {budgetType: viewer.project.budgetType});
	const {formatMessage} = useIntl();
	const {path} = useRouteMatch();
	const location = useLocation();
	const [hasClient, setHasClient] = useState(!!viewer.project.client);

	const [startDate, setStartDate] = useState(dateObjectToIsoDate(moment().startOf('month')));
	const [endDate, setEndDate] = useState(dateObjectToIsoDate(moment().endOf('month')));

	const onDatePickerDateChanged = newDates => {
		trackEvent('Uninvoiced Date Range', 'Changed', {startDate: newDates.startDate, endDate: newDates.endDate});
		setStartDate(newDates.startDate);
		setEndDate(newDates.endDate);
	};

	const doRefetch = () => {
		refetch({projectId: viewer.project.id, ...dateFragmentPropsFromIntervalInIsoFormat(startDate, endDate)});
	};

	useEffect(doRefetch, [startDate, endDate]);

	const handleSelectClient = selected => {
		const onSuccess = () => {
			setHasClient(true);
		};
		Util.CommitMutation(
			UpdateProjectMutation,
			{
				project: viewer.project,
				clientId: selected ? (selected.value ? selected.value : selected) : null,
			},
			onSuccess
		);
	};

	const showAddClientModal = () => {
		showModal({type: MODAL_TYPE.ADD_CLIENT});
	};
	return (
		<>
			<CustomScrollDiv>
				{hasClient ? (
					<FlexColumn gap={'xl'}>
						<ProjectInvoicingTotals project={viewer.project} company={viewer.company} />
						{viewer.project.budgetType !== BUDGET_TYPE.FIXED_PRICE &&
							viewer.project.budgetType !== BUDGET_TYPE.FIXED_PRICE_V2 &&
							viewer.project.budgetType !== BUDGET_TYPE.NON_BILLABLE && (
								<ProjectInvoicingUninvoicedItemsTableSection
									project={viewer.project}
									startDate={startDate}
									endDate={endDate}
									onChangeDateInterval={onDatePickerDateChanged}
								/>
							)}
						<ProjectInvoicingTableSection
							viewer={viewer}
							project={viewer.project}
							company={viewer.company}
							refetch={doRefetch}
						/>
					</FlexColumn>
				) : (
					<GenericEmptyState
						iconClass={'client'}
						headerText={formatMessage({id: 'empty_state.project_client.header_text'})}
						text={formatMessage({id: 'empty_state.project_client.text'})}
						extraContent={
							hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE) ? (
								<ClientSelector
									canChangeClient={true}
									clients={cloneDeep(viewer.company.clients.edges)}
									showAddClientModal={showAddClientModal}
									handleSelectItem={handleSelectClient}
									showNoClient={false}
								/>
							) : null
						}
					/>
				)}
			</CustomScrollDiv>
			<Route
				path={`${path}/I-:companyInvoiceId`}
				render={compObj =>
					location.hash === '#invoicing' ? (
						<StandaloneEditInvoiceModalWrapper
							retry={refetch}
							{...compObj}
							companyInvoiceId={compObj.match.params.companyInvoiceId}
						/>
					) : null
				}
			/>
		</>
	);
};

export const ProjectInvoicingPageQuery = graphql`
	query ProjectInvoicingPage_Query(
		$projectId: ID
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
	) {
		viewer {
			actualPersonId
			component(name: "project_invoicing_page")
			...ProjectInvoicingPage_viewer
				@arguments(
					projectId: $projectId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export default createRefetchContainer(
	ProjectInvoicingPage,
	{
		viewer: graphql`
			fragment ProjectInvoicingPage_viewer on Viewer
			@argumentDefinitions(
				projectId: {type: "ID"}
				startYear: {type: "Int!", defaultValue: 2020}
				startMonth: {type: "Int!", defaultValue: 1}
				startDay: {type: "Int!", defaultValue: 1}
				endYear: {type: "Int!", defaultValue: 2099}
				endMonth: {type: "Int!", defaultValue: 12}
				endDay: {type: "Int!", defaultValue: 31}
			) {
				...ProjectInvoicingTableSection_viewer
				company {
					currency
					clients {
						edges {
							node {
								id
								name
								xeroContactId
								economicCustomerId
								quickbooksCustomerId
								sageIntacctCustomerId
							}
						}
					}
					...ProjectInvoicingTableSection_company
				}
				project(internalId: $projectId) {
					id
					client {
						id
					}
					budgetType
					...ProjectInvoicingTotals_project
					...ProjectInvoicingUninvoicedItemsTableSection_project
						@arguments(
							startYear: $startYear
							startMonth: $startMonth
							startDay: $startDay
							endYear: $endYear
							endMonth: $endMonth
							endDay: $endDay
						)
					...ProjectInvoicingTableSection_project
				}
			}
		`,
	},
	graphql`
		query ProjectInvoicingPageRefetchQuery(
			$projectId: ID
			$startYear: Int!
			$startMonth: Int!
			$startDay: Int!
			$endYear: Int!
			$endMonth: Int!
			$endDay: Int!
		) {
			viewer {
				...ProjectInvoicingPage_viewer
					@arguments(
						projectId: $projectId
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
					)
			}
		}
	`
);
