import React from 'react';
import {Button, CaretIcon} from 'web-components';
import {FakeHeaderContainer, GoBackContainer, GoBackText} from './app_salesforce_style';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {FormattedMessage, useIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {ButtonsWrapper, DescriptionWrapper, IconAndDescriptionWrapper} from './app_hubspot_style';
import {MainSectionWrapper, PageWrapper, SubSection} from '../../project/project_settings/ProjectSettings_styled';
import goSimploLogo from '../../../images/integrations/third-party/go-simplo.png';

const AppGoSimplo = ({history}) => {
	const intl = useIntl();

	const goBackToIntegrationsPage = () => {
		history.push('/admin/integrations');
	};

	const getStarted = () => {
		window.open('https://www.forecast.app/integrations/gosimplo');
	};

	return (
		<PageWrapper>
			<MainSectionWrapper width={1250}>
				<FakeHeaderContainer onClick={goBackToIntegrationsPage}>
					<GoBackContainer>
						<CaretIcon size={CaretIcon.SIZE.STANDARD} color={'#535353'} direction={CaretIcon.DIRECTION.LEFT} />
						<GoBackText>
							{intl.formatMessage({id: 'app_salesforce.salesforce_configuration.go_back_to_integrations'})}
						</GoBackText>
					</GoBackContainer>
				</FakeHeaderContainer>

				<CustomScrollDiv>
					<SubSection>
						<IconAndDescriptionWrapper>
							<img src={goSimploLogo} />
							<DescriptionWrapper>
								<FormattedMessage id="settings.app_catalog.go_simplo.description" />
							</DescriptionWrapper>
						</IconAndDescriptionWrapper>

						<ButtonsWrapper>
							<Button variant={Button.VARIANT.GREEN_FILLED} onClick={getStarted}>
								<FormattedMessage id="common.get_started" />
							</Button>
						</ButtonsWrapper>
					</SubSection>
				</CustomScrollDiv>
			</MainSectionWrapper>
		</PageWrapper>
	);
};

export default withRouter(AppGoSimplo);
