import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import MoveTaskToAnotherProjectMutation from '../../mutations/move_task_to_another_project_mutation';
import CreateTaskMutation from '../../mutations/create_task_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {withRouter} from 'react-router-dom';
import Util from '../../forecast-app/shared/util/util';
import {dispatch, EVENT_ID} from '../event_manager';
import {createTask} from '../../components/scheduling/scheduling_mutations';
import GenericModal from '../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import ProjectDropdownRelay from '../../forecast-app/shared/components/dropdowns/project-dropdown-b/Project_dropdown';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../components/warning';
import {pathIncludesTask, removeTaskLinkFromUrl} from '../../forecast-app/shared/util/UrlUtil';

class taskLocationModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			moveToProjectOptionSelected: this.props.currentProjectId,
		};
	}

	confirm() {
		if (!this.props.taskId) {
			const onSuccess = result => {
				Util.showTaskModal(result.createTask.task.node.companyTaskId, this.props.history);
				if (this.props.onCreateSuccess) {
					this.props.onCreateSuccess(result.createTask.task.node.id);
				}
			};
			const isTime = window.location.href.includes('/time');
			// Automatically create time reg functionality
			const onTimeSuccess = result => {
				const taskId = result.createTask.task.node.id;
				const companyTaskId = result.createTask.task.node.companyTaskId;
				// Notify time registration to create a time reg
				dispatch(EVENT_ID.NEW_TIME_REG_TASK, taskId, this.state.moveToProjectOptionSelected);
				if (this.props.onCreateSuccess) {
					this.props.onCreateSuccess(result.createTask.task.node.id);
				}
				Util.showTaskModal(companyTaskId, this.props.history);
			};

			let statusColumnId;
			//find corresponding status column for selected project
			const project = this.props.viewer.projects.edges.find(
				project => project.node.id === this.state.moveToProjectOptionSelected
			);
			if (this.props.projectGroupStatusColumnId) {
				if (project !== null && project !== undefined) {
					statusColumnId = project.node.statusColumnsV2.edges.find(
						col => col.node.projectGroupStatusColumnId === this.props.projectGroupStatusColumnId
					).id;
				}
			}

			if (Util.isScheduling()) {
				const mutationObject = {
					projectId: this.state.moveToProjectOptionSelected,
					name: '',
					socketClientId: Util.getClientId(),
				};
				createTask(mutationObject, response => {
					Util.showTaskModal(response.createTask.task.node.companyTaskId, this.props.history);
					Util.dispatchScheduleEvent(response);
				});
			} else {
				Util.localStorageDeleteFincancialMap();
				Util.CommitSchedulingModalUpdate(
					CreateTaskMutation,
					{
						projectId: this.state.moveToProjectOptionSelected,
						placementOfTask: 'TOP',
						statusColumnId: statusColumnId,
						companyId: this.props.viewer.company.id,
						name: this.props.name ? this.props.name : isTime ? 'New Task' : '',
						description: this.props.description ? this.props.description : undefined,
						deadlineYear: this.props.deadlineYear ? this.props.deadlineYear : undefined,
						deadlineMonth: this.props.deadlineMonth ? this.props.deadlineMonth : undefined,
						deadlineDay: this.props.deadlineDay ? this.props.deadlineDay : undefined,
						startYear: this.props.startYear ? this.props.startYear : undefined,
						startMonth: this.props.startMonth ? this.props.startMonth : undefined,
						startDay: this.props.startDay ? this.props.startDay : undefined,
						forecast: this.props.estimate || undefined,
						assignedPersons: this.props.assignedPersons ? this.props.assignedPersons : undefined,
						roleId: this.props.roleId ? this.props.roleId : undefined,
					},
					isTime ? onTimeSuccess : onSuccess
				);
			}

			const path = window.location.pathname;
			if (pathIncludesTask(path)) {
				const newUrl = removeTaskLinkFromUrl(path);
				this.props.history.push(newUrl);
			}
		} else if (this.props.currentProjectId !== this.state.moveToProjectOptionSelected) {
			const onSuccess = result => {
				if (result.updateTask?.errors?.length > 0) {
					showModal({
						type: MODAL_TYPE.GENERIC,
						className: 'default-warning-modal',
						content: <Warning message={result.updateTask.errors[0]} />,
						buttons: [
							{
								text: this.props.intl.formatMessage({id: 'common.cancel'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				} else {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({id: 'task_location_modal.card_moved'}),
					});
					if (this.props.onSuccess) {
						this.props.onSuccess();
					}
				}
			};
			const ids = this.props.taskId.constructor === Array ? this.props.taskId : [this.props.taskId];
			const mutationObject = {
				ids: ids,
				projectId: this.state.moveToProjectOptionSelected,
				viewer: this.props.viewer,
			};
			if (this.props.currentProjectId || this.props.projectGroupId) {
				const selectedProject = this.props.viewer.projects.edges.find(
					project => project.node.id === this.state.moveToProjectOptionSelected
				);
				const currentProject = this.props.viewer.projects.edges.find(
					project => project.node.id === this.props.currentProjectId
				);

				mutationObject.previousProjectId = this.props.currentProjectId;
				mutationObject.sameProjectGroup =
					selectedProject.node.projectGroupId &&
					selectedProject.node.projectGroupId === (this.props.projectGroupId || currentProject.node.projectGroupId);

				if (this.props.statusColumns) {
					const affectedStatusColumns = this.props.statusColumns;
					const filters = affectedStatusColumns.reduce((acc, col) => {
						if (col.projectGroupStatusColumnId) {
							return acc.concat([col.id, col.projectGroupStatusColumnId]);
						} else {
							acc.push(col.id);
							return acc;
						}
					}, []);

					mutationObject.filters = filters;
				}
			}

			Util.CommitSchedulingModalUpdate(MoveTaskToAnotherProjectMutation, mutationObject, onSuccess);
		}
	}

	handleProjectChange(selected) {
		this.setState({moveToProjectOptionSelected: selected.value});
	}

	handleProjectSelected(selected) {
		this.setState({moveToProjectOptionSelected: selected[0]});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const hasOpportunityAccess = Util.hasOpportunityAccess(this.props.viewer.company.modules);

		const projects = this.props.currentProjectGroupId
			? this.props.viewer.projects.edges.filter(
					project => project.node.projectGroupId === this.props.currentProjectGroupId
			  )
			: this.props.viewer.projects.edges;
		const content = (
			<div className="content project-dropdown-wrapper">
				<ProjectDropdownRelay
					showHalted={false}
					showDone={false}
					projects={projects}
					optionsName={formatMessage({id: 'common.projects'})}
					selectedItems={this.state.moveToProjectOptionSelected ? [this.state.moveToProjectOptionSelected] : []}
					onRemove={this.handleProjectSelected.bind(this)}
					onSelect={this.handleProjectSelected.bind(this)}
					groupByStatus
					showRunning
					showPlanning
					showOpportunity={hasOpportunityAccess}
					width={372}
					maxHeight={255}
					name={
						this.props.currentProjectGroupName
							? 'Projects in ' + this.props.currentProjectGroupName
							: formatMessage({id: 'common.all_projects'})
					}
					userpilot="timesheets-select-project"
					cy={'project-list'}
				/>
			</div>
		);

		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: this.props.taskId ? formatMessage({id: 'common.move'}) : formatMessage({id: 'common.select'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.confirm.bind(this),
						cy: 'task-modal-location-submit',
						disabled: !this.state.moveToProjectOptionSelected,
					},
				]}
				cy={'task-location-modal'}
				content={content}
				overflowVisible={true}
				headerText={
					this.props.taskId
						? formatMessage({id: 'task_location_modal.title'})
						: formatMessage({id: 'task_location_modal.select_project'})
				}
			/>
		);
	}
}
taskLocationModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	name: PropTypes.string,
	description: PropTypes.string,
	deadlineYear: PropTypes.number,
	deadlineMonth: PropTypes.number,
	deadlineDay: PropTypes.number,
	startYear: PropTypes.number,
	startMonth: PropTypes.number,
	startDay: PropTypes.number,
	estimate: PropTypes.number,
	assignedPersons: PropTypes.arrayOf(PropTypes.string),
	roleId: PropTypes.string,
	onCreateSuccess: PropTypes.func,
};

const taskLocationModalQuery = graphql`
	query taskLocationModal_Query {
		viewer {
			actualPersonId
			component(name: "task_location_modal")
			...taskLocationModal_viewer
		}
	}
`;

export {taskLocationModalQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(taskLocationModal, {
			viewer: graphql`
				fragment taskLocationModal_viewer on Viewer {
					id
					company {
						id
						modules {
							moduleType
						}
					}
					availableFeatureFlags {
						key
					}
					projects(first: 100000, excludeDoneOrHalted: true, excludeReadOnly: true, excludeRestricted: true)
						@connection(key: "Viewer_projects", filters: []) {
						edges {
							...ProjectDropdown_projects
							node {
								id
								projectColor
								projectGroupId
								name
								companyProjectId
								status
								isJiraProject
								harvestProject {
									id
								}
								statusColumnsV2(first: 1000000) {
									edges {
										node {
											id
											category
											projectGroupStatusColumnId
											order
										}
									}
								}
							}
						}
					}
				}
			`,
		})
	)
);
