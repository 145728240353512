import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {Button, CaretIcon, Checkbox, SalesforceIcon} from 'web-components';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import createSalesforceVerificationKeyMutation from '../../../mutations/create_salesforce_verification_key_mutation';
import Util from '../../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {
	ArrowsIcon,
	ButtonWrapper,
	ContentWrapper,
	Description,
	FakeHeaderContainer,
	ForecastLogo,
	GoBackContainer,
	GoBackText,
	GreenCheckboxMark,
	LogosWrapper,
	ModalContent,
	ModalTitle,
	PageWrapper,
	ParentContentWrapper,
	SandboxWrapper,
	Title,
} from './app_salesforce_style';
import {getLocalStorageSilent, setLocalStorageSilent} from '../../../forecast-app/shared/util/LocalStorageUtil';

export const installPackage = useSandbox => {
	Util.localStorageSetItem('salesforce-package-installed', true);
	window.location.href = `https://${useSandbox ? 'test' : 'login'}.salesforce.com/packaging/installPackage.apexp?${
		process.env.SALESFORCE_PACKAGE_URL
	}`;
};

const salesforceInstallView = ({intl}) => {
	const history = useHistory();
	const localStoragePackageInstalled = Util.localStorageGetItem('salesforce-package-installed');
	const wasInstallButtonClicked = localStoragePackageInstalled ? localStoragePackageInstalled : false;

	const [useSandbox, setUseSandbox] = useState(
		getLocalStorageSilent('salesforce-use-sandbox') &&
			getLocalStorageSilent('salesforce-use-sandbox').toLowerCase() === 'true'
	);

	const toggleSandbox = () => {
		const val = !useSandbox;
		setUseSandbox(val);
		setLocalStorageSilent('salesforce-use-sandbox', val.toString());
	};

	const activate = () => {
		Util.CommitMutation(
			createSalesforceVerificationKeyMutation,
			{useSandbox: useSandbox},
			({createSalesforceVerificationKey}) =>
				(window.location.href = `https://${useSandbox ? 'test' : 'login'}.salesforce.com/services/oauth2/authorize?
				client_id=${process.env.SALESFORCE_APP_CLIENT_ID}
				&redirect_uri=${process.env.API_URL}/salesforce/oauth
				&response_type=code
				&scope=api+refresh_token+offline_access
				&state=${createSalesforceVerificationKey.key}`)
		);
	};

	const goBackToIntegrationsPage = () => {
		history.push('/admin/integrations');
	};

	useEffect(() => {
		if (window.location.search && window.location.search.includes('install')) {
			showModal({
				type: MODAL_TYPE.GENERIC,
				headerText: (
					<ModalTitle>
						<GreenCheckboxMark></GreenCheckboxMark>
						<div>{intl.formatMessage({id: 'salesforce_configuration.installation_successfull_modal_title'})}</div>
					</ModalTitle>
				),
				content: (
					<ModalContent>
						{intl.formatMessage({id: 'salesforce_configuration.installation_successfull_modal_description'})}
					</ModalContent>
				),
				className: 'default-warning-modal',
				buttons: [
					{
						text: intl.formatMessage({id: 'common.activate'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: activate,
					},
				],
			});
		}
	}, []);

	const title = <Title>{intl.formatMessage({id: 'app_salesforce.integrate_page.title'})}</Title>;

	const pageDescription = !wasInstallButtonClicked ? (
		<Description>
			<FormattedMessage
				id="settings.app_catalog.salesforce.description"
				values={{
					a: msg => (
						<a
							href="https://support.forecast.app/hc/en-us/articles/5126937540497"
							target="_blank"
							rel="noopener noreferrer"
						>
							{msg}
						</a>
					),
				}}
			/>
		</Description>
	) : null;

	const logos = (
		<LogosWrapper>
			<SalesforceIcon size={SalesforceIcon.SIZE.EXTRA_BIG} />
			<ArrowsIcon />
			<ForecastLogo />
		</LogosWrapper>
	);

	const buttonText = wasInstallButtonClicked
		? intl.formatMessage({id: 'common.activate'})
		: intl.formatMessage({id: 'settings.app_catalog.salesforce.package'});

	const buttonOnClickHandler = () => {
		if (!wasInstallButtonClicked) {
			installPackage(useSandbox);
		} else {
			activate();
		}
	};

	const button = (
		<ButtonWrapper>
			<Button size={Button.SIZE.STANDARD} variant={Button.VARIANT.PURPLE_OUTLINE} onClick={buttonOnClickHandler}>
				{buttonText}
			</Button>
			<SandboxWrapper>
				<label htmlFor={'use-sandbox-checkbox'}>
					{intl.formatMessage({id: 'app_salesforce.salesforce_configuration.use_sandbox'})}
				</label>
				<Checkbox id={'use-sandbox-checkbox'} checked={useSandbox} onClick={() => toggleSandbox()} />
			</SandboxWrapper>
		</ButtonWrapper>
	);

	const installOrActivateDescriptionText = wasInstallButtonClicked ? (
		<Description>
			<FormattedMessage
				id="settings.app_catalog.salesforce.not_installed"
				values={{
					a: chunks => <a onClick={() => installPackage(useSandbox)}>{chunks}</a>,
				}}
			/>
		</Description>
	) : (
		<Description>
			<FormattedMessage
				id="settings.app_catalog.salesforce.already_installed"
				values={{
					a: chunks => <a onClick={activate}>{chunks}</a>,
				}}
			/>
		</Description>
	);

	const helpCenterText = (
		<Description>
			<FormattedMessage
				id="settings.app_catalog.salesforce.help_center_text"
				values={{
					a: t => <a href={'https://support.forecast.app/hc/en-us/articles/5126937540497'}>{t}</a>,
				}}
			/>
		</Description>
	);

	return (
		<div>
			<PageWrapper>
				<FakeHeaderContainer>
					<GoBackContainer onClick={() => goBackToIntegrationsPage()}>
						<CaretIcon size={CaretIcon.SIZE.STANDARD} color={'#535353'} direction={CaretIcon.DIRECTION.LEFT} />
						<GoBackText>
							{intl.formatMessage({id: 'app_salesforce.salesforce_configuration.go_back_to_integrations'})}
						</GoBackText>
					</GoBackContainer>
				</FakeHeaderContainer>
			</PageWrapper>
			<ParentContentWrapper>
				<ContentWrapper>
					{title}
					{pageDescription}
					{logos}
					{button}
					{installOrActivateDescriptionText}
					{helpCenterText}
				</ContentWrapper>
			</ParentContentWrapper>
		</div>
	);
};

export default salesforceInstallView;
