import React from 'react';
import { Button, TableBar, Tabs } from '@forecast-it/design-system';
import { FormattedMessage } from 'react-intl';
import { useRemappingFormatMessage } from '../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import { TABS } from './ProjectBreakdownTable';
const ProjectBreakdownHeader = ({ tab, onTabChange, onExportCsv, useBaseline, }) => {
    const formatMessage = useRemappingFormatMessage();
    const showTab = (messageId) => useBaseline || messageId !== TABS.baseline;
    return (React.createElement(TableBar, { title: 'Breakdown', "data-cy": 'breakdown-table-bar' },
        React.createElement(Button, { emphasis: 'medium', onClick: onExportCsv },
            React.createElement(FormattedMessage, { id: "common.export-csv" })),
        React.createElement(Tabs, { id: TableBar.ELEMENTS.TABS, value: tab, onValueChange: onTabChange }, Object.values(TABS).map(messageId => showTab(messageId) && React.createElement(Tabs.Tab, { value: messageId }, formatMessage({ id: messageId }))))));
};
export default ProjectBreakdownHeader;
