import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import * as tracking from '../../../tracking';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import DisableSalesforceMutation from '../../../mutations/DisableSalesforceMutation';
import Util from '../../../forecast-app/shared/util/util';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import {BasicTextInput, Button, CaretIcon, CheckmarkIcon, PageHeader, Switch, WarningIcon} from 'web-components';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import Warning from '../../../components/warning';
import SalesforceStagesTable from './SalesforceStagesTable';
import {
	DropdownAddButtonWrapper,
	DropdownWrapper,
	FakeHeaderContainer,
	GoBackContainer,
	GoBackText,
	HorizontalFlexWrapper,
	RefNumberWrapper,
	TableHeaderWrapper,
	TestStatusWrapper,
	TextAreaWrapper,
} from './app_salesforce_style';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import {
	InformationSection,
	MainSectionWrapper,
	PageWrapper,
	SelectionElementLabel,
	SubSection,
} from '../../project/project_settings/ProjectSettings_styled';
import {installPackage} from './SalesforceInstallView';
import SalesforceTemplatesTable from './SalesforceTemplatesTable';
import AddButton from '../../../components/add_button';
import {cloneDeep} from 'lodash';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import DirectApi from '../../../directApi';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';

class appSalesforce extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fetchingStages: false,
			stages: [],
			projectTemplates: {useProjectTemplates: null, templatesList: []},
			localStages: [],
			projectToDuplicate: null,
			referenceNumberToCreate: null,
			projectIdFromProjectSettings: null,
			showDuplicateProjectView: false,
			duplicateExpenses: CompanySetupUtil.hasFinance(), // Set to false if company does not have financial module
			duplicateRetainerPeriods: CompanySetupUtil.hasFinance(), // Set to false if company does not have financial module
			duplicateColor: true,
			newUpdateAvailable: false,
			contextMenuData: null,
			opportunityCondition: {query: '', enabled: false},
			showOpportunityCondition: false,
			testingOpportunityCondition: false,
			connectionFailed: false,
			importQuoteEnabled: false,
			importQuoteStage: '',
		};

		this.superPropertyChecksum = trackPage('App Catalog - Salesforce');
	}

	componentDidMount() {
		const latestPackageVersion = {
			minorVersion: 2,
			majorVersion: 1,
		};
		document.title = 'Salesforce - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-salesforce');

		if (this.props.viewer.company.newSalesforceEnabled) {
			this.setState({fetchingStages: true});

			DirectApi.Fetch('salesforce/opportunity_stages')
				.then(json => {
					this.setState({
						isSandbox: json.isSandbox,
						stages: json.stagesSettings,
						localStages: json.stagesSettings.filter(s => !!s.ProjectStatus || !!s.CreateProject),
						fetchingStages: false,
						projectTemplates: {
							...json.projectTemplates,
							templatesList: json.projectTemplates.templatesList
								? json.projectTemplates.templatesList.filter(
										pT =>
											this.props.viewer.projects.edges.filter(
												p => Util.getIdFromBase64String(p.node.id) === pT.projectId
											).length > 0
								  )
								: [],
						},
						duplicateSettings: json.projectSettings && json.projectSettings.duplicateSettings,
						duplicateTasks: json.projectSettings && json.projectSettings.duplicateTasks,
						duplicatePhases: json.projectSettings && json.projectSettings.duplicatePhases,
						duplicateTeam: json.projectSettings && json.projectSettings.duplicateTeam,
						duplicateAllocations: json.projectSettings && json.projectSettings.duplicateAllocations,
						duplicateExpenses: json.projectSettings && json.projectSettings.duplicateExpenses,
						duplicateRetainerPeriods: json.projectSettings && json.projectSettings.duplicateRetainerPeriods,
						duplicateColor: json.projectSettings && json.projectSettings.duplicateColor,
						projectIdFromProjectSettings: json.projectSettings && json.projectSettings.projectId,
						showDuplicateProjectView: json.projectSettings ? !!json.projectSettings.projectId : false,
					});
				})
				.then(() => {
					this.saveStages(
						this.getCurrentStagesSettings(),
						this.getCurrentProjectSettings(),
						this.state.projectTemplates,
						true
					);
				})
				.catch(() => {
					this.setState({fetchingStages: false, connectionFailed: true});
				});

			DirectApi.Fetch('salesforce/package_version').then(response => {
				const installedPackageVersion = response.packageVersion;
				if (
					latestPackageVersion.majorVersion > installedPackageVersion.MajorVersion ||
					(latestPackageVersion.majorVersion === installedPackageVersion.MajorVersion &&
						latestPackageVersion.minorVersion > installedPackageVersion.MinorVersion)
				) {
					this.setState({
						newUpdateAvailable: true,
					});
				}
			});

			DirectApi.Fetch('salesforce/opportunity_condition').then(response =>
				this.setState({
					opportunityCondition: response,
					showOpportunityCondition: response && response.enabled,
				})
			);

			if (hasFeatureFlag('salesforce_import_opportunity_quote')) {
				DirectApi.Fetch('salesforce/import_quote').then(response =>
					this.setState({
						importQuoteEnabled: response.enabled,
						importQuoteStage: response.stage,
					})
				);
			}
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5126937540497');
	}

	goBackToIntegrationsPage() {
		this.props.history.push('/admin/integrations');
	}

	handleProjectSelectChange(selectedProject) {
		let projectSettings;
		const stagesSettings = this.getCurrentStagesSettings();
		if (selectedProject === null) {
			projectSettings = {...this.getCurrentProjectSettings(), projectId: null};
			this.saveStages(stagesSettings, projectSettings).then(() => {});
		} else {
			projectSettings = {...this.getCurrentProjectSettings(), projectId: Util.getIdFromBase64String(selectedProject)};
			this.saveStages(stagesSettings, projectSettings).then(() => {});
		}
	}

	getStateWithNullValues() {
		return {
			duplicateSettings: null,
			duplicateTasks: null,
			duplicatePhases: null,
			duplicateTeam: null,
			duplicateAllocations: null,
			duplicateExpenses: null,
			duplicateRetainerPeriods: null,
			duplicateColor: null,
		};
	}

	handleProjectTemplateViewChange(showDuplicateProjectView) {
		if (showDuplicateProjectView === true) {
			this.saveStages(this.getCurrentStagesSettings(), this.getCurrentProjectSettings());
		} else {
			const stagesSettings = this.getCurrentStagesSettings();
			const projectSettings = {...this.getCurrentProjectSettings(), ...this.getStateWithNullValues(), projectId: null};
			this.saveStages(stagesSettings, projectSettings).then(() => {
				this.setState({...this.getStateWithNullValues(), showDuplicateProjectView: false});
			});
		}
	}

	getCurrentProjectSettings() {
		return {
			projectId: this.state.projectIdFromProjectSettings,
			duplicateSettings: this.state.duplicateSettings,
			duplicateTasks: this.state.duplicateTasks,
			duplicatePhases: this.state.duplicatePhases,
			duplicateTeam: this.state.duplicateTeam,
			duplicateAllocations: this.state.duplicateAllocations,
			duplicateExpenses: this.state.duplicateExpenses,
			duplicateRetainerPeriods: this.state.duplicateRetainerPeriods,
			duplicateColor: this.state.duplicateColor,
		};
	}

	getCurrentStagesSettings() {
		return this.state.localStages.filter(s => s.ProjectStatus || s.CreateProject);
	}

	genericHandleTemplatesCheckboxChange(fieldToUpdate, index) {
		const stagesSettings = this.getCurrentStagesSettings();
		let projectSettings = this.getCurrentProjectSettings();
		projectSettings[fieldToUpdate] = !projectSettings[fieldToUpdate];
		let projectTemplatesCopy = cloneDeep(this.state.projectTemplates);
		let templateToUpdate = projectTemplatesCopy.templatesList[index];
		templateToUpdate[fieldToUpdate] = !templateToUpdate[fieldToUpdate];
		this.saveStages(stagesSettings, projectSettings, projectTemplatesCopy).then(() => {
			this.setState({projectTemplates: projectTemplatesCopy});
		});
	}

	handleDefaultTemplateChange(index) {
		const stagesSettings = this.getCurrentStagesSettings();
		const projectSettings = {...this.getCurrentProjectSettings(), duplicateColor: !this.state.duplicateColor};
		const currentDefaultTemplateIndex = this.state.projectTemplates.templatesList.findIndex(
			template => template.default === true
		);
		let projectTemplatesCopy = cloneDeep(this.state.projectTemplates);
		projectTemplatesCopy.templatesList[currentDefaultTemplateIndex].default = false;
		projectTemplatesCopy.templatesList[index].default = true;
		this.saveStages(stagesSettings, projectSettings, projectTemplatesCopy).then(() => {
			this.setState({projectTemplates: projectTemplatesCopy});
		});
	}

	handleAddProject() {
		const stagesSettings = this.getCurrentStagesSettings();
		const projectSettings = this.getCurrentProjectSettings();
		const projectTemplatesToAdd = {
			referenceNumberToCreate: null,
			projectTemplatesToAdd: null,
			useProjectTemplates: true,
			templatesList: [
				...this.state.projectTemplates.templatesList,
				{
					projectId: Util.getIdFromBase64String(this.state.projectToDuplicate),
					default: this.state.projectTemplates.templatesList.length === 0,
					reference: this.state.referenceNumberToCreate,
					...this.getStateWithNullValues(),
				},
			],
		};
		this.saveStages(stagesSettings, projectSettings, projectTemplatesToAdd).then(() => {
			this.setState({projectTemplates: projectTemplatesToAdd, referenceNumberToCreate: null});
		});
	}

	deactivateIntegration() {
		const {formatMessage} = this.props.intl;

		const callbackPositive = params => {
			Util.CommitMutation(DisableSalesforceMutation);

			// Empty state in case of re-enable shenanigans
			this.setState({
				fetchingStages: false,
				stages: [],
				localStages: [],
			});
		};

		let info = 'settings.app_catalog.salesforce.disable_message_warning';

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId={info} />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			className: 'default-warning-modal',
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					callback: callbackPositive,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
				},
			],
		});
	}

	async saveStages(stagesSettings, projectSettings, projectTemplates, suppressToast) {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const init = {
			headers: headers,
			credentials: 'include',
			method: 'PUT',
			body: JSON.stringify({
				stagesSettings: stagesSettings,
				projectSettings,
				projectTemplates: projectTemplates,
			}),
		};

		fetch(DirectApi.graphqlServerEndpoint('salesforce/opportunity_stages'), init).then(() => {
			if (!suppressToast)
				createToast({
					duration: 2000,
					message: this.props.intl.formatMessage({id: 'common.changes_saved'}),
				});
		});
	}

	async synchronizeProjectTemplates() {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const init = {
			headers: headers,
			credentials: 'include',
			method: 'POST',
		};

		fetch(DirectApi.graphqlServerEndpoint('salesforce/project_templates'), init).then(() => {
			createToast({
				duration: 2000,
				message: this.props.intl.formatMessage({id: 'settings.app_catalog.salesforce.project_templates_synchronized'}),
			});
		});
	}

	async setStageState(stage) {
		const localStagesAsc = [...this.state.localStages.filter(s => s.Id !== stage.Id), stage].sort(
			(a, b) => a.SortOrder - b.SortOrder
		);

		console.log('Stage state set: ', stage);

		let createProject = false;
		let projectStatusSet = false;
		for (var i = 0; i < localStagesAsc.length; i++) {
			const stage = localStagesAsc[i];
			if (stage.CreateProject) createProject = true;
			if (stage.ProjectStatus) projectStatusSet = true;

			if (stage.CreateProject) {
				// Ensure - All stages with create project is on or before a project stage. Else default stage
				if (!projectStatusSet) {
					stage.ProjectStatus = Util.getProjectStageForDropdown(this.props.intl, true)[0].value;
					projectStatusSet = true;
				}
			}

			if (stage.ProjectStatus) {
				// Ensure - All stages with non-null Project stage is after atleast one create project Else no stage
				if (!createProject) {
					stage.ProjectStatus = null;
				}
			}
		}

		const projectSettings = this.getCurrentProjectSettings();
		const stagesSettings = localStagesAsc;

		await this.saveStages(stagesSettings, projectSettings);

		this.setState({
			localStages: localStagesAsc,
		});
	}

	handleDeleteTemplate(templateIndex) {
		const stagesSettings = this.getCurrentStagesSettings();
		const projectSettings = this.getCurrentProjectSettings();
		const templatesListCopy = [...this.state.projectTemplates.templatesList];
		templatesListCopy.splice(templateIndex, 1);
		const updatedProjectTemplates = {
			useProjectTemplates: true,
			templatesList: [...templatesListCopy],
		};
		this.saveStages(stagesSettings, projectSettings, updatedProjectTemplates).then(() => {
			this.setState({projectTemplates: updatedProjectTemplates, referenceNumberToCreate: null});
		});
	}

	handleSwitchChange() {
		const stagesSettings = this.getCurrentStagesSettings();
		const projectSettings = this.getCurrentProjectSettings();
		const updatedProjectTemplates = {
			...this.state.projectTemplates,
			useProjectTemplates: !this.state.projectTemplates.useProjectTemplates,
		};
		this.saveStages(stagesSettings, projectSettings, updatedProjectTemplates).then(() => {
			this.setState({projectTemplates: updatedProjectTemplates});
		});
	}

	toggleOpportunityCondition() {
		if (this.state.showOpportunityCondition && this.state.opportunityCondition?.enabled) {
			// Enabled on back-end and disabling. Should disable on back-end
			const headers = new Headers();
			headers.append('Content-Type', 'application/json');
			fetch(DirectApi.graphqlServerEndpoint('salesforce/opportunity_condition'), {
				headers: headers,
				credentials: 'include',
				method: 'PUT',
				body: JSON.stringify({query: this.state.opportunityCondition?.query, enabled: false}),
			});

			this.setState({
				showOpportunityCondition: !this.state.showOpportunityCondition,
				opportunityCondition: {query: this.state.opportunityCondition?.query, enabled: false},
			});
		} else {
			this.setState({
				showOpportunityCondition: !this.state.showOpportunityCondition,
			});
		}
	}

	setOpportunityCondition(query, enabled) {
		const body = {
			query,
			enabled,
		};

		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		if (query && enabled) {
			this.setState({
				testingOpportunityCondition: true,
			});
		}

		fetch(DirectApi.graphqlServerEndpoint('salesforce/opportunity_condition'), {
			headers: headers,
			credentials: 'include',
			method: 'PUT',
			body: JSON.stringify(body),
		})
			.then(result => result.json())
			.then(response => {
				if (response.enabled) {
					this.setState({
						opportunityCondition: response,
						showOpportunityCondition: response.enabled,
						testingOpportunityCondition: false,
					});
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({
							id: 'settings.app_catalog.salesforce.opportunity_condition_passed_test',
						}),
					});
				} else {
					this.setState({
						testingOpportunityCondition: false,
					});

					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({
							id: 'settings.app_catalog.salesforce.opportunity_condition_failed_test',
						}),
					});
				}
			});
	}

	generateRefSuggestion(projectName) {
		let nameSuggestion;

		const nameSplit = projectName.split(' ');
		if (nameSplit.length > 2) {
			nameSuggestion = `${nameSplit[0][0]}${nameSplit[1][0]}${nameSplit[2][0]}`;
		} else if (projectName.length > 2) {
			nameSuggestion = `${projectName[0]}${projectName[1]}${projectName[2]}`;
		} else {
			nameSuggestion = projectName;
		}

		if (!this.checkRefAvailability(nameSuggestion)) {
			let number = 1;
			while (true) {
				let suggestionWithNumber = `${nameSuggestion} - ${number}`;
				if (this.checkRefAvailability(suggestionWithNumber)) {
					return suggestionWithNumber;
				}
				number++;
			}
		}

		return nameSuggestion;
	}

	checkRefAvailability(ref) {
		return this.state.projectTemplates.templatesList.filter(template => template.reference === ref.trim()).length === 0;
	}

	toggleImportQuote() {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		fetch(DirectApi.graphqlServerEndpoint('salesforce/import_quote'), {
			headers: headers,
			credentials: 'include',
			method: 'PUT',
			body: JSON.stringify({enabled: !this.state.importQuoteEnabled, stage: this.state.importQuoteStage}),
		}).then(() => {
			createToast({
				duration: 2000,
				message: this.props.intl.formatMessage({id: 'common.changes_saved'}),
			});
		});

		this.setState({
			importQuoteEnabled: !this.state.importQuoteEnabled,
		});
	}

	setImportQuoteStage(option) {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		fetch(DirectApi.graphqlServerEndpoint('salesforce/import_quote'), {
			headers: headers,
			credentials: 'include',
			method: 'PUT',
			body: JSON.stringify({enabled: this.state.importQuoteEnabled, stage: option.value}),
		}).then(() => {
			createToast({
				duration: 2000,
				message: this.props.intl.formatMessage({id: 'common.changes_saved'}),
			});
		});

		this.setState({
			importQuoteStage: option.value,
		});
	}

	render() {
		const projects = this.props.viewer.projects.edges;

		const addDisabled =
			!this.state.projectToDuplicate ||
			!this.state.referenceNumberToCreate ||
			this.state.referenceNumberToCreate === '' ||
			!this.checkRefAvailability(this.state.referenceNumberToCreate);

		const deactivateSection = (
			<SubSection
				title={this.props.intl.formatMessage({
					id: 'salesforce_configuration.deactivate_salesforce',
				})}
				description={
					this.state.connectionFailed
						? this.props.intl.formatMessage({
								id: 'settings.app_catalog.salesforce.connection_failed',
						  })
						: ''
				}
				actionElement={
					<Button
						size={Button.SIZE.STANDARD}
						variant={Button.VARIANT.RED_OUTLINE}
						onClick={() => this.deactivateIntegration()}
					>
						{this.props.intl.formatMessage({id: 'common.deactivate'})}
					</Button>
				}
			></SubSection>
		);

		const projectTemplateSection = (
			<SubSection
				title={this.props.intl.formatMessage({id: 'settings.app_catalog.common.project_template'})}
				description={<FormattedMessage id="settings.app_catalog.common.project_template_description" />}
			>
				<>
					<TableHeaderWrapper>
						<HorizontalFlexWrapper>
							<Switch
								checked={this.state.projectTemplates.useProjectTemplates}
								onChange={this.handleSwitchChange.bind(this)}
							/>
							<SelectionElementLabel>
								{this.props.intl.formatMessage({id: 'common.enable'})}
							</SelectionElementLabel>
						</HorizontalFlexWrapper>

						<ForecastTooltip
							content={this.props.intl.formatMessage({
								id: 'settings.app_catalog.salesforce.export_to_salesforce.tooltip',
							})}
						>
							<Button
								size={Button.SIZE.STANDARD}
								variant={Button.VARIANT.GREEN_FILLED}
								onClick={() => this.synchronizeProjectTemplates()}
							>
								{this.props.intl.formatMessage({
									id: 'settings.app_catalog.salesforce.export_to_salesforce',
								})}
							</Button>
						</ForecastTooltip>
					</TableHeaderWrapper>
					{this.state.projectTemplates.useProjectTemplates ? (
						<>
							{this.state.projectTemplates.templatesList.length > 0 && (
								<SalesforceTemplatesTable
									templates={this.state.projectTemplates.templatesList}
									projects={this.props.viewer.projects.edges}
									genericHandleTemplatesCheckboxChange={this.genericHandleTemplatesCheckboxChange.bind(this)}
									handleDefaultTemplateChange={this.handleDefaultTemplateChange.bind(this)}
									handleDeleteTemplate={this.handleDeleteTemplate.bind(this)}
									intl={this.props.intl}
								/>
							)}
							<DropdownAddButtonWrapper>
								<DropdownWrapper>
									<Dropdown
										onChange={option => {
											this.setState({
												projectToDuplicate: option.value,
												referenceNumberToCreate: this.generateRefSuggestion(option.label),
											});
										}}
										value={this.state.projectToDuplicate ? this.state.projectToDuplicate : ''}
										options={projects.map(project => ({
											label: project.node.name,
											value: project.node.id,
										}))}
										hideLabel={false}
										customHeight={30}
										placeholder={this.props.intl.formatMessage({
											id: 'cards.select-project',
										})}
									/>
								</DropdownWrapper>
								<RefNumberWrapper>
									<ForecastTooltip
										content={this.props.intl.formatMessage({
											id: 'settings.app_catalog.salesforce.ref_no_tooltip',
										})}
									>
										<BasicTextInput
											value={this.state.referenceNumberToCreate}
											onChange={option => {
												this.setState({referenceNumberToCreate: option});
											}}
											width="122px"
											placeholder={'Ref.'}
											maxLength={12}
										/>
									</ForecastTooltip>
								</RefNumberWrapper>
								<AddButton onClick={this.handleAddProject.bind(this)} disabled={addDisabled} />
							</DropdownAddButtonWrapper>
						</>
					) : null}
				</>
			</SubSection>
		);

		const opportunityConditionSection = (
			<SubSection
				title={this.props.intl.formatMessage({
					id: 'settings.app_catalog.salesforce.opportunity_condition',
				})}
				description={this.props.intl.formatMessage({
					id: 'settings.app_catalog.salesforce.opportunity_condition_description',
				})}
			>
				<TableHeaderWrapper>
					<HorizontalFlexWrapper>
						<Switch
							checked={this.state.showOpportunityCondition}
							onChange={this.toggleOpportunityCondition.bind(this)}
						/>
						<SelectionElementLabel>{this.props.intl.formatMessage({id: 'common.enable'})}</SelectionElementLabel>
					</HorizontalFlexWrapper>
					{this.state.showOpportunityCondition ? (
						<ForecastTooltip
							content={this.props.intl.formatMessage({
								id: 'settings.app_catalog.salesforce.test_and_save_condition.tooltip',
							})}
						>
							<HorizontalFlexWrapper>
								<TestStatusWrapper>
									{this.state.testingOpportunityCondition ? (
										<InlineLoader />
									) : this.state.opportunityCondition.enabled ? (
										<CheckmarkIcon />
									) : (
										<WarningIcon size={WarningIcon.SIZE.STANDARD} updated color={'#E01212'} />
									)}
								</TestStatusWrapper>
								<Button
									size={Button.SIZE.STANDARD}
									variant={Button.VARIANT.GREEN_FILLED}
									onClick={() => this.setOpportunityCondition(this.state.opportunityCondition.query, true)}
								>
									{this.props.intl.formatMessage({id: 'common.save'})}
								</Button>
							</HorizontalFlexWrapper>
						</ForecastTooltip>
					) : (
						''
					)}
				</TableHeaderWrapper>

				{this.state.showOpportunityCondition ? (
					<TextAreaWrapper>
						<textarea
							value={this.state.opportunityCondition.query}
							onChange={q =>
								this.setState({
									opportunityCondition: {
										query: q.target.value,
										enabled: false,
									},
								})
							}
						></textarea>
					</TextAreaWrapper>
				) : (
					''
				)}
			</SubSection>
		);

		const opportunityStageSection = (
			<SubSection
				title={this.props.intl.formatMessage({
					id: 'settings.app_catalog.salesforce.opportunity_stages',
				})}
				description={
					<FormattedMessage
						id="settings.app_catalog.salesforce.opportunity_description"
						values={{
							a: msg => (
								<a
									href="https://support.forecast.app/hc/en-us/articles/5126937540497"
									target="_blank"
									rel="noopener noreferrer"
								>
									{msg}
								</a>
							),
						}}
					/>
				}
			>
				<div className="stages-selector">
					<SalesforceStagesTable
						setStageState={this.setStageState.bind(this)}
						intl={this.props.intl}
						stages={this.state.stages}
						localStages={this.state.localStages}
					/>
				</div>
			</SubSection>
		);

		const importQuoteSection = (
			<SubSection
				title={this.props.intl.formatMessage({
					id: 'settings.app_catalog.salesforce.import_quote',
				})}
				description={this.props.intl.formatMessage({
					id: 'settings.app_catalog.salesforce.import_quote.description',
				})}
			>
				<>
					<TableHeaderWrapper>
						<HorizontalFlexWrapper>
							<Switch checked={this.state.importQuoteEnabled} onChange={this.toggleImportQuote.bind(this)} />
							<SelectionElementLabel>
								{this.props.intl.formatMessage({id: 'common.enable'})}
							</SelectionElementLabel>
						</HorizontalFlexWrapper>
					</TableHeaderWrapper>

					{this.state.importQuoteEnabled && (
						<DropdownWrapper>
							<Dropdown
								onChange={option => this.setImportQuoteStage(option)}
								value={this.state.importQuoteStage}
								options={this.state.stages.map(stage => ({
									label: stage.ApiName,
									value: stage.ApiName,
								}))}
								hideLabel={false}
								customHeight={30}
								placeholder={this.props.intl.formatMessage({
									id: 'cards.select-project',
								})}
							/>
						</DropdownWrapper>
					)}
				</>
			</SubSection>
		);

		let content = (
			<div>
				{this.props.viewer.company.newSalesforceEnabled ? (
					<>
						{this.state.newUpdateAvailable && (
							<InformationSection
								title={'New update available'}
								actionElement={<a onClick={() => installPackage(this.state.isSandbox)}>Update now</a>}
							></InformationSection>
						)}
						{!this.state.fetchingStages ? (
							<>
								{this.state.connectionFailed ? '' : opportunityStageSection}
								{this.state.connectionFailed ? '' : opportunityConditionSection}
								{this.state.connectionFailed ? '' : projectTemplateSection}
								{this.state.connectionFailed
									? ''
									: hasFeatureFlag('salesforce_import_opportunity_quote')
									? importQuoteSection
									: ''}
								{deactivateSection}
							</>
						) : (
							<InlineLoader />
						)}
					</>
				) : (
					''
				)}
			</div>
		);

		return (
			<PageWrapper>
				{this.props.children}

				<div style={{padding: '16px 24px'}}>
					<PageHeader>
						{this.props.intl.formatMessage({id: 'app_salesforce.salesforce_configuration.title'})}
					</PageHeader>
				</div>
				<FakeHeaderContainer onClick={() => this.goBackToIntegrationsPage()}>
					<GoBackContainer>
						<CaretIcon size={CaretIcon.SIZE.STANDARD} color={'#535353'} direction={CaretIcon.DIRECTION.LEFT} />
						<GoBackText>
							{this.props.intl.formatMessage({
								id: 'app_salesforce.salesforce_configuration.go_back_to_integrations',
							})}
						</GoBackText>
					</GoBackContainer>
				</FakeHeaderContainer>
				<CustomScrollDiv>
					<MainSectionWrapper width={1250}>{content}</MainSectionWrapper>
				</CustomScrollDiv>
			</PageWrapper>
		);
	}
}

const appSalesforceQuery = graphql`
	query appSalesforce_Query {
		viewer {
			actualPersonId
			component(name: "app_salesforce")
			...appSalesforce_viewer
		}
	}
`;

export {appSalesforceQuery};

export default createFragmentContainer(injectIntl(withRouter(appSalesforce)), {
	viewer: graphql`
		fragment appSalesforce_viewer on Viewer {
			id
			availableFeatureFlags {
				key
			}
			projects(first: 10000) {
				edges {
					node {
						id
						name
						companyProjectId
						useManualAllocations
						useBaseline
						projectColor
						...DeprecatedProjectIndicatorJS_project
					}
				}
			}
			company {
				id
				salesforceEnabled
				newSalesforceEnabled
				salesforceAutoCreate
				modules {
					moduleType
				}
				salesforceStages {
					id
					name
					sortOrder
					autoCreate
				}
			}
		}
	`,
});
