import React, {useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {CSS_CONSTANTS} from '../../css_variables';
import {RateCardsV2} from '../../components/settings-finance/settings_finance_rate_cards_v2';
import {ExpenseCategories} from '../../components/settings-finance/settings_finance_expense_categories';
import {Currencies} from '../../components/settings-finance/settings_finance_currencies';
import {Invoicing} from '../../components/settings-finance/settings_finance_invoicing';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {Tab} from './tabs/tab';
import {Tabs} from './tabs/tabs';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import {HIDDEN_FEATURES, MODULE_TYPES} from '../../constants';
import {BudgetTolerance} from '../../components/settings-finance/SettingsFinanceBudgetTolerance';
import Util from '../../forecast-app/shared/util/util';
import CompanySetupUtil, {isFeatureHidden} from '../../forecast-app/shared/util/CompanySetupUtil';

const SettingsFinanceStyles = styled.div`
	.inner {
		padding: 16px 36px 50px;
		.header {
			display: flex;
			.finance-headline {
				flex-grow: 1;
			}
			.custom-button-container {
				height: 30px;
			}
		}
	}

	h1 {
		font-size: 24px;
		font-family: 'neue-haas-grotesk-text', sans-serif;
		margin-bottom: 0;
		padding-bottom: 20px;
	}

	h2 {
		font-size: 20px;
		font-family: 'neue-haas-grotesk-text', sans-serif;
		font-weight: 500;
		margin-top: 32px;
		margin-bottom: 0;
	}

	.description {
		width: 50%;
		font-size: 13px;
		margin-top: 16px;
		margin-bottom: 0;
	}

	table {
		margin-top: 24px;
		width: 100%;

		thead {
			th {
				font-size: 9px;
				text-transform: uppercase;
				font-weight: 500;
				color: ${CSS_CONSTANTS.v2_text_light_gray};
				text-align: left;
				letter-spacing: 1px;
				border-bottom: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
				height: 30px;

				.header-wrapper {
					display: flex;
					align-items: center;
				}
			}
		}

		tbody {
			tr {
				td {
					height: 48px;
					border-bottom: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
					color: ${CSS_CONSTANTS.v2_text_gray};
					font-size: 13px;
					vertical-align: center;
				}

				&:hover {
					td {
						background-color: ${CSS_CONSTANTS.v2_list_background_purple};
					}
				}
			}
		}
	}
`;

const TABS = {
	RATE_CARDS: 'rate-cards',
	EXPENSE_CATEGORIES: 'expense-categories',
	CURRENCIES: 'currencies',
	BUDGET_TOLERANCE: 'budget-tolerance',
	INVOICING: 'invoicing',
};

const getDefaultTab = hash => {
	if (hash) {
		const hashTab = hash.replace('#', '');
		if (Object.values(TABS).some(tab => tab === hashTab)) {
			return hashTab;
		}
	}

	return isFeatureHidden(HIDDEN_FEATURES.REVENUE) ? TABS.EXPENSE_CATEGORIES : TABS.RATE_CARDS;
};

const settingsFinance = ({intl: {formatMessage}, viewer: {company, projects}}) => {
	const history = useHistory();
	const hasNovaInsights = hasModule(MODULE_TYPES.NOVA_INSIGHTS) && Util.hasNovaInsights(company);
	const rateCards = company.rateCards.edges.map(edge => edge.node);
	const expenseCategories = company.expenseCategories.edges.map(edge => edge.node);
	const exchangeRates = company.exchangeRates.edges.map(edge => edge.node);
	projects = projects.edges.map(edge => edge.node);

	const invoicingIntegrationEnabled = company.newXeroEnabled || company.economicEnabled || company.quickbooksEnabled;

	useEffect(() => {
		document.title = 'Finance - Account - Forecast';
	}, []);

	const defaultTab = getDefaultTab(history.location.hash);

	const onSelectTab = tabIndex => {
		location.hash = Object.values(TABS)[tabIndex];
	};

	return (
		<div className="section-content settings-app settings-finance" data-cy={'settings-finance-page'}>
			<TopHeaderBarWrapper sidePadding={24}>
				<TopHeaderBar title={formatMessage({id: 'settings_finance.menuTitle'})} />
			</TopHeaderBarWrapper>
			<CustomScrollDiv>
				<SettingsFinanceStyles>
					<div className="section-body">
						<div className="inner">
							<Tabs onTabSelect={onSelectTab}>
								{!isFeatureHidden(HIDDEN_FEATURES.REVENUE) ? (
									<Tab
										key={TABS.RATE_CARDS}
										defaultSelected={defaultTab === TABS.RATE_CARDS}
										title={formatMessage({id: 'common.rate-cards'})}
									>
										<RateCardsV2 company={company} projects={projects} rateCards={rateCards} />
									</Tab>
								) : (
									<></>
								)}
								{CompanySetupUtil.hasCreateExpense() ? (
									<Tab
										key={TABS.EXPENSE_CATEGORIES}
										defaultSelected={defaultTab === TABS.EXPENSE_CATEGORIES}
										title={formatMessage({id: 'common.expense-categories'})}
									>
										<ExpenseCategories company={company} expenseCategories={expenseCategories} />
									</Tab>
								) : (
									<></>
								)}
								{!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) ? (
									<Tab
										key={TABS.CURRENCIES}
										defaultSelected={defaultTab === TABS.CURRENCIES}
										title={formatMessage({id: 'settings.currencies'})}
									>
										<Currencies company={company} exchangeRates={exchangeRates} />
									</Tab>
								) : (
									<></>
								)}
								{hasNovaInsights && CompanySetupUtil.hasFinance() ? (
									<Tab
										key={TABS.BUDGET_TOLERANCE}
										defaultSelected={defaultTab === TABS.BUDGET_TOLERANCE}
										title={'Budget Tolerance'}
									>
										<BudgetTolerance company={company} />
									</Tab>
								) : (
									<></>
								)}
								{CompanySetupUtil.hasCreateInvoice() ? (
									<Tab
										key={TABS.INVOICING}
										defaultSelected={defaultTab === TABS.INVOICING}
										title={formatMessage({id: 'common.invoicing'})}
									>
										<Invoicing
											company={company}
											invoicingIntegrationEnabled={invoicingIntegrationEnabled}
										/>
									</Tab>
								) : (
									<></>
								)}
							</Tabs>
						</div>
					</div>
				</SettingsFinanceStyles>
			</CustomScrollDiv>
		</div>
	);
};

export const settingsFinanceQuery = graphql`
	query settingsFinance_Query {
		viewer {
			actualPersonId
			component(name: "settings_finance")
			...settingsFinance_viewer
		}
	}
`;

export default injectIntl(
	createFragmentContainer(settingsFinance, {
		viewer: graphql`
			fragment settingsFinance_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				company {
					id
					tier
					forecastDemo
					currency
					defaultRevenueMarginLow
					defaultRevenueMarginHigh
					invoiceQuantityDecimals
					defaultRateCard {
						id
					}
					defaultExpenseCategory {
						id
					}
					exchangeRates(first: 10000) @connection(key: "Company_exchangeRates") {
						edges {
							node {
								id
								currency
								rate
								isUsed
							}
						}
					}
					rateCards(first: 10000) @connection(key: "Company_rateCards") {
						edges {
							node {
								id
								name
								defaultRate
								parentRateCardId
								currency
								disabled
								isRateCardUsed
								disabledRoles {
									id
								}
								rates(first: 10000) {
									edges {
										node {
											id
											rate
											startDate
											defaultRate
											role {
												id
												name
											}
										}
									}
								}
							}
						}
					}
					expenseCategories(first: 10000) @connection(key: "Company_expenseCategories") {
						edges {
							node {
								id
								name
								disabled
								baselineUses
								canDelete
							}
						}
					}
					newXeroEnabled
					economicEnabled
					quickbooksEnabled
					integrations {
						userCanUnlinkInvoice
					}
					billFroms {
						billTos(first: 10000) @connection(key: "BillFrom_billTos", filters: []) {
							edges {
								node {
									id
								}
							}
						}
					}
				}
				projects(first: 1000000) {
					edges {
						node {
							id
							companyProjectId
							hasLockedPeriod
							hasInvoices
							rateCard {
								id
							}
						}
					}
				}
			}
		`,
	})
);
