import React from 'react';
import ContactPerson from './ContactPerson';
import {Button} from 'web-components';
import Styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../css_variables';
import {useIntl} from 'react-intl';
import {CONTACT_TYPES} from './ContactDetails';
import Util from '../../../forecast-app/shared/util/util';
import DeleteCompanyContactMutation from '../../../mutations/DeleteCompanyContactMutation';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';

const HeaderText = Styled.div`
  color: ${CSS_CONSTANTS.color_dark_gray};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const ActionContainer = Styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: self-end;
  visibility: hidden;
`;

const ContactTypeBoxContainer = Styled.div`
  width: 300px;
  border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
  padding: 20px;
  display: grid;
  &:hover {
  	background-color: ${CSS_CONSTANTS.color_medium_gray};
  	${ActionContainer} {
  		visibility: visible;
  	}
  }
`;

const ContactTypeBox = ({contactType, person, companyId}) => {
	const {formatMessage} = useIntl();
	const contactTypeFormatted = formatMessage({id: 'settings_details.contact_type.' + contactType});

	const updateContactType = () => {
		showModal({
			type: MODAL_TYPE.UPDATE_CONTACT_TYPE,
			contactType,
			person,
		});
	};

	const clearContactType = () => {
		Util.CommitMutation(
			DeleteCompanyContactMutation,
			{
				companyId,
				contactType,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_details.contact_cleared'}, {contactType: contactTypeFormatted}),
				});
			}
		);
	};

	return (
		<ContactTypeBoxContainer>
			<HeaderText>{contactTypeFormatted}</HeaderText>
			{person && <ContactPerson person={person} />}
			<ActionContainer>
				<Button
					size={Button.SIZE.SMALL}
					variant={Button.VARIANT.PURPLE_FILLED}
					onClick={updateContactType}
					cy={'update-contact-' + contactType}
				>
					Update
				</Button>
				{person && contactType !== CONTACT_TYPES.PRIMARY && (
					<Button
						size={Button.SIZE.SMALL}
						variant={Button.VARIANT.PURPLE_FILLED}
						onClick={clearContactType}
						cy={'clear-contact-' + contactType}
					>
						Clear
					</Button>
				)}
			</ActionContainer>
		</ContactTypeBoxContainer>
	);
};

export default ContactTypeBox;
