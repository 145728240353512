import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import DeleteInsightMutation from '../../mutations/delete_insight_mutation';
import DeleteSavedReportMutation from '../../mutations/delete_saved_report_mutation';
import DuplicateSavedReportMutation from '../../mutations/duplicate_saved_report_mutation';
import InsightsDashboardCard from '../../components/insights/insights_dashboard_card';
import SavedReportCard from './saved_report_card';
import InsightsEmptyState from '../../forecast-app/shared/components/empty-states/insights_empty_state';
import {injectIntl} from 'react-intl';
import * as tracking from '../../tracking';
import {BUTTON_COLOR, BUTTON_STYLE, categoryColors, ELEMENT_TYPE, HIDDEN_FEATURES, REPORT_TYPE} from '../../constants';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import TdLink from '../../components/td_link';
import Util from '../../forecast-app/shared/util/util';
import HeaderBar from '../../forecast-app/shared/components/headers/header-bar/header_bar';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {getSavedReportURLByReportType} from '../../components/insights/saved_report_util';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {hasPermission, hasSomePermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {ArrowIcon} from '../../images/arrow_icon';
import styled from 'styled-components';
import {trackEvent, trackPage, unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import {NoReports} from '../../forecast-app/reports-tab/NoReports';

class insightsSection extends Component {
	constructor(props) {
		super(props);

		const displayMode = localStorage.getItem('displayMode-insights-section');

		this.state = {
			expanded: true,
			selectedInsightId: null,
			selectedMenuItem: 'all',
			searchText: '',
			displayMode: displayMode || 'cards',
			sortBy: {column: null, ascending: true},
			showSavedReports: true,
			showComponentReports: true,
		};

		this.superPropertyChecksum = trackPage('Insights');
	}

	componentDidMount() {
		document.title = 'My insights - Forecast';
		// Segment
		tracking.trackPage('insights');
		if (!hasPermission(PERMISSION_TYPE.INSIGHTS_READ)) {
			this.props.history.push('/not-authorized');
		}
		this.setShowSavedReports(
			Util.localStorageGetItem('show-saved-reports-insights-section')
				? JSON.parse(Util.localStorageGetItem('show-saved-reports-insights-section'))
				: true
		);
		this.setShowComponentReports(
			Util.localStorageGetItem('show-component-reports-insights-section')
				? JSON.parse(Util.localStorageGetItem('show-component-reports-insights-section'))
				: true
		);
	}

	componentDidUpdate(prevState) {
		if (prevState.searchText !== this.state.searchText) {
			if (this.state.searchText && this.state.searchText !== '') {
				const hasFinancialAccess = hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
				]);

				const insights = [...this.props.viewer.company.insights.edges];
				const matchingInsights = insights.filter(insight => {
					const selectedCategory = this.state.selectedMenuItem.toLowerCase();
					const matchCategory =
						selectedCategory === 'all' || selectedCategory === insight.node.category.toLowerCase();
					const matchSearchText =
						this.state.searchText === '' ||
						(insight.node.name && Util.normalizedIncludes(insight.node.name, this.state.searchText));
					return matchCategory && matchSearchText;
				}).length;

				const savedReports = [...this.props.viewer.company.allSavedReports.edges];

				const matchingSavedReports = savedReports
					.filter(savedReport => {
						const selectedCategory = this.state.selectedMenuItem.toLowerCase();
						const matchCategory = selectedCategory === 'all' || selectedCategory === 'portfolio';
						const matchSearchText =
							this.state.searchText === '' ||
							(savedReport.node.name && Util.normalizedIncludes(savedReport.node.name, this.state.searchText));
						return matchCategory && matchSearchText;
					})
					.filter(savedReport => savedReport.node.type !== REPORT_TYPE.PROJECT_PORTFOLIO_REPORT || hasFinancialAccess)
					.filter(
						savedReport =>
							savedReport.node.type === REPORT_TYPE.TIME_REG ||
							savedReport.node.type === REPORT_TYPE.UTILIZATION_REPORT ||
							savedReport.node.type === REPORT_TYPE.PROJECT_PORTFOLIO_REPORT ||
							savedReport.node.type === REPORT_TYPE.TASK_REPORT
					).length;

				trackEvent('Search', 'Results', {
					searchString: this.state.searchText,
					matching: matchingInsights + matchingSavedReports,
				});
			}
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	openInsight(insight, edit) {
		this.props.history.push('/reports/' + insight.id + (edit ? '/edit' : ''));
	}

	deleteInsight(insight) {
		showModal({
			type: MODAL_TYPE.DELETE_REPORT_MODAL,
			reportName: insight.name,
			callback: () => {
				const onSuccess = () => {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({id: 'report.has-been-deleted'}, {name: insight.name}),
					});
				};
				Util.CommitMutation(
					DeleteInsightMutation,
					{
						id: insight.id,
						companyId: this.props.viewer.company.id,
					},
					onSuccess
				);
			},
		});
	}

	deleteSavedReport(report) {
		showModal({
			type: MODAL_TYPE.DELETE_REPORT_MODAL,
			reportName: report.name,
			callback: () => {
				const onSuccess = () => {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({id: 'report.has-been-deleted'}, {name: report.name}),
					});
				};
				Util.CommitMutation(
					DeleteSavedReportMutation,
					{
						id: report.id,
						reportService: report.reportService,
						companyId: this.props.viewer.company.id,
					},
					onSuccess
				);
			},
		});
	}

	duplicateSavedReport(report) {
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({id: 'report.has-been-duplicated'}, {name: report.name}),
			});
		};

		Util.CommitMutation(
			DuplicateSavedReportMutation,
			{
				id: report.id,
				companyId: this.props.viewer.company.id,
			},
			onSuccess
		);
	}

	setSelectedMenuItem(selectedMenuItem) {
		//new navigation returns object, old string
		this.setState({selectedMenuItem: selectedMenuItem.value ? selectedMenuItem.value : selectedMenuItem});
	}

	handleSearchChange(searchText) {
		this.setState({searchText});
	}

	setDisplayMode() {
		const val = this.state.displayMode === 'list' ? 'cards' : 'list';
		Util.localStorageSetItem('displayMode-insights-section', val);
		this.setState({displayMode: val});
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	setShowSavedReports(show) {
		this.setState({showSavedReports: show});
	}

	toggleShowSavedReports() {
		Util.localStorageSetItem('show-saved-reports-insights-section', !this.state.showSavedReports);
		this.setShowSavedReports(!this.state.showSavedReports);
	}

	setShowComponentReports(show) {
		this.setState({showComponentReports: show});
	}

	toggleShowComponentReports() {
		Util.localStorageSetItem('show-component-reports-insights-section', !this.state.showComponentReports);
		this.setShowComponentReports(!this.state.showComponentReports);
	}

	handleCreateInsight() {
		this.props.history.push('/reports/new/step1');
	}

	getHeaderTitle() {
		const content = [];

		return (
			<TopHeaderBarWrapper sidePadding={66}>
				<TopHeaderBar title="All Reports" content={content} />
			</TopHeaderBarWrapper>
		);
	}

	isReportShared = report => {
		return report.shares ? report.shares.edges && report.shares.edges.length > 0 : false;
	};

	getHeader(noReports) {
		const {formatMessage} = this.props.intl;
		const rightContent = [],
			leftContent = [];
		if (!hasFeatureFlag('cant_use_old_component_reports')) {
			const categories_options = [
				{label: formatMessage({id: 'insights.category.projects'}), value: 'projects'},
				{label: formatMessage({id: 'insights.category.people'}), value: 'people'},
				{label: formatMessage({id: 'insights.category.business'}), value: 'business'},
				{label: formatMessage({id: 'insights.category.portfolio'}), value: 'portfolio'},
				{label: formatMessage({id: 'common.all'}), value: 'all'},
			];
			// TODO: Remove state.selectedMenuItem when component reports are fully sunseted
			leftContent.push({
				type: ELEMENT_TYPE.LEFT_DROPDOWN,
				dropdownOptions: categories_options,
				value: this.state.selectedMenuItem,
				callback: this.setSelectedMenuItem.bind(this),
			});
		}
		// Hide New button, search and display mode button, when there is no reports,
		// and user has the cant_use_old_component_reports feature flag
		if (!(noReports && hasFeatureFlag('cant_use_old_component_reports'))) {
			leftContent.push({
				type: ELEMENT_TYPE.VIEW_TYPE_BUTTON,
				callback: this.setDisplayMode.bind(this),
				currentView: this.state.displayMode === 'cards' ? 'list' : 'grid',
			});

			rightContent.push({
				type: ELEMENT_TYPE.SEARCH_LAZY,
				value: this.state.searchText,
				onChange: this.handleSearchChange.bind(this),
			});

			rightContent.push({
				dataCy: 'new-report-button',
				userpilot: 'new-report-button',
				type: ELEMENT_TYPE.BUTTON,
				text: this.props.intl.formatMessage({id: 'new_report.title'}),
				callback: this.handleCreateInsight.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		return (
			<HeaderBar
				innerRef={div => (this.header_bar = div)}
				parentGroup={null}
				leftContent={leftContent}
				rightContent={rightContent}
			/>
		);
	}

	render() {
		const noTimeReg = Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS);
		const {formatMessage} = this.props.intl;

		const insights = [...this.props.viewer.company.insights.edges];
		const savedReports = [...this.props.viewer.company.allSavedReports.edges];
		const hasFinancialAccess = hasSomePermission([
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
		]);

		// Only add the financial portfolio report for user that has financial access and
		// don't have cant_use_old_component_reports feature flag
		if (hasFinancialAccess && !hasFeatureFlag('cant_use_old_component_reports')) {
			insights.unshift({
				node: {
					id: 'financial-portfolio-report',
					name: formatMessage({id: 'financial_portfolio_report.title'}),
					category: 'Portfolio',
					unEditable: true,
				},
			});
		}

		let filteredInsights = insights.filter(insight => {
			const selectedCategory = this.state.selectedMenuItem.toLowerCase();
			const matchCategory = selectedCategory === 'all' || selectedCategory === insight.node.category.toLowerCase();
			const matchSearchText =
				this.state.searchText === '' ||
				(insight.node.name && Util.normalizedIncludes(insight.node.name, this.state.searchText));
			return matchCategory && matchSearchText;
		});

		let filteredSavedReports = savedReports
			.filter(savedReport => {
				const selectedCategory = this.state.selectedMenuItem.toLowerCase();
				const matchCategory = selectedCategory === 'all' || selectedCategory === 'portfolio';
				const matchSearchText =
					this.state.searchText === '' ||
					(savedReport.node.name && Util.normalizedIncludes(savedReport.node.name, this.state.searchText));
				return matchCategory && matchSearchText;
			})
			.filter(savedReport => savedReport.node.type !== REPORT_TYPE.PROJECT_PORTFOLIO_REPORT || hasFinancialAccess)
			.filter(
				savedReport =>
					savedReport.node.type === REPORT_TYPE.TIME_REG ||
					savedReport.node.type === REPORT_TYPE.UTILIZATION_REPORT ||
					savedReport.node.type === REPORT_TYPE.PROJECT_PORTFOLIO_REPORT ||
					savedReport.node.type === REPORT_TYPE.TASK_REPORT
			);
		// If the no time registration is enabled filter out all time registration reports
		if (noTimeReg) {
			filteredSavedReports = filteredSavedReports.filter(savedReport => savedReport.node.type !== REPORT_TYPE.TIME_REG);
		}

		if (this.state.sortBy.column) {
			const direction = this.state.sortBy.ascending ? 1 : -1;
			filteredInsights.sort((a, b) =>
				a.node[this.state.sortBy.column] > b.node[this.state.sortBy.column] ? direction : direction * -1
			);
		}

		// If both are empty then there is no reports aka. noReports === true
		const noReports = filteredSavedReports.length < 1 && filteredInsights.length < 1;

		return (
			<section className="app-main insights-section">
				{this.props.children}
				<div className="section-main" data-cy={'insights-page'}>
					<div className={'dashboard useNewNavigation'}>
						{this.getHeaderTitle()}
						{this.getHeader(noReports)}
						{filteredInsights.length > 0 || filteredSavedReports.length > 0 ? (
							this.state.displayMode === 'cards' ? (
								<>
									{!hasFeatureFlag('cant_use_old_component_reports') ? (
										<div className="cards-container">
											<ReportHeaderWrapper>
												<div
													style={{display: 'flex'}}
													onClick={
														filteredSavedReports.length > 0
															? this.toggleShowSavedReports.bind(this)
															: null
													}
													disabled={filteredSavedReports.length === 0}
												>
													{formatMessage({id: 'insights.standard_reports'})}
													{filteredSavedReports.length !== -1 && (
														<ReportsAmount>{'(' + filteredSavedReports.length + ')'}</ReportsAmount>
													)}
													{filteredSavedReports.length > 0 && (
														<ArrowIcon
															direction={
																this.state.showSavedReports
																	? ArrowIcon.DIRECTION.UP
																	: ArrowIcon.DIRECTION.DOWN
															}
														/>
													)}
												</div>
											</ReportHeaderWrapper>
											{this.state.showSavedReports && (
												<div className="cards">
													<React.Fragment>
														{filteredSavedReports.map(report => {
															return (
																<SavedReportCard
																	key={report.node.id}
																	deleteSavedReport={this.deleteSavedReport.bind(
																		this,
																		report.node
																	)}
																	duplicateSavedReport={this.duplicateSavedReport.bind(
																		this,
																		report.node
																	)}
																	report={report.node}
																	intl={this.props.intl}
																	actualPersonId={this.props.viewer.actualPersonId}
																/>
															);
														})}
													</React.Fragment>
												</div>
											)}
											<ReportHeaderWrapper>
												<div
													style={{display: 'flex'}}
													onClick={
														filteredInsights.length > 0
															? this.toggleShowComponentReports.bind(this)
															: null
													}
													disabled={filteredInsights.length === 0}
												>
													{formatMessage({id: 'insights.component_reports'})}
													{filteredInsights.length !== -1 && (
														<ReportsAmount>{'(' + filteredInsights.length + ')'}</ReportsAmount>
													)}
													{filteredInsights.length > 0 && (
														<ArrowIcon
															direction={
																this.state.showComponentReports
																	? ArrowIcon.DIRECTION.UP
																	: ArrowIcon.DIRECTION.DOWN
															}
														/>
													)}
												</div>
											</ReportHeaderWrapper>
											{this.state.showComponentReports && (
												<div className="cards">
													<React.Fragment>
														{filteredInsights.map(insight => (
															<InsightsDashboardCard
																key={insight.node.id}
																insight={insight.node}
																editInsight={this.openInsight.bind(this, insight.node, true)}
																deleteInsight={this.deleteInsight.bind(this, insight.node)}
																canEdit={
																	hasPermission(PERMISSION_TYPE.INSIGHTS_UPDATE) &&
																	!insight.node.unEditable
																}
																cy={insight.node.name}
															/>
														))}
													</React.Fragment>
												</div>
											)}
										</div>
									) : (
										<div className="cards-container">
											<div className="cards">
												<React.Fragment>
													{filteredInsights.map(insight => (
														<InsightsDashboardCard
															key={insight.node.id}
															insight={insight.node}
															editInsight={this.openInsight.bind(this, insight.node, true)}
															deleteInsight={this.deleteInsight.bind(this, insight.node)}
															canEdit={
																hasPermission(PERMISSION_TYPE.INSIGHTS_UPDATE) &&
																!insight.node.unEditable
															}
															cy={insight.node.name}
														/>
													))}
													{filteredSavedReports.map(report => {
														return (
															<SavedReportCard
																key={report.node.id}
																deleteSavedReport={this.deleteSavedReport.bind(
																	this,
																	report.node
																)}
																duplicateSavedReport={this.duplicateSavedReport.bind(
																	this,
																	report.node
																)}
																report={report.node}
																intl={this.props.intl}
																actualPersonId={this.props.viewer.actualPersonId}
															/>
														);
													})}
												</React.Fragment>
											</div>
										</div>
									)}
								</>
							) : (
								<>
									{!hasFeatureFlag('cant_use_old_component_reports') ? (
										<div className="table-container">
											<ReportHeaderWrapper>
												<div
													style={{display: 'flex'}}
													onClick={
														filteredSavedReports.length > 0
															? this.toggleShowSavedReports.bind(this)
															: null
													}
													disabled={filteredSavedReports.length === 0}
												>
													{formatMessage({id: 'insights.standard_reports'})}
													{filteredSavedReports.length !== -1 && (
														<ReportsAmount>{'(' + filteredSavedReports.length + ')'}</ReportsAmount>
													)}
													{filteredSavedReports.length > 0 && (
														<ArrowIcon
															direction={
																this.state.showSavedReports
																	? ArrowIcon.DIRECTION.UP
																	: ArrowIcon.DIRECTION.DOWN
															}
														/>
													)}
												</div>
											</ReportHeaderWrapper>
											{this.state.showSavedReports && (
												<table className="insight-list-table">
													<thead>
														<tr>
															<th className="insight-column">&nbsp;</th>
															<th
																style={{width: '70%'}}
																className="sortable"
																onClick={this.setSortBy.bind(this, 'name')}
															>
																{formatMessage({id: 'time_reg_report.report_name'})}
																<span>&nbsp;&nbsp;</span>
															</th>
															<th
																className="sortable"
																onClick={this.setSortBy.bind(this, 'category')}
															>
																{formatMessage({id: 'insights.category'})}
																<span>&nbsp;&nbsp;</span>
															</th>
															<th className="insight-column">
																{formatMessage({id: 'common.shared'})}
															</th>
														</tr>
													</thead>
													<tbody>
														{filteredSavedReports.map((report, index) => {
															return (
																<tr key={index}>
																	<TdLink
																		style={{
																			backgroundColor: categoryColors.Portfolio.dark,
																		}}
																		path={getSavedReportURLByReportType(
																			report.node.type,
																			report.node.id
																		)}
																		content=""
																	/>
																	<TdLink
																		className="name"
																		path={getSavedReportURLByReportType(
																			report.node.type,
																			report.node.id
																		)}
																		content={report.node.name}
																	/>
																	<TdLink
																		className="category"
																		path={getSavedReportURLByReportType(
																			report.node.type,
																			report.node.id
																		)}
																		content={this.props.intl.formatMessage({
																			id: 'insights.category.portfolio',
																		})}
																	/>
																	<TdLink
																		className="Shared"
																		path={getSavedReportURLByReportType(
																			report.node.type,
																			report.node.id
																		)}
																		content={
																			this.isReportShared(report.node) &&
																			this.props.intl.formatMessage({
																				id: 'common.shared',
																			})
																		}
																	/>
																</tr>
															);
														})}
													</tbody>
												</table>
											)}
											<ReportHeaderWrapper>
												<div
													style={{display: 'flex'}}
													onClick={
														filteredInsights.length > 0
															? this.toggleShowComponentReports.bind(this)
															: null
													}
													disabled={filteredInsights.length === 0}
												>
													{formatMessage({id: 'insights.component_reports'})}
													{filteredInsights.length !== -1 && (
														<ReportsAmount>{'(' + filteredInsights.length + ')'}</ReportsAmount>
													)}
													{filteredInsights.length > 0 && (
														<ArrowIcon
															direction={
																this.state.showComponentReports
																	? ArrowIcon.DIRECTION.UP
																	: ArrowIcon.DIRECTION.DOWN
															}
														/>
													)}
												</div>
											</ReportHeaderWrapper>
											{this.state.showComponentReports && (
												<table className="insight-list-table">
													<thead>
														<tr>
															<th className="insight-column">&nbsp;</th>
															<th
																style={{width: '70%'}}
																className="sortable"
																onClick={this.setSortBy.bind(this, 'name')}
															>
																{formatMessage({id: 'time_reg_report.report_name'})}
																<span>&nbsp;&nbsp;</span>
															</th>
															<th
																className="sortable"
																onClick={this.setSortBy.bind(this, 'category')}
															>
																{formatMessage({id: 'insights.category'})}
																<span>&nbsp;&nbsp;</span>
															</th>
															<th className="insight-column">
																{formatMessage({id: 'common.shared'})}
															</th>
														</tr>
													</thead>
													<tbody>
														{filteredInsights.map((insight, index) => (
															<tr key={index}>
																<TdLink
																	style={{
																		backgroundColor:
																			categoryColors[insight.node.category].dark,
																	}}
																	path={window.location.pathname + '/' + insight.node.id}
																	content=""
																/>
																<TdLink
																	className="name"
																	path={window.location.pathname + '/' + insight.node.id}
																	content={insight.node.name}
																/>
																<TdLink
																	className="category"
																	path={window.location.pathname + '/' + insight.node.id}
																	content={insight.node.category}
																/>
																<TdLink
																	className="Shared"
																	path={window.location.pathname + '/' + insight.node.id}
																	content={
																		this.isReportShared(insight.node) &&
																		this.props.intl.formatMessage({
																			id: 'common.shared',
																		})
																	}
																/>
															</tr>
														))}
													</tbody>
												</table>
											)}
										</div>
									) : (
										<div className="table-container">
											<table className="insight-list-table">
												<thead>
													<tr>
														<th className="insight-column">&nbsp;</th>
														<th className="sortable" onClick={this.setSortBy.bind(this, 'name')}>
															{formatMessage({id: 'header.my-reports'})}
															<span>&nbsp;&nbsp;</span>
														</th>
														<th
															className="sortable"
															onClick={this.setSortBy.bind(this, 'category')}
														>
															{formatMessage({id: 'insights.category'})}
															<span>&nbsp;&nbsp;</span>
														</th>
														<th className="insight-column">
															{formatMessage({id: 'common.shared'})}
														</th>
													</tr>
												</thead>
												<tbody>
													{filteredInsights.map((insight, index) => (
														<tr key={index}>
															<TdLink
																style={{
																	backgroundColor: categoryColors[insight.node.category].dark,
																}}
																path={window.location.pathname + '/' + insight.node.id}
																content=""
															/>
															<TdLink
																className="name"
																path={window.location.pathname + '/' + insight.node.id}
																content={insight.node.name}
															/>
															<TdLink
																className="category"
																path={window.location.pathname + '/' + insight.node.id}
																content={insight.node.category}
															/>
															<TdLink className="Shared" />
														</tr>
													))}
													{filteredSavedReports.map((report, index) => {
														return (
															<tr key={index}>
																<TdLink
																	style={{backgroundColor: categoryColors.Portfolio.dark}}
																	path={getSavedReportURLByReportType(
																		report.node.type,
																		report.node.id
																	)}
																	content=""
																/>
																<TdLink
																	className="name"
																	path={getSavedReportURLByReportType(
																		report.node.type,
																		report.node.id
																	)}
																	content={report.node.name}
																/>
																<TdLink
																	className="category"
																	path={getSavedReportURLByReportType(
																		report.node.type,
																		report.node.id
																	)}
																	content={this.props.intl.formatMessage({
																		id: 'insights.category.portfolio',
																	})}
																/>
																<TdLink
																	className="Shared"
																	path={getSavedReportURLByReportType(
																		report.node.type,
																		report.node.id
																	)}
																	content={
																		this.isReportShared(report.node) &&
																		this.props.intl.formatMessage({
																			id: 'common.shared',
																		})
																	}
																/>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									)}
								</>
							)
						) : this.state.searchText.length > 0 ? (
							<InsightsEmptyState infoMessageId={'reports.no-reports-match'} searchText={this.state.searchText} />
						) : (
							<>
								{hasFeatureFlag('cant_use_old_component_reports') ? (
									<NoReports
										history={this.props.history}
										isUsingProjectAllocation={this.props.viewer.company.isUsingProjectAllocation}
										isUsingSchedulingPlanMode={this.props.viewer.company.isUsingSchedulingPlanMode}
										isUsingMixedMode={Util.isMixedAllocationModeEnabled(this.props.viewer.company)}
									/>
								) : (
									<InsightsEmptyState infoMessageId={'reports.no-reports'} />
								)}
							</>
						)}
					</div>
				</div>
			</section>
		);
	}
}

export const ReportHeaderWrapper = styled.div`
	display: flex;
	font-size: 16px;
	font-weight: 500;
	background: transparent;
	top: 0;
	left: 0;
	overflow-x: hidden;
	z-index: 4;
	padding: 10px 0px 10px 10px;
`;

export const ReportsAmount = styled.div`
	margin-left: 4px;
	margin-right: 8px;
	font-weight: 400;
`;

const insightsSectionQuery = graphql`
	query insightsSection_Query {
		viewer {
			actualPersonId
			component(name: "insight_section")
			...insightsSection_viewer
		}
	}
`;

export {insightsSectionQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(insightsSection, {
			viewer: graphql`
				fragment insightsSection_viewer on Viewer {
					id
					actualPersonId
					availableFeatureFlags {
						key
					}
					company {
						id
						isUsingProjectAllocation
						isUsingSchedulingPlanMode
						isUsingMixedAllocation
						modules {
							moduleType
						}
						insights(first: 10000) @connection(key: "Company_insights") {
							edges {
								node {
									id
									name
									category
									shares {
										edges {
											node {
												id
											}
										}
									}
								}
							}
						}
						allSavedReports(first: 100000) @connection(key: "Company_allSavedReports") {
							edges {
								node {
									id
									name
									type
									person {
										id
										active
									}
									reportService
									shares {
										edges {
											node {
												id
											}
										}
									}
								}
							}
						}
					}
				}
			`,
		})
	)
);
