import React from 'react';
import {ModalText} from '../../modal/modal_styled';

export const TimeOffApprovalIncompatibleContent = ({integrationName}) => {
	return (
		<>
			<ModalText bold>{`The ${integrationName} integration is not compatible with Time Off Approval`}</ModalText>
			<ModalText>{`Time Off Approval locks time off registrations, which means that the ${integrationName} integration will not be able to synchronize changes correctly.`}</ModalText>
			<ModalText>{`If you wish to use this integration, please deactivate Time Off Approval from the Time Management page`}</ModalText>
		</>
	);
};
