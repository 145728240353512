import React, {useState} from 'react';
import {BasicTextInput} from 'web-components';
import styled from 'styled-components';
import {useIntl} from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import CreateProfileMutation from '../../../mutations/CreateProfileMutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import AddButton from '../../../components/add_button';

const NewProfileStyle = styled.div`
	margin-top: 10px;
	margin-left: 8px;

	.input-row {
		display: flex;
		flex-direction: row;
	}

	.error-message {
		color: #d0021b;
		font-size: 13px;
		padding-left: 8px;
		padding-top: 4px;
	}

	.button {
		margin-left: 8px;
		.add-button-component {
			width: 30px;
			height: 30px;
		}
	}
`;

export const NewProfile = ({companyId}) => {
	const intl = useIntl();

	const [name, setName] = useState('');
	const [error, setError] = useState(null);

	const handleSetName = name => {
		setName(name);
		if (name.length > 191) {
			setError(intl.formatMessage({id: 'settings_finance.name_limit_191'}));
		} else {
			setError(null);
		}
	};

	const canCreate = name && !error;

	const createProfile = () => {
		setName('');

		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: intl.formatMessage({id: 'settings.profile_created'}),
			});
		};

		Util.CommitMutation(
			CreateProfileMutation,
			{
				name,
				companyId,
			},
			onSuccess
		);
	};

	return (
		<NewProfileStyle>
			<div className="input-row">
				<div data-userpilot={'new-profile'}>
					<BasicTextInput
						value={name}
						onChange={handleSetName}
						hasError={error}
						width="250px"
						placeholder={intl.formatMessage({id: 'settings.new_profile'})}
						callbackOnEnter={createProfile.bind(this)}
					/>
				</div>
				<div className="button">
					<AddButton disabled={!canCreate} onClick={canCreate ? createProfile : undefined} />
				</div>
			</div>
			{error && <div className="error-message">{error}</div>}
		</NewProfileStyle>
	);
};
