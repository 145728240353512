import {FormattedHTMLMessage, useIntl} from 'react-intl';

import React from 'react';
import {FlexRow, FlexColumn, DeprecatedText as Text} from '@forecast-it/design-system';
import Util from '../../../forecast-app/shared/util/util';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import {createFragmentContainer, graphql} from 'react-relay';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {RadioButton} from 'web-components';
import {Section, SubSection} from './Styles';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const AllocationMode = {
	Task: 'task',
	Allocation: 'allocation',
	Mixed: 'mixed',
};

const ResourceManagement = React.memo(({company}) => {
	const {formatMessage} = useIntl();
	const {isUsingProjectAllocation, isUsingSchedulingPlanMode} = company;
	const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(company);
	const hasTPHeatmap = hasFeatureFlag('tp_heatmap_spike');

	const fallbackMode = isUsingProjectAllocation ? AllocationMode.Allocation : AllocationMode.Task;
	const currentMode = isMixedAllocationModeEnabled ? AllocationMode.Mixed : fallbackMode;

	const inMixedMode = currentMode === AllocationMode.Mixed;
	const inAllocationMode = currentMode === AllocationMode.Allocation;
	const inTaskMode = currentMode === AllocationMode.Task;

	const showChangesSavedToast = () => {
		createToast({duration: 2000, message: formatMessage({id: 'common.changes_saved'})});
	};

	const toggleSchedulingMode = () => {
		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: company.id,
				isUsingSchedulingPlanMode: !isUsingSchedulingPlanMode,
			},
			() => {
				showChangesSavedToast();
				trackEvent('Resource Management Scheduling Mode', 'Changed', {
					combinedMode: inMixedMode,
					isUsingSchedulingPlanMode,
				});
			}
		);
	};

	const handleMixedModeChange = enabled => {
		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: company.id,
				isUsingMixedAllocation: enabled,
			},
			() => {
				showChangesSavedToast();
				if (enabled) {
					trackEvent('Resource Management Combined Mode', 'Enabled', {
						isUsingSchedulingPlanMode,
					});
				} else {
					trackEvent('Resource Management Combined Mode', 'Disabled');
				}
			}
		);
	};

	const handleAllocationModeChange = useProjectAllocation => {
		if (inMixedMode) {
			handleMixedModeChange(false);
		} else {
			showModal({
				type: MODAL_TYPE.CHANGE_ALLOCATION_TYPE,
				companyId: company.id,
				isUsingProjectAllocation: useProjectAllocation,

				changeAllocationOption: () => {},
			});
		}
	};

	const PlanModePicker = ({isMixedAllocation}) => (
		<>
			<SubSection isInnerSubSection={true}>
				<FlexRow alignItems={'start'} gap={'s'}>
					<RadioButton
						checked={isUsingSchedulingPlanMode}
						onClick={() => !isUsingSchedulingPlanMode && toggleSchedulingMode()}
					/>

					<FlexColumn gap={'s'}>
						<Text>
							<FormattedHTMLMessage
								id={
									isMixedAllocation
										? 'settings_details.mixed_allocation.exclude_time'
										: 'settings_details.heatmap_planned'
								}
							/>
						</Text>
						<Text type={'small'}>
							<FormattedHTMLMessage
								id={
									isMixedAllocation
										? 'settings_details.mixed_allocation.exclude_time_tooltip_text'
										: 'settings_details.heatmap_planned_tooltip_text'
								}
							/>
						</Text>
					</FlexColumn>
				</FlexRow>
				<FlexRow alignItems={'start'} gap={'s'}>
					<RadioButton
						checked={!isUsingSchedulingPlanMode}
						onClick={() => isUsingSchedulingPlanMode && toggleSchedulingMode()}
					/>

					<FlexColumn gap={'s'}>
						<Text>
							<FormattedHTMLMessage
								id={
									isMixedAllocation
										? 'settings_details.mixed_allocation.include_time'
										: 'settings_details.heatmap_actual'
								}
							/>
						</Text>
						<Text type={'small'}>
							<FormattedHTMLMessage
								id={
									isMixedAllocation
										? 'settings_details.mixed_allocation.include_time_tooltip_text'
										: 'settings_details.heatmap_actual_tooltip_text'
								}
							/>
						</Text>
					</FlexColumn>
				</FlexRow>
			</SubSection>
		</>
	);

	return (
		<Section>
			<Text type={'heading'} variant={'m'}>
				{formatMessage({id: 'settings_details.resource_management'})}
			</Text>

			<Text>{formatMessage({id: 'settings_details.resource_management_section_info'})}</Text>
			<SubSection>
				<FlexRow alignItems={'start'} gap={'s'}>
					<RadioButton
						checked={inAllocationMode}
						disabled={inMixedMode && fallbackMode !== AllocationMode.Allocation}
						onClick={() => !inAllocationMode && handleAllocationModeChange(true)}
					/>
					<FlexColumn gap={'s'}>
						<Text>
							<FormattedHTMLMessage id="settings_details.project_allocation" />
						</Text>

						<Text type={'small'}>
							<FormattedHTMLMessage id="settings_details.project_allocation_text" />
						</Text>
					</FlexColumn>
				</FlexRow>

				<FlexRow alignItems={'start'} gap={'s'}>
					<RadioButton
						checked={inTaskMode}
						disabled={inMixedMode && fallbackMode !== AllocationMode.Task}
						onClick={() => !inTaskMode && handleAllocationModeChange(false)}
					/>
					<FlexColumn gap={'s'}>
						<Text>
							<FormattedHTMLMessage id="settings_details.task_allocation" />
						</Text>
						<Text type={'small'}>
							<FormattedHTMLMessage id="settings_details.task_allocation_text" />
						</Text>
					</FlexColumn>
				</FlexRow>

				{inTaskMode && <PlanModePicker />}

				{hasTPHeatmap && (
					<FlexRow alignItems={'start'} gap={'s'}>
						<RadioButton checked={inMixedMode} onClick={() => !inMixedMode && handleMixedModeChange(true)} />

						<FlexColumn gap={'s'}>
							<Text>
								<FormattedHTMLMessage id="settings_details.mixed_allocation" />
							</Text>
							<Text type={'small'}>
								<FormattedHTMLMessage id="settings_details.mixed_allocation_text" />
							</Text>
						</FlexColumn>
					</FlexRow>
				)}
				{inMixedMode && <PlanModePicker isMixedAllocation={true} />}
			</SubSection>
		</Section>
	);
});

export default createFragmentContainer(ResourceManagement, {
	company: graphql`
		fragment ResourceManagement_company on Company {
			isUsingSchedulingPlanMode
			isUsingProjectAllocation
			isUsingMixedAllocation
		}
	`,
});
