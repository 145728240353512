import React, {useEffect, useRef, useState} from 'react';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import Button from '../../../forecast-app/shared/components/buttons/button/button';
import {useIntl} from 'react-intl';
import {colors} from '../../../constants';
import {NewLabelRowStyled} from './new_label_row.styled.';
import ColorsPicker from '../../../components/colors_picker';
import Util from '../../../forecast-app/shared/util/util';
import CreateLabelMutation from '../../../mutations/create_label_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {createFragmentContainer, graphql} from 'react-relay';

const NewLabelRow = ({company, category, disabled}) => {
	const {formatMessage} = useIntl();

	const addLabelWrapperRef = useRef(null);
	const inputRef = useRef(null);

	const [expanded, setExpanded] = useState(false);
	const [name, setName] = useState('');
	const [color, setColor] = useState(colors[Math.floor(Math.random() * colors.length)]);
	const existingNames = new Set(company.labels.edges.map(edge => edge.node).map(label => label.name));
	const [nameValid, setNameValid] = useState(true);

	useEffect(() => {
		if (expanded) {
			inputRef.current?.focus();
		}
	}, [expanded, inputRef]);

	const handleNameChange = event => {
		const newName = event.target.value;
		const duplicateName = existingNames.has(newName);
		setName(newName);
		setNameValid(!duplicateName);
	};

	const handleColorChange = color => {
		setColor(color);
	};

	const handleClose = () => {
		setName('');
		setExpanded(false);
		setNameValid(true);
	};

	const handleAddNewLabel = () => {
		if (name && name !== '' && nameValid && color) {
			setName('');
			inputRef.current?.focus();
			Util.CommitMutation(
				CreateLabelMutation,
				{
					name,
					color,
					categoryId: category ? category.id : null,
					companyId: company.id,
				},
				() => {
					setColor(colors[Math.floor(Math.random() * colors.length)]);
					createToast({
						duration: 5000,
						message: formatMessage({id: 'settings_labels.label_created_toast'}),
					});
				}
			);
		}
	};

	const handleEnterKeyPress = event => {
		if (event.keyCode === 13) {
			handleAddNewLabel();
		}
	};

	const clickedOnColorPicker = (target, level) => {
		if (target && target.className && target.className.includes?.('options-window')) {
			return true;
		} else if (target && level > 0) {
			return clickedOnColorPicker(target.parentNode, level - 1);
		} else {
			return false;
		}
	};

	const handleClickOutside = event => {
		const clickedInsideWrapper = addLabelWrapperRef.current && addLabelWrapperRef.current.contains(event.target);

		// keep level to 5 - otherwise handleClose will be called when choosing color
		if (!clickedInsideWrapper && !clickedOnColorPicker(event.target, 5)) {
			handleClose();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<NewLabelRowStyled>
			<div className="new-label-row-wrapper" ref={addLabelWrapperRef}>
				{expanded ? (
					<>
						<input
							ref={inputRef}
							type="text"
							placeholder={formatMessage({id: 'settings_labels.new_label'})}
							value={name}
							onChange={handleNameChange}
							onKeyDown={handleEnterKeyPress}
							autoComplete="off"
							id={'add-new-label-name'}
							className={nameValid ? '' : 'invalid'}
							maxLength={191}
							data-cy={'new-label-name-input'}
						/>

						<ColorsPicker value={color} onColorSelect={handleColorChange} size={20} usePortal />

						<Button
							disabled={disabled || name === ''}
							colorTheme={BUTTON_COLOR.GREEN}
							buttonStyle={BUTTON_STYLE.FILLED}
							className={
								'add-to-row-button ' +
								(disabled || name === '' ? 'add-to-row-button-disabled' : 'add-to-row-button-enabled')
							}
							onClick={handleAddNewLabel}
							isDisabled={!nameValid}
							cy={'add-new-label'}
						/>
					</>
				) : (
					<Button
						colorTheme={BUTTON_COLOR.PURPLE}
						buttonStyle={BUTTON_STYLE.TEXT}
						onClick={() => setExpanded(true)}
						text={'+ ' + formatMessage({id: 'settings_labels.new_label'})}
						uppercase={false}
						normalFontWeight
						cy={'add-new-label'}
					/>
				)}
			</div>
		</NewLabelRowStyled>
	);
};

export default createFragmentContainer(NewLabelRow, {
	company: graphql`
		fragment newLabelRow_company on Company {
			id
			createdAt
			labels(first: 1000) @connection(key: "Company_labels") {
				edges {
					node {
						id
						name
					}
				}
			}
		}
	`,
});
