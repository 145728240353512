import React, { useState } from 'react';
import { BetaTag, Checkbox, FlexRow, Link, SubHeading, Text } from '@forecast-it/design-system';
import Util from '../../../forecast-app/shared/util/util';
import { trackEvent } from '../../../tracking/amplitude/TrackingV2';
import UpdatePersonCalendarMutation from '../../../mutations/update_person_calendar_mutation.modern';
import UpdateViewerMutation from '../../../mutations/update_viewer_mutation.modern';
import RevokeGoogleCalendarTokenMutation from '../../../mutations/revoke_google_calendar_token_mutation';
import Styled from 'styled-components';
import GoogleCalendarOAuth from './GoogleCalendarOAuth';
import { LOCALSTORAGE_TIMESHEETS_SELECTED_TAB } from '../../../forecast-app/my-work-tab/my-timesheets-page/top-section/TimesheetsCalendarUtil';
const CalendarSection = Styled.div `
	padding-top: 8px;
`;
const SubTitle = Styled.div `
	padding-right: 8px;
`;
const SubTitleSection = Styled.div `
	padding-top: 8px;
	display: flex;
	align-items: center;
`;
const Description = Styled.div `
	padding-top: 8px;
	padding-bottom: 16px;
	width: 35rem;
`;
const CheckboxContainer = Styled.div `
	display: flex;
	flex-direction: row;
	padding-top: 16px;
`;
const PrivacySettingsTitle = Styled.div `
	width 150px;
`;
const SettingsCheckbox = Styled.div `
	display: flex;
	flex-direction: column;
	> div {
		padding-bottom: 8px;
	}
`;
const ProfileContainer = Styled.div `
	display: flex;
	flex-direction: row;
	align-items: center;
	> div {
		padding-right: 8px;
	}
`;
const SettingsCalendar = ({ viewer, personId }) => {
    var _a, _b, _c, _d, _e;
    const [calendars, setCalendars] = useState((_a = viewer.calendars) === null || _a === void 0 ? void 0 : _a.edges.map(edge => edge.node));
    const [enabledCalendars, setEnabledCalendars] = useState(viewer.enabledCalendars);
    if ((enabledCalendars === null || enabledCalendars === void 0 ? void 0 : enabledCalendars.length) !== ((_b = viewer.enabledCalendars) === null || _b === void 0 ? void 0 : _b.length)) {
        setEnabledCalendars(viewer.enabledCalendars);
    }
    if ((calendars === null || calendars === void 0 ? void 0 : calendars.length) !== ((_d = (_c = viewer.calendars) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.length)) {
        setCalendars((_e = viewer.calendars) === null || _e === void 0 ? void 0 : _e.edges.map(edge => edge.node));
    }
    const isCalendarEnabled = (id) => {
        return enabledCalendars.includes(id);
    };
    const updateEnabledCalendars = (calendarIds) => {
        trackEvent('Google Calendar Enabled Calendars', 'Toggled');
        Util.CommitMutation(UpdatePersonCalendarMutation, {
            personId: personId,
            calendarIds: calendarIds,
        });
    };
    const toggleCalendar = (id) => {
        let checkedCalendars;
        if (isCalendarEnabled(id)) {
            checkedCalendars = enabledCalendars.filter(calendarId => calendarId !== id);
        }
        else {
            checkedCalendars = [id, ...enabledCalendars];
        }
        setEnabledCalendars(checkedCalendars);
        updateEnabledCalendars(checkedCalendars);
    };
    const toggleViewPrivate = () => {
        trackEvent('Google Calendar View Private', 'Toggled', { viewPrivate: !viewer.calendarViewPrivateEvents });
        Util.CommitMutation(UpdateViewerMutation, {
            id: viewer.id,
            calendarViewPrivateEvents: !viewer.calendarViewPrivateEvents,
        });
    };
    const revokeTokens = () => {
        trackEvent('Google Calendar Unlink Account', 'Clicked');
        localStorage.removeItem(LOCALSTORAGE_TIMESHEETS_SELECTED_TAB);
        Util.CommitMutation(RevokeGoogleCalendarTokenMutation, {
            personId: personId,
        }, () => {
            setCalendars([]);
            setEnabledCalendars([]);
        });
    };
    return (React.createElement(CalendarSection, null,
        React.createElement(SubTitleSection, null,
            React.createElement(SubTitle, null,
                React.createElement(SubHeading, { size: '1' }, "View your calendar events inside Forecast")),
            React.createElement(BetaTag, null)),
        React.createElement(Description, null,
            React.createElement(Text, { size: '2' }, "The integration fetches your Google calendar events into Forecast, making it easy to reference what you worked on when you need to log time. That means no more jumping back and forth between your Google calendar and Forecast.")),
        React.createElement(FlexRow, null,
            viewer.calendarUser ? (React.createElement(ProfileContainer, null,
                React.createElement("div", null, "Linked Google Account:"),
                viewer.calendarUser.picture ? (React.createElement("div", null,
                    React.createElement("img", { crossOrigin: "anonymous", src: viewer.calendarUser.picture, width: "32", height: "32", alt: "google profile" }))) : null,
                React.createElement("div", null, viewer.calendarUser.name),
                React.createElement(Link, { onClick: revokeTokens }, "Unlink Account"))) : null,
            !viewer.calendarUser || viewer.calendarTokenError ? (React.createElement(GoogleCalendarOAuth, { calendarTokenError: viewer.calendarTokenError, personId: personId })) : null),
        (calendars === null || calendars === void 0 ? void 0 : calendars.length) ? (React.createElement(React.Fragment, null,
            React.createElement(CheckboxContainer, null,
                React.createElement(PrivacySettingsTitle, null, "Shown Calendars:"),
                React.createElement(SettingsCheckbox, null, calendars.map(calendar => {
                    return (React.createElement(Checkbox, { checked: isCalendarEnabled(calendar.id), onChange: () => toggleCalendar(calendar.id), labelText: calendar.name, notBoldLabel: true, bgColor: calendar.backgroundColor, fgColor: calendar.foregroundColor }));
                }))),
            React.createElement(CheckboxContainer, null,
                React.createElement(PrivacySettingsTitle, null, "Privacy settings:"),
                React.createElement(SettingsCheckbox, null,
                    React.createElement(Checkbox, { checked: viewer.calendarViewPrivateEvents, onChange: toggleViewPrivate, labelText: 'View Calendar events that are marked as private', notBoldLabel: true }))))) : null));
};
export default SettingsCalendar;
