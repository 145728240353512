import React from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import AutosizeInput from 'react-input-autosize';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import VerifyHarvestIntegrationMutation from '../../../mutations/verify_harvest_integration_mutation';
import * as tracking from '../../../tracking';
import harvestLogo from '../../../images/integrations/harvest-logo.png';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Util from '../../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_STYLE, BUTTON_COLOR, ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {hasFeatureFlag, usingTimeApproval} from '../../../forecast-app/shared/util/FeatureUtil';
import {TimeApprovalIncompatibleContent} from './TimeApprovalIncompatibleContent';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class appHarvest extends React.Component {
	constructor(props) {
		super(props);

		const company = props.viewer.company;

		this.state = {
			harvestAccountName: company.harvestAccountName ? company.harvestAccountName : '',
			harvestUserName: company.harvestUserName ? company.harvestUserName : '',
			harvestPassword: company.harvestPassword ? company.harvestPassword : '',
			verifying: false,
			verifyingSave: false,
		};

		this.superPropertyChecksum = trackPage('App Catalog - Harvest');
	}

	componentDidMount() {
		document.title = 'Harvest - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-harvest');

		if (!hasFeatureFlag('show_harvest_integration')) {
			this.props.history.push('/not-authorized');
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleChange(property, e) {
		this.setState({[property]: e.target.value});
	}

	enableDisable() {
		const {formatMessage} = this.props.intl;
		if (this.props.viewer.company.harvestEnabled) {
			// Will deactivate the harvest integration

			const deactivateForReal = () => {
				Util.CommitMutation(UpdateCompanyMutation, {
					id: this.props.viewer.company.id,
					harvestDeactivate: true,
				});
				this.setState({
					harvestAccountName: '',
					harvestUserName: '',
					harvestPassword: '',
				});
			};

			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'warning-modal.edit-warning-title',
				warningInformation: [formatMessage({id: 'settings.app_catalog.harvest.deactivate_warning'})],
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.deactivate'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: deactivateForReal,
					},
				],
			});
			this.setState({verifying: false});
		} else {
			if (this.state.harvestAccountName === '') {
				this._account_name.focus();
				return;
			}
			if (this.state.harvestUserName === '') {
				this._account_username.focus();
				return;
			}
			if (this.state.harvestPassword === '') {
				this._account_password.focus();
				return;
			}

			this.setState({verifying: true});
			const onSuccess = response => {
				tracking.trackEvent('Harvest integration enabled');
				trackEvent('Harvest Integration', 'Enabled');
				this.setState({verifying: false});
				if (response.verifyHarvestIntegration.success) {
					showModal({
						type: MODAL_TYPE.WARNING,
						warningMessageId: 'integration.verification_success',
						warningInformation: [formatMessage({id: 'integration.verification_succeeded'})],
						useInfoIcon: true,
						buttons: [
							{
								text: formatMessage({id: 'common.ok'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				} else {
					showModal({
						type: MODAL_TYPE.WARNING,
						warningMessageId: 'integration.verification_failed',
						warningInformation: [
							response.verifyHarvestIntegration.message === 'URL_NOT_FOUND'
								? 'The account was not found. Make sure that the account name is correct'
								: 'Got an error from Harvest. The error is: ' + response.verifyHarvestIntegration.message,
						],
						buttons: [
							{
								text: formatMessage({id: 'common.ok'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				}
			};

			let trimmedAccountName = this.state.harvestAccountName.trim();
			if (trimmedAccountName.startsWith('http://') || trimmedAccountName.startsWith('https://')) {
				trimmedAccountName = trimmedAccountName.replace('https://', '').replace('http://', '');
			}
			if (trimmedAccountName !== this.state.harvestAccountName) {
				this.setState({harvestAccountName: trimmedAccountName});
			}

			Util.CommitMutation(
				VerifyHarvestIntegrationMutation,
				{
					harvestAccountName: trimmedAccountName,
					harvestUserName: this.state.harvestUserName,
					harvestPassword: this.state.harvestPassword,
				},
				onSuccess
			);
		}
	}

	verifyAndSave() {
		const {formatMessage} = this.props.intl;
		this.setState({verifyingSave: true});
		const onSuccess = response => {
			this.setState({verifyingSave: false});
			if (response.verifyHarvestIntegration.success) {
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId: 'integration.verification_success',
					warningInformation: [formatMessage({id: 'integration.verification_succeeded'})],
					useInfoIcon: true,
					buttons: [
						{
							text: formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			} else {
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId: 'integration.verification_failed',
					warningInformation: [
						response.verifyHarvestIntegration.message === 'URL_NOT_FOUND'
							? 'The account was not found. Make sure that the account name is correct'
							: 'Got an error from Harvest. The error is: ' + response.verifyHarvestIntegration.message,
					],
					buttons: [
						{
							text: formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			}
		};

		Util.CommitMutation(
			VerifyHarvestIntegrationMutation,
			{
				harvestAccountName: this.state.harvestAccountName,
				harvestUserName: this.state.harvestUserName,
				harvestPassword: this.state.harvestPassword,
			},
			onSuccess
		);
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5563414513937');
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;

		const useTimeApproval = usingTimeApproval(this.props.viewer.company.useTimeApproval);

		let enableButtonText = formatMessage({id: 'settings.app_catalog.harvest.activate_button'});
		if (this.state.verifying) {
			enableButtonText = formatMessage({id: 'settings.app_catalog.verifying_button'});
		} else if (this.props.viewer.company.harvestEnabled) {
			enableButtonText = formatMessage({id: 'settings.app_catalog.harvest.deactivate_button'});
		}
		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<img width={100} height={100} alt="harvest logo" src={harvestLogo} />
									<div className="title-description-wrapper">
										<div className="description">
											<FormattedHTMLMessage id="settings.app_catalog.harvest.description" />
										</div>
									</div>
								</div>
								{useTimeApproval ? (
									<TimeApprovalIncompatibleContent integrationName={'Harvest'} />
								) : (
									<div className="input-fields">
										<label htmlFor="harvest_account_name">
											<FormattedMessage id="settings.app_catalog.harvest.account_name_title" /> <br />
											https://
											<AutosizeInput
												id="harvest_account_name"
												type="text"
												ref={input => (this._account_name = input)}
												className="harvest-account-name"
												placeholder={formatMessage({
													id: 'settings.app_catalog.harvest.account_name_placeholder',
												})}
												value={this.state.harvestAccountName}
												onChange={this.handleChange.bind(this, 'harvestAccountName')}
												disabled={this.props.viewer.company.harvestEnabled}
											/>
											.harvestapp.com
										</label>
										<label htmlFor="harvest_account_username">
											<FormattedMessage id="settings.app_catalog.harvest.account_username_title" />
											<input
												id="harvest_account_username"
												type="text"
												ref={input => (this._account_username = input)}
												placeholder={formatMessage({
													id: 'settings.app_catalog.harvest.account_username_placeholder',
												})}
												value={this.state.harvestUserName}
												onChange={this.handleChange.bind(this, 'harvestUserName')}
											/>
										</label>
										<label htmlFor="harvest_account_password">
											<FormattedMessage id="settings.app_catalog.harvest.account_password_title" />
											<input
												id="harvest_account_password"
												type="password"
												ref={input => (this._account_password = input)}
												placeholder={formatMessage({
													id: 'settings.app_catalog.harvest.account_password_placeholder',
												})}
												value={this.state.harvestPassword}
												onChange={this.handleChange.bind(this, 'harvestPassword')}
											/>
										</label>
										{this.props.viewer.company.harvestEnabled ? (
											<button
												disabled={this.state.verifyingSave}
												className="submit-button save-button"
												onClick={this.verifyAndSave.bind(this)}
											>
												{this.state.verifyingSave
													? formatMessage({id: 'settings.app_catalog.verifying_button'})
													: formatMessage({id: 'common.save'})}
											</button>
										) : null}
										<button
											disabled={this.state.verifying}
											className="submit-button"
											onClick={this.enableDisable.bind(this)}
										>
											{enableButtonText}
										</button>
										<button className="help-button" onClick={this.openHelpCenter.bind(this)}>
											<FormattedMessage id="settings.app_catalog.help_button" />
										</button>
									</div>
								)}
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appHarvestQuery = graphql`
	query appHarvest_Query {
		viewer {
			actualPersonId
			component(name: "app_harvest")
			...appHarvest_viewer
		}
	}
`;

export {appHarvestQuery};

export default createFragmentContainer(injectIntl(withRouter(appHarvest)), {
	viewer: graphql`
		fragment appHarvest_viewer on Viewer {
			id
			availableFeatureFlags {
				key
			}
			company {
				id
				useTimeApproval
				harvestEnabled
				harvestAccountName
				harvestUserName
				harvestPassword
				modules {
					moduleType
				}
			}
		}
	`,
});
