import React from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import {DeprecatedText as Text} from '@forecast-it/design-system';
import {Divider, Page} from './Styles';
import {ToggleRevenue} from './ToggleRevenue';
import {ToggleTimeSheets} from './ToggleTimeSheets';
import {ToggleClients} from './ToggleClients';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';

const SettingsSetup = React.memo(({viewer}) => {
	const {formatMessage} = useIntl();
	const company = viewer.company;

	useTrackPage('Settings Setup');

	return (
		<CustomScrollDiv>
			<div className="section-content" data-cy={'settings-setup-page'}>
				<Page>
					<Text type={'heading'} variant={'xl'}>
						{formatMessage({id: 'settings.setup.title'})}
					</Text>

					<ToggleRevenue company={company} />

					<Divider />

					{company.sageIntacctTimeRegsFromForecast ? (
						<>
							<ToggleTimeSheets company={company} />

							<Divider />
						</>
					) : null}

					<ToggleClients company={company} />
				</Page>
			</div>
		</CustomScrollDiv>
	);
});

export const SettingsSetupQuery = graphql`
	query SettingsSetupQuery {
		viewer {
			actualPersonId
			component(name: "settings_setup")
			...SettingsSetup_viewer
		}
	}
`;

export default createRefetchContainer(
	SettingsSetup,
	{
		viewer: graphql`
			fragment SettingsSetup_viewer on Viewer {
				id
				company {
					id
					companyTarget
					revenueEnabled
					timesheetsEnabled
					clientsEnabled
					sageIntacctTimeRegsFromForecast
					...ResourceManagement_company
				}
			}
		`,
	},
	SettingsSetupQuery
);
