import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputField from '../../components/new-ui/input_field';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import {BUTTON_COLOR, BUTTON_STYLE, SEAT, TIERS} from '../../constants';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {createPerson} from '../../components/scheduling/scheduling_mutations';
import CreatePersonMutationModern from '../../mutations/create_person_mutation.modern';
import CreateProjectPersonMutation from '../../mutations/create_project_person_mutation';
import {injectIntl} from 'react-intl';
import Button from '../../forecast-app/shared/components/buttons/button/button';
import Util from '../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {Link} from 'react-router-dom';
import {
	getSelectableProfiles,
	hasPermission,
	personIsAdmin,
	personIsClientUser,
	personIsCollaboratorUser,
	personIsVirtualUser,
} from '../../forecast-app/shared/util/PermissionsUtil';
import {usesSso} from '../../forecast-app/shared/util/FeatureUtil';
import {Checkbox} from 'web-components';
import {Icon} from '@forecast-it/design-system';
import UpdatePersonMutationModern from '../../mutations/update_person_mutation.modern';
import {PERMISSION_TYPE} from '../../Permissions';

const EmailInvitationLabel = styled.div`
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	text-transform: capitalize;
	color: #aaaaaa;
	margin: 8px 0px;
`;
const CheckboxText = styled.div`
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;

	margin-left: 8px;

	color: #535353;
`;
const CheckboxWrapper = styled.div`
	display: flex;
`;

const StyledInviteButton = styled.button`
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 10px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #535353;
	border: 1px solid #e6e6e6;
	gap: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 24px;
`;

class AddTeamMemberModalContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			selectedRole: null,
			selectedPermission: null,
			selectedProfile: null,
			selectedSeat: null,
			showEmailValidationError: false,
			emailValidationErrorMessage: '',
			showNameValidationError: false,
			nameValidationErrorMessage: '',
			canSendRequest: true,
			createdPersons: [],
			inviteNewUsers: false,
			invitingPersonIds: [],
		};
	}

	onInviteChange() {
		this.setState({inviteNewUsers: !this.state.inviteNewUsers});
	}

	checkEmailValidity(isValid) {
		this.setState({
			showEmailValidationError: !isValid,
			emailValidationErrorMessage: !isValid
				? this.props.intl.formatMessage({id: 'settings_team.invalid-email'})
				: this.props.intl.formatMessage({id: 'common.field-cannot-be-empty'}),
		});
	}

	onCreatePersonSuccess(result) {
		if (result.createPerson.errors && result.createPerson.errors.length > 0) {
			let nameErrorText = '';
			let emailErrorText = '';
			for (let error of result.createPerson.errors) {
				switch (error) {
					case 'FIRST_NAME_TOO_LONG':
						nameErrorText = this.props.intl.formatMessage({id: 'errors.name-too-long'});
						break;
					case 'LAST_NAME_TOO_LONG':
						nameErrorText = this.props.intl.formatMessage({id: 'errors.name-too-long'});
						break;
					case 'ACCOUNT_ALREADY_EXIST':
						emailErrorText = this.props.intl.formatMessage({id: 'settings_team.duplicate-email'});
						break;
					case 'MAIL_ADDRESS_TOO_LONG':
						emailErrorText = error;
						break;
					case 'EMAIL_INVALID':
						emailErrorText = this.props.intl.formatMessage({id: 'login.incorrect_email'});
						break;
					default:
						break;
				}
			}
			this.setState({
				showEmailValidationError: emailErrorText.length > 0,
				emailValidationErrorMessage: this.props.intl.formatMessage({id: 'settings_team.duplicate-email'}),
				showNameValidationError: nameErrorText.length > 0,
				nameValidationErrorMessage: nameErrorText,
				canSendRequest: true,
			});
		} else {
			const name = result.createPerson.person.node.firstName + ' ' + result.createPerson.person.node.lastName;
			this.state.createdPersons.push({
				id: result.createPerson.person.node.id,
				name,
				email: result.createPerson.person.node.email,
				role: this.state.selectedRole,
				permission: this.state.selectedPermission,
				profile: this.state.selectedProfile,
				invited: result.createPerson.person.node.invited,
			});
			this.setState({createdPersons: this.state.createdPersons});
			if (this.props.personInvited) {
				this.props.personInvited(result.createPerson.person.node);
			}
			if (this.props.projectId) {
				Util.CommitMutation(
					CreateProjectPersonMutation,
					{
						projectId: this.props.projectId,
						projectGroupId: this.props.projectGroupId,
						personId: result.createPerson.person.node.id,
						roleId: this.state.selectedRole || undefined,
					},
					this.onCreateProjectPersonSuccess(result.createPerson.person.node.invited)
				);
			} else {
				this.onCreateProjectPersonSuccess(result.createPerson.person.node.invited);
			}
			Util.dispatchScheduleEvent(result);
		}
	}

	onCreateProjectPersonSuccess(invited) {
		this.setState({
			name: '',
			email: '',
		});
		createToast({
			duration: 5000,
			message: invited
				? this.props.intl.formatMessage({id: 'add_team_member_modal.team-member-invited'})
				: this.props.intl.formatMessage({id: 'add_team_member_modal.team-member-added'}),
		});
		this.setState({canSendRequest: true});
	}

	handleInputChange(property, value) {
		this.setState({
			[property]: value,
			showNameValidationError: false,
			showEmailValidationError: false,
		});
	}

	handleSelectChange(property, option) {
		this.setState({
			[property]: option ? option.value : null,
			showUserTypeValidationError: false,
			userTypeValidationErrorMessage: '',
			showUserRoleValidationError: false,
			userRoleValidationErrorMessage: '',
		});
	}

	handleProfileChange(profileOption) {
		const isCore = this.props.viewer.company.tier === TIERS.CORE;
		const state = {
			selectedProfile: profileOption || null,
			showUserTypeValidationError: false,
			userTypeValidationErrorMessage: '',
			showUserRoleValidationError: false,
			userRoleValidationErrorMessage: '',
		};

		// If on CORE tier also set the selected seat type, to match the selected profile
		if (isCore) {
			state.selectedSeat =
				{
					value: profileOption?.profile?.seat,
				} || null;
		}

		this.setState(state);
	}

	handleSeatChange(seatOption) {
		this.setState({selectedProfile: null, selectedSeat: seatOption || null});
	}

	clearValidation() {
		this.setState({
			showUserTypeValidationError: false,
			userTypeValidationErrorMessage: '',
			showUserRoleValidationError: false,
			userRoleValidationErrorMessage: '',
		});
	}

	getSeatOptions() {
		const isCore = this.props.viewer.company.tier === TIERS.CORE;
		const seatOptions = [];
		if (isCore) {
			seatOptions.push({
				value: SEAT.CORE,
				label: this.props.intl.formatMessage({id: 'settings_people.seat.core'}),
			});
			seatOptions.push({
				value: SEAT.COLLABORATOR,
				label: this.props.intl.formatMessage({id: 'settings_people.seat.collaborator'}),
			});
		} else {
			seatOptions.push({
				value: SEAT.FULL,
				label: this.props.intl.formatMessage({id: 'settings_people.seat.full'}),
			});
		}
		seatOptions.push({
			value: SEAT.VIRTUAL,
			label: this.props.intl.formatMessage({id: 'settings_people.seat.virtual'}),
		});

		return seatOptions;
	}

	createPersonFromProfile() {
		const name = this.state.name;
		const emailRequired = this.state.selectedProfile && !personIsVirtualUser(this.state.selectedProfile.profile);
		const emailValid = !this.state.showEmailValidationError;
		const fullName = name.trim();
		let first;
		let last;
		if (fullName.indexOf(' ') <= 0) {
			first = fullName;
			last = '';
		} else {
			first = fullName.substr(0, fullName.indexOf(' ')).trim();
			last = fullName.substr(fullName.indexOf(' ') + 1).trim();
		}

		const showNameValidationError = name === '' || name === null;
		const showEmailValidationError = !emailValid || (emailRequired && !this.state.email);
		const showUserTypeValidationError = this.state.selectedProfile == null;
		const showUserRoleValidationError = this.state.selectedRole == null;

		const nameValidationErrorMessage =
			name === '' || name === null ? this.props.intl.formatMessage({id: 'common.field-cannot-be-empty'}) : '';
		const emailValidationErrorMessage = !emailValid
			? this.state.emailValidationErrorMessage
			: this.props.intl.formatMessage({id: 'common.field-cannot-be-empty'});
		const userTypeValidationErrorMessage = showUserTypeValidationError
			? this.props.intl.formatMessage({id: 'common.field-cannot-be-empty'})
			: '';
		const userRoleValidationErrorMessage = showUserRoleValidationError
			? this.props.intl.formatMessage({id: 'common.field-cannot-be-empty'})
			: '';

		this.setState({
			showNameValidationError,
			nameValidationErrorMessage,
			showEmailValidationError,
			emailValidationErrorMessage,
			showUserTypeValidationError,
			userTypeValidationErrorMessage,
			userRoleValidationErrorMessage,
			showUserRoleValidationError,
		});

		if (
			showNameValidationError ||
			showEmailValidationError ||
			showUserTypeValidationError ||
			showUserRoleValidationError ||
			!this.state.canSendRequest
		) {
			return;
		}

		this.setState({canSendRequest: false});

		const data = {
			email: this.state.email === '' ? null : this.state.email,
			firstName: first,
			lastName: last,
			roleId: this.state.selectedRole || undefined,
			profileIds: [this.state.selectedProfile.value],
			invited:
				personIsVirtualUser(this.state.selectedProfile.profile) || !this.state.inviteNewUsers
					? false
					: this.state.email !== '',
		};

		const showPasswordPrompt = personIsAdmin(this.state.selectedProfile.profile) && !usesSso(this.props.viewer.company);

		if (Util.isScheduling()) {
			if (
				name !== null &&
				name !== '' &&
				this.state.canSendRequest &&
				!(emailRequired && !emailValid) &&
				!(emailRequired && !this.state.email)
			) {
				if (showPasswordPrompt) {
					showModal({
						type: MODAL_TYPE.CONFIRM_PASSWORD_MODAL,
						schedulingCreatePerson: createPerson,
						mutationName: 'createPerson',
						data,
						onSuccess: this.onCreatePersonSuccess.bind(this),
						onFailure: result => {
							this.setState({canSendRequest: true});
						},
					});
				} else {
					createPerson(data, this.onCreatePersonSuccess.bind(this));
				}
			}
		} else {
			if (showPasswordPrompt) {
				showModal({
					type: MODAL_TYPE.CONFIRM_PASSWORD_MODAL,
					mutation: CreatePersonMutationModern,
					mutationName: 'createPerson',
					data: {companyId: this.props.viewer.company.id, ...data},
					onSuccess: this.onCreatePersonSuccess.bind(this),
					onFailure: result => {
						this.setState({canSendRequest: true});
					},
				});
			} else {
				Util.CommitMutation(
					CreatePersonMutationModern,
					{companyId: this.props.viewer.company.id, ...data},
					this.onCreatePersonSuccess.bind(this)
				);
			}
		}
	}

	handleLinkClick() {
		if (this.props.closeModal) {
			this.props.closeModal();
		}
	}

	getSeatsInfoWithProfile() {
		const isCore = this.props.viewer.company.tier === TIERS.CORE;
		const {formatMessage} = this.props.intl;
		const isChargebeeCustomer = this.props.viewer.company.isChargebeeCustomer;

		// All active
		const activated = this.props.viewer.company.allPersons.edges
			.filter(person => person.node.active && person.node.client == null)
			.map(p => p.node);

		const selectedVirtualUser = this.state.selectedProfile ? personIsVirtualUser(this.state.selectedProfile.profile) : null;
		const selectedCollaboratorUser = this.state.selectedProfile
			? personIsCollaboratorUser(this.state.selectedProfile.profile)
			: null;

		if (isChargebeeCustomer) {
			// Seats from chargebee subscription
			const userSeats = this.props.viewer.company.userSeats;
			const virtualSeats = this.props.viewer.company.virtualSeats;
			const collaboratorSeats = this.props.viewer.company.collaboratorSeats;

			// How many active seats the acount actual has
			const actualUserSeats = activated.filter(
				person => !personIsVirtualUser(person) && !personIsClientUser(person) && !personIsCollaboratorUser(person)
			).length;
			const actualVirtualSeats = activated.filter(person => personIsVirtualUser(person)).length;
			const actualCollaboratorSeats = activated.filter(person => personIsCollaboratorUser(person)).length;

			// If account is going above its seats
			const overUserCap = userSeats <= actualUserSeats;
			const overVirtualUserCap = virtualSeats <= actualVirtualSeats;
			const overCollaboratorUserCap = collaboratorSeats <= actualCollaboratorSeats;

			const isCoreTier = this.props.viewer.company.isCoreTier || isCore;

			if (overUserCap && selectedVirtualUser === false && selectedCollaboratorUser === false) {
				return (
					<div className={'seats-count warning'}>
						<div className={'seats-text'}>
							<div className={'seats-warning-text'}>
								{formatMessage(
									{
										id: isCoreTier
											? 'team_modal.seats_warning_action_prevented_core_tier'
											: 'team_modal.seats_warning_action_prevented',
									},
									{type: ' ', max: `(${userSeats})`}
								)}
								{!isCoreTier && (
									<Link onClick={this.handleLinkClick.bind(this)} to="/settings/subscription">
										{formatMessage({id: 'team_modal.seats_warning_subscription_page'})}
									</Link>
								)}
							</div>
						</div>
					</div>
				);
			}

			if (overVirtualUserCap && selectedVirtualUser) {
				return (
					<div className={'seats-count warning'}>
						<div className={'seats-text'}>
							<div className={'seats-warning-text'}>
								{formatMessage(
									{
										id: isCoreTier
											? 'team_modal.seats_warning_action_prevented_core_tier'
											: 'team_modal.seats_warning_action_prevented',
									},
									{type: 'virtual ', max: `(${virtualSeats})`}
								)}
								{!isCoreTier && (
									<Link onClick={this.handleLinkClick.bind(this)} to="/settings/subscription">
										{formatMessage({id: 'team_modal.seats_warning_subscription_page'})}
									</Link>
								)}
							</div>
						</div>
					</div>
				);
			}

			if (isCore && overCollaboratorUserCap && selectedCollaboratorUser) {
				return (
					<div className={'seats-count warning'}>
						<div className={'seats-text'}>
							<div className={'seats-warning-text'}>
								{formatMessage(
									{
										id: isCoreTier
											? 'team_modal.seats_warning_action_prevented_core_tier'
											: 'team_modal.seats_warning_action_prevented',
									},
									{type: 'collaborator ', max: `(${collaboratorSeats})`}
								)}
								{!isCoreTier && (
									<Link onClick={this.handleLinkClick.bind(this)} to="/settings/subscription">
										{formatMessage({id: 'team_modal.seats_warning_subscription_page'})}
									</Link>
								)}
							</div>
						</div>
					</div>
				);
			}

			return (
				<div className={'seats-count info'}>
					<div className={'seats-text'}>
						{formatMessage({id: 'team_modal.seats_info'}, {amount: actualUserSeats, total: userSeats})}
					</div>

					<div className={'seats-text'}>
						{formatMessage(
							{id: 'team_modal.virtual_seats_info'},
							{amount: actualVirtualSeats, total: virtualSeats}
						)}
					</div>

					{isCore ? (
						<div className={'seats-text'}>
							{formatMessage(
								{id: 'team_modal.collaborator_seats_info'},
								{amount: actualCollaboratorSeats, total: collaboratorSeats}
							)}
						</div>
					) : null}
				</div>
			);
		} else if (selectedVirtualUser) {
			return (
				<div className={'seats-count warning'}>
					<div className={'seats-text'}>
						<div className={'seats-text'}>
							<div className={'seats-warning-text'}>
								{formatMessage({id: 'add_team_member_modal.disclaimer'})}
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	createdPersonsRow(p, index) {
		const isCore = this.props.viewer.company.tier === TIERS.CORE;
		const {formatMessage} = this.props.intl;
		const sortedProfiles = getSelectableProfiles(this.props.viewer.company.profiles.edges, p.profile.profile.seat);
		const profileOptions = sortedProfiles.map(profile => {
			return {label: profile.name, value: profile.id, profile: profile};
		});

		const seatOptions = this.getSeatOptions();

		return (
			<div className={'add-team-member-modal-content created created-vs-invited'} key={p.email}>
				<InputField
					label={formatMessage({id: 'common.full_name'})}
					hideLabel={index !== 0}
					value={p.name}
					type="text"
					locked={true}
				/>
				<InputField
					label={formatMessage({id: 'common.email'})}
					hideLabel={index !== 0}
					value={p.email}
					type="email"
					locked={true}
				/>
				<Dropdown
					options={this.props.viewer.company.roles.edges.map(role => {
						return {value: role.node.id, label: role.node.name};
					})}
					label={formatMessage({id: 'add_team_member_modal.main-role'})}
					hideLabel={index !== 0}
					disabled={true}
					value={p.role}
					customWidth={200}
					onChange={() => false}
				/>
				{isCore ? (
					<Dropdown
						options={seatOptions}
						label={formatMessage({id: 'common.seat_type'})}
						hideLabel={index !== 0}
						disabled={true}
						value={p.profile.profile.seat}
						customWidth={200}
						onChange={() => false}
					/>
				) : null}
				<Dropdown
					options={profileOptions}
					value={p.profile.value}
					label={formatMessage({id: 'common.permission'})}
					hideLabel={index !== 0}
					disabled={true}
					customWidth={200}
					onChange={() => false}
				/>
				<div className={'invited-success' + (index === 0 ? ' header-margin' : '')}>
					<Icon icon="tick" color="success" size="l" />
					<div className={'success-text'}>{p.invited ? 'INVITED' : 'CREATED'}</div>
				</div>
				{!p.invited && p.email ? (
					<StyledInviteButton
						onClick={this.invitePerson.bind(this, p.id)}
						disabled={this.state.invitingPersonIds.includes(p.id)}
					>
						<Icon icon="mail" size="s" />
						Invite
					</StyledInviteButton>
				) : null}
			</div>
		);
	}

	invitePerson(personId) {
		let invitingPersonIds = this.state.invitingPersonIds;
		invitingPersonIds.push(personId);
		this.setState({invitingPersonIds});
		Util.CommitMutation(UpdatePersonMutationModern, {id: personId, invited: true}, result => {
			let createdPersons = this.state.createdPersons;
			const personIndex = createdPersons.findIndex(person => person.id === personId);
			createdPersons[personIndex].invited = result.updatePerson.person.invited;

			this.setState({
				invitingPersonIds: this.state.invitingPersonIds.filter(id => id !== personId),
				createdPersons,
			});
		});
	}

	render() {
		const isCore = this.props.viewer.company.tier === TIERS.CORE;
		const {formatMessage} = this.props.intl;
		const sortedProfiles = getSelectableProfiles(this.props.viewer.company.profiles.edges, this.state.selectedSeat?.value);
		const profileOptions = sortedProfiles.map(profile => {
			return {label: profile.name, value: profile.id, profile: profile};
		});

		const seatOptions = this.getSeatOptions();

		if (!hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS)) {
			const adminOption = profileOptions.find(option => Util.getIdFromBase64String(option.value) === -3);
			if (adminOption) {
				adminOption.disabled = true;
				adminOption.description = 'Only administrators can create other administrators';
			}
		}

		const showHeader = this.state.createdPersons.length === 0;

		let overUserCap;
		let overVirtualUserCap;
		let overCollaboratorUserCap;

		const isChargebeeCustomer = this.props.viewer.company.isChargebeeCustomer;
		// All active
		const activated = this.props.viewer.company.allPersons.edges.filter(person => person.node.active).map(p => p.node);

		if (isChargebeeCustomer) {
			// Seats from chargebee subscription
			const userSeats = this.props.viewer.company.userSeats;
			const virtualSeats = this.props.viewer.company.virtualSeats;
			const collaboratorSeats = this.props.viewer.company.collaboratorSeats;

			// How many active seats the account actual has
			const actualUserSeats = activated.filter(
				person => !personIsVirtualUser(person) && !personIsClientUser(person) && !personIsCollaboratorUser(person)
			).length;
			const actualVirtualSeats = activated.filter(person => personIsVirtualUser(person)).length;
			const actualCollaboratorSeats = activated.filter(person => personIsCollaboratorUser(person)).length;

			// If account is going above its seats
			overUserCap = userSeats <= actualUserSeats;
			overVirtualUserCap = virtualSeats <= actualVirtualSeats;
			overCollaboratorUserCap = collaboratorSeats <= actualCollaboratorSeats;
		}

		const blockPersonCreation =
			(overUserCap &&
				this.state.selectedProfile &&
				!personIsVirtualUser(this.state.selectedProfile.profile) &&
				!personIsCollaboratorUser(this.state.selectedProfile.profile)) ||
			(overVirtualUserCap && this.state.selectedProfile && personIsVirtualUser(this.state.selectedProfile.profile)) ||
			(overCollaboratorUserCap &&
				this.state.selectedProfile &&
				personIsCollaboratorUser(this.state.selectedProfile.profile));

		return (
			<div className={'add-team-member-modal created-vs-invited'}>
				{<div className={'seats-count-info'}>{this.getSeatsInfoWithProfile()}</div>}
				<div>
					<EmailInvitationLabel>E-Mail Invitation</EmailInvitationLabel>
					<CheckboxWrapper>
						<Checkbox
							cy={'send-email'}
							checked={this.state.inviteNewUsers}
							onChange={this.onInviteChange.bind(this)}
						/>
						<CheckboxText>
							Send an email invitation to the newly created team members. You can always do this step later.
						</CheckboxText>
					</CheckboxWrapper>
				</div>
				{this.state.createdPersons.map((p, index) => this.createdPersonsRow(p, index))}
				<div className={'add-team-member-modal-content created-vs-invited'}>
					<InputField
						label={formatMessage({id: 'common.full_name'})}
						hideLabel={!showHeader}
						value={this.state.name}
						onChange={this.handleInputChange.bind(this, 'name')}
						autoFocus={true}
						type="text"
						placeholder=""
						invalidInput={this.state.showNameValidationError}
						errorMessage={this.state.nameValidationErrorMessage}
						cy={'name-input-field'}
					/>
					<InputField
						label={formatMessage({id: 'common.email'})}
						hideLabel={!showHeader}
						value={this.state.email}
						onChange={this.handleInputChange.bind(this, 'email')}
						type="email"
						placeholder=""
						checkEmailValidity={this.checkEmailValidity.bind(this)}
						invalidInput={this.state.showEmailValidationError}
						errorMessage={this.state.emailValidationErrorMessage}
						shouldFocus={this.state.showEmailValidationError}
						autoFocus={false}
						cy={'email-input-field'}
					/>
					<Dropdown
						options={this.props.viewer.company.roles.edges.map(role => {
							return {value: role.node.id, label: role.node.name};
						})}
						onChange={this.handleSelectChange.bind(this, 'selectedRole')}
						value={this.state.selectedRole}
						multi={false}
						label={formatMessage({id: 'add_team_member_modal.main-role'})}
						hideLabel={!showHeader}
						clearable={true}
						inputName={'role-input'}
						dataCy={'role-input-select'}
						invalidInput={this.state.showUserRoleValidationError}
						errorMessage={this.state.userRoleValidationErrorMessage}
						customWidth={200}
						listDataCy={'role'}
					/>
					{isCore ? (
						<Dropdown
							options={seatOptions}
							onChange={this.handleSeatChange.bind(this)}
							value={this.state.selectedSeat?.value}
							multi={false}
							label={formatMessage({id: 'common.seat_type'})}
							hideLabel={!showHeader}
							clearable={false}
							inputName={'seat-input'}
							dataCy={'seat-input-select'}
							customWidth={200}
							listDataCy={'seat'}
						/>
					) : null}
					<Dropdown
						options={profileOptions}
						onChange={this.handleProfileChange.bind(this)}
						value={this.state.selectedProfile?.value}
						multi={false}
						label={formatMessage({id: 'common.permission'})}
						hideLabel={!showHeader}
						inputName={'permission-input'}
						dataCy={'permission-input-select'}
						invalidInput={this.state.showUserTypeValidationError}
						errorMessage={this.state.userTypeValidationErrorMessage}
						onFocus={this.clearValidation.bind(this)}
						customWidth={200}
						listDataCy={'permission'}
					/>
					<Button
						cy={'invite-new-button'}
						className={'invite-new' + (showHeader ? ' header-margin' : '')}
						isDisabled={blockPersonCreation}
						key={'create-new-btn'}
						text={'Create Team Member'.toUpperCase()}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={BUTTON_COLOR.GREEN}
						onClick={blockPersonCreation ? undefined : this.createPersonFromProfile.bind(this)}
						onKeyDown={blockPersonCreation ? undefined : this.createPersonFromProfile.bind(this)}
					/>
				</div>
			</div>
		);
	}
}

AddTeamMemberModalContent.propTypes = {
	closeModal: PropTypes.func,
	buttonLocation: PropTypes.string.isRequired,
	projectId: PropTypes.string,
	projectGroupId: PropTypes.string,
};

export default injectIntl(AddTeamMemberModalContent);
