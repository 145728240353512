import * as tracking from '../../tracking';
import {
	BUTTON_COLOR,
	BUTTON_STYLE,
	ELEMENT_TYPE,
	EMPTY_STATE,
	FILTER_SECTION,
	FILTER_TYPE,
	HIDDEN_FEATURES,
	MODULE_TYPES,
	PROJECT_STATUS,
	SOCKET_ACTION,
	SOCKET_EVENT_TYPE,
} from '../../constants';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import React, {Component} from 'react';
import {cloneDeep} from 'lodash';
import DoneProjectsEmptyState from './empty_states/overview_done_projects_empty_state';
import EmptyState from '../../forecast-app/shared/components/empty-states/empty_state';
import HeaderBar from '../../forecast-app/shared/components/headers/header-bar/header_bar';
import {Link, withRouter} from 'react-router-dom';
import OverviewProjectsGroupLineItem from '../../components/overview-projects/overview_projects_group_line_item';
import OverviewProjectsLineItem from '../../components/overview-projects/overview_projects_line_item';
import OverviewProjectsProjectCard from '../../components/overview-projects/overview_projects_project_card';
import {createRefetchContainer, graphql} from 'react-relay';
import UploadingOverlay from '../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import Util from '../../forecast-app/shared/util/util';
import util from '../../forecast-app/shared/util/util';
import {injectIntl} from 'react-intl';
import {withSocketHandling} from '../../socket/withSocketHandling';
import {getOrderedFilters} from '../../forecast-app/shared/components/filters/filter_util';
import {FILTER_SECTIONS} from '../../forecast-app/shared/components/filters/FilterWrapper';
import {getFilterFunctions} from '../../forecast-app/shared/components/filters/filter_logic';
import {hasTimeApproval} from '../../forecast-app/shared/util/FeatureUtil';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import DirectApi from '../../directApi';
import {hasPermission, hasSomePermission, isClientUser} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {LoadMore} from '../../components/loaders/LoadMore';
import ProjectsLoader, {projectsLoaderQuery} from './ProjectsLoader';
import {sortProjects} from './SortProjects';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import {filterProjects} from './FilterProjects';
import {remapOptionTranslationIds} from '../../forecast-app/shared/util/FinancialInternationalisationUtil';
import {trackEvent, trackPage, unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import CompanySetupUtil, {isFeatureHidden} from '../../forecast-app/shared/util/CompanySetupUtil';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import ReactVisibilitySensor from 'react-visibility-sensor';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import {setQueryInRelayStore, Query} from '../../forecast-app/shared/util/cache/RelayQueryCache';
import CustomScrollToNativeScroll from '../../forecast-app/shared/components/scroll-bars/CustomScrollToNativeScroll';

class overviewProjectsV2 extends Component {
	constructor(props) {
		super(props);

		if (props.viewer.company) {
			this.state = this.getOverviewProjectConstructorState(props);
		}

		this.resize = this.resize.bind(this);
		this.handleShortcutProjects = this.handleShortcutProjects.bind(this);
		this.hasTimeApproval = hasTimeApproval();

		let columns;
		if (this.state.displayMode === 'list') {
			columns = this.state.columns
				.filter(column => column.checked)
				.map(column => {
					return {name: column.name, checked: column.checked};
				});
		} else {
			columns = this.state.cardLayoutDisplay
				.filter(column => column.checked)
				.map(column => {
					return {name: column.name, checked: column.checked};
				});
		}

		this.superPropertyChecksum = trackPage('Projects', {columns, display: this.state.displayMode});
	}

	componentDidMount() {
		document.title = 'My projects - Forecast';
		document.addEventListener('keydown', this.handleShortcutProjects);
		if (this.props.viewer.company) {
			this.overviewProjectDidMount(this.props);
		}
		if (this.props.excludeDoneOrHalted && this.props.relay.refetch) {
			this.props.relay.refetch(
				{
					excludeDoneOrHalted: false,
				},
				null,
				() => {
					this.setState({lazyDataFetched: true});
					setQueryInRelayStore(Query.OverviewProjects);
				}
			);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state === null && nextProps.viewer.company) {
			const stateObject = this.getOverviewProjectConstructorState(nextProps);
			this.setState(stateObject, () => {
				this.overviewProjectDidMount(nextProps);
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return !!nextProps.viewer.company;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState !== null && this.state !== null) {
			// need to handle a case when project status section was expanded.
			let anyProjectStatusExpanded = false;
			let loadedSection = false;
			this.project_statuses.forEach(project_status => {
				if (!anyProjectStatusExpanded) {
					anyProjectStatusExpanded =
						this.state.projectStatusSectionExpandedMap.get(project_status) &&
						!prevState.projectStatusSectionExpandedMap.get(project_status);
					loadedSection = true;
				}
			});
			if (
				anyProjectStatusExpanded ||
				this.state.displayMode !== prevState.displayMode ||
				this.state.searchFilterValue !== prevState.searchFilterValue ||
				this.state.filterChanged
			) {
				const {numberOfProjectTilesAfterLastGroupMap} = this.state;
				this.project_statuses.forEach(project_status => {
					const numberOfProjects = this.getNumberOfExtraMarginProjects(project_status);
					numberOfProjectTilesAfterLastGroupMap.set(project_status, numberOfProjects);
				});
				const stateObject = {numberOfProjectTilesAfterLastGroupMap, filterChanged: false};
				stateObject.loadingSection = !loadedSection;
				this.setState(stateObject);
			}
		}

		if (this.state.searchFilterValue !== prevState.searchFilterValue) {
			if (this.state.searchFilterValue && this.state.searchFilterValue !== '') {
				let projects = [];
				this.props.viewer.projectGroups.edges.forEach(pGroup => {
					projects.push(cloneDeep(pGroup));
				});
				this.props.viewer.projects.edges
					.filter(p => this.props.viewer.client || !p.node.isInProjectGroup)
					.forEach(p => projects.push(p));
				//add projects to list end

				projects = filterProjects(
					projects,
					this.state.searchFilterValue,
					this.state.filters,
					this.state.filterFunctions,
					this.calculatedDataMap
				);
				const projectCount = projects.length;
				trackEvent('Search', 'Results', {searchString: this.state.searchFilterValue, matching: projectCount});
			}
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);

		document.removeEventListener('keydown', this.handleShortcutProjects);
		window.removeEventListener('resize', this.resize);
		cancelAnimationFrame(this.requestedFrame);

		if (this.abortController) {
			this.abortController.abort();
		}
	}

	async fetchCalculatedData(status) {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		if (window.AbortController) {
			//Not supported by IE
			this.abortController = new window.AbortController();
		}
		const signal = this.abortController ? this.abortController.signal : undefined;
		const init = {headers, signal, credentials: 'include', method: 'POST', body: JSON.stringify({status})};

		return await DirectApi.Fetch_WithErrorHandling('calculated_fields_by_status', init)
			.then(response => response.json())
			.then(projectDataArray => {
				for (const projectData of projectDataArray) {
					this.calculatedDataMap.set(projectData.id, projectData);
				}
				Util.localStorageSetItem(
					'projects-page-calculated-data-map',
					JSON.stringify(Array.from(this.calculatedDataMap.entries()))
				);
				Util.localStorageSetItem('projects-page-data-timestamp', Date.now());
				this.forceUpdate();
				return projectDataArray;
			});
	}

	// Temporary solution due to Relay fuckery
	getOverviewProjectConstructorState(props) {
		this.project_statuses = [PROJECT_STATUS.PLANNING, PROJECT_STATUS.RUNNING, PROJECT_STATUS.HALTED, PROJECT_STATUS.DONE];

		//
		if (Util.hasOpportunityAccess()) {
			this.project_statuses.unshift(PROJECT_STATUS.OPPORTUNITY);
		}
		const displayMode = localStorage.getItem('displayMode-projects-overview');
		const hasPriorityLevels = props.viewer.company.priorityLevels
			? props.viewer.company.priorityLevels.edges.length > 0
			: false;

		const localStateColumns = JSON.parse(localStorage.getItem('columns-projects-overview-v3')) || [];
		const localStateCardLayoutDisplay = JSON.parse(localStorage.getItem('projects-overview-card-layout-display')) || [];

		// ascending value as a string
		const ascending = localStorage.getItem('overview-projects-sort-ascending') || 'true';
		// Set initial sort
		const sortBy = {
			column: localStorage.getItem('overview-projects-sort-column') || null,
			ascending: ascending === 'true',
		};

		const currencySymbol = util.GetCurrencySymbol(props.viewer.company.currency);
		const clientSelected = localStateCardLayoutDisplay.find(c => c.name === 'client')
			? localStateCardLayoutDisplay.find(c => c.name === 'client').checked
			: true;
		const contactSelected = localStateCardLayoutDisplay.find(c => c.name === 'contact')
			? localStateCardLayoutDisplay.find(c => c.name === 'contact').checked
			: false;
		const descriptionSelected = localStateCardLayoutDisplay.find(c => c.name === 'description')
			? localStateCardLayoutDisplay.find(c => c.name === 'description').checked
			: true;
		const cardLayoutDisplay = [
			{
				name: 'id',
				translationId: 'common.id',
				checked: localStateCardLayoutDisplay.find(c => c.name === 'id')
					? localStateCardLayoutDisplay.find(c => c.name === 'id').checked
					: false,
			},
			{
				name: 'status',
				translationId: 'common.status',
				checked: localStateCardLayoutDisplay.find(c => c.name === 'status')
					? localStateCardLayoutDisplay.find(c => c.name === 'status').checked
					: false,
			},
			{
				name: 'dates',
				translationId: 'project_overview.project_dates',
				checked: localStateCardLayoutDisplay.find(c => c.name === 'dates')
					? localStateCardLayoutDisplay.find(c => c.name === 'dates').checked
					: true,
			},
			...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
				? [
						{
							name: 'client',
							translationId: 'common.client',
							checked: clientSelected,
						},
				  ]
				: []),
			{
				name: 'contact',
				translationId: 'common.project_owner',
				checked: contactSelected,
			},
			{
				name: 'description',
				translationId: 'common.description',
				checked: descriptionSelected,
			},
		];

		const oldStatusIndex = cardLayoutDisplay.findIndex(col => col.name === 'status');
		const newStatus = {
			name: 'statusV2',
			translationId: 'common.status',
			checked: localStateCardLayoutDisplay.find(c => c.name === 'statusV2')
				? localStateCardLayoutDisplay.find(c => c.name === 'statusV2').checked
				: true,
		};
		cardLayoutDisplay.splice(oldStatusIndex, 1, newStatus);
		const columns = isClientUser()
			? [
					{
						name: 'id',
						translationId: 'common.id',
						checked: true,
					},
					{
						name: 'name',
						translationId: 'common.name',
						checked: true,
					},
					{
						name: 'statusColor',
						translationId: 'common.status',
						checked: localStateColumns.find(column => column.name === 'statusColor')
							? localStateColumns.find(column => column.name === 'statusColor').checked
							: false,
					},
					...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
						? [
								{
									name: 'client',
									translationId: 'common.client',
									checked: localStateColumns.find(column => column.name === 'client')
										? localStateColumns.find(column => column.name === 'client').checked
										: true,
								},
						  ]
						: []),
					{
						name: 'contact',
						translationId: 'common.project_owner',
						checked: localStateColumns.find(column => column.name === 'contact')
							? localStateColumns.find(column => column.name === 'contact').checked
							: false,
					},
					{
						name: 'startDate',
						translationId: 'common.project_start',
						checked: localStateColumns.find(column => column.name === 'startDate')
							? localStateColumns.find(column => column.name === 'startDate').checked
							: true,
					},
					{
						name: 'endDate',
						translationId: 'insights.component.list.column.projectDeadline',
						checked: localStateColumns.find(column => column.name === 'endDate')
							? localStateColumns.find(column => column.name === 'endDate').checked
							: true,
					},
			  ]
			: [
					{
						name: 'id',
						translationId: 'common.id',
						checked: true,
					},
					{
						name: 'name',
						translationId: 'common.name',
						checked: true,
					},
					{
						name: 'startDate',
						translationId: 'common.project_start',
						checked: localStateColumns.find(column => column.name === 'startDate')
							? localStateColumns.find(column => column.name === 'startDate').checked
							: true,
					},
					{
						name: 'endDate',
						translationId: 'insights.component.list.column.projectDeadline',
						checked: localStateColumns.find(column => column.name === 'endDate')
							? localStateColumns.find(column => column.name === 'endDate').checked
							: true,
					},
					...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
						? [
								{
									name: 'client',
									translationId: 'common.client',
									checked: localStateColumns.find(column => column.name === 'client')
										? localStateColumns.find(column => column.name === 'client').checked
										: true,
								},
						  ]
						: []),
					...(hasPriorityLevels
						? [
								{
									name: 'priorityLevel',
									translationId: 'common.priority',
									checked: localStateColumns.find(column => column.name === 'priorityLevel')
										? localStateColumns.find(column => column.name === 'priorityLevel').checked
										: false,
								},
						  ]
						: []),
					{
						name: 'contact',
						translationId: 'common.project_owner',
						checked: localStateColumns.find(column => column.name === 'contact')
							? localStateColumns.find(column => column.name === 'contact').checked
							: false,
					},
					{
						name: 'completion',
						translationId: 'common.progress',
						checked: localStateColumns.find(column => column.name === 'completion')
							? localStateColumns.find(column => column.name === 'completion').checked
							: true,
					},
					{
						name: 'completionBar',
						translationId: 'common.completion-bar',
						checked: localStateColumns.find(column => column.name === 'completionBar')
							? localStateColumns.find(column => column.name === 'completionBar').checked
							: false,
					},
					{
						name: 'statusColor',
						translationId: 'common.status',
						checked: localStateColumns.find(column => column.name === 'statusColor')
							? localStateColumns.find(column => column.name === 'statusColor').checked
							: false,
					},
					{
						name: 'forecastTotal',
						translationId: 'common.total-scope',
						checked: localStateColumns.find(column => column.name === 'forecastTotal')
							? localStateColumns.find(column => column.name === 'forecastTotal').checked
							: true,
					},
					{
						name: 'forecast',
						translationId: 'common.approved-scope',
						checked: localStateColumns.find(column => column.name === 'forecast')
							? localStateColumns.find(column => column.name === 'forecast').checked
							: false,
					},
					{
						name: 'reported',
						translationId: 'common.reported',
						checked: localStateColumns.find(column => column.name === 'reported')
							? localStateColumns.find(column => column.name === 'reported').checked
							: false,
					},
					{
						name: 'remaining',
						translationId: 'common.remaining',
						checked: localStateColumns.find(column => column.name === 'remaining')
							? localStateColumns.find(column => column.name === 'remaining').checked
							: true,
					},
					{
						name: 'variance_to_forecast',
						translationId: 'project_section.variance_to_forecast',
						checked: localStateColumns.find(column => column.name === 'variance_to_forecast')
							? localStateColumns.find(column => column.name === 'variance_to_forecast').checked
							: false,
					},
					{
						name: 'tasksCount',
						translationId: 'common.cards-total',
						checked: localStateColumns.find(column => column.name === 'tasksCount')
							? localStateColumns.find(column => column.name === 'tasksCount').checked
							: false,
					},
					{
						name: 'doneTasksCount',
						translationId: 'common.cards-done',
						checked: localStateColumns.find(column => column.name === 'doneTasksCount')
							? localStateColumns.find(column => column.name === 'doneTasksCount').checked
							: false,
					},
					{
						name: 'labels',
						translationId: 'common.labels',
						checked: localStateColumns.find(column => column.name === 'labels')
							? localStateColumns.find(column => column.name === 'labels').checked
							: false,
					},
					{
						name: 'projectPersonsCount',
						translationId: 'common.team-size',
						checked: localStateColumns.find(column => column.name === 'projectPersonsCount')
							? localStateColumns.find(column => column.name === 'projectPersonsCount').checked
							: false,
					},
			  ];

		const oldStatusColorIndex = columns.findIndex(col => col.name === 'statusColor');
		const newStatusColor = {
			name: 'statusColorV2',
			translationId: 'common.status',
			checked: localStateColumns.find(c => c.name === 'statusColorV2')
				? localStateColumns.find(c => c.name === 'statusColorV2').checked
				: true,
		};
		columns.splice(oldStatusColorIndex, 1, newStatusColor);
		let financialColumns = [
			{
				name: 'win-chance',
				translationId: 'project.win_chance',
				checked: localStateColumns.find(column => column.name === 'win-chance')
					? localStateColumns.find(column => column.name === 'win-chance').checked
					: false,
			},
			...(!hasFeatureFlag('financial_categories_update')
				? [
						...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
							? [
									{
										name: 'budget',
										translationId: 'common.fixed_price',
										checked: localStateColumns.find(column => column.name === 'budget')
											? localStateColumns.find(column => column.name === 'budget').checked
											: false,
									},
									{
										name: 'forecast-money',
										translationId: 'project_budget.plan_revenue',
										suffix: currencySymbol,
										checked: localStateColumns.find(column => column.name === 'forecast-money')
											? localStateColumns.find(column => column.name === 'forecast-money').checked
											: false,
									},
									{
										name: 'reported-money',
										translationId: 'project_budget.actual_revenue',
										suffix: currencySymbol,
										checked: localStateColumns.find(column => column.name === 'reported-money')
											? localStateColumns.find(column => column.name === 'reported-money').checked
											: false,
									},
									{
										name: 'remaining-money',
										translationId: 'project_budget.remaining_revenue',
										suffix: currencySymbol,
										checked: localStateColumns.find(column => column.name === 'remaining-money')
											? localStateColumns.find(column => column.name === 'remaining-money').checked
											: false,
									},
									{
										name: 'projected-money',
										translationId: 'project_budget.forecast_revenue',
										suffix: currencySymbol,
										checked: localStateColumns.find(column => column.name === 'projected-money')
											? localStateColumns.find(column => column.name === 'projected-money').checked
											: false,
									},
									{
										name: 'forecast-profit',
										translationId: 'project_budget.planned_profit',
										suffix: currencySymbol,
										checked: localStateColumns.find(column => column.name === 'forecast-profit')
											? localStateColumns.find(column => column.name === 'forecast-profit').checked
											: false,
									},
									{
										name: 'projected-profit',
										translationId: 'insights.component.list.column.forecastProfit',
										suffix: currencySymbol,
										checked: localStateColumns.find(column => column.name === 'projected-profit')
											? localStateColumns.find(column => column.name === 'projected-profit').checked
											: false,
									},
							  ]
							: []),
						{
							name: 'cost',
							translationId: 'project_budget.plan_cost',
							suffix: currencySymbol,
							checked: localStateColumns.find(column => column.name === 'cost')
								? localStateColumns.find(column => column.name === 'cost').checked
								: false,
						},
						{
							name: 'reported-cost',
							translationId: 'common.actual_cost',
							suffix: currencySymbol,
							checked: localStateColumns.find(column => column.name === 'reported-cost')
								? localStateColumns.find(column => column.name === 'reported-cost').checked
								: false,
						},
						{
							name: 'remaining-cost',
							translationId: 'project_budget.remaining_cost',
							suffix: currencySymbol,
							checked: localStateColumns.find(column => column.name === 'remaining-cost')
								? localStateColumns.find(column => column.name === 'remaining-cost').checked
								: false,
						},
						{
							name: 'projected-cost',
							translationId: 'project_budget.forecast_cost',
							suffix: currencySymbol,
							checked: localStateColumns.find(column => column.name === 'projected-cost')
								? localStateColumns.find(column => column.name === 'projected-cost').checked
								: false,
						},
						...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
							? [
									{
										name: 'xero-amount-total',
										xero: true,
										translationId: 'common.invoiced',
										checked: localStateColumns.find(column => column.name === 'xero-amount-total')
											? localStateColumns.find(column => column.name === 'xero-amount-total').checked
											: false,
									},
									{
										name: 'xero-paid',
										xero: true,
										translationId: 'project_section.xero_invoice_paid',
										checked: localStateColumns.find(column => column.name === 'xero-paid')
											? localStateColumns.find(column => column.name === 'xero-paid').checked
											: false,
									},
									{
										name: 'xero-due',
										xero: true,
										translationId: 'project_section.xero_due',
										checked: localStateColumns.find(column => column.name === 'xero-due')
											? localStateColumns.find(column => column.name === 'xero-due').checked
											: false,
									},
									{
										name: 'invoice-amount-total',
										invoicing: true,
										translationId: 'common.invoiced',
										checked: localStateColumns.find(column => column.name === 'invoice-amount-total')
											? localStateColumns.find(column => column.name === 'invoice-amount-total').checked
											: false,
									},
									{
										name: 'invoice-paid',
										invoicing: true,
										translationId: 'project_section.xero_invoice_paid',
										checked: localStateColumns.find(column => column.name === 'invoice-paid')
											? localStateColumns.find(column => column.name === 'invoice-paid').checked
											: false,
									},
									{
										name: 'invoice-due',
										invoicing: true,
										translationId: 'project_section.xero_due',
										checked: localStateColumns.find(column => column.name === 'invoice-due')
											? localStateColumns.find(column => column.name === 'invoice-due').checked
											: false,
									},
									{
										name: 'rateCard',
										translationId: 'common.rate-card',
										checked: localStateColumns.find(column => column.name === 'rateCard')
											? localStateColumns.find(column => column.name === 'rateCard').checked
											: false,
									},
							  ]
							: []),
						...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
							? [
									{
										name: 'recognition-locked-revenue',
										translationId: 'project_budget.revenue_recognition_from_locked_months',
										suffix: currencySymbol,
										checked: localStateColumns.find(column => column.name === 'recognition-locked-revenue')
											? localStateColumns.find(column => column.name === 'recognition-locked-revenue')
													.checked
											: false,
									},
									{
										name: 'recognition-remaining-revenue',
										translationId: 'project_budget.revenue_recognition_from_open_months',
										suffix: currencySymbol,
										checked: localStateColumns.find(
											column => column.name === 'recognition-remaining-revenue'
										)
											? localStateColumns.find(column => column.name === 'recognition-remaining-revenue')
													.checked
											: false,
									},
									{
										name: 'recognition-forecast-revenue',
										translationId: 'project_budget.recognition_forecast_revenue',
										suffix: currencySymbol,
										checked: localStateColumns.find(
											column => column.name === 'recognition-forecast-revenue'
										)
											? localStateColumns.find(column => column.name === 'recognition-forecast-revenue')
													.checked
											: false,
									},
							  ]
							: []),
				  ]
				: []),
		];

		if (CompanySetupUtil.hasFinance() && !isClientUser()) {
			const pushAtIndex = columns.findIndex(col => col.name === 'doneTasksCount');
			columns.splice(pushAtIndex + 1, 0, ...financialColumns);
		}

		this.budgetColumnNames = [
			'budget',
			'forecast-money',
			'reported-money',
			'remaining-money',
			'projected-money',
			'recognition-locked-revenue',
			'recognition-remaining-revenue',
			'recognition-forecast-revenue',
			'forecast-profit',
			'projected-profit',
			'cost',
			'reported-cost',
			'remaining-cost',
			'projected-cost',
		];

		this.NUMERIC_COLUMNS = [
			'win-chance',
			'forecastTotal',
			'forecast',
			'reported',
			'remaining',
			'tasksCount',
			'doneTasksCount',
			'budget',
			'forecast-money',
			'reported-money',
			'remaining-money',
			'projected-money',
			'recognition-locked-revenue',
			'recognition-remaining-revenue',
			'recognition-forecast-revenue',
			'forecast-profit',
			'projected-profit',
			'cost',
			'reported-cost',
			'remaining-cost',
			'projected-cost',
			'xero-amount-total',
			'xero-paid',
			'xero-due',
			'invoice-amount-total',
			'invoice-paid',
			'invoice-due',
			'projectPersonsCount',
			'variance_to_forecast',
		]; // new Map(JSON.parse(localStorage.getItem('projects-page-section-expanded-map')))
		const projectStatusSectionExpandedMap = localStorage.getItem('projects-page-section-expanded-map')
				? new Map(JSON.parse(localStorage.getItem('projects-page-section-expanded-map')))
				: new Map(),
			projectSectionCardHeightMap = new Map(),
			numberOfProjectTilesAfterLastGroupMap = new Map();

		this.project_statuses.forEach(project_status => {
			if (!projectStatusSectionExpandedMap.has(project_status)) {
				projectStatusSectionExpandedMap.set(project_status, project_status === PROJECT_STATUS.RUNNING);
			}
			const height = this.calculateHeighestCardV2(
				project_status,
				clientSelected,
				contactSelected,
				descriptionSelected,
				props.viewer.projects,
				props.viewer.projectGroups
			);
			projectSectionCardHeightMap.set(project_status, height);
			numberOfProjectTilesAfterLastGroupMap.set(project_status, null);
		});

		const localStorageTimestamp = localStorage.getItem('projects-page-data-timestamp');

		this.calculatedDataMap =
			localStorage.getItem('projects-page-calculated-data-map') &&
			localStorageTimestamp &&
			Date.now() - +localStorageTimestamp < 1000 * 60 * 60
				? new Map(JSON.parse(localStorage.getItem('projects-page-calculated-data-map')))
				: new Map();

		let filters;
		let filtersKey = 'overview-projects-filters-v4';
		if (localStorage.getItem(`${filtersKey}`)) {
			filters = JSON.parse(localStorage.getItem(`${filtersKey}`));
		} else {
			filters = {
				project: {},
			};
		}
		const filterFunctions = getFilterFunctions(filters);
		// Return state object
		return {
			searchFilterValue: '',
			sortBy,
			displayMode: displayMode || 'list',
			listOverflowWidth: null,
			nameOverflowPercentage: null,
			labelFilterValue: (JSON.parse(localStorage.getItem('overview-projects-filter-value-label')) || []).filter(
				val => val !== null
			),
			clientFilterValue: (JSON.parse(localStorage.getItem('overview-projects-filter-value-client')) || []).filter(
				val => val !== null
			),
			statusFilterValue: (JSON.parse(localStorage.getItem('overview-projects-filter-value-status')) || []).filter(
				val => val !== null
			),
			rateCardFilterValue: (JSON.parse(localStorage.getItem('overview-projects-filter-value-rateCard')) || []).filter(
				val => val !== null
			),
			contactFilterValue: (JSON.parse(localStorage.getItem('overview-projects-filter-value-contact')) || []).filter(
				val => val !== null
			),
			columns: columns,
			cardLayoutDisplay,
			projectStatusSectionExpandedMap,
			projectStatusFinancialDataFetchedMap: new Map(),
			projectStatusCalculatedDataFetchedMap: new Map(),
			projectSectionCardHeightMap,
			numberOfProjectTilesAfterLastGroupMap,
			filters,
			filterFunctions,
			lazyDataFetched: !this.props.excludeDoneOrHalted,
		};
	}

	overviewProjectDidMount(props) {
		const {numberOfProjectTilesAfterLastGroupMap} = this.state;
		this.project_statuses.forEach(project_status => {
			const numberOfProjects = this.getNumberOfExtraMarginProjects(project_status);
			numberOfProjectTilesAfterLastGroupMap.set(project_status, numberOfProjects);
		});
		this.setState({numberOfProjectTilesAfterLastGroupMap});

		const socketEvents = [
			{
				type: SOCKET_EVENT_TYPE.PROJECT,
				action: SOCKET_ACTION.CREATE,
				personIds: !hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) ? props.viewer.backendId : 'ALL',
			},
			{
				type: SOCKET_EVENT_TYPE.PROJECT,
				action: SOCKET_ACTION.DELETE,
				personIds: !hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) ? props.viewer.backendId : 'ALL',
			},
		];
		if (!hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL)) {
			socketEvents.push({
				type: SOCKET_EVENT_TYPE.PROJECT_PERSON,
				action: SOCKET_ACTION.CREATE,
				personIds: props.viewer.backendId,
			});
			socketEvents.push({
				type: SOCKET_EVENT_TYPE.PROJECT_PERSON,
				action: SOCKET_ACTION.DELETE,
				personIds: props.viewer.backendId,
			});
		}

		props.setSocketConfig(socketEvents, this.props.relay.refetch);
		let columns;
		if (this.state.displayMode === 'list') {
			columns = this.state.columns
				.filter(column => column.checked)
				.map(column => {
					return {name: column.name, checked: column.checked};
				});
		} else {
			columns = this.state.cardLayoutDisplay
				.filter(column => column.checked)
				.map(column => {
					return {name: column.name, checked: column.checked};
				});
		}

		tracking.trackPage('projects', null, columns, {display: this.state.displayMode});
		this.resize();
		window.addEventListener('resize', this.resize);

		this.fetchCalculatedDataIfRequired();
	}

	fetchCalculatedDataIfRequired() {
		const {projectStatusSectionExpandedMap, projectStatusCalculatedDataFetchedMap} = this.state;

		for (const status of [
			PROJECT_STATUS.PLANNING,
			PROJECT_STATUS.RUNNING,
			PROJECT_STATUS.HALTED,
			PROJECT_STATUS.DONE,
			PROJECT_STATUS.OPPORTUNITY,
		]) {
			if (projectStatusSectionExpandedMap.get(status) && !projectStatusCalculatedDataFetchedMap.has(status)) {
				this.fetchCalculatedData(status);
				projectStatusCalculatedDataFetchedMap.set(status, true);
			}
		}
	}

	getNumberOfExtraMarginProjects(project_status) {
		//needed in v2. Calculate amount of projects that need extra margin on top
		if (this['last_project_group_item_' + project_status] && this.scrollable) {
			const filledWidth =
				this['last_project_group_item_' + project_status].getBoundingClientRect().x -
				66 +
				this['last_project_group_item_' + project_status].getBoundingClientRect().width +
				16;
			//add 17px (66 left margin - 49 right margin) to take margin on both sides into account
			const totalWidth = this.scrollable.getBoundingClientRect().width - 115;
			const availableWidth = totalWidth - filledWidth;
			return Math.floor(availableWidth / 265);
		}
		return null;
	}

	calculateHeighestCardV2(project_status, clientSelected, contactSelected, descriptionSelected, projects, projectGroups) {
		let projectNameHeightMax = 0,
			descriptionHeightMax = 0,
			projectClientHeightMax = 0,
			projectContactHeightMax = 0;
		// find longest project name and calculate how many lines it will take. Needed in new UI to calc additional height card needs.
		const calcProjectHeight = project => {
			const projectName = project.node.name ? project.node.name : '';
			//if there are no white spaces name will be only in one line so no additional height needed
			const projectNameLines = projectName.includes(' ') ? Math.ceil((9 * projectName.length) / 201) : 0;
			const projectNameHeight = projectNameLines > 1 ? 20 : 0;
			const descriptionHeight = descriptionSelected ? (project.node.description ? 85 : 48) : 0;
			const projectClientheight = clientSelected && project.node.client ? 26 : 0;
			const projectContactHeight =
				contactSelected && project.node.projectPersons
					? project.node.projectPersons.edges.filter(pp => pp.node.isContactPerson).length !== 0
						? 59
						: 48
					: 0;
			projectNameHeightMax = projectNameHeight > projectNameHeightMax ? projectNameHeight : projectNameHeightMax;
			descriptionHeightMax = descriptionHeight > descriptionHeightMax ? descriptionHeight : descriptionHeightMax;
			projectClientHeightMax =
				projectClientheight > projectClientHeightMax ? projectClientheight : projectClientHeightMax;
			projectContactHeightMax =
				projectContactHeight > projectContactHeightMax ? projectContactHeight : projectContactHeightMax;
			return 146 + projectNameHeight + descriptionHeight + projectClientheight + projectContactHeight;
		};
		let height = 0;
		projects.edges
			.filter(project => project.node.status === project_status)
			.forEach(project => {
				const projectHeight = calcProjectHeight(project);
				if (height < projectHeight) {
					height = projectHeight;
				}
			});
		projectGroups.edges.forEach(pg => {
			pg.node.projects.edges
				.filter(project => project.node.status === project_status)
				.forEach(project => {
					const projectHeight = calcProjectHeight(project);
					if (height < projectHeight) {
						height = projectHeight;
					}
				});
		});
		return {
			heightTotal: Math.floor(height),
			projectNameHeightMax,
			descriptionHeightMax,
			projectClientHeightMax,
			projectContactHeightMax,
		};
	}

	resize() {
		const {numberOfProjectTilesAfterLastGroupMap} = this.state;
		this.project_statuses.forEach(project_status => {
			const numberOfProjects = this.getNumberOfExtraMarginProjects(project_status);
			numberOfProjectTilesAfterLastGroupMap.set(project_status, numberOfProjects);
		});
		this.requestedFrame = null;
		const element = this.table;
		if (element === null || element === undefined) {
			this.setState({numberOfProjectTilesAfterLastGroupMap});
			return;
		}

		if (this.state.listOverflowWidth === null) {
			if (element.scrollWidth > element.parentElement.clientWidth) {
				this.setState({
					listOverflowWidth: element.scrollWidth,
					nameOverflowPercentage: this.nameHeader
						? Math.floor((this.nameHeader.clientWidth / element.scrollWidth) * 100)
						: 0,
					numberOfProjectTilesAfterLastGroupMap,
					shouldRecalculateCompletionBarWidth: true,
				});
			}
		} else {
			if (this.state.listOverflowWidth <= element.parentElement.clientWidth) {
				this.setState({
					listOverflowWidth: null,
					nameOverflowPercentage: null,
					numberOfProjectTilesAfterLastGroupMap,
					shouldRecalculateCompletionBarWidth: true,
				});
			} else {
				this.setState({numberOfProjectTilesAfterLastGroupMap, shouldRecalculateCompletionBarWidth: true});
			}
		}
	}

	resetShouldRecalculateCompletionBarWidth() {
		if (this.state.shouldRecalculateCompletionBarWidth) {
			this.setState({shouldRecalculateCompletionBarWidth: false});
		}
	}

	handleShortcutProjects(e) {
		if (e.altKey && e.shiftKey && e.keyCode === 88) {
			this.clearFilters();
		}
	}

	handleColumnsFilter(selected) {
		const allSelected = [
			selected.name,
			...(selected.nestedOptions
				? selected.nestedOptions.flatMap(c => [c.name, ...(c.nestedOptions ? c.nestedOptions.map(gc => gc.name) : [])])
				: []),
		];
		if (this.state.displayMode === 'list') {
			let columns = this.state.columns.map(col => {
				if (allSelected.includes(col.name)) {
					return {
						name: col.name,
						suffix: col.suffix ? col.suffix : null,
						checked: !selected.checked,
						translationId: col.translationId,
						xero: col.xero,
						invoicing: col.invoicing,
					};
				} else {
					return {
						name: col.name,
						suffix: col.suffix ? col.suffix : null,
						checked: col.checked,
						translationId: col.translationId,
						xero: col.xero,
						invoicing: col.invoicing,
					};
				}
			});

			Util.localStorageSetItem('columns-projects-overview-v3', JSON.stringify(columns));
			this.setState({columns: columns}, () => {
				this.fetchCalculatedDataIfRequired();
			});
		} else {
			let contactSelected, clientSelected, descriptionSelected;
			let cardLayoutDisplay = this.state.cardLayoutDisplay.map(el => {
				const obj =
					el.name === selected.name
						? {
								name: el.name,
								translationId: el.translationId,
								checked: !selected.checked,
						  }
						: {name: el.name, translationId: el.translationId, checked: el.checked};
				if (obj.name === 'contact') {
					contactSelected = obj.checked;
				} else if (obj.name === 'client') {
					clientSelected = obj.checked;
				} else if (obj.name === 'description') {
					descriptionSelected = obj.checked;
				}
				return obj;
			});
			Util.localStorageSetItem('projects-overview-card-layout-display', JSON.stringify(cardLayoutDisplay));
			const {projectSectionCardHeightMap} = this.state;
			this.project_statuses.forEach(project_status => {
				const projectSectionCardHeight = this.calculateHeighestCardV2(
					project_status,
					clientSelected,
					contactSelected,
					descriptionSelected,
					this.props.viewer.projects,
					this.props.viewer.projectGroups
				);
				projectSectionCardHeightMap.set(project_status, projectSectionCardHeight);
			});
			this.setState({cardLayoutDisplay, projectSectionCardHeightMap});
		}
	}

	handleFilterChange(filterType, value) {
		this.setState({[filterType + 'FilterValue']: value, filterChanged: true});
		Util.localStorageSetItem('overview-projects-filter-value-' + filterType, JSON.stringify(value));
	}

	clearFilters() {
		this.setState({
			searchFilterValue: '',
			labelFilterValue: [],
			clientFilterValue: [],
			statusFilterValue: [],
			rateCardFilterValue: [],
			contactFilterValue: [],
		});
		localStorage.removeItem('overview-projects-filter-value-label');
		localStorage.removeItem('overview-projects-filter-value-client');
		localStorage.removeItem('overview-projects-filter-value-status');
		localStorage.removeItem('overview-projects-filter-value-rateCard');
		localStorage.removeItem('overview-projects-filter-value-contact');
	}

	getClearFilterText() {
		const {formatMessage} = this.props.intl;
		if (
			this.state.labelFilterValue.length ||
			this.state.clientFilterValue.length ||
			this.state.statusFilterValue.length ||
			this.state.rateCardFilterValue.length ||
			this.state.contactFilterValue.length
		) {
			if (this.state.searchFilterValue.trim().length) {
				return formatMessage({id: 'common.clear-all'});
			} else {
				return formatMessage({id: 'common.clear-filters'});
			}
		}
		if (this.state.searchFilterValue.trim().length) {
			return formatMessage({id: 'common.clear-search'});
		}
		return '';
	}

	setDisplayMode(value) {
		Util.localStorageSetItem('displayMode-projects-overview', value);
		tracking.trackPageAction('Display changed', {display: value});
		trackEvent('Display Mode', 'Changed', {display: value});

		this.setState({displayMode: value}, () => {
			this.fetchCalculatedDataIfRequired();
		});
	}

	handleSearchChange(e) {
		this.setState({searchFilterValue: e});
	}

	handleAddProjectFromEmptyState() {
		showModal({
			type: MODAL_TYPE.NEW_PROJECT_V2,
		});
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		Util.localStorageSetItem('overview-projects-sort-column', column);
		Util.localStorageSetItem('overview-projects-sort-ascending', ascending);
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	getGroupHTML(project_status, group, nameStyle, currencySymbol, projectNameColumnWidth, hasFinancialAccess) {
		const rows = [];
		group.node.projects.edges
			.filter(project => project.node.showProject && project.node.status === project_status)
			.forEach(project => {
				const lineItem = (
					<OverviewProjectsLineItem
						project={project}
						key={project.node.id}
						nameStyle={nameStyle}
						currencySymbol={
							project.node.rateCard ? util.GetCurrencySymbol(project.node.rateCard.currency) : currencySymbol
						}
						columns={this.state.columns}
						xeroEnabled={this.props.viewer.company.xeroEnabled}
						isInProjectGroup={true}
						selectedMenuItem={this.state.selectedMenuItem}
						searchFilterValue={this.state.searchFilterValue}
						filterChanged={this.state.filterChanged}
						projectNameColumnWidth={projectNameColumnWidth}
						calculatedDataMap={new Map(this.calculatedDataMap)}
						shouldRecalculateCompletionBarWidth={this.state.shouldRecalculateCompletionBarWidth}
						resetShouldRecalculateCompletionBarWidth={this.resetShouldRecalculateCompletionBarWidth.bind(this)}
						hasFinancialAccess={hasFinancialAccess}
					/>
				);
				rows.push(
					<ReactVisibilitySensor partialVisibility={true} key={project.node.id} offset={{top: -200, bottom: -200}}>
						{({isVisible}) => {
							return isVisible ? lineItem : <tr style={{height: '40px'}} />;
						}}
					</ReactVisibilitySensor>
				);
			});
		if (rows.length !== 0) {
			const groupLineItem = (
				<OverviewProjectsGroupLineItem
					key={group.node.id}
					projectGroup={group}
					projectGroupCompletion={group.node.completion}
					groupCompanyId={group.node.companyProjectGroupId}
					columns={this.state.columns}
					useFinancialService={true}
					currencySymbol={currencySymbol}
					hasXeroEnabled={this.props.viewer.company.xeroEnabled}
					selectedMenuItem={this.state.selectedMenuItem}
					searchFilterValue={this.state.searchFilterValue}
					filterChanged={this.state.filterChanged}
					projectNameColumnWidth={projectNameColumnWidth}
					calculatedDataMap={new Map(this.calculatedDataMap)}
					shouldRecalculateCompletionBarWidth={this.state.shouldRecalculateCompletionBarWidth}
					resetShouldRecalculateCompletionBarWidth={this.resetShouldRecalculateCompletionBarWidth.bind(this)}
					hasFinancialAccess={hasFinancialAccess}
				/>
			);
			rows.unshift(
				<ReactVisibilitySensor partialVisibility={true} key={group.node.id} offset={{top: -200, bottom: -200}}>
					{({isVisible}) => {
						return isVisible ? groupLineItem : <tr style={{height: '40px'}} />;
					}}
				</ReactVisibilitySensor>
			);
		}
		return rows;
	}

	onFilterChange(filters, filterFunctions) {
		this.setState({filters, filterFunctions});
	}

	groupEyeOptions(options) {
		const projectBasicsOptions = options.filter(option =>
			hasFeatureFlag('financial_categories_update')
				? [
						'client',
						'contact',
						'statusColor',
						'statusColorV2',
						'priorityLevel',
						'projectPersonsCount',
						'win-chance',
				  ].includes(option.name)
				: ['client', 'contact', 'statusColor', 'statusColorV2', 'priorityLevel', 'projectPersonsCount'].includes(
						option.name
				  )
		);
		const datesOptions = options.filter(option => ['startDate', 'endDate'].includes(option.name));
		const scopeOptions = options.filter(option =>
			['completion', 'forecastTotal', 'forecast', 'remaining', 'variance_to_forecast'].includes(option.name)
		);
		const timeRegOptions = options.filter(option => ['reported'].includes(option.name));
		const tasksOptions = options.filter(option => ['tasksCount', 'doneTasksCount'].includes(option.name));
		const financialOptions = options.filter(option =>
			[
				'budget',
				'win-chance',
				'rateCard',
				'forecast-money',
				'reported-money',
				'remaining-money',
				'projected-money',
				'cost',
				'reported-cost',
				'remaining-cost',
				'projected-cost',
				'forecast-profit',
				'projected-profit',
				'recognition-locked-revenue',
				'recognition-remaining-revenue',
				'recognition-forecast-revenue',
			].includes(option.name)
		);
		const invoiceOptions = options.filter(option =>
			['invoice-amount-total', 'invoice-paid', 'invoice-due'].includes(option.name)
		);
		const revenuesOptions = financialOptions.filter(option =>
			['forecast-money', 'reported-money', 'remaining-money', 'projected-money'].includes(option.name)
		);
		const revenueRecognitionOptions = financialOptions.filter(option =>
			['recognition-locked-revenue', 'recognition-remaining-revenue', 'recognition-forecast-revenue'].includes(
				option.name
			)
		);
		const costOptions = financialOptions.filter(option =>
			['cost', 'reported-cost', 'remaining-cost', 'projected-cost'].includes(option.name)
		);
		const profitOptions = financialOptions.filter(option => ['forecast-profit', 'projected-profit'].includes(option.name));

		const nestedOptions = [
			{
				name: 'projectBasics',
				disabled: false,
				checked: projectBasicsOptions.some(option => option.checked),
				translationId: 'common.project_basics',
				nestedOptions: projectBasicsOptions,
			},
			{
				name: 'dates',
				disabled: false,
				checked: datesOptions.some(option => option.checked),
				translationId: 'project_overview.project_dates',
				nestedOptions: datesOptions,
			},
			{
				name: 'scope',
				disabled: false,
				checked: scopeOptions.some(option => option.checked),
				translationId: 'common.scope',
				nestedOptions: scopeOptions,
			},
			...(isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)
				? []
				: [
						{
							name: 'timeRegistrations',
							disabled: false,
							checked: timeRegOptions.some(option => option.checked),
							translationId: 'common.time_registrations',
							nestedOptions: timeRegOptions,
						},
				  ]),
			{
				name: 'tasks',
				disabled: false,
				checked: tasksOptions.some(option => option.checked),
				translationId: 'common.tasks',
				nestedOptions: tasksOptions,
			},

			options.find(option => option.name === 'labels'),
		].filter(option => option && (!option.nestedOptions || (option.nestedOptions && option.nestedOptions.length > 0)));
		const hasFinancialAccess = hasSomePermission([
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
		]);
		const hasInvoiceReadAccess = hasPermission(PERMISSION_TYPE.INVOICE_READ);
		const filteredFinancialOptions = financialOptions
			.filter(option => hasFinancialAccess && ['budget', 'win-chance', 'rateCard'].includes(option.name))
			.concat(
				hasFinancialAccess
					? [
							...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
								? [
										{
											name: 'revenues',
											disabled: false,
											checked: revenuesOptions.some(option => option.checked),
											translationId: 'project_budget.revenue',
											nestedOptions: revenuesOptions,
										},
								  ]
								: []),
							...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
								? [
										{
											name: 'revenueRecognition',
											disabled: false,
											checked: revenueRecognitionOptions.some(option => option.checked),
											translationId: 'project_budget.revenue_recognition',
											nestedOptions: revenueRecognitionOptions,
										},
								  ]
								: []),
							...(!isFeatureHidden(HIDDEN_FEATURES.COST)
								? [
										{
											name: 'costs',
											disabled: false,
											checked: costOptions.some(option => option.checked),
											translationId: 'common.cost',
											nestedOptions: costOptions,
										},
								  ]
								: []),
							...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
								? [
										{
											name: 'profits',
											disabled: false,
											checked: profitOptions.some(option => option.checked),
											translationId: 'common.profit',
											nestedOptions: profitOptions,
										},
								  ]
								: []),
					  ]
					: []
			)
			.concat(hasInvoiceReadAccess && !isFeatureHidden(HIDDEN_FEATURES.REVENUE) ? invoiceOptions : []);
		const nestedFinancialOptions = {
			name: 'financials',
			disabled: false,
			checked: filteredFinancialOptions.some(option => option.checked),
			translationId: 'common.financials',
			nestedOptions: filteredFinancialOptions,
		};
		if (
			CompanySetupUtil.hasFinance() &&
			hasSomePermission([
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
			]) &&
			!hasFeatureFlag('financial_categories_update') &&
			filteredFinancialOptions.length
		) {
			const pushAtIndex = nestedOptions.findIndex(col => col.name === 'tasks');
			nestedOptions.splice(pushAtIndex + 1, 0, nestedFinancialOptions);
		}
		remapOptionTranslationIds(nestedOptions);
		return nestedOptions;
	}

	getHeaderTitle() {
		return (
			<TopHeaderBarWrapper sidePadding={66}>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'common.all_projects'})} content={[]} />
			</TopHeaderBarWrapper>
		);
	}

	getHeader(columnOptions) {
		const leftContent = [],
			rightContent = [];
		const columnsFiltered = columnOptions.filter(col => col.name !== 'completionBar');
		const hasPriorityLevels = this.props.viewer.company.priorityLevels
			? this.props.viewer.company.priorityLevels.edges.length > 0
			: false;

		leftContent.push({
			type: ELEMENT_TYPE.THE_EYE,
			openRight: true,
			options: this.state.displayMode === 'list' ? this.groupEyeOptions(columnsFiltered) : this.state.cardLayoutDisplay,
			onSelect: this.handleColumnsFilter.bind(this),
		});

		leftContent.push({
			type: ELEMENT_TYPE.VIEW_TYPE_BUTTON,
			callback: this.setDisplayModeV2.bind(this),
			currentView: this.state.displayMode === 'cards' ? 'list' : 'grid',
		});

		rightContent.push({
			type: ELEMENT_TYPE.SEARCH_LAZY,
			value: this.state.searchFilterValue,
			onChange: this.handleSearchChange.bind(this),
		});
		if (!this.props.viewer.client) {
			// INTERNAL TIME

			const projectFilters = [/*FILTER_TYPE.PERSON,*/ /*FILTER_TYPE.PROJECT, */ FILTER_TYPE.PROJECT_STAGE];
			const projectAdvancedFilters = [
				FILTER_TYPE.DEADLINE,
				FILTER_TYPE.CLIENT,
				FILTER_TYPE.CONTACT,
				FILTER_TYPE.LABEL,
				FILTER_TYPE.PROGRESS,
				...(hasPriorityLevels ? [FILTER_TYPE.PRIORITY_LEVEL] : []),
				FILTER_TYPE.PROJECT_STATUS,
			];

			if (
				CompanySetupUtil.hasFinance() &&
				!isFeatureHidden(HIDDEN_FEATURES.REVENUE) &&
				hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
				])
			) {
				projectAdvancedFilters.push(FILTER_TYPE.PROJECT_TYPE);
				projectAdvancedFilters.push(FILTER_TYPE.RATE_CARD);
			}

			const taskFilters = [];
			const peopleFilters = [];
			rightContent.push({
				type: ELEMENT_TYPE.FILTER_V4,
				defaultSection: FILTER_SECTIONS.PROJECTS,
				projectFilters: getOrderedFilters(projectFilters, projectAdvancedFilters, this.props.intl.formatMessage),
				peopleFilters,
				taskFilters,
				primaryFilters: {
					[FILTER_SECTIONS.PROJECTS]: [
						FILTER_TYPE.CLIENT,
						FILTER_TYPE.CONTACT,
						FILTER_TYPE.LABEL,
						FILTER_TYPE.PROJECT_TYPE,
						FILTER_TYPE.PROJECT_STAGE,
						FILTER_TYPE.RATE_CARD,
					],
				},
				viewer: this.props.viewer,
				filterSection: FILTER_SECTION.PROJECTS_OVERVIEW,
				appliedFiltersName: 'overview-projects-filters-v4',
				onFiltersChange: this.onFilterChange.bind(this),
				noMenu: true,
			});
		}

		if (hasPermission(PERMISSION_TYPE.PROJECTS_CREATE) && !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: this.props.intl.formatMessage({id: 'new_project.title'}),
				userpilot: 'new-project-button',
				callback: this.handleCreateProject.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		return (
			<HeaderBar
				innerRef={div => (this.header_bar = div)}
				parentGroup={null}
				leftContent={leftContent}
				rightContent={rightContent}
			/>
		);
	}

	setDisplayModeV2() {
		this.setDisplayMode(this.state.displayMode === 'cards' ? 'list' : 'cards');
	}

	handleCreateProject() {
		showModal({
			type: MODAL_TYPE.NEW_PROJECT_V2,
		});
	}

	getProjectGroupHTML(project_status, projectGroup, groupIndex, isLastItem) {
		const lastViewedPage = localStorage.getItem('project-group-section-last-viewed') || 'workflow';
		const path = '/connected/X-' + projectGroup.node.companyProjectGroupId + '/' + lastViewedPage;
		const projectsToShow = projectGroup.node.projects.edges
			.filter(project => project.node.showProject && project_status === project.node.status)
			.sort((a, b) => {
				if (a.node.companyProjectId < b.node.companyProjectId) return -1;
				return 1;
			});
		const textColor = util.getTextColorV2(projectGroup.node.color);
		return (
			<div
				className="project-group-v2"
				key={groupIndex}
				ref={isLastItem ? el => (this['last_project_group_item_' + project_status] = el) : null}
			>
				<Link to={path}>
					<div
						className={
							'project-group-header' +
							(projectGroup.node.color === '#ffffff' ? ' add-border' : ' only-bottom-border')
						}
						style={{backgroundColor: projectGroup.node.color, color: textColor}}
					>
						<div className={'icon' + (textColor === '#ffffff' ? ' white' : '')} />
						<span className="project-group-id">{`X${projectGroup.node.companyProjectGroupId}`}</span>
						<span className="project-group-name">{projectGroup.node.name}</span>
					</div>
				</Link>
				<div className="connected-projects-container">
					{projectsToShow.map((project, index) => (
						<OverviewProjectsProjectCard
							key={index}
							projectCardHeight={this.state.projectSectionCardHeightMap.get(project_status)}
							cardLayoutDisplay={this.state.cardLayoutDisplay}
							project={project}
							index={index}
							hasTimeApproval={this.hasTimeApproval}
							calculatedDataMap={this.calculatedDataMap}
						/>
					))}
				</div>
			</div>
		);
	}

	toggleProjectStatusSection(project_status) {
		const projectStatusSectionExpandedMap = new Map(this.state.projectStatusSectionExpandedMap);
		this.project_statuses.forEach(status => {
			const setStatus =
				status === project_status ? !this.state.projectStatusSectionExpandedMap.get(project_status) : false;
			projectStatusSectionExpandedMap.set(status, setStatus);
		});

		const stateObject = {projectStatusSectionExpandedMap};
		if (!this.state.projectStatusSectionExpandedMap.get(project_status)) {
			stateObject.loadingSection = true;
		}
		Util.localStorageSetItem(
			'projects-page-section-expanded-map',
			JSON.stringify(Array.from(projectStatusSectionExpandedMap.entries()))
		);
		this.setState(stateObject, () => {
			this.fetchCalculatedDataIfRequired();
		});
	}

	hasAnyProjectsInProjectStatusSection(projectGroup, project_status) {
		const connectedProjectsStatuses = projectGroup.node.projects.edges
			.filter(p => p.node.showProject)
			.map(p => p.node.status);
		return connectedProjectsStatuses.includes(project_status);
	}

	isStatusSectionReady(project_status, lazyDataFetched) {
		if ((project_status === PROJECT_STATUS.HALTED || project_status === PROJECT_STATUS.DONE) && !lazyDataFetched) {
			return false;
		}
		return true;
	}

	getProjectStatusSectionCount(projects, project_status, lazyDataFetched) {
		if (!this.isStatusSectionReady(project_status, lazyDataFetched)) {
			return '-';
		}
		let count = 0;
		projects.forEach(p => {
			if (p.node.projects) {
				const connectedProjectsLength = p.node.projects.edges.filter(
					nestedProject => nestedProject.node.showProject && nestedProject.node.status === project_status
				).length;
				count += connectedProjectsLength;
			} else {
				if ((this.props.viewer.client || !p.node.isInProjectGroup) && p.node.status === project_status) {
					++count;
				}
			}
		});
		return count;
	}

	getColumnWidth(columnName) {
		switch (columnName) {
			case 'id':
				return '60px';
			case 'completion':
			case 'forecastTotal':
			case 'forecast':
			case 'reported':
			case 'remaining':
			case 'tasksCount':
			case 'doneTasksCount':
			case 'budget':
			case 'forecast-money':
			case 'reported-money':
			case 'remaining-money':
			case 'projected-money':
			case 'forecast-profit':
			case 'projected-profit':
			case 'cost':
			case 'xero-paid':
			case 'xero-amount-total':
			case 'xero-due':
			case 'invoice-amount-total':
			case 'invoice-paid':
			case 'invoice-due':
				return '100px';
			case 'client':
			case 'rateCard':
				return '140px';
			case 'statusColor':
			case 'statusColorV2':
				return '50px';
			case 'labels':
				return '105px';
			case 'startDate':
			case 'endDate':
				return '110px';
			case 'contact':
				return '100px';
			case 'projectPersonsCount':
				return '70px';
			default:
				return '85px';
		}
	}

	getProjectGroupIdsByStatus(projects, status) {
		return projects
			.filter(project => project.node.projects)
			.filter(projectGroup => projectGroup.node.projects.edges.some(project => project.node.status === status))
			.map(projectGroup => projectGroup.node.id);
	}

	render() {
		if (!this.props.viewer.company) return <div />;
		const {formatMessage} = this.props.intl;
		const hasFinancialAccess = hasSomePermission([
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
		]);
		const hasInvoiceReadPermission = hasPermission(PERMISSION_TYPE.INVOICE_READ);
		const canAddProjects = hasPermission(PERMISSION_TYPE.PROJECTS_CREATE);
		const currencySymbol = util.GetCurrencySymbol(this.props.viewer.company.currency);

		//add projects to list start
		let projects = [];
		this.props.viewer.projectGroups.edges.forEach(pGroup => {
			projects.push(cloneDeep(pGroup));
		});
		this.props.viewer.projects.edges
			.filter(p => this.props.viewer.client || !p.node.isInProjectGroup)
			.forEach(p => projects.push(p));
		//add projects to list end

		projects = filterProjects(
			projects,
			this.state.searchFilterValue,
			this.state.filters,
			this.state.filterFunctions,
			this.calculatedDataMap
		);

		//empty state message start
		const hasActiveFilters =
			this.state.statusFilterValue.length ||
			this.state.clientFilterValue.length ||
			this.state.rateCardFilterValue.length ||
			this.state.labelFilterValue.length;
		let infoMessageId;
		if (this.state.searchFilterValue !== '') {
			infoMessageId = 'empty_state_projects.no-match';
		} else if (
			hasActiveFilters ||
			(this.state.filters && this.state.filters.project && Object.keys(this.state.filters.project).length > 0)
		) {
			infoMessageId = 'empty_state_projects.filters_no_match';
		} else {
			if (hasPermission(PERMISSION_TYPE.PROJECTS_CREATE)) {
				infoMessageId = 'empty_state_projects.title-manager';
			} else {
				infoMessageId = 'empty_state_projects.title-member';
			}
		}
		//empty state message end

		const columns = this.state.columns.filter(col => {
			return (
				col.name !== 'id' &&
				(((![
					'rateCard',
					'forecast-money',
					'budget',
					'win-chance',
					'reported-money',
					'remaining-money',
					'projected-money',
					'projected-profit',
					'forecast-profit',
					'cost',
				].includes(col.name) ||
					hasFinancialAccess) &&
					col.name !== 'xero-paid' &&
					col.name !== 'xero-amount-total' &&
					col.name !== 'xero-due' &&
					col.name !== 'invoice-paid' &&
					col.name !== 'invoice-due' &&
					col.name !== 'invoice-amount-total') ||
					((col.name === 'xero-paid' || col.name === 'xero-amount-total' || col.name === 'xero-due') &&
						hasInvoiceReadPermission &&
						this.props.viewer.company.xeroEnabled) ||
					((col.name === 'invoice-paid' || col.name === 'invoice-amount-total' || col.name === 'invoice-due') &&
						hasInvoiceReadPermission))
			);
		});

		let nameStyle = {};
		if (this.state.listOverflowWidth !== null) {
			nameStyle = {width: this.state.nameOverflowPercentage + '%', maxWidth: '150px', minWidth: '80px'};
		}
		const overflowingList =
			this.state.displayMode === 'list' &&
			this.table &&
			this.table.getBoundingClientRect().width > window.innerWidth - 66;
		const activeColNumber = this.state.columns.filter(
			col =>
				col.name !== 'name' &&
				col.name !== 'id' &&
				col.name !== 'completionBar' &&
				(this.props.viewer.company.xeroEnabled || !col.name.includes('xero')) &&
				col.checked
		).length;
		const projectNameColumnWidth =
			this.state.displayMode === 'list'
				? this.scrollable
					? (this.scrollable.getBoundingClientRect().width * (activeColNumber > 5 ? 30 : 50)) / 100 + 'px'
					: activeColNumber > 5
					? '30%'
					: '50%'
				: null;
		const hasRightToViewColumn = col => {
			const xeroColumns = ['xero-paid', 'xero-amount-total', 'xero-due'];
			const xeroCriteria =
				!xeroColumns.includes(col.name) || (hasInvoiceReadPermission && this.props.viewer.company.xeroEnabled);
			const invoiceCriteria =
				(col.name !== 'invoice-amount-total' && col.name !== 'invoice-paid' && col.name !== 'invoice-due') ||
				hasInvoiceReadPermission;
			return (
				(col.name === 'id' || col.checked) &&
				col.name !== 'completionBar' &&
				(!this.budgetColumnNames.includes(col.name) || hasFinancialAccess) &&
				xeroCriteria &&
				invoiceCriteria
			);
		};

		return (
			<section className={'app-main overview-section useNewNavigation'}>
				{this.state.loadingSection ? <UploadingOverlay /> : null}
				{this.props.children}
				{this.getHeaderTitle()}
				{this.getHeader(columns)}
				<div
					className={'section-body all-projects-section' + (this.props.buyNowTime ? ' show-trial-footer' : '')}
					data-cy={'projects-page'}
				>
					{(this.props.viewer.projectGroups && this.props.viewer.projectGroups.edges.length !== 0) ||
					(this.props.viewer.projects && this.props.viewer.projects.edges.length !== 0) ? (
						<div
							className={
								'all-projects useNewNavigation ' +
								(this.state.displayMode === 'list' ? ' list-view' : ' grid-view') +
								(overflowingList ? ' overflowing-list' : '')
							}
						>
							<CustomScrollToNativeScroll className="custom-scrollbar-div" hasFocusableContent isPageScroller>
								<div className="scrollable" ref={div => (this.scrollable = div)}>
									{projects.length !== 0 ? (
										this.project_statuses.map((project_status, sectionIndex) => (
											<div className={'project-status-section-wrapper'} key={sectionIndex}>
												<button
													className={
														'project-status-section-title' +
														(overflowingList ? ' overflowing-list' : '')
													}
													onClick={this.toggleProjectStatusSection.bind(this, project_status)}
												>
													<span
														className="title"
														data-cy={Util.getProjectStageTranslation(
															project_status,
															this.props.intl
														)}
													>
														{Util.getProjectStageTranslation(project_status, this.props.intl)}
													</span>
													{`(${this.getProjectStatusSectionCount(
														projects,
														project_status,
														this.state.lazyDataFetched
													)})`}
													<div
														className={
															'arrow ' +
															(this.state.projectStatusSectionExpandedMap.get(project_status)
																? 'expanded'
																: 'collapsed')
														}
													/>
												</button>
												{this.state.projectStatusSectionExpandedMap.get(project_status) ? (
													!this.isStatusSectionReady(project_status, this.state.lazyDataFetched) ? (
														<InlineLoader />
													) : projects.filter(
															project =>
																(!project.node.projects &&
																	project.node.status === project_status) ||
																(project.node.projects &&
																	this.hasAnyProjectsInProjectStatusSection(
																		project,
																		project_status
																	))
													  ).length === 0 ? null : this.state.displayMode === 'list' ? (
														<table
															className={
																'project-list-table' +
																(this.state.listOverflowWidth !== null
																	? ''
																	: ' not-overflowing')
															}
															ref={el => (this.table = el)}
														>
															<thead className={'table-header'}>
																<tr>
																	{this.state.columns.map(col =>
																		hasRightToViewColumn(col) ? (
																			<th
																				title={
																					col.name === 'id'
																						? 'ID'
																						: formatMessage(
																								{id: col.translationId},
																								col.suffix
																									? {suffix: col.suffix}
																									: ''
																						  )
																				}
																				key={col.name}
																				ref={el => {
																					if (col.name === 'name') {
																						this.nameHeader = el;
																					}
																				}}
																				className={
																					'sortable ' +
																					(col.name === 'id' ? 'id-column ' : '') +
																					(this.state.sortBy.column === col.name
																						? this.state.sortBy.ascending
																							? 'ascending'
																							: 'descending'
																						: '') +
																					(col.name === 'statusColor' ||
																					col.name === 'completion' ||
																					col.name === 'dates'
																						? ' center-text'
																						: '') +
																					(this.NUMERIC_COLUMNS.includes(col.name)
																						? ' numeric'
																						: '')
																				}
																				onClick={this.setSortBy.bind(this, col.name)}
																				style={
																					col.name === 'name'
																						? {
																								minWidth:
																									projectNameColumnWidth,
																								width: projectNameColumnWidth,
																								maxWidth:
																									projectNameColumnWidth,
																						  }
																						: col.name === 'client' ||
																						  col.name === 'rateCard'
																						? {
																								minWidth: '140px',
																								width: '140px',
																								maxWidth: '140px',
																						  }
																						: col.name === 'startDate' ||
																						  col.name === 'endDate'
																						? {
																								minWidth: '110px',
																								width: '110px',
																								maxWidth: '110px',
																						  }
																						: {
																								minWidth: this.getColumnWidth(
																									col.name
																								),
																						  }
																				}
																			>
																				{col.name === 'id' ? (
																					col.checked ? (
																						'ID'
																					) : (
																						''
																					)
																				) : col.name.includes('xero') ? (
																					<div className="header-name-icon-wrapper">
																						{formatMessage({id: col.translationId})}{' '}
																						(Xero)
																						<span>&nbsp;&nbsp;</span>
																					</div>
																				) : (
																					formatMessage(
																						{id: col.translationId},
																						col.suffix ? {suffix: col.suffix} : ''
																					)
																				)}
																				{!col.name.includes('xero') ? (
																					this.state.sortBy.column === col.name ? (
																						<span>&nbsp;&nbsp;</span>
																					) : null
																				) : null}
																			</th>
																		) : null
																	)}
																</tr>
															</thead>
															<tbody>
																<LoadMore
																	key="query-render-projects-in-state"
																	query={projectsLoaderQuery}
																	loader={
																		<ProjectsLoader
																			projectFromOutside={this.props.viewer.projects}
																			projectGroupsFromOutside={
																				this.props.viewer.projectGroups
																			}
																			calculatedDataMap={this.calculatedDataMap}
																			project_status={project_status}
																			hasFinancialCategoriesFeatureFlag={hasFeatureFlag(
																				'financial_categories_update'
																			)}
																		/>
																	}
																	customLoader={() => <InlineLoader />}
																>
																	{({data: projectsData}) => {
																		projectsData = filterProjects(
																			projectsData,
																			this.state.searchFilterValue,
																			this.state.filters,
																			this.state.filterFunctions,
																			this.calculatedDataMap
																		);
																		projectsData = sortProjects(
																			projectsData,
																			this.state.sortBy,
																			this.calculatedDataMap
																		);
																		return projectsData.map(project => {
																			if (project.node.projects) {
																				return this.getGroupHTML(
																					project_status,
																					project,
																					nameStyle,
																					currencySymbol,
																					projectNameColumnWidth,
																					hasFinancialAccess
																				);
																			} else {
																				// prettier-ignore
																				const lineItem = (
																					<OverviewProjectsLineItem
																						project={project}
																						key={project.id}
																						nameStyle={nameStyle}
																						currencySymbol={project.node.rateCard ? util.GetCurrencySymbol(project.node.rateCard.currency) : currencySymbol}
																						columns={this.state.columns}
																						xeroEnabled={this.props.viewer.company.xeroEnabled}
																						selectedMenuItem={this.state.selectedMenuItem}
																						searchFilterValue={this.state.searchFilterValue}
																						filterChanged={this.state.filterChanged}
																						projectNameColumnWidth={projectNameColumnWidth}
																						calculatedDataMap={new Map(this.calculatedDataMap)}
																						shouldRecalculateCompletionBarWidth={this.state.shouldRecalculateCompletionBarWidth}
																						resetShouldRecalculateCompletionBarWidth={this.resetShouldRecalculateCompletionBarWidth.bind(this)}
																						hasFinancialAccess={hasFinancialAccess}
																					/>
																				);
																				// prettier-ignore
																				return (
																					<ReactVisibilitySensor
																						partialVisibility={true}
																						offset={{ top: -200, bottom: -200 }}
																						key={project.node.id}
																					>
																						{({ isVisible }) => {
																							return isVisible ? (
																								lineItem
																							) : (
																								<tr style={{ height: '40px' }} />
																							);
																						}}
																					</ReactVisibilitySensor>
																				)
																			}
																		});
																	}}
																</LoadMore>
															</tbody>
														</table>
													) : (
														<div className="project-cards v2" data-cy="projects-containers">
															{projects
																.filter(
																	p =>
																		p.node.projects &&
																		this.hasAnyProjectsInProjectStatusSection(
																			p,
																			project_status
																		)
																)
																.map((projectGroup, index) =>
																	this.getProjectGroupHTML(
																		project_status,
																		projectGroup,
																		index,
																		index ===
																			projects.filter(
																				p =>
																					p.node.projects &&
																					this.hasAnyProjectsInProjectStatusSection(
																						p,
																						project_status
																					)
																			).length -
																				1
																	)
																)}
															{projects
																.filter(
																	p => !p.node.projects && p.node.status === project_status
																)
																.map((project, index) => (
																	<div key={index}>
																		<OverviewProjectsProjectCard
																			cardLayoutDisplay={this.state.cardLayoutDisplay}
																			project={project}
																			index={index}
																			includeWrapper={
																				this.state.numberOfProjectTilesAfterLastGroupMap.has(
																					project_status
																				) &&
																				this.state.numberOfProjectTilesAfterLastGroupMap.get(
																					project_status
																				) >=
																					index + 1
																			}
																			projectCardHeight={this.state.projectSectionCardHeightMap.get(
																				project_status
																			)}
																			calculatedDataMap={this.calculatedDataMap}
																			hasTimeApproval={this.hasTimeApproval}
																		/>
																	</div>
																))}
														</div>
													)
												) : null}
											</div>
										))
									) : this.state.searchFilterValue !== '' ||
									  hasActiveFilters ||
									  (this.state.filters?.project && Object.keys(this.state.filters.project).length > 0) ? (
										<DoneProjectsEmptyState
											infoMessageId={infoMessageId}
											searchText={this.state.searchFilterValue}
											canAddProjects={false}
										/>
									) : (
										<DoneProjectsEmptyState
											infoMessageId={infoMessageId}
											addProject={this.handleAddProjectFromEmptyState.bind(this)}
											canAddProjects={
												canAddProjects &&
												this.state.selectedMenuItem !== 'HALTED' &&
												this.state.selectedMenuItem !== 'DONE'
											}
										/>
									)}
								</div>
							</CustomScrollToNativeScroll>
						</div>
					) : hasPermission(PERMISSION_TYPE.PROJECTS_CREATE) ? (
						<EmptyState pageName={EMPTY_STATE.PROJECTS} />
					) : (
						<EmptyState pageName={EMPTY_STATE.PROJECTS_COLLABORATOR} />
					)}
				</div>
			</section>
		);
	}
}

const overviewProjectsQueryV2 = graphql`
	query overviewProjectsV2_Query(
		$loadSingleCurrency: Boolean!
		$loadMultiCurrency: Boolean!
		$excludeDoneOrHalted: Boolean!
		$excludeGroupProjectsFromProjectConnection: Boolean!
	) {
		viewer {
			actualPersonId
			component(name: "overview_projects")
			...overviewProjectsV2_viewer
				@arguments(
					loadSingleCurrency: $loadSingleCurrency
					loadMultiCurrency: $loadMultiCurrency
					excludeDoneOrHalted: $excludeDoneOrHalted
					excludeGroupProjectsFromProjectConnection: $excludeGroupProjectsFromProjectConnection
				)
		}
	}
`;

export {overviewProjectsQueryV2};

graphql`
	fragment overviewProjectsV2_projectGroup on ProjectGroupType {
		id
		companyProjectGroupId
		name
		color
		projects(first: 1000000) {
			edges {
				node {
					id
					status
					name
					budgetType
					isInProjectGroup
					readOnlyAccess
					# used in card layout
					description
					projectStartDay
					projectStartMonth
					projectStartYear
					projectEndDay
					projectEndMonth
					projectEndYear

					# used in search
					companyProjectId
					customProjectId
					client {
						id
						name
					}
					rateCard {
						id
					}
					projectLabels(first: 10000) {
						edges {
							node {
								label {
									id
								}
							}
						}
					}
					# used for filtering
					projectColor
					...StatusIndicatorWithTooltip_project
					currentProjectStatus {
						id
						color
						person {
							firstName
							lastName
						}
					}
					priorityLevel {
						id
						name
						weight
					}
					projectPersons(first: 1000000, contactsOnly: true) {
						edges {
							node {
								id
								isContactPerson
								person {
									id
									# used for card layout
									firstName
									lastName
									profilePictureId
									profilePictureDefaultId
									initials
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default withSocketHandling(
	injectIntl(
		withRouter(
			createRefetchContainer(
				overviewProjectsV2,
				{
					viewer: graphql`
						fragment overviewProjectsV2_viewer on Viewer
						@argumentDefinitions(
							loadSingleCurrency: {type: "Boolean!"}
							loadMultiCurrency: {type: "Boolean!"}
							excludeDoneOrHalted: {type: "Boolean!"}
							excludeGroupProjectsFromProjectConnection: {type: "Boolean!"}
						) {
							id
							backendId
							firstName
							actualPersonId
							availableFeatureFlags {
								key
							}
							client {
								id
							}
							filters(first: 1000000, filterSection: PROJECTS_OVERVIEW)
								@connection(key: "Viewer_filters", filters: []) {
								edges {
									node {
										id
										name
										section
										value
										updatedAt
									}
								}
							}
							company {
								currency
								xeroEnabled
								priorityLevels {
									edges {
										node {
											id
										}
									}
								}
							}
							projectGroups(first: 1000000, excludeDoneOrHalted: $excludeDoneOrHalted)
								@connection(key: "Viewer_projectGroups", filters: []) {
								edges {
									node {
										...overviewProjectsV2_projectGroup @relay(mask: false)
										projects(first: 1000000) {
											edges {
												node {
													id
													...ProjectsLoader_project @relay(mask: false)
													...ProjectsLoader_financialsMultiCurrencies
														@include(if: $loadMultiCurrency)
														@relay(mask: false)
													...ProjectsLoader_financialsCompanyCurrency
														@include(if: $loadSingleCurrency)
														@relay(mask: false)
												}
											}
										}
									}
								}
							}
							projects(
								first: 1000000
								excludeRestricted: true
								excludeGroupProjects: $excludeGroupProjectsFromProjectConnection
								excludeDoneOrHalted: $excludeDoneOrHalted
							) @connection(key: "Viewer_projects", filters: []) {
								edges {
									node {
										id
										...ProjectsLoader_project @relay(mask: false)
										...ProjectsLoader_financialsMultiCurrencies
											@include(if: $loadMultiCurrency)
											@relay(mask: false)
										...ProjectsLoader_financialsCompanyCurrency
											@include(if: $loadSingleCurrency)
											@relay(mask: false)
										name
										budgetType
										status
										isInProjectGroup
										readOnlyAccess
										# used in card layout
										description
										projectStartDay
										projectStartMonth
										projectStartYear
										projectEndDay
										projectEndMonth
										projectEndYear
										# used in search
										companyProjectId
										customProjectId
										client {
											id
											name
										}
										rateCard {
											id
										}
										projectLabels(first: 10000) {
											edges {
												node {
													label {
														id
													}
												}
											}
										}
										# used for filtering
										projectColor
										...StatusIndicatorWithTooltip_project
										currentProjectStatus {
											id
											color
											person {
												firstName
												lastName
											}
										}
										priorityLevel {
											id
											name
											weight
										}
										projectPersons(first: 1000000, contactsOnly: true) {
											edges {
												node {
													id
													isContactPerson
													person {
														id
														# used for card layout
														firstName
														lastName
														profilePictureId
														profilePictureDefaultId
														initials
													}
												}
											}
										}
									}
								}
							}
						}
					`,
				},
				graphql`
					query overviewProjectsV2_RefetchQuery(
						$loadSingleCurrency: Boolean!
						$loadMultiCurrency: Boolean!
						$excludeDoneOrHalted: Boolean!
						$excludeGroupProjectsFromProjectConnection: Boolean!
					) {
						viewer {
							actualPersonId
							component(name: "overview_projects_refetch")
							...overviewProjectsV2_viewer
								@arguments(
									loadSingleCurrency: $loadSingleCurrency
									loadMultiCurrency: $loadMultiCurrency
									excludeDoneOrHalted: $excludeDoneOrHalted
									excludeGroupProjectsFromProjectConnection: $excludeGroupProjectsFromProjectConnection
								)
						}
					}
				`
			)
		)
	)
);
