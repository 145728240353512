import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import * as tracking from '../../../tracking';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import CreateTeamsVerificationKeyMutation from '../../../mutations/create_teams_verification_key_mutation';
import Util from '../../../forecast-app/shared/util/util';
import {ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import DisableTeamsMutation from '../../../mutations/disable_teams_mutation';
import InputField from '../../../components/new-ui/input_field';
import {MSTeamsIcon, SIZE} from 'web-components';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class appTeams extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			tenant: this.props.viewer.company.teamsTenant,
		};

		this.superPropertyChecksum = trackPage('App Catalog - MS Teams');
	}

	componentDidMount() {
		document.title = 'Teams - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-teams');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5613585269521');
	}

	toggle() {
		if (this.props.viewer.company.teamsEnabled) {
			Util.CommitMutation(DisableTeamsMutation);
		} else {
			const onSuccess = ({createTeamsVerificationKey}) => {
				tracking.trackEvent('Teams integration enabled');
				trackEvent('Teams Integration', 'Enabled');
				window.location.href = `https://login.microsoftonline.com/${this.state.tenant}.onmicrosoft.com/adminconsent?
				client_id=${process.env.TEAMS_CLIENT_ID}
				&redirect_uri=${process.env.API_URL}/teams/oauth
				&state=${createTeamsVerificationKey.key}`;
			};

			Util.CommitMutation(CreateTeamsVerificationKeyMutation, {tenant: this.state.tenant}, onSuccess);
		}
	}

	onTenantChange(v) {
		this.setState({
			tenant: v,
		});
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const buttonLabel = this.props.viewer.company.teamsEnabled
			? formatMessage({id: 'integration.disable'})
			: formatMessage({id: 'integration.enable'});

		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<MSTeamsIcon size={SIZE.EXTRA_BIG} />
									<div className="title-description-wrapper">
										<div className="description">
											{formatMessage({id: 'settings.app_catalog.teams_description'})}
										</div>
									</div>
								</div>

								<div className="input-fields">
									<div className="tenant-container">
										<InputField
											label={formatMessage({id: 'settings.app_catalog.teams.tenant'})}
											value={this.state.tenant}
											locked={this.props.viewer.company.teamsEnabled}
											onChange={v => this.onTenantChange(v)}
										/>
									</div>

									<button
										className="submit-button"
										onClick={this.toggle.bind(this)}
										disabled={!this.props.viewer.company.teamsEnabled && !this.state.tenant}
									>
										{buttonLabel}
									</button>
									<button className="help-button" onClick={this.openHelpCenter.bind(this)}>
										<FormattedMessage id="settings.app_catalog.help_button" />
									</button>
								</div>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appTeamsQuery = graphql`
	query appTeams_Query {
		viewer {
			actualPersonId
			component(name: "app_teams")
			...appTeams_viewer
		}
	}
`;

export {appTeamsQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(appTeams, {
			viewer: graphql`
				fragment appTeams_viewer on Viewer {
					id
					availableFeatureFlags {
						key
					}
					company {
						id
						teamsEnabled
						teamsTenant
					}
				}
			`,
		})
	)
);
