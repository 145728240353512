import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import InputFieldV2 from '../../../components/new-ui/input_field';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import CreateLabelCategoryMutation from '../../../mutations/create_label_category_mutation';
import Util from '../../../forecast-app/shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';
import {Checkbox} from 'web-components';
import {LabelCheckboxStyled, LabelCheckboxheaderStyled} from './label_checkbox.styled';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';

const CreateLabelCategoryModal = ({company, closeModal}) => {
	const {formatMessage} = useIntl();

	const [name, setName] = useState('');
	const [allowOnTasks, setAllowOnTasks] = useState(true);
	const [allowOnProjects, setAllowOnProjects] = useState(true);
	const [allowOnPeople, setAllowOnPeople] = useState(true);
	const [errorMessage, setErrorMessage] = useState();

	const labelCategories = company.labelCategories.edges.map(labelCategory => labelCategory.node);
	const existingCategoryNames = new Set(labelCategories.map(labelCategory => labelCategory.name));

	const createCategory = () => {
		Util.CommitMutation(
			CreateLabelCategoryMutation,
			{
				companyId: company.id,
				name,
				allowOnTasks,
				allowOnProjects,
				allowOnPeople,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_labels.category_created_toast'}),
				});
			}
		);
	};

	const onNameChange = newName => {
		setName(newName);
		const duplicateName =
			existingCategoryNames.has(newName) || newName === formatMessage({id: 'settings_labels.no_category'});

		if (duplicateName) {
			setErrorMessage(formatMessage({id: 'settings_labels.category_already_exists'}));
		} else {
			setErrorMessage(null);
		}
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({id: 'common.create_label_category'})}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.create'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: createCategory,
					disabled: !name || !!errorMessage,
					cy: 'button-save',
				},
			]}
			content={
				<ModalBody>
					<Field>
						<InputFieldV2
							value={name}
							cy={'label-category-name'}
							id="labelCategoryName"
							label={formatMessage({id: 'common.name'})}
							placeholder={formatMessage({id: 'common.name_placeholder'})}
							type="text"
							onChange={onNameChange}
							errorMessage={errorMessage}
							invalidInput={!!errorMessage}
							required={true}
							maxLength={191}
						/>
					</Field>

					<LabelCheckboxheaderStyled>
						<span>{formatMessage({id: 'settings_labels.select_allowed_entities'}) + ':'}</span>
					</LabelCheckboxheaderStyled>

					<LabelCheckboxStyled>
						<Checkbox
							id="allow-on-tasks-checkbox"
							checked={allowOnTasks}
							onChange={() => setAllowOnTasks(!allowOnTasks)}
						/>
						<label htmlFor="allow-on-tasks-checkbox">{formatMessage({id: 'common.tasks'})}</label>
					</LabelCheckboxStyled>

					<LabelCheckboxStyled>
						<Checkbox
							id="allow-on-projects-checkbox"
							checked={allowOnProjects}
							onChange={() => setAllowOnProjects(!allowOnProjects)}
						/>
						<label htmlFor="allow-on-projects-checkbox">{formatMessage({id: 'common.projects'})}</label>
					</LabelCheckboxStyled>

					<LabelCheckboxStyled>
						<Checkbox
							id="allow-on-people-checkbox"
							checked={allowOnPeople}
							onChange={() => setAllowOnPeople(!allowOnPeople)}
						/>
						<label htmlFor="allow-on-people-checkbox">{formatMessage({id: 'common.people'})}</label>
					</LabelCheckboxStyled>
				</ModalBody>
			}
		/>
	);
};

const createLabelCategoryModalQuery = graphql`
	query CreateLabelCategoryModal_Query {
		viewer {
			actualPersonId
			component(name: "create_label_category_modal")
			company {
				...CreateLabelCategoryModal_company
			}
		}
	}
`;

export {createLabelCategoryModalQuery};

export default createFragmentContainer(CreateLabelCategoryModal, {
	company: graphql`
		fragment CreateLabelCategoryModal_company on Company {
			id
			labelCategories(first: 10000) @connection(key: "Company_labelCategories") {
				edges {
					node {
						id
						name
					}
				}
			}
		}
	`,
});
