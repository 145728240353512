import React from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {Button, Detail, FlexColumn, FlexRow, Heading, Text, TextField} from '@forecast-it/design-system';
import ActivateJiraServerMutation from '../../../mutations/activate_jira_server_mutation';
import FetchJiraServerOAuthUrlMutation from '../../../mutations/fetch_jira_server_oauth_url_mutation';
import DeactivateJiraServerMutation from '../../../mutations/deactivate_jira_server_mutation';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import * as tracking from '../../../tracking';
import jiraLogo from '../../../images/integrations/jira-software.png';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import InputField from '../../../components/inputs/input_field';
import Util from '../../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../../components/warning';
import {BUTTON_COLOR, BUTTON_STYLE, MODULE_TYPES} from '../../../constants';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';
import OldButton from '../../../forecast-app/shared/components/buttons/button/button';
import RadioButton from '../../../components/new-ui/radio_button';
import UploadingOverlay from '../../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import jiraUtil from '../../../components/jira/jira_util';
import {hasFeatureFlag, usingTimeApproval} from '../../../forecast-app/shared/util/FeatureUtil';
import {TimeApprovalIncompatibleContent} from './TimeApprovalIncompatibleContent';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import InformationIcon from '../../../images/information-icon';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import Switch from '../../../forecast-app/shared/components/switch/switch';
import UpdateJiraServerSyncSettings from '../../../mutations/jira/server/update_jira_server_sync_settings_mutation';

function toggleJiraServerToForecastOneWaySync(checked) {
	Util.CommitMutation(UpdateJiraServerSyncSettings, {isJiraToForecastOneWaySync: checked ? 'true' : 'false'});
}

class appJiraServer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activated: false,
			synchronizing: false,
			url: props.viewer.company.jiraServerUrl,
			authenticating: false,
			syncedProjectsExpandedMap: new Map(),
			showOldInstructions: false,
			clientId: '',
			clientSecret: '',
			clientIdError: false,
			clientSecretError: false,
		};

		this.superPropertyChecksum = trackPage('App Catalog - Jira Server');
	}

	componentDidMount() {
		document.title = 'Jira Server - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-jira-server');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	async fetchJiraProjectCountDetails(jiraProjectId) {
		const issuesCountObject = await jiraUtil.fetchIssuesCountForJiraProject(jiraProjectId, 'server');
		const projectCounts = this.state.projectCounts !== undefined ? this.state.projectCounts : new Map();
		const count = {issuesCount: issuesCountObject.tasks, subtasksCount: issuesCountObject.subtasks};
		projectCounts.set(jiraProjectId, count);
		this.setState({projectCounts});
	}

	toggleSynedProjectExpand(project) {
		const {syncedProjectsExpandedMap} = this.state;
		if (syncedProjectsExpandedMap.has(project.id)) {
			syncedProjectsExpandedMap.set(project.id, !syncedProjectsExpandedMap.get(project.id));
		} else {
			syncedProjectsExpandedMap.set(project.id, true);
			this.fetchJiraProjectCountDetails(project.jiraServerProject.id);
		}
		this.setState({syncedProjectsExpandedMap});
	}

	activate() {
		Util.CommitMutation(ActivateJiraServerMutation);
		this.setState({activated: true});
	}

	deactivate() {
		const {formatMessage} = this.props.intl;
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId={'settings.app_catalog.jira_server.deactivate'} />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			className: 'default-warning-modal',
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.deactivate'}),
					callback: () => {
						Util.CommitMutation(DeactivateJiraServerMutation);
						this.setState({activated: false});
					},
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
				},
			],
		});
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5311829935121');
	}

	syncJiraProject(project) {
		showModal({
			type: MODAL_TYPE.SYNC_JIRA_PROJECT,
			jiraProject: project,
			jiraType: 'server',
			syncType: 'project',
		});
	}
	unsyncJiraProject(project) {
		jiraUtil.unlinkProject(project);
	}

	changeJiraPrefixType(jiraPrefixType) {
		jiraUtil.changeJiraPrefixType(jiraPrefixType, this.props.viewer.company.id, true);
	}

	handleUrlChange(value) {
		this.setState({url: value});
	}

	authenticate() {
		this.setState({authenticating: true});
		const onUpdateSuccess = result => {
			const onFetchSuccess = result => {
				// Open url in a new window
				if (result.fetchJiraServerOAuthUrl.url) {
					// window.open(result.fetchJiraServerOAuthUrl.url, "_blank");
					window.location.href = result.fetchJiraServerOAuthUrl.url;
				} else if (result.fetchJiraServerOAuthUrl.errors) {
					showModal({
						type: MODAL_TYPE.GENERIC,
						content: (
							<div className="default-warning-modal">
								<Warning messageId="common.an_error_occoured" />
								<div className="warning-part-2">
									{this.props.intl.formatHTMLMessage({id: 'jira_server.authentication_error'})}
								</div>
							</div>
						),
						buttons: [
							{
								text: this.props.intl.formatMessage({id: 'common.ok'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				}
				this.setState({authenticating: false});
			};
			Util.CommitMutation(
				FetchJiraServerOAuthUrlMutation,
				{
					jiraServerUrl: this.state.url,
				},
				onFetchSuccess
			);
		};

		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: this.props.viewer.company.id,
				jiraServerUrl: this.state.url,
			},
			onUpdateSuccess
		);
	}

	authenticateNew() {
		if (!this.state.clientId || !this.state.clientSecret) {
			this.setState({clientIdError: !this.state.clientId, clientSecretError: !this.state.clientSecret});
			return;
		}

		this.setState({authenticating: true});
		const onFetchSuccess = result => {
			// Open url in a new window
			if (result.fetchJiraServerOAuthUrl.url) {
				window.location.href = result.fetchJiraServerOAuthUrl.url;
			} else if (result.fetchJiraServerOAuthUrl.errors) {
				showModal({
					type: MODAL_TYPE.GENERIC,
					content: (
						<div className="default-warning-modal">
							<Warning messageId="common.an_error_occoured" />
							<div className="warning-part-2">
								{this.props.intl.formatHTMLMessage({id: 'jira_server.authentication_error'})}
							</div>
						</div>
					),
					buttons: [
						{
							text: this.props.intl.formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			}
			this.setState({authenticating: false});
		};
		Util.CommitMutation(
			FetchJiraServerOAuthUrlMutation,
			{
				jiraServerUrl: this.state.url,
				clientId: this.state.clientId,
				clientSecret: this.state.clientSecret,
			},
			onFetchSuccess
		);
	}

	helpButton() {
		return (
			<OldButton
				text={this.props.intl.formatMessage({id: 'settings.app_catalog.help_button'})}
				onClick={this.openHelpCenter.bind(this)}
				buttonStyle={BUTTON_STYLE.OUTLINE}
				colorTheme={BUTTON_COLOR.GREY}
			/>
		);
	}

	getProjectsTable(isSyncedProjects, projects) {
		const {syncedProjectsExpandedMap, projectCounts} = this.state;
		return (
			<table className="jira-projects-sync-table">
				<thead>
					<tr>
						<th>
							<div className="logo jira">
								<FormattedMessage id="common.jira_project" />
							</div>
						</th>
						{isSyncedProjects ? (
							<th>
								<div className="logo forecast">
									<FormattedMessage id="common.forecast_project" />
								</div>
							</th>
						) : null}
						<th className="sync-controls-cell"></th>
					</tr>
				</thead>
				<tbody>
					{projects.map((project, index) => (
						<tr key={index}>
							<td>
								{isSyncedProjects ? (
									<div>
										<button
											className={`expand-control ${
												!syncedProjectsExpandedMap.has(project.id) ||
												!syncedProjectsExpandedMap.get(project.id)
													? 'collapsed'
													: ''
											}`}
											onClick={this.toggleSynedProjectExpand.bind(this, project)}
										/>
										{project.jiraServerProject.name}
									</div>
								) : (
									project.name
								)}
								{isSyncedProjects &&
								syncedProjectsExpandedMap.has(project.id) &&
								syncedProjectsExpandedMap.get(project.id) ? (
									projectCounts && projectCounts.has(project.jiraServerProject.id) ? (
										<div className="project-info-count-wrapper">
											<span>
												<span className="value">
													{projectCounts.get(project.jiraServerProject.id).issuesCount}
												</span>{' '}
												<FormattedMessage id="common.tasks" />
											</span>
											{projectCounts.get(project.jiraServerProject.id).subtasksCount ? (
												<span>
													<span className="value">
														{projectCounts.get(project.jiraServerProject.id).subtasksCount}
													</span>{' '}
													<FormattedMessage id="common.sub_tasks" />
												</span>
											) : null}
										</div>
									) : (
										<UploadingOverlay />
									)
								) : null}
							</td>
							{isSyncedProjects ? (
								<td>
									<div className="forecast-project-wrapper">
										<div
											className="forecast-project-indicator"
											style={{
												backgroundColor: project.projectColor,
												color: Util.getTextColorV2(project.projectColor),
											}}
										>
											{project.customProjectId ? project.customProjectId : `P${project.companyProjectId}`}
										</div>
										{project.name}
									</div>
								</td>
							) : null}
							<td className="sync-controls-cell">
								{isSyncedProjects ? (
									<button onClick={this.unsyncJiraProject.bind(this, project)} className="unsync-button">
										<FormattedMessage id="app_jira.live_page.stop_sync" />
									</button>
								) : (
									<button onClick={this.syncJiraProject.bind(this, project)} className="sync-button">
										<FormattedMessage id="app_jira.live_page.sync" />
									</button>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}

	getContentHeader(title) {
		return (
			<div className="settings-app-jira-section center">
				<span className="settings-app-jira-section-title large">{title}</span>
				<div className="logos-wrapper">
					<div className="jira-cloud-logo" />
					<div className="arrows-icon" />
					<div className="forecast-logo" />
				</div>
			</div>
		);
	}

	getMainHTML() {
		const {formatMessage} = this.props.intl;
		const synchedJiraProjects = this.props.viewer.projects.edges
			.filter(project => project.node.jiraServerProject)
			.map(project => project.node);
		const unSynchedJiraProjects = this.props.viewer.company.unSynchedJiraServerProjects;
		return (
			<>
				{this.getContentHeader(formatMessage({id: 'app_jira_server.live_page.title'}))}
				<div className="settings-app-jira-section live">
					<span className="settings-app-jira-section-title standard">
						<FormattedMessage id="settings.title" />
					</span>
					<div className="settings-app-jira-section-body">
						{hasFeatureFlag('jira_to_forecast_one_way_sync') && (
							<div className="current-settings">
								<div className="settings-title-container">
									<div className="current-settings-title">sync settings</div>
								</div>
								<div style={{display: 'flex', gap: '4px'}}>
									<Switch
										checked={
											this.props.viewer.company.integrations.jiraServer.syncSettings
												.isJiraToForecastOneWaySync
										}
										onChange={checked => toggleJiraServerToForecastOneWaySync(checked)}
										sliderWidth={38}
										sliderHeight={20}
									/>
									<div className="info-text">
										{formatMessage({id: 'integration.jira.one_way_sync_description'})}
									</div>
								</div>
							</div>
						)}
						<div className="current-settings">
							<div className="settings-title-container">
								<div className="current-settings-title">
									{formatMessage({id: 'settings.app_catalog.jira.prefix.title'})}
								</div>
								<ForecastTooltip
									content={formatMessage({id: 'app_jira.live_page.prefix_section.info'})}
									delay={0}
								>
									<InformationIcon />
								</ForecastTooltip>
							</div>
							<RadioButton
								text={formatMessage({id: 'app_jira.live_page.prefix_section.option_1'})}
								onSelected={this.changeJiraPrefixType.bind(this)}
								value="NONE"
								stateValue={this.props.viewer.company.jiraServerPrefixType}
								locked={this.props.viewer.company.jiraServerPrefixLoading}
							/>
							<RadioButton
								onSelected={this.changeJiraPrefixType.bind(this)}
								value="SIMPLE"
								text={formatMessage({id: 'app_jira.live_page.prefix_section.option_2'})}
								stateValue={this.props.viewer.company.jiraServerPrefixType}
								locked={this.props.viewer.company.jiraServerPrefixLoading}
							/>
							<RadioButton
								text={formatMessage({id: 'app_jira.live_page.prefix_section.option_3'})}
								value="FULL"
								onSelected={this.changeJiraPrefixType.bind(this)}
								stateValue={this.props.viewer.company.jiraServerPrefixType}
								locked={this.props.viewer.company.jiraServerPrefixLoading}
							/>
						</div>
					</div>
				</div>
				<div className="settings-app-jira-section live">
					<span className="settings-app-jira-section-title standard">
						<FormattedMessage id="app_jira.live_page.unsynced_projects_section.title" />
					</span>
					<div className="settings-app-jira-section-body">{this.getProjectsTable(false, unSynchedJiraProjects)}</div>
				</div>
				<div className="settings-app-jira-section live">
					<span className="settings-app-jira-section-title standard">
						<FormattedMessage id="app_jira.live_page.synced_projects_section.title" />
					</span>
					<div className="settings-app-jira-section-body">{this.getProjectsTable(true, synchedJiraProjects)}</div>
				</div>
				<div className="settings-app-jira-section live">
					<span className="settings-app-jira-section-title standard">
						<FormattedMessage id="app_jira.live_page.disconnect_section.title" />
					</span>
					<span className="info-text">
						<FormattedMessage id="app_jira.live_page.disconnect_section.info" />
					</span>
					<div className="buttons">
						<OldButton
							buttonStyle={BUTTON_STYLE.OUTLINE}
							colorTheme={BUTTON_COLOR.RED}
							text={formatMessage({id: 'app_jira.live_page.disconnect_section.disconnect_button'})}
							onClick={this.deactivate.bind(this)}
						/>
					</div>
				</div>
			</>
		);
	}

	getSetupIntegrationHTML() {
		return (
			<>
				<div className="jira-server-instructions">
					<div>
						<FlexRow>
							<Detail>{`Using ${
								this.state.showOldInstructions ? 'a newer' : 'an older'
							} version of Jira Server?`}</Detail>
							<Button
								emphasis="low"
								onClick={() => {
									this.setState({showOldInstructions: !this.state.showOldInstructions});
								}}
							>
								click here
							</Button>
						</FlexRow>
					</div>
					{this.state.showOldInstructions ? this.getOldSetupInstructions() : this.getNewSetupInstructions()}
				</div>
			</>
		);
	}

	getOldSetupInstructions() {
		return (
			<>
				<p>
					<FormattedMessage id="settings.app_catalog.jira_server.installation" />
				</p>
				<ol>
					<li>
						<FormattedMessage id="settings.app_catalog.jira_server.installation.step0" />
					</li>
					<li>
						<FormattedMessage id="settings.app_catalog.jira_server.installation.step1" />
					</li>
					<li>
						<FormattedHTMLMessage id="settings.app_catalog.jira_server.installation.step2" />
					</li>
					<li>
						<FormattedHTMLMessage id="settings.app_catalog.jira_server.installation.step3" />
					</li>
					<li>
						<FormattedHTMLMessage id="settings.app_catalog.jira_server.installation.step4" />
					</li>
					<li>
						<FormattedMessage id="settings.app_catalog.jira_server.installation.step5" />
					</li>
					<li>
						<FormattedMessage id="settings.app_catalog.jira_server.installation.step6" />
					</li>
					<li>
						<FormattedHTMLMessage
							id="settings.app_catalog.jira_server.installation.step7"
							values={{key: this.props.viewer.company.jiraServerConsumerKey}}
						/>
					</li>
					<li>
						<FormattedHTMLMessage id="settings.app_catalog.jira_server.installation.step8" />
					</li>
					<li>
						<FormattedMessage id="settings.app_catalog.jira_server.installation.step9" />
					</li>
					<textarea rows={4} cols={100} readOnly={true}>
						{this.props.viewer.company.jiraServerPublicRsaKey}
					</textarea>
					<li>
						<FormattedHTMLMessage id="settings.app_catalog.jira_server.installation.step10" />
					</li>
					<li>
						<FormattedHTMLMessage id="settings.app_catalog.jira_server.installation.step10a" />
					</li>
					<li>
						<FormattedHTMLMessage id="settings.app_catalog.jira_server.installation.step10b" />
					</li>
					<li>
						<FormattedHTMLMessage id="settings.app_catalog.jira_server.installation.step10c" />
					</li>
					<li>
						<FormattedMessage id="settings.app_catalog.jira_server.installation.step11" />
					</li>
				</ol>
				<FormattedMessage id="settings.app_catalog.jira_server.installation.step12" />
				<div className="options">
					<InputField
						label={this.props.intl.formatMessage({id: 'settings.app_catalog.jira_server.url.label'})}
						type="text"
						placeholder="jira.domain.com"
						value={this.state.url}
						onChange={this.handleUrlChange.bind(this)}
						locked={this.props.viewer.company.jiraServerAccessToken !== ''}
					/>
					<OldButton
						text={this.props.intl.formatMessage({id: 'common.authenticate'})}
						isDisabled={this.state.authenticating}
						onClick={this.authenticate.bind(this)}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={BUTTON_COLOR.GREEN}
					/>

					{this.state.authenticating ? (
						<div style={{width: '30px', height: '30px', marginBottom: '3px'}}>
							<InlineLoader />
						</div>
					) : null}
				</div>
			</>
		);
	}

	getNewSetupInstructions() {
		return (
			<FlexColumn gap="l">
				<FlexColumn gap="m">
					<Heading size="4">Set up integration</Heading>
					<FlexColumn>
						<Text>
							In order to set up a connection to Jira Data Center, you must first create an Application Link in
							your Jira instance by following the instrations below:
						</Text>
						<Text>
							1. Log into Jira as an administrator and go to 'Administration' -&gt; 'Applications' -&gt;
							'Application links'
						</Text>
						<Text>
							2. Click 'Create link' and choose 'External application' and 'Incoming', then click 'Continue'
						</Text>
						<Text>
							3. Give the application link a name like '<b>Forecast</b>', set the Redirect URL to '
							<b>https://api.forecast.it/jira-server/oauth2</b>', and set Permission to '<b>Admin</b>'
						</Text>
						<Text>
							4. Now that the application link has been created, copy it's credentials '<b>Client ID</b>' and '
							<b>Client Secret</b>' into the input fields below.
						</Text>
					</FlexColumn>
				</FlexColumn>
				<FlexColumn gap="m">
					<TextField
						label="Client ID"
						placeholder="Copy Client ID here"
						value={this.state.clientId}
						errorText={this.state.clientIdError ? 'Input client id' : undefined}
						onValueChange={value => {
							this.setState({clientId: value});
						}}
					/>
					<TextField
						label="Client Secret"
						placeholder="Copy Client Secret here"
						value={this.state.clientSecret}
						errorText={this.state.clientSecretError ? 'Input client secret' : undefined}
						onValueChange={value => {
							this.setState({clientSecret: value});
						}}
					/>
					<TextField
						label="Jira URL"
						placeholder="Input your Jira URL here. Example: jira.domain.com"
						value={this.state.url}
						onValueChange={value => {
							this.setState({url: value});
						}}
					/>
				</FlexColumn>
				<FlexColumn>
					<Button disabled={this.state.authenticating || !this.state.url} onClick={this.authenticateNew.bind(this)}>
						Activate
					</Button>
				</FlexColumn>
			</FlexColumn>
		);
	}

	render() {
		const hasJiraModule = hasModule(MODULE_TYPES.JIRA);
		const useTimeApproval = usingTimeApproval(this.props.viewer.company.useTimeApproval);

		return (
			<div className="section-content settings-app settings-app-jira">
				<div className="header">
					<button className="back-button" onClick={this.props.history.goBack}>
						Integrations
					</button>
				</div>
				<CustomScrollDiv>
					<div className="content">
						{hasJiraModule ? (
							useTimeApproval ? (
								<TimeApprovalIncompatibleContent integrationName={'JIRA Server'} />
							) : !this.props.viewer.company.jiraServerConsumerKey && !this.state.activated ? (
								<>
									<div className="header-wrapper">
										<img width={100} height={100} alt="jira logo" src={jiraLogo} />
										<div className="title-description-wrapper">
											<div className="description">
												<FormattedHTMLMessage id="settings.app_catalog.jira.description" />
											</div>
											<div className="input-fields">
												<OldButton
													text={this.props.intl.formatMessage({
														id: 'settings.app_catalog.jira.activate',
													})}
													onClick={this.activate.bind(this)}
													buttonStyle={BUTTON_STYLE.FILLED}
													colorTheme={BUTTON_COLOR.GREEN}
												/>
												{this.helpButton()}
											</div>
										</div>
									</div>
								</>
							) : this.props.viewer.company.jiraServerAccessToken ||
							  this.props.viewer.company.jiraServerEnabled ? (
								this.getMainHTML()
							) : (
								this.getSetupIntegrationHTML()
							)
						) : (
							<span className="jira-unavailable">
								<FormattedMessage id="settings.app_catalog.jira.unavailable" />
							</span>
						)}
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appJiraServerQuery = graphql`
	query appJiraServer_Query {
		viewer {
			actualPersonId
			component(name: "app_jira_server")
			...appJiraServer_viewer
		}
	}
`;

export {appJiraServerQuery};

export default createFragmentContainer(injectIntl(withRouter(appJiraServer)), {
	viewer: graphql`
		fragment appJiraServer_viewer on Viewer {
			id
			availableFeatureFlags {
				key
			}
			company {
				id
				useTimeApproval
				integrations {
					jiraServer {
						syncSettings {
							isJiraToForecastOneWaySync
						}
					}
				}
				jiraServerPublicRsaKey
				jiraServerConsumerKey
				jiraServerAccessToken
				jiraServerUrl
				jiraServerPrefixType
				jiraServerPrefixLoading
				jiraServerState
				jiraServerEnabled
				unSynchedJiraServerProjects {
					id
					name
					key
				}
			}
			projects(first: 10000) {
				edges {
					node {
						id
						projectColor
						companyProjectId
						customProjectId
						name
						isJiraProject
						jiraServerProject {
							name
							id
						}
					}
				}
			}
		}
	`,
});
