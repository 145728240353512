import React, {useState} from 'react';
import {Checkbox, KebabMenuIcon, RadioButton, Table} from 'web-components';
import {PageWrapper} from '../../../components/insights/utilization-report/UtilizationReport.styled';
import ContextMenu from '../../../forecast-app/shared/components/context-menus/context_menu';
import Util from '../../../forecast-app/shared/util/util';
import DeprecatedProjectIndicatorJS from '../../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

const salesforceTemplatesTable = ({
	intl,
	templates,
	projects,
	genericHandleTemplatesCheckboxChange,
	handleDefaultTemplateChange,
	handleDeleteTemplate,
}) => {
	const [contextMenuData, setContextMenuData] = useState(null);
	const getProjectIconAndNameFromProjectId = projectId => {
		const foundProject = projects.find(project => Util.getIdFromBase64String(project.node.id) === projectId);
		return <DeprecatedProjectIndicatorJS clearSubPath={true} project={foundProject.node} showProjectName />;
	};

	const getContextMenuOptions = templateIndex => {
		const options = [
			{
				text: intl.formatMessage({id: 'common.delete'}),
				onClick: () => handleDeleteTemplate(templateIndex),
			},
		];

		return options;
	};

	const handleOpenContextMenu = (e, templateIndex) => {
		e.preventDefault();
		const newContextMenuData = {
			templateIndex,
		};
		const btn = document.elementFromPoint(e.pageX, e.pageY);
		if (btn != null && e.type !== 'contextmenu') {
			const btnBounds = btn.getBoundingClientRect();
			newContextMenuData.posX = btnBounds.right - btn.clientWidth - 72;
			newContextMenuData.posY = btnBounds.y + 23;
		} else {
			newContextMenuData.posX = e.pageX;
			//check if there is place for menu underneath cursor
			if (window.innerHeight - e.pageY < 250) {
				newContextMenuData.posY = e.pageY - 250;
			} else {
				newContextMenuData.posY = e.pageY;
			}
		}
		setContextMenuData(newContextMenuData);
	};

	const handleCloseContextMenu = () => {
		setContextMenuData(null);
	};

	const buildTemplateRow = (template, index) => {
		return {
			project: getProjectIconAndNameFromProjectId(template.projectId),
			reference: template.reference,
			settingsAndWorkflows: (
				<Checkbox
					checked={template.duplicateSettings}
					onClick={() => genericHandleTemplatesCheckboxChange('duplicateSettings', index)}
				/>
			),
			allTasks: (
				<Checkbox
					checked={template.duplicateTasks}
					onClick={() => genericHandleTemplatesCheckboxChange('duplicateTasks', index)}
				/>
			),
			allPhases: (
				<Checkbox
					checked={template.duplicatePhases}
					onClick={() => genericHandleTemplatesCheckboxChange('duplicatePhases', index)}
				/>
			),
			allTeamMembers: (
				<Checkbox
					checked={template.duplicateTeam}
					onClick={() => genericHandleTemplatesCheckboxChange('duplicateTeam', index)}
				/>
			),
			allocations: (
				<Checkbox
					checked={template.duplicateAllocations}
					onClick={() => genericHandleTemplatesCheckboxChange('duplicateAllocations', index)}
				/>
			),
			expenses: (
				<Checkbox
					checked={template.duplicateExpenses}
					onClick={() => genericHandleTemplatesCheckboxChange('duplicateExpenses', index)}
				/>
			),
			retainerPeriods: (
				<Checkbox
					checked={template.duplicateRetainerPeriods}
					onClick={() => genericHandleTemplatesCheckboxChange('duplicateRetainerPeriods', index)}
				/>
			),
			projectColor: (
				<Checkbox
					checked={template.duplicateColor}
					onClick={() => genericHandleTemplatesCheckboxChange('duplicateColor', index)}
				/>
			),
			default: <RadioButton checked={template.default} onClick={() => handleDefaultTemplateChange(index)} />,
			contextMenu: (
				<KebabMenuIcon
					onClick={e => {
						handleOpenContextMenu(e, index);
					}}
					cy={'invoice-row-context-menu'}
				/>
			),
		};
	};
	const data = {rows: templates.map((template, index) => buildTemplateRow(template, index))};

	return (
		<PageWrapper>
			{!!contextMenuData && (
				<div style={{zIndex: 600}}>
					<ContextMenu
						options={getContextMenuOptions(contextMenuData.templateIndex)}
						contextMenuPosition={{x: contextMenuData.posX, y: contextMenuData.posY}}
						closeContextMenu={handleCloseContextMenu}
						userpilot={'context-menu-wrapper'}
					/>
				</div>
			)}
			<Table>
				<Table.Header>
					<Table.HeaderColumn flex={1} usePadding visible width="LARGE" align="left">
						<Table.HeaderColumn.Title>Project</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn flex={1} usePadding visible width="SMALL" align="center">
						<Table.HeaderColumn.Title>Reference</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding visible width="MEDIUM" align="center">
						<Table.HeaderColumn.Title>{'Settings & Workflow columns'}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding visible width="SMALL" align="center">
						<Table.HeaderColumn.Title>All tasks</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding visible width="SMALL" align="center">
						<Table.HeaderColumn.Title>All phases</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding visible width="SMALL" align="center">
						<Table.HeaderColumn.Title>All team members</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding visible width="SMALL" align="center">
						<Table.HeaderColumn.Title>Expenses</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding visible width="SMALL" align="center">
						<Table.HeaderColumn.Title>Project color</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding visible width="EXTRA_SMALL" align="center">
						<Table.HeaderColumn.Title>Default</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn align={'center'} visible={true} width={'EXTRA_SMALL'}>
						{/* context  menu*/}
					</Table.HeaderColumn>
				</Table.Header>
				<Table.Rows data={data} canExpand={false}>
					{({rowData, tableColumnsProps}) => {
						return (
							<Table.Row {...tableColumnsProps}>
								<Table.Column>{rowData.project}</Table.Column>
								<Table.Column>{rowData.reference}</Table.Column>
								<Table.Column>{rowData.settingsAndWorkflows}</Table.Column>
								<Table.Column>{rowData.allTasks}</Table.Column>
								<Table.Column>{rowData.allPhases}</Table.Column>
								<Table.Column>{rowData.allTeamMembers}</Table.Column>
								<Table.Column>{rowData.expenses}</Table.Column>
								<Table.Column>{rowData.projectColor}</Table.Column>
								<Table.Column>{rowData.default}</Table.Column>
								<Table.Column>{rowData.contextMenu}</Table.Column>
							</Table.Row>
						);
					}}
				</Table.Rows>
			</Table>
		</PageWrapper>
	);
};

export default salesforceTemplatesTable;
