import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import UpdateLabelCategoryMutation from '../../../mutations/update_label_category_mutation';
import {createFragmentContainer, graphql} from 'react-relay';
import {Checkbox, ModalHeaderInput} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';
import {ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import {LabelCheckboxheaderStyled, LabelCheckboxStyled} from './label_checkbox.styled';
import LabelEntityWarning from './label_entity_warning';

const UpdateLabelCategoryModal = ({labelCategoryId, company, closeModal}) => {
	const {formatMessage} = useIntl();

	const labelCategories = company.labelCategories.edges.map(labelCategory => labelCategory.node);
	const labels = company.labels.edges.map(label => label.node);

	const existingCategoryNames = new Set(
		labelCategories.filter(labelCategory => labelCategory.id !== labelCategoryId).map(labelCategory => labelCategory.name)
	);

	const labelCategory = labelCategories.find(labelCategory => labelCategory.id === labelCategoryId);

	if (!labelCategory) {
		return null;
	}

	const categoryLabels = labels.filter(label => label.category?.id === labelCategory.id);

	// check if there are any labels assigned to tasks, projects or people
	const labelsAssignedTo = {tasks: false, projects: false, people: false};
	categoryLabels.forEach(label => {
		if (label.taskCount > 0) {
			labelsAssignedTo.tasks = true;
		}

		if (label.projectCount > 0) {
			labelsAssignedTo.projects = true;
		}

		if (label.peopleCount > 0) {
			labelsAssignedTo.people = true;
		}
	});

	const [name, setName] = useState(labelCategory.name);
	const [allowOnTasks, setAllowOnTasks] = useState(labelCategory.allowOnTasks);
	const [allowOnProjects, setAllowOnProjects] = useState(labelCategory.allowOnProjects);
	const [allowOnPeople, setAllowOnPeople] = useState(labelCategory.allowOnPeople);

	const [errorMessage, setErrorMessage] = useState();
	const [nameValid, setNameValid] = useState(true);

	const createCategory = () => {
		Util.CommitMutation(
			UpdateLabelCategoryMutation,
			{
				name,
				id: labelCategoryId,
				companyId: company.id,
				allowOnTasks,
				allowOnProjects,
				allowOnPeople,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_labels.changes_saved'}),
				});
			}
		);
	};

	const onNameChange = newName => {
		setName(newName);
		const duplicateName =
			existingCategoryNames.has(newName) || newName === formatMessage({id: 'settings_labels.no_category'});
		if (duplicateName) {
			setNameValid(false);
			setErrorMessage(formatMessage({id: 'settings_labels.category_already_exists'}));
		} else {
			setNameValid(!!newName);
			setErrorMessage(null);
		}
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.save'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: createCategory,
					disabled: !nameValid || !!errorMessage,
					cy: 'button-save',
				},
			]}
			content={
				<div>
					<ModalHeaderInput
						cy="label-category-name"
						placeholder={formatMessage({id: 'common.name_placeholder'})}
						value={name}
						onChange={onNameChange}
						hasError={!nameValid}
						errorMessage={errorMessage}
					/>
					<ModalBody>
						<LabelCheckboxheaderStyled>
							<span>{formatMessage({id: 'settings_labels.select_allowed_entities'}) + ':'}</span>
						</LabelCheckboxheaderStyled>

						<LabelCheckboxStyled>
							<Checkbox
								id="allow-on-tasks-checkbox"
								checked={allowOnTasks}
								onChange={() => setAllowOnTasks(!allowOnTasks)}
							/>
							<label htmlFor="allow-on-tasks-checkbox">{formatMessage({id: 'common.tasks'})}</label>
						</LabelCheckboxStyled>

						<LabelCheckboxStyled>
							<Checkbox
								id="allow-on-projects-checkbox"
								checked={allowOnProjects}
								onChange={() => setAllowOnProjects(!allowOnProjects)}
							/>
							<label htmlFor="allow-on-projects-checkbox">{formatMessage({id: 'common.projects'})}</label>
						</LabelCheckboxStyled>

						<LabelCheckboxStyled>
							<Checkbox
								id="allow-on-people-checkbox"
								checked={allowOnPeople}
								onChange={() => setAllowOnPeople(!allowOnPeople)}
							/>
							<label htmlFor="allow-on-people-checkbox">{formatMessage({id: 'common.people'})}</label>
						</LabelCheckboxStyled>

						{!allowOnTasks && labelsAssignedTo.tasks && (
							<LabelEntityWarning
								updated
								itemWarning={formatMessage(
									{id: 'settings_labels.category_update_warning'},
									{entity: formatMessage({id: 'common.tasks'})}
								)}
								key={'warning-labels-assigned-to-tasks'}
								cy={`warning-labels-assigned-to-tasks`}
							/>
						)}

						{!allowOnProjects && labelsAssignedTo.projects && (
							<LabelEntityWarning
								updated
								itemWarning={formatMessage(
									{id: 'settings_labels.category_update_warning'},
									{entity: formatMessage({id: 'common.projects'})}
								)}
								key={'warning-labels-assigned-to-projects'}
								cy={`warning-labels-assigned-to-projects`}
							/>
						)}

						{!allowOnPeople && labelsAssignedTo.people && (
							<LabelEntityWarning
								updated
								itemWarning={formatMessage(
									{id: 'settings_labels.category_update_warning'},
									{entity: formatMessage({id: 'common.people'})}
								)}
								key={'warning-labels-assigned-to-people'}
								cy={`warning-labels-assigned-to-people`}
							/>
						)}
					</ModalBody>
				</div>
			}
		/>
	);
};

const updateLabelCategoryModalQuery = graphql`
	query UpdateLabelCategoryModal_Query {
		viewer {
			actualPersonId
			component(name: "update_label_category_modal")
			company {
				...UpdateLabelCategoryModal_company
			}
		}
	}
`;

export {updateLabelCategoryModalQuery};

export default createFragmentContainer(UpdateLabelCategoryModal, {
	company: graphql`
		fragment UpdateLabelCategoryModal_company on Company {
			id
			labelCategories(first: 10000) @connection(key: "Company_labelCategories") {
				edges {
					node {
						id
						name
						allowOnTasks
						allowOnProjects
						allowOnPeople
					}
				}
			}
			labels(first: 10000) @connection(key: "Company_labels") {
				edges {
					node {
						id
						name
						taskCount
						projectCount
						peopleCount
						category {
							id
						}
					}
				}
			}
		}
	`,
});
