import SettingsCalendar from './SettingsCalendar';
import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';

const SettingsCalendarWrapper = ({viewer, personId}) => {
	return <SettingsCalendar viewer={viewer} personId={personId} />;
};

export default createFragmentContainer(SettingsCalendarWrapper, {
	viewer: graphql`
		fragment SettingsCalendarWrapper_viewer on Viewer {
			id
			calendarUser {
				name
				email
				picture
			}
			calendarTokenError
			calendarViewPrivateEvents
			enabledCalendars
			calendars {
				edges {
					node {
						id
						name
						description
						backgroundColor
						foregroundColor
					}
				}
			}
		}
	`,
});
