import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import UpdateGoogleDriveAppMutation from '../../../mutations/update_google_drive_mutation';
import * as tracking from '../../../tracking';
import gdriveLogo from '../../../images/integrations/gdrive-logo.png';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Util from '../../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_STYLE, BUTTON_COLOR, ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class appGoogleDrive extends React.Component {
	constructor(props) {
		super(props);

		this.superPropertyChecksum = trackPage('App Catalog - Google Drive');
	}

	componentDidMount() {
		document.title = 'Google Drive - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-google-drive');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	update() {
		const {formatMessage} = this.props.intl;

		if (this.props.viewer.company.gdriveEnabled) {
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'warning-modal.edit-warning-title',
				warningInformation: [formatMessage({id: 'settings.app_catalog.google_drive.deactivate_warning'})],
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.deactivate'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: this.actualUpdate.bind(this),
					},
				],
			});
			this.setState({verifying: false});
		} else {
			tracking.trackEvent('Google Drive integration enabled');
			trackEvent('Google Drive Integration', 'Enabled');
			this.actualUpdate();
		}
	}

	actualUpdate() {
		const newActicatedState = !this.props.viewer.company.gdriveEnabled;
		Util.CommitMutation(UpdateGoogleDriveAppMutation, {
			gdriveEnabled: newActicatedState,
		});
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5563226887953');
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;

		const buttonLabel = this.props.viewer.company.gdriveEnabled
			? formatMessage({id: 'settings.app_catalog.google_drive.disable'})
			: formatMessage({id: 'settings.app_catalog.google_drive.enable'});
		return (
			<div className="section-content settings-app settings-app-page settings-app-jira">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<img width={100} height={100} alt="Google Drive" src={gdriveLogo} />
									<div className="title-description-wrapper">
										<div className="description">
											{formatMessage({id: 'settings.app_catalog.google_drive_description'})}
										</div>
									</div>
								</div>
								<div className="input-fields">
									<button className="submit-button" onClick={this.update.bind(this)}>
										{buttonLabel}
									</button>
									<button className="help-button" onClick={this.openHelpCenter.bind(this)}>
										<FormattedMessage id="settings.app_catalog.help_button" />
									</button>
								</div>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appGoogleDriveQuery = graphql`
	query appGoogleDrive_Query {
		viewer {
			actualPersonId
			component(name: "app_gdrive")
			...appGoogleDrive_viewer
		}
	}
`;

export {appGoogleDriveQuery};

export default createFragmentContainer(injectIntl(withRouter(appGoogleDrive)), {
	viewer: graphql`
		fragment appGoogleDrive_viewer on Viewer {
			id
			company {
				id
				gdriveEnabled
			}
		}
	`,
});
