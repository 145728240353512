import React from 'react';
import {useIntl} from 'react-intl';
import {InputLabel, BasicTextInput, Dropdown, CurrencyInput} from 'web-components';
import {RateCardSettingsRow, RateCardSettingsInput, RateCardSettingsContainer} from './RateCard.styled';
import {currencies, DATE_PICKER_STYLE} from '../../../constants';
import DatePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import moment from 'moment';
import Util from '../../../forecast-app/shared/util/util';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';

const RateCardSettings = ({
	rateCardVersions,
	activeRateCardVersion,
	isRateCardUsed,
	handleDateChange,
	handleDefaultRateChange,
	currency,
	handleCurrencyChange,
	name,
	nameError,
	handleNameChange,
	exchangeRates,
	companyCurrency,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;

	const currency_options = exchangeRates.edges.map(exchangeRate => {
		return currencies.find(currency => currency.value === exchangeRate.node.currency);
	});
	currency_options.unshift(currencies.find(currency => currency.value === companyCurrency));

	return (
		<RateCardSettingsContainer>
			<RateCardSettingsRow>
				<RateCardSettingsInput>
					<InputLabel
						text={formatMessage({id: 'settings.rate-card-name'})}
						required
						activeError={!!nameError}
						extension={nameError}
						child={
							<BasicTextInput
								cy={'rate-card-name-input'}
								placeholder={formatMessage({id: 'rate_card_modal.name_placeholder'})}
								value={name}
								hasError={!!nameError}
								shouldFocus={true}
								modalView={true}
								onChange={handleNameChange}
							/>
						}
					/>
				</RateCardSettingsInput>
				<RateCardSettingsInput>
					<InputLabel
						text={formatMessage({id: 'settings_details.currency_section'})}
						required
						activeError={false}
						child={
							<ForecastTooltip
								content={formatMessage({id: 'rate_card_modal.currency_disabled'})}
								disabled={!isRateCardUsed}
							>
								<Dropdown
									selectedItems={currency}
									onSelect={option => handleCurrencyChange(option[0])}
									disabled={isRateCardUsed}
									size={'large'}
									usePortal
									name={formatMessage({id: 'common.currency'})}
								>
									{currency_options.map(option => (
										<Dropdown.SingleText
											key={option.value}
											value={option.value}
											searchString={option.label}
											disabled={isRateCardUsed}
										>
											{option.label}
										</Dropdown.SingleText>
									))}
								</Dropdown>
							</ForecastTooltip>
						}
					/>
				</RateCardSettingsInput>
			</RateCardSettingsRow>
			<RateCardSettingsRow>
				<RateCardSettingsInput>
					<InputLabel
						text={formatMessage({id: 'rate_card_modal.effective_date'})}
						required
						activeError={false}
						child={
							<DatePicker
								startDate={activeRateCardVersion?.startDate ? moment(activeRateCardVersion.startDate) : null}
								startDateEmptyText={formatMessage({id: 'rate_card_modal.initial_version'})}
								datePickerStyle={DATE_PICKER_STYLE.RATE_CARD_MODAL}
								isSingleDatePicker={true}
								buttonCy={'rate-card-date-input'}
								fullWidth
								leftAlign
								handleDateRangeChange={handleDateChange}
								disabled={!activeRateCardVersion?.startDate}
								disabledDates={rateCardVersions.map(rateCardVersion => moment(rateCardVersion.startDate))}
							/>
						}
					/>
				</RateCardSettingsInput>
				<RateCardSettingsInput>
					<InputLabel
						text={formatMessage({id: 'rate_card_modal.default_rate'})}
						required
						activeError={false}
						child={
							<CurrencyInput
								cy={'default-rate-input'}
								value={activeRateCardVersion.defaultRate}
								modalView={true}
								alignLeft={true}
								currencySymbole={Util.GetCurrencySymbol(currency)}
								callback={handleDefaultRateChange}
								filterNonNumerics={true}
							/>
						}
					/>
				</RateCardSettingsInput>
			</RateCardSettingsRow>
		</RateCardSettingsContainer>
	);
};

export default RateCardSettings;
