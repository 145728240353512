import React, {useReducer} from 'react';
import {
	CornerChip,
	TaskContainer,
	TableRowHeader,
	StaticTableRowItem,
	GrowableTableRowItem,
	Line,
	TaskItemTableRow,
	TaskItemsContainer,
	WrapStaticRowItem,
	LabelContainer,
	DropdownContainer,
} from './project_automate_modal.styled';
import AssignedDropdown from '../../../forecast-app/shared/components/dropdowns/assigned-dropdown/assigned_dropdown';
import HoursInput from '../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {graphql, createFragmentContainer} from 'react-relay';
import {useEffect} from 'react';
import DropdownV2 from '../../../forecast-app/shared/components/dropdowns/dropdown';
import {Label} from 'web-components';
import {union} from 'lodash';
const initalState = {
	estimates: {},
	estimatesAi: {},
	roles: {},
	rolesAi: {},
	assignees: {},
	assigneesAI: {},
	labels: {},
	labelsAI: {},
	similarProject: null,
};

const ACTIONS = {
	SET_ESTIMATE: 'setEstimate',
	SET_ESTIMATE_AI: 'setEstimate_ai',
	SET_SIMILAR_PROJECT: 'setSimilarProject',
	SET_ROLE: 'setRole',
	SET_ROLE_AI: 'setRole_ai',
	SET_ASSIGNEE: 'setAssignee',
	SET_ASSIGNEE_AI: 'setAssignee_ai',
	UNASSIGN_ASSIGNEE: 'unassign_assignee',
	SET_LABEL: 'setLabel',
	SET_LABEL_AI: 'setLabel_ai',
	REMOVE_LABEL: 'removeLabel',
};

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.SET_ESTIMATE: {
			const {estimates} = state;
			estimates[action.data.taskId] = action.data.estimate;
			return {
				...state,
				estimates,
			};
		}
		case ACTIONS.SET_ESTIMATE_AI: {
			const {estimatesAi} = state;
			estimatesAi[action.data.taskId] = action.data.estimate;
			return {
				...state,
				estimatesAi,
			};
		}
		case ACTIONS.SET_SIMILAR_PROJECT: {
			return {
				...state,
				similarProject: action.data.similarProject,
			};
		}
		case ACTIONS.SET_ROLE: {
			const {roles} = state;
			roles[action.data.taskId] = action.data.roleId;
			return {
				...state,
				roles,
			};
		}
		case ACTIONS.SET_ROLE_AI: {
			const {rolesAi} = state;
			rolesAi[action.data.taskId] = action.data.roleId;
			return {
				...state,
				rolesAi,
			};
		}
		case ACTIONS.SET_ASSIGNEE: {
			const {assignees} = state;
			assignees[action.data.taskId] = action.data.assigneeIds;
			return {
				...state,
				assignees,
			};
		}
		case ACTIONS.SET_ASSIGNEE_AI: {
			const {assigneesAI} = state;
			assigneesAI[action.data.taskId] = action.data.assigneeIds;
			return {
				...state,
				assigneesAI,
			};
		}
		case ACTIONS.UNASSIGN_ASSIGNEE: {
			const {assignees} = state;
			assignees[action.data.taskId] = assignees[action.data.taskId].filter(id => id !== action.data.assigneeId);
			return {
				...state,
				assignees,
			};
		}
		case ACTIONS.SET_LABEL: {
			const {labels} = state;
			if (!labels[action.data.taskId] || action.data.reset) {
				labels[action.data.taskId] = [];
			}
			labels[action.data.taskId] = union(labels[action.data.taskId], action.data.labelIds);
			return {
				...state,
				labels,
			};
		}
		case ACTIONS.SET_LABEL_AI: {
			const {labelsAI} = state;
			labelsAI[action.data.taskId] = action.data.labelIds;
			return {
				...state,
				labelsAI,
			};
		}
		case ACTIONS.REMOVE_LABEL: {
			const {labels} = state;
			labels[action.data.taskId] = labels[action.data.taskId].filter(id => id !== action.data.labelId);
			return {
				...state,
				labels,
			};
		}
		default:
			return state;
	}
};

export const taskList = ({
	intl,
	tasks,
	estimateVisible,
	assigneesVisible,
	roleVisible,
	labelsVisible,
	onError,
	passState,
	roles,
	persons,
	labels,
	...props
}) => {
	const [state, dispatch] = useReducer(reducer, {...initalState});
	const showAIIconForEstimate = id => state.estimates && state.estimates[id] === state.estimatesAi[id];
	const showAIIconForRole = id => state.roles[id] === state.rolesAi[id] && state.rolesAi[id] !== null;
	const showAIIconForAssignee = id => {
		return (
			state.assignees[id] &&
			state.assignees[id].length === 1 &&
			state.assigneesAI[id] &&
			state.assigneesAI[id].length === 1 &&
			state.assigneesAI[id][0] === state.assignees[id][0]
		);
	};

	useEffect(() => {
		passState(state);
	});

	const setEstimate = (estimate, taskId) => {
		dispatch({
			type: ACTIONS.SET_ESTIMATE,
			data: {
				taskId,
				estimate,
			},
		});
	};

	const setEstimateAI = (estimate, taskId) => {
		dispatch({
			type: ACTIONS.SET_ESTIMATE_AI,
			data: {
				taskId,
				estimate,
			},
		});
	};

	const setRole = (roleId, taskId) => {
		dispatch({
			type: ACTIONS.SET_ROLE,
			data: {
				taskId,
				roleId,
			},
		});
	};

	const setRoleAI = (roleId, taskId) => {
		dispatch({
			type: ACTIONS.SET_ROLE_AI,
			data: {
				taskId,
				roleId,
			},
		});
	};

	const setAssignees = (assigneeIds, taskId) => {
		dispatch({
			type: ACTIONS.SET_ASSIGNEE,
			data: {
				taskId,
				assigneeIds,
			},
		});
	};

	const setAssigneeAI = (assigneeIds, taskId) => {
		dispatch({
			type: ACTIONS.SET_ASSIGNEE_AI,
			data: {
				taskId,
				assigneeIds,
			},
		});
	};

	const unassignAssignee = (assigneeId, taskId) => {
		dispatch({
			type: ACTIONS.UNASSIGN_ASSIGNEE,
			data: {
				taskId,
				assigneeId,
			},
		});
	};

	const setLabels = (labelIds, taskId, reset) => {
		dispatch({
			type: ACTIONS.SET_LABEL,
			data: {
				taskId,
				labelIds,
				reset,
			},
		});
	};

	const setLabelsAI = (labelIds, taskId) => {
		dispatch({
			type: ACTIONS.SET_LABEL_AI,
			data: {
				taskId,
				labelIds,
			},
		});
	};

	const removeLabel = (labelId, taskId) => {
		dispatch({
			type: ACTIONS.REMOVE_LABEL,
			data: {
				taskId,
				labelId,
			},
		});
	};

	useEffect(() => {
		/*
			First set all estimates to 0
			as our base estimate
		*/
		tasks.forEach(task => {
			setEstimate(0, task.id);
			setRole(task.role ? task.role.id : null, task.id);
			setAssignees(task.assignedPersons ? task.assignedPersons.map(person => person.id) : [], task.id);
			setLabels(task.taskLabels ? task.taskLabels.map(label => label.label.id) : [], task.id, true);
		});
		// for all estimates set the estimate
		props.viewer.tasks.edges.forEach(edge => {
			estimateVisible && setEstimate(edge.node.highLowSuggestion.estimate / 60, edge.node.id);
			estimateVisible && setEstimateAI(edge.node.highLowSuggestion.estimate / 60, edge.node.id);
			// TODO set ai role
			if (roleVisible && edge.node.roleSuggestion.length > 0) {
				setRoleAI(edge.node.roleSuggestion[0], edge.node.id);
				setRole(edge.node.roleSuggestion[0], edge.node.id);
			}
			if (assigneesVisible && edge.node.assigneeSuggestion.length > 0) {
				setAssigneeAI([edge.node.assigneeSuggestion[0]], edge.node.id);
				setAssignees([edge.node.assigneeSuggestion[0]], edge.node.id);
			}
			if (labelsVisible && edge.node.labelSuggestion.length > 0) {
				setLabelsAI([edge.node.labelSuggestion[0]], edge.node.id);
				setLabels([edge.node.labelSuggestion[0]], edge.node.id);
			}
		});
	}, [props.viewer.tasks]);

	return (
		<TaskContainer large={labelsVisible}>
			<TaskListHeader
				intl={intl}
				estimateVisible={estimateVisible}
				assigneesVisible={assigneesVisible}
				roleVisible={roleVisible}
				labelsVisible={labelsVisible}
			></TaskListHeader>
			<CustomScrollDiv autoHeight={true} autoHeightMin={'1vh'} autoHeightMax={'50vh'}>
				<TaskItemsContainer large={labelsVisible}>
					{tasks.map((task, index) => (
						<TaskItem
							showEstimateAI={estimateVisible && showAIIconForEstimate(task.id)}
							key={task.companyTaskId}
							estimateVisible={estimateVisible}
							roleVisible={roleVisible}
							labelsVisible={labelsVisible}
							labels={labels}
							labelIds={labelsVisible && state.labels[task.id]}
							labelAIIds={labelsVisible && state.labelsAI[task.id]}
							addLabel={setLabels}
							removeLabel={removeLabel}
							assigneesVisible={assigneesVisible}
							onChangeEstimate={setEstimate}
							estimate={estimateVisible && state.estimates[task.id]}
							task={task}
							roles={roles}
							onChangeRole={setRole}
							roleId={state.roles[task.id]}
							showRoleAI={roleVisible && showAIIconForRole(task.id)}
							persons={persons}
							first={index === 0}
							intl={intl}
							setAssignees={setAssignees}
							unassignAssignee={unassignAssignee}
							assigneeIds={assigneesVisible && state.assignees[task.id]}
							showAssigneeAI={assigneesVisible && showAIIconForAssignee(task.id)}
						/>
					))}
				</TaskItemsContainer>
			</CustomScrollDiv>
		</TaskContainer>
	);
};

export const TaskListHeader = ({intl, estimateVisible, assigneesVisible, roleVisible, labelsVisible}) => {
	return (
		<TableRowHeader large={labelsVisible}>
			<StaticTableRowItem width={65}>{intl.formatMessage({id: 'common.id'})}</StaticTableRowItem>
			<GrowableTableRowItem>{intl.formatMessage({id: 'common.name'})}</GrowableTableRowItem>
			{estimateVisible && (
				<StaticTableRowItem width={110}>{intl.formatMessage({id: 'common.estimate'})}</StaticTableRowItem>
			)}
			{assigneesVisible && (
				<StaticTableRowItem width={220}>{intl.formatMessage({id: 'common.assignees'})}</StaticTableRowItem>
			)}
			{roleVisible && <StaticTableRowItem width={179}>{intl.formatMessage({id: 'common.role'})}</StaticTableRowItem>}
			{labelsVisible && <StaticTableRowItem width={380}>{intl.formatMessage({id: 'common.labels'})}</StaticTableRowItem>}
			{labelsVisible && <StaticTableRowItem width={190}></StaticTableRowItem>}
		</TableRowHeader>
	);
};

export const TaskItem = ({
	estimateVisible,
	showEstimateAI,
	estimate,
	onChangeEstimate,
	task,
	first,
	assigneesVisible,
	roleVisible,
	labelsVisible,
	roles,
	labels,
	labelIds,
	labelAIIds,
	addLabel,
	removeLabel,
	onChangeRole,
	roleId,
	showRoleAI,
	persons,
	setAssignees,
	assigneeIds,
	unassignAssignee,
	showAssigneeAI,
	rowSelected,
	intl,
}) => {
	return (
		<Line>
			<TaskItemTableRow first={first} rowSelected={rowSelected}>
				<StaticTableRowItem width={65} first={first}>
					<span>{'T' + task.companyTaskId}</span>
				</StaticTableRowItem>
				<GrowableTableRowItem first={first}>
					<span title={task.name}>{task.name}</span>
				</GrowableTableRowItem>
				{estimateVisible && (
					<StaticTableRowItem width={110}>
						<HoursInput
							noMaxWidth
							showAI={showEstimateAI}
							customClassName={'hours-input'}
							value={estimate}
							mutation={value => onChangeEstimate(value, task.id)}
							cy={'estimate-input'}
						/>
					</StaticTableRowItem>
				)}
				{assigneesVisible && (
					<StaticTableRowItem width={220}>
						<AssignedDropdown
							task={task}
							assignablePersons={persons.map(person => ({node: {person}}))}
							assignedPersons={assigneeIds ? persons.filter(person => assigneeIds.includes(person.id)) : null}
							shouldShowAiIcon={showAssigneeAI}
							useNewAiIcon
							isMultiSelect
							unassignPerson={id => unassignAssignee(id, task.id)}
							assignPerson={ids => setAssignees(ids, task.id)}
							shortInput={true}
							showSuggestions={true}
							id={task.id}
							hideDelete
						/>
					</StaticTableRowItem>
				)}
				{roleVisible && (
					<StaticTableRowItem width={179}>
						<AssignedDropdown
							task={task}
							assignableRoles={roles.map(role => ({node: role}))} // assinged expect an array of objects containing node object
							assignedRole={roles.find(role => role.id === roleId)}
							shouldShowAiIcon={showRoleAI}
							useNewAiIcon
							assignRole={roleId => onChangeRole(roleId, task.id)}
							shortInput={true}
							showSuggestions={true}
							id={task.id}
							hideDelete
						/>
					</StaticTableRowItem>
				)}
				{labelsVisible && (
					<WrapStaticRowItem width={380}>
						{labelIds &&
							labelIds.map(labelId => {
								const label = labels.find(label => label.id === labelId);
								return label ? (
									<LabelContainer key={label.id}>
										<Label
											name={label.name}
											onRemove={() => removeLabel(label.id, task.id)}
											showAI={labelAIIds && labelAIIds.includes(label.id)}
											color={label.color}
										/>
									</LabelContainer>
								) : null;
							})}
					</WrapStaticRowItem>
				)}
				{labelsVisible && (
					<StaticTableRowItem width={190}>
						<DropdownContainer>
							<DropdownV2
								customWidth={166}
								stylingTheme={'white'}
								hideLabel={true}
								placeholder={intl.formatMessage({id: 'task_modal.find_label'})}
								onChange={label => addLabel([label.value], task.id)} //(this.handleLabelSelected.bind(this))} // TODO
								options={
									!labelIds
										? []
										: labels
												.map(label => ({value: label.id, label: label.name}))
												.filter(label => !labelIds.some(id => id === label.value))
								} // TODO
								customHeight={30}
								filterDropdown={false}
								stayOpenOnSelect={true}
								iconClass={'labels-icon'}
								inputCy={'project-automate-label-picker-input'}
								listDataCy="label"
								userpilot={'project-automate-labels-dropdown'}
							/>
						</DropdownContainer>
					</StaticTableRowItem>
				)}
			</TaskItemTableRow>
			<CornerChip />
		</Line>
	);
};

const taskListQuery = graphql`
	query taskList_Query(
		$fetchAssignee: Boolean!
		$fetchHighLow: Boolean!
		$fetchRoles: Boolean!
		$fetchLabels: Boolean!
		$taskIds: [ID]!
	) {
		viewer {
			actualPersonId
			component(name: "task_list")
			...taskList_viewer
				@arguments(
					fetchAssignee: $fetchAssignee
					fetchHighLow: $fetchHighLow
					fetchRoles: $fetchRoles
					fetchLabels: $fetchLabels
					taskIds: $taskIds
				)
		}
	}
`;

export {taskListQuery};
export default createFragmentContainer(taskList, {
	viewer: graphql`
		fragment taskList_viewer on Viewer
		@argumentDefinitions(
			fetchAssignee: {type: "Boolean!"}
			fetchHighLow: {type: "Boolean!"}
			fetchRoles: {type: "Boolean!"}
			fetchLabels: {type: "Boolean!"}
			taskIds: {type: "[ID]!"}
		) {
			availableFeatureFlags {
				key
			}
			tasks(taskIds: $taskIds, first: 10000) {
				edges {
					node {
						id
						highLowSuggestion @include(if: $fetchHighLow) {
							high
							low
							estimate
						}
						roleSuggestion @include(if: $fetchRoles)
						assigneeSuggestion @include(if: $fetchAssignee)
						labelSuggestion @include(if: $fetchLabels)
					}
				}
			}
		}
	`,
});
