import {useIntl} from 'react-intl';
import {Checkbox, Table} from 'web-components';
import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import * as tracking from '../../../tracking';
import {ActionMenuWrapper} from './table_row.styled';
import ColorsPicker from '../../../components/colors_picker';
import Util from '../../../forecast-app/shared/util/util';
import UpdateLabelMutation from '../../../mutations/update_label_mutation';
import {LabelEntityCountsStyled} from './label_entity_counts.styled.';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import DeleteLabelMutation from '../../../mutations/delete_label_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const LabelRow = ({company, tableColumnsProps, checked, toggleSelect, label, labelCategory, cy}) => {
	const {formatMessage} = useIntl();

	const openLabel = () => {
		tracking.trackEvent('labels - label selected');
		trackEvent('Label', 'Selected');
		showModal({
			type: MODAL_TYPE.UPDATE_LABEL,
			labelId: label.id,
		});
	};

	const getActionsMenuOptions = () => {
		return [
			{
				text: formatMessage({id: 'settings_labels.delete_label'}),
				cy: 'delete',
				onClick: () => {
					showModal({
						type: MODAL_TYPE.CONFIRM_LABEL_DELETION,
						title: formatMessage({id: 'settings_labels.delete_title'}, {name: label.name}),
						warningMessage: formatMessage({id: 'settings_labels.delete_warning'}),
						confirmText: formatMessage({id: 'settings.confirm'}),
						onDelete: () => {
							Util.CommitMutation(
								DeleteLabelMutation,
								{
									id: label.id,
									companyId: company.id,
								},
								() => {
									createToast({
										duration: 5000,
										message: formatMessage({id: 'settings_labels.delete_toast'}, {name: label.name}),
									});
								}
							);
						},
					});
				},
			},
		].filter(Boolean);
	};

	const handleColorChange = value => {
		if (label.color !== value) {
			Util.CommitMutation(
				UpdateLabelMutation,
				{
					id: label.id,
					color: value,
				},
				null,
				true
			);
		}
	};

	return (
		<Table.Row hoverStyle={{visibility: 'visible'}} cy={cy} onClick={openLabel} {...tableColumnsProps}>
			<Table.Column>
				<Table.Column.Cell>
					<Checkbox checked={checked} onClick={toggleSelect} cy={'checkbox'} />
					<span data-cy="label-name">{label.name}</span>
				</Table.Column.Cell>
			</Table.Column>
			<Table.Column>
				<Table.Column.Cell>
					<ColorsPicker value={label.color} onColorSelect={handleColorChange} size={20} usePortal />
				</Table.Column.Cell>
			</Table.Column>
			<Table.Column>
				<Table.Column.Cell>
					{labelCategory && !labelCategory.allowOnTasks ? (
						<ForecastTooltip
							placement={'bottom'}
							content={formatMessage(
								{id: 'settings_labels.not-allowed-entity'},
								{entity: formatMessage({id: 'settings_labels.not_allowed_tasks'})}
							)}
						>
							<LabelEntityCountsStyled disabled={!labelCategory.allowOnTasks}>
								<span>N/A</span>
							</LabelEntityCountsStyled>
						</ForecastTooltip>
					) : (
						<LabelEntityCountsStyled>
							<span>{label.taskCount || 0}</span>
						</LabelEntityCountsStyled>
					)}
				</Table.Column.Cell>
			</Table.Column>
			<Table.Column>
				<Table.Column.Cell>
					{labelCategory && !labelCategory.allowOnProjects ? (
						<ForecastTooltip
							placement={'bottom'}
							content={formatMessage(
								{id: 'settings_labels.not-allowed-entity'},
								{entity: formatMessage({id: 'settings_labels.not_allowed_projects'})}
							)}
						>
							<LabelEntityCountsStyled disabled={!labelCategory.allowOnProjects}>
								<span>N/A</span>
							</LabelEntityCountsStyled>
						</ForecastTooltip>
					) : (
						<LabelEntityCountsStyled>
							<span>{label.projectCount || 0}</span>
						</LabelEntityCountsStyled>
					)}
				</Table.Column.Cell>
			</Table.Column>
			<Table.Column>
				<Table.Column.Cell>
					{labelCategory && !labelCategory.allowOnPeople ? (
						<ForecastTooltip
							placement={'bottom'}
							content={formatMessage(
								{id: 'settings_labels.not-allowed-entity'},
								{entity: formatMessage({id: 'settings_labels.not_allowed_people'})}
							)}
						>
							<LabelEntityCountsStyled disabled={!labelCategory.allowOnPeople}>
								<span>N/A</span>
							</LabelEntityCountsStyled>
						</ForecastTooltip>
					) : (
						<LabelEntityCountsStyled>
							<span>{label.peopleCount || 0}</span>
						</LabelEntityCountsStyled>
					)}
				</Table.Column.Cell>
			</Table.Column>
			<Table.Column>
				<Table.Column.Cell>
					<ActionMenuWrapper
						cy={'label'}
						className={'row-hover'}
						whiteInner={true}
						options={getActionsMenuOptions()}
					/>
				</Table.Column.Cell>
			</Table.Column>
		</Table.Row>
	);
};

export default createFragmentContainer(LabelRow, {
	company: graphql`
		fragment labelRow_company on Company {
			id
			createdAt
		}
	`,
	label: graphql`
		fragment labelRow_label on Label {
			id
			name
			color
			taskCount
			projectCount
			peopleCount
		}
	`,
	labelCategory: graphql`
		fragment labelRow_labelCategory on LabelCategory {
			allowOnTasks
			allowOnProjects
			allowOnPeople
		}
	`,
});
