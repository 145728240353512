import React, {Component} from 'react';
import Styled from 'styled-components';
import InputField from './../../components/new-ui/input_field';
import GenericModal from './generic_modal';
import {injectIntl} from 'react-intl';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';
import RadioButton from '../../components/new-ui/radio_button';
import {Checkbox} from 'web-components';
import {CSS_CONSTANTS} from '../../css_variables';

const CheckboxContainer = Styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
`;

const CheckboxText = Styled.div`
	font-size: 12px;
	color: '#535353';
	margin-right: 4px;
`;

const ExtraText = Styled.div`
	font-size: 12px;
	font-style: italic;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	margin-right: 4px;
	margin-top: 16px;
`;

class SeatManagementModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			seatsToChange: '',
			collaboratorSeatsToChange: '',
			virtualSeatsToChange: '',
			errorMessage: '',
			errorMessageVirtual: '',
			reasonForSeatReduction: '',
			removeSeatsReasonAdded: false,
			reasonElaborated: '',
			termsAccepted: false,
		};
	}

	handleSeatsInputChange(val) {
		if (val === '' || !val || val < 0) {
			this.setState({seatsToChange: ''});
		} else this.setState({seatsToChange: Math.floor(val)});

		let msg = '';

		if (val && val > 99) {
			msg = this.props.intl.formatMessage({id: 'common.invalid_amount'});
			this.setState({seatsToChange: 99});
			return;
		}
		if (val && val < 0) return;

		if (!this.props.isAdd && val && this.props.numSeats - val < this.props.minNumSeats) {
			msg = this.props.intl.formatMessage({id: 'common.invalid_amount'});
		}
		this.setState({errorMessage: msg});
	}

	handleCollaboratorSeatsInputChange(val) {
		if (val === '' || !val || val < 0) {
			this.setState({collaboratorSeatsToChange: ''});
		} else this.setState({collaboratorSeatsToChange: Math.floor(val)});

		let msg = '';

		if (val && val > 99) {
			msg = this.props.intl.formatMessage({id: 'common.invalid_amount'});
			this.setState({collaboratorSeatsToChange: 99});
			return;
		}
		if (val && val < 0) return;

		this.setState({errorMessage: msg});
	}

	handleRemoveReason(radioInput) {
		this.setState({reasonForSeatReduction: radioInput, removeSeatsReasonAdded: true});
	}

	handleTermsAccepted() {
		this.setState({termsAccepted: !this.state.termsAccepted});
	}

	handleVirtualSeatsInputChange(val) {
		if (val === '' || !val || val < 0) {
			this.setState({virtualSeatsToChange: ''});
		} else this.setState({virtualSeatsToChange: Math.floor(val)});

		let msg = '';
		if (val && val > 99) {
			msg = this.props.intl.formatMessage({id: 'common.invalid_amount'});
			this.setState({virtualSeatsToChange: 99});
			return;
		}
		if (val && val < 0) return;
		if (!this.props.isAdd && val && this.props.numVirtualSeats - val < this.props.minNumVirtualSeats) {
			msg = this.props.intl.formatMessage({id: 'common.invalid_amount'});
		}
		this.setState({errorMessageVirtual: msg});
	}

	handleButtonClick(func) {
		func(
			this.state.seatsToChange,
			this.state.collaboratorSeatsToChange,
			this.state.virtualSeatsToChange,
			this.state.reasonForSeatReduction,
			this.state.reasonElaborated
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const buttons = this.props.buttons;

		let listNumberSeats = [{value: 0, label: '0'}];
		for (let i = 1; i <= this.props.numSeats - this.props.minNumSeats; i++) {
			listNumberSeats.push({value: i, label: '' + i});
		}

		let listNumberCollaboratorSeats = [{value: 0, label: '0'}];
		for (let i = 1; i <= this.props.numCollaboratorSeats; i++) {
			listNumberCollaboratorSeats.push({value: i, label: '' + i});
		}

		let listNumberVirtualSeats = [{value: 0, label: '0'}];
		for (let i = 1; i <= this.props.numVirtualSeats - this.props.minNumVirtualSeats; i++) {
			listNumberVirtualSeats.push({value: i, label: '' + i});
		}

		buttons.forEach(button => {
			if (button.mayBeLocked) {
				button.disabled =
					this.state.errorMessage !== '' ||
					this.state.errorMessageVirtual !== '' ||
					(this.props.isAdd
						? !this.state.termsAccepted
						: (!this.state.removeSeatsReasonAdded && !this.props.hideQuestions) ||
						  (this.state.seatsToChange === '' &&
								this.state.collaboratorSeatsToChange === '' &&
								this.state.virtualSeatsToChange === ''));
			}
			button.callback = this.handleButtonClick.bind(this, button.callback ? button.callback : this.props.closeModal);
		});

		const content = (
			<div className={'seat-management-container'} data-cy={'seat-management-modal'}>
				{this.props.isAdd ? (
					<div className={'seat-disclaimer'}>{formatMessage({id: 'seats_modal.add_text'})}</div>
				) : this.props.hideQuestions ? null : (
					<div className={'seat-disclaimer'}>{formatMessage({id: 'seats_modal.remove_text_request'})}</div>
				)}
				<table className={'seat-information'}>
					<thead>
						<tr>
							<th className={'table-header left'}>{formatMessage({id: 'settings_subscription.seat_type'})}</th>
							<th className={'table-header center'}>{formatMessage({id: 'seats_modal.current_seats'})}</th>
							<th className={'table-header center'}>{formatMessage({id: 'common.assigned'})}</th>
							<th className={'table-header left'}>
								{this.props.isAdd ? formatMessage({id: 'common.add'}) : formatMessage({id: 'common.remove'})}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr className={'table-row'}>
							<td className={'table-cell'}>
								{this.props.isCore
									? formatMessage({id: 'settings_people.seat.core'})
									: formatMessage({id: 'settings_people.seat.full'})}
							</td>
							<td className={'table-cell center'}>{this.props.numSeats}</td>
							<td className={'table-cell center'}>{this.props.curSeats}</td>
							<td className={'table-cell'}>
								{this.props.isAdd ? (
									<InputField
										id={'seats-input'}
										customClass={'height-30'}
										defaultValue={''}
										placeholder={'0'}
										type="number"
										hideLabel={true}
										value={this.state.seatsToChange}
										onChange={this.handleSeatsInputChange.bind(this)}
										invalidInput={this.state.errorMessage !== ''}
										errorMessage={this.state.errorMessage}
										maxNumber={99}
										minNumber={0}
										step={1}
										cy={'seats-input-field'}
									/>
								) : (
									<DropdownV2
										id={'seats-input'}
										stylingTheme={'white'}
										hideLabel={true}
										value={this.state.seatsToChange}
										placeholder={'0'}
										onChange={e => this.handleSeatsInputChange(e.value)}
										options={listNumberSeats}
										customHeight={30}
										customWidth={80}
										dataCy={'seats-dropdown'}
										listDataCy={'seats-value'}
										disabled={listNumberSeats.length < 2}
										inputTitle={
											listNumberSeats.length < 2
												? formatMessage({id: 'seats_modal.no_seats_available'})
												: formatMessage({id: 'seats_modal.seats_to_remove'})
										}
									/>
								)}
							</td>
						</tr>
						{this.props.isCore ? (
							<tr className={'table-row'}>
								<td className={'table-cell'}>{formatMessage({id: 'settings_people.seat.collaborator'})}</td>
								<td className={'table-cell center'}>{this.props.numCollaboratorSeats}</td>
								<td className={'table-cell center'}>{this.props.curCollaboratorSeats}</td>
								<td className={'table-cell'}>
									{this.props.isAdd ? (
										<InputField
											id={'seats-input'}
											customClass={'height-30'}
											defaultValue={''}
											placeholder={'0'}
											type="number"
											hideLabel={true}
											value={this.state.collaboratorSeatsToChange}
											onChange={this.handleCollaboratorSeatsInputChange.bind(this)}
											invalidInput={this.state.errorMessage !== ''}
											errorMessage={this.state.errorMessage}
											maxNumber={99}
											minNumber={0}
											step={1}
											cy={'seats-input-field'}
											locked={true}
										/>
									) : (
										<DropdownV2
											id={'seats-input'}
											stylingTheme={'white'}
											hideLabel={true}
											value={this.state.collaboratorSeatsToChange}
											placeholder={'0'}
											onChange={e => this.handleCollaboratorSeatsInputChange(e.value)}
											options={listNumberCollaboratorSeats}
											customHeight={30}
											customWidth={80}
											dataCy={'seats-dropdown'}
											listDataCy={'seats-value'}
											disabled={true}
											inputTitle={
												listNumberCollaboratorSeats.length < 2
													? formatMessage({id: 'seats_modal.no_seats_available'})
													: formatMessage({id: 'seats_modal.seats_to_remove'})
											}
										/>
									)}
								</td>
							</tr>
						) : null}
						<tr className={'table-row'}>
							<td className={'table-cell'}>{formatMessage({id: 'settings_people.seat.virtual'})}</td>
							<td className={'table-cell center'}>{this.props.numVirtualSeats}</td>
							<td className={'table-cell center'}>{this.props.curVirtualSeats}</td>
							<td className={'table-cell'}>
								{this.props.isAdd ? (
									<InputField
										id={'virtual-seats-input'}
										customClass={'height-30'}
										placeholder={'0'}
										defaultValue={''}
										type="number"
										value={this.state.virtualSeatsToChange}
										onChange={this.handleVirtualSeatsInputChange.bind(this)}
										invalidInput={this.state.errorMessageVirtual !== ''}
										errorMessage={this.state.errorMessageVirtual}
										maxNumber={99}
										minNumber={0}
										step={1}
										cy={'virtual-seats-input-field'}
									/>
								) : (
									<DropdownV2
										id={'virtual-seats-input'}
										stylingTheme={'white'}
										hideLabel={true}
										value={this.state.virtualSeatsToChange}
										placeholder={'0'}
										onChange={e => this.handleVirtualSeatsInputChange(e.value)}
										options={listNumberVirtualSeats}
										customHeight={30}
										customWidth={80}
										dataCy={'virtual-seats-dropdown'}
										listDataCy={'virtual-seats-value'}
										disabled={listNumberVirtualSeats.length < 2}
										inputTitle={
											listNumberVirtualSeats.length < 2
												? formatMessage({id: 'seats_modal.no_virtual_seats_available'})
												: formatMessage({id: 'seats_modal.virtual_seats_to_remove'})
										}
									/>
								)}
							</td>
						</tr>
					</tbody>
				</table>
				{!this.props.isAdd && !this.props.hideQuestions ? (
					<div className="seat-reduction-reason-container">
						<div className="header-text">{formatMessage({id: 'seats_modal.reasons_to_remove_seats_title'})}</div>

						<div>
							<RadioButton
								cy="staff-redution-option"
								onSelected={this.handleRemoveReason.bind(this, 'Staff Reduction')}
								text={formatMessage({id: 'seats_modal.reasons_staff_reduction'})}
								value={'Staff Reduction'}
								stateValue={this.state.reasonForSeatReduction}
							/>
							<RadioButton
								onSelected={this.handleRemoveReason.bind(this, 'Product Issues')}
								text={formatMessage({id: 'seats_modal.reasons_product_issues'})}
								value="Product Issues"
								stateValue={this.state.reasonForSeatReduction}
							/>
							<RadioButton
								onSelected={this.handleRemoveReason.bind(this, 'Missing Features')}
								text={formatMessage({id: 'seats_modal.reasons_missing_features'})}
								value={'Missing Features'}
								stateValue={this.state.reasonForSeatReduction}
							/>
							<RadioButton
								onSelected={this.handleRemoveReason.bind(this, 'Other')}
								text={formatMessage({id: 'common.select-other'})}
								value={'Other'}
								stateValue={this.state.reasonForSeatReduction}
							/>
							<textarea
								rows={5}
								value={this.state.reasonElaborated}
								placeholder={formatMessage({id: 'seats_modal.reasons_textarea_placeholder'})}
								onChange={e => {
									this.setState({reasonElaborated: e.target.value});
								}}
							/>
						</div>
					</div>
				) : null}

				{this.props.isAdd ? null : (
					<div className={'remove-text'}>
						{!this.state.errorMessage && !this.state.errorMessageVirtual
							? this.state.seatsToChange ||
							  this.state.collaboratorSeatsToChange ||
							  this.state.virtualSeatsToChange
								? formatMessage(
										{id: 'seats_modal.remove_seats_text_request'},
										{
											numSeats:
												this.state.seatsToChange +
												this.state.collaboratorSeatsToChange +
												this.state.virtualSeatsToChange,
											totalUsers: this.props.numSeats - this.state.seatsToChange,
											totalCollaboratorUsers:
												this.props.numCollaboratorSeats - this.state.collaboratorSeatsToChange,
											totalVirtualUsers: this.props.numVirtualSeats - this.state.virtualSeatsToChange,
										}
								  )
								: formatMessage(
										{id: 'seats_modal_remove_seats_text_no_virtual_request'},
										{
											numSeats: this.state.seatsToChange + this.state.virtualSeatsToChange,
											totalUsers: this.props.numSeats - this.state.seatsToChange,
										}
								  )
							: null}
					</div>
				)}
				{this.props.isAdd && (
					<div>
						<CheckboxContainer>
							<CheckboxText>
								I confirm I am adding additional seats to my plan and will be charged for these users
							</CheckboxText>
							<Checkbox
								checked={this.state.termsAccepted}
								onClick={this.handleTermsAccepted.bind(this)}
								cy={'terms-accepted-checkbox'}
							/>
						</CheckboxContainer>
						<ExtraText>
							<div>
								Any incremental subscription charges will be invoiced one-time from the date of addition pro
								rata to the end of your current subscription term, in line with the payment terms under the
								Terms of Service (https://www.forecast.app/terms-of-service)
							</div>
							<br />
							<div>
								User subscriptions for the AVA Team and Baseline product addons must match the core underlying
								User subscription licenses to the Forecast Service. Adding an additional underlying user will
								also necessarily add an additional Ava Team and/or Baseline user subscription where these addons
								have been previously purchased
							</div>
						</ExtraText>
					</div>
				)}
			</div>
		);
		return (
			<GenericModal
				className={'seat-management-modal'}
				closeModal={this.props.closeModal}
				content={content}
				headerText={this.props.title}
				cy={this.props.cy}
				buttons={buttons}
			/>
		);
	}
}
SeatManagementModal.propTypes = {};
export default injectIntl(SeatManagementModal);
