import {useIntl} from 'react-intl';
import {Section} from './Styles';
import {DeprecatedText as Text} from '@forecast-it/design-system';
import NumericInput, {TYPE} from './NumericInput';
import React from 'react';
import Util from '../../../forecast-app/shared/util/util';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import {showChangesSavedToast} from '../../../forecast-app/shared/components/toasts/toast';

const persistCompanyTarget = (newValue, company, formatMessage) => {
	if (newValue !== company.companyTarget) {
		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: company.id,
				companyTarget: newValue,
			},
			() => showChangesSavedToast(formatMessage)
		);
	}
};

export const BillableUtilisationTarget = ({company}) => {
	const {formatMessage} = useIntl();
	const {revenueEnabled, companyTarget} = company;

	return (
		<>
			<Section>
				<Text type={'heading'} variant={'m'}>
					{formatMessage({id: 'settings.setup.revenue_tracking.billable_utilisation_target.title'})}
				</Text>
				<Text>{formatMessage({id: 'settings.setup.revenue_tracking.billable_utilisation_target.description'})}</Text>
				<NumericInput
					type={TYPE.PERCENTAGE_DECIMAL}
					value={companyTarget}
					onNumberChanged={value => persistCompanyTarget(value, company, formatMessage)}
					disabled={!revenueEnabled}
				/>
			</Section>
		</>
	);
};
