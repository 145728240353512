import React, {useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useHistory, withRouter} from 'react-router-dom';
import {createFragmentContainer, graphql} from 'react-relay';
import Moment from 'moment';
import * as tracking from '../../../tracking';
import Util from '../../../forecast-app/shared/util/util';
import {getProjectHealthTopHeaderContent} from './ProjectHealthHeaders';
import {ProjectHealthContentSection, ProjectHealthPageWrapper} from './project_health_styles/ProjectHealthPage.styled';
import ProjectHealthStatusSection from './ProjectHealthStatusSection';
import {ProjectHealthTableSection} from './ProjectHealthTableSection';
import {getWarningVariantFromRAGStatus, INSIGHTS} from './ProjectHealthLogic';
import {BUDGET_TYPE, PROJECT_STATUS} from '../../../constants';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {showBudgetModal, showPredictedEndDateModal, showUpdateStatusModal} from './ProjectHealthUtil';
import {partition} from 'lodash';
import {WarningIcon} from 'web-components/icons';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {trackComplexEvent, trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {EVENT_ID, subscribe, unsubscribe} from '../../event_manager';
import ProjectHeader from '../../../forecast-app/project-tab/projects/shared/ProjectHeader';
import {projectUrl} from '../../../directApi';
import {getProjectIndicatorString} from '../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';

const projectEndIsAfterCurrentWeek = projectEndDate => {
	const today = Moment();
	return projectEndDate ? projectEndDate.isAfter(today, 'week') : null;
};

const getPredictedEndDateData = (
	project,
	predictedEndDateRAGStatus,
	predictedEndDateInfo,
	predictedEndDateError,
	isUsingProjectAllocation,
	performanceRolling,
	focus,
	projectEndDate
) => {
	const intl = useIntl();
	const predictedProjectEndBaseInfo = {id: INSIGHTS.PREDICTED_PROJECT_END, title: 'Predicted Project End'};
	if (project.status === PROJECT_STATUS.OPPORTUNITY) {
		return {
			...predictedProjectEndBaseInfo,
			info: 'Not available in Opportunity',
			disabled: true,
		};
	} else if (project.status === PROJECT_STATUS.DONE) {
		return {
			...predictedProjectEndBaseInfo,
			info: 'Project is Done',
			warningVariant: WarningIcon.VARIANT.NO_WARNING,
			disabled: false,
			actionCallback: () =>
				showModal({
					type: MODAL_TYPE.PREDICTED_END_DATE,
					project: project,
					endDateInfo: 'Project is Done',
				}),
		};
	}
	if (predictedEndDateError) {
		return {
			...predictedProjectEndBaseInfo,
			info: predictedEndDateError,
			disabled: true,
		};
	}

	const predictedEndDateVariant = getWarningVariantFromRAGStatus(predictedEndDateRAGStatus);

	const assistCallback =
		hasFeatureFlag('nova_allocation_assistance') &&
		predictedEndDateVariant !== WarningIcon.VARIANT.NO_WARNING &&
		isUsingProjectAllocation &&
		!project.useBaseline &&
		projectEndIsAfterCurrentWeek(projectEndDate)
			? e => {
					e.stopPropagation();
					e.preventDefault();
					showModal({
						type: MODAL_TYPE.ALLOCATION_ASSISTANCE,
						project: project,
						focus: focus,
						performance: performanceRolling,
						endDateInfo: predictedEndDateInfo,
						projectEndDate: projectEndDate,
					});
			  }
			: undefined;

	return {
		...predictedProjectEndBaseInfo,
		warningVariant: predictedEndDateVariant,
		info: predictedEndDateInfo,
		assistText: intl.formatMessage({id: 'common.assist'}),
		disabled: false,
		assistCallback: assistCallback,
		actionCallback: () =>
			showModal({
				type: MODAL_TYPE.PREDICTED_END_DATE,
				project: project,
				endDateInfo: predictedEndDateInfo,
			}),
	};
};

const getPerformanceData = (project, performance, performanceRAGStatus, performanceInfo, performanceError, history) => {
	const navigateWorkflow = () => {
		tracking.trackElementClicked('Set tasks to done', {
			projectId: project.id,
			projectName: project.name,
		});
		trackEvent('Set Tasks To Done', 'Clicked');
		history.push(`${projectUrl(project.companyProjectId, project.customProjectId)}/workflow`);
	};

	const performanceBaseInfo = {id: INSIGHTS.PERFORMANCE, title: 'Task Performance'};
	if (project.status === PROJECT_STATUS.OPPORTUNITY) {
		return {
			...performanceBaseInfo,
			info: 'Not available in Opportunity',
			disabled: true,
		};
	}
	if (performanceError) {
		return {
			...performanceBaseInfo,
			info: performanceError,
			actionText: 'Set tasks to done',
			disabled: true,
			actionCallback: () => navigateWorkflow(),
		};
	}

	const performanceVariant = getWarningVariantFromRAGStatus(performanceRAGStatus);
	return {
		...performanceBaseInfo,
		warningVariant: performanceVariant,
		info: performanceInfo,
		disabled: false,
		actionCallback: () =>
			showModal({
				type: MODAL_TYPE.PERFORMANCE,
				projectId: project.id,
				performance: performance,
				warningInfo: performanceInfo,
			}),
	};
};

const getFocusData = (project, focus, focusRAGStatus, focusInfo, focusError, history, intl) => {
	const navigateCompanyTimesheet = () => {
		tracking.trackElementClicked('Send Reminder', {
			projectId: project.id,
			projectName: project.name,
		});
		trackEvent('Send Reminder', 'Clicked');
		history.push('/resourcing/timesheet-entries');
	};

	const focusBaseInfo = {id: INSIGHTS.FOCUS, title: 'Team Focus'};
	if (project.status === PROJECT_STATUS.OPPORTUNITY) {
		return {
			...focusBaseInfo,
			info: 'Not available in Opportunity',
			disabled: true,
		};
	}
	if (focusError) {
		return {
			...focusBaseInfo,
			info: focusError,
			actionText: 'Send Reminder',
			disabled: true,
			actionCallback: () => navigateCompanyTimesheet(),
		};
	}

	const focusFormatted = intl.formatNumber(focus * 100) + '%';
	const focusVariant = getWarningVariantFromRAGStatus(focusRAGStatus);
	return {
		...focusBaseInfo,
		warningVariant: focusVariant,
		info: focusInfo,
		disabled: false,
		actionCallback: () =>
			showModal({
				type: MODAL_TYPE.TEAM_FOCUS,
				projectId: project.id,
				focus: focusFormatted,
				warningInfo: focusInfo,
				warningVariant: focusVariant,
			}),
	};
};

const getBudgetData = (project, forecastRevenue, budgetInsightRAGStatus, budgetInsightInfo, budgetInsightError, history) => {
	const navigateSettings = () => {
		history.push(`${projectUrl(project.companyProjectId, project.customProjectId)}/settings#financials+budget-tolerance`);
	};

	const budgetBaseInfo = {id: INSIGHTS.BUDGET, title: 'Budget'};

	if (project.status === PROJECT_STATUS.OPPORTUNITY) {
		return {
			...budgetBaseInfo,
			info: 'Not available in Opportunity',
			disabled: true,
		};
	}
	if (!project.rateCard) {
		return {
			...budgetBaseInfo,
			info: 'Not available without a ratecard',
			disabled: true,
		};
	}

	if (budgetInsightError) {
		return {
			...budgetBaseInfo,
			info: budgetInsightError,
			actionText: 'Update',
			disabled: true,
			actionCallback: () => navigateSettings(),
		};
	}
	const fixedPrice = project.budget;
	const budgetInsightVariant = getWarningVariantFromRAGStatus(budgetInsightRAGStatus);
	return {
		...budgetBaseInfo,
		warningVariant: budgetInsightVariant,
		info: budgetInsightInfo,
		disabled: false,
		actionCallback: () =>
			showModal({
				type: MODAL_TYPE.FORECAST_SURPLUS,
				projectId: project.id,
				fixedPrice: fixedPrice,
				forecastRevenue: forecastRevenue,
				forecastSurplusInfo: budgetInsightInfo,
				forecastSurplusVariant: budgetInsightVariant,
			}),
	};
};

export const getNovaInsightsData = (project, isUsingProjectAllocation, history, intl) => {
	const {projectHealth, projectEndYear, projectEndMonth, projectEndDay} = project;
	const projectEndDate =
		projectEndYear && projectEndMonth && projectEndDay
			? Util.CreateNonUtcMomentDate(projectEndYear, projectEndMonth, projectEndDay)
			: null;

	const data = [
		getPredictedEndDateData(
			project,
			projectHealth.predictedEndDateRAGStatus,
			projectHealth.predictedEndDateInfo,
			projectHealth.predictedEndDateError,
			isUsingProjectAllocation,
			projectHealth.performanceRolling,
			projectHealth.focus,
			projectEndDate
		),
		getPerformanceData(
			project,
			projectHealth.performanceRolling,
			projectHealth.performanceRAGStatus,
			projectHealth.performanceInfo,
			projectHealth.performanceError,
			history
		),
		getFocusData(
			project,
			projectHealth.focus,
			projectHealth.focusRAGStatus,
			projectHealth.focusInfo,
			projectHealth.focusError,
			history,
			intl
		),
	];

	if (
		(project.budgetType === BUDGET_TYPE.FIXED_PRICE || project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2) &&
		hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION)
	) {
		data.push(
			getBudgetData(
				project,
				projectHealth.forecastRevenue,
				projectHealth.budgetInsightRAGStatus,
				projectHealth.budgetInsightInfo,
				projectHealth.budgetInsightError,
				history,
				intl
			)
		);
	}

	return data;
};

const ProjectHealthPage = ({viewer, retry}) => {
	const intl = useIntl();
	const history = useHistory();
	const {project, company} = viewer;

	const insightsData = getNovaInsightsData(project, company.isUsingProjectAllocation, history, intl);

	const insightsPartition = partition(insightsData, insight => {
		return !insight.disabled;
	});
	const availableInsightsData = insightsPartition[0];
	const lockedInsightsData = insightsPartition[1];

	const activeInsightsInfoText = availableInsightsData.map(insight => insight.info);

	const projectOpportunity = project.status === PROJECT_STATUS.OPPORTUNITY;
	const projectHalted = project.status === PROJECT_STATUS.HALTED;
	const projectEditingDisabled = Util.projectEditingDisabled(project);

	const usingPredictedEndCompatibilityFix =
		availableInsightsData.some(insight => insight.title === 'Predicted Project End') &&
		!availableInsightsData.some(insight => insight.title === 'Task Performance' || insight.title === 'Team Focus');
	const availableInsights = availableInsightsData.map(insight =>
		insight.title === 'Predicted Project End' && usingPredictedEndCompatibilityFix
			? 'Predicted Project End - Compatibility Mode'
			: insight.title
	);

	const allocationAssistanceShown = availableInsightsData.some(insight => insight.assistCallback);
	if (allocationAssistanceShown) {
		availableInsights.push('Allocation Assistance');
	}

	useTrackPage('Nova Insights', {availableInsights: availableInsights}, {projectId: project.id, projectName: project.name});

	useEffect(() => {
		const name =
			viewer.project.name !== null && viewer.project.name !== ''
				? viewer.project.name
				: getProjectIndicatorString(viewer.project.companyProjectId, viewer.project.customProjectId);
		document.title = 'Nova Insights - ' + name + ' - Forecast';

		tracking.trackPage('nova-insights', null, null, {
			'available-insights': availableInsights,
			projectId: project.id,
			projectName: project.name,
		});

		if (history.location.hash) {
			const hash = history.location.hash.replace('#', '').toLowerCase();
			if (hash === 'update-status') {
				tracking.trackPageAction('Status Modal triggered by Status Digest', {
					projectId: project.id,
					projectName: project.name,
				});
				trackComplexEvent('Status Modal', 'Triggered', {triggerLocation: 'From Status Digest'});
				showUpdateStatusModal(
					projectEditingDisabled,
					project.id,
					project.projectHealth.suggestedStatusRag,
					activeInsightsInfoText,
					true
				);
			} else if (hash === 'budget-program') {
				if (project.projectHealth.budgetInsightRAGStatus) {
					const {projectHealth} = project;
					trackComplexEvent('Budget Modal', 'Triggered', {triggerLocation: 'From Program Overview'});
					showBudgetModal(
						project.id,
						project.budget,
						projectHealth.forecastRevenue,
						projectHealth.budgetInsightInfo,
						projectHealth.budgetInsightRAGStatus
					);
				}
			} else if (hash === 'suggested-status-program') {
				trackComplexEvent('Status Modal', 'Triggered', {triggerLocation: 'From Program Overview'});
				showUpdateStatusModal(
					projectEditingDisabled,
					project.id,
					project.projectHealth.suggestedStatusRag,
					activeInsightsInfoText
				);
			} else if (hash === 'predicted-end-program') {
				if (project.projectHealth.predictedEndDateRAGStatus) {
					trackComplexEvent('Predicted End Date Modal', 'Triggered', {triggerLocation: 'From Program Overview'});
					showPredictedEndDateModal(project);
				}
			}
		}
	}, []);

	const getFreshData = useCallback(result => {
		if (result.updateProject) {
			retry();
		}
	}, []);

	useEffect(() => {
		subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, getFreshData);
		return () => {
			unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, getFreshData);
		};
	}, [getFreshData]);

	return (
		<ProjectHealthPageWrapper data-cy="project-health-page">
			<ProjectHeader
				title="Nova Insights"
				titleContent={getProjectHealthTopHeaderContent()}
				project={viewer.project}
				psProject={viewer.psProject}
			/>
			<ProjectHealthContentSection>
				<ProjectHealthTableSection
					projectId={project.id}
					availableInsightsData={availableInsightsData}
					lockedInsightsData={lockedInsightsData}
					projectHalted={projectHalted}
					projectOpportunity={projectOpportunity}
				/>
				<ProjectHealthStatusSection project={viewer.project} activeInsightsInfoText={activeInsightsInfoText} />
			</ProjectHealthContentSection>
		</ProjectHealthPageWrapper>
	);
};

const ProjectHealthPageQuery = graphql`
	query ProjectHealthPage_Query($projectId: String) {
		viewer {
			actualPersonId
			component(name: "project_health")
			company {
				tier
				forecastDemo
			}
			project(id: $projectId) {
				id
				projectGroupId
				companyProjectId
				customProjectId
				estimationUnit
				budgetType
			}
			...ProjectHealthPage_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {ProjectHealthPageQuery};

export default withRouter(
	createFragmentContainer(ProjectHealthPage, {
		viewer: graphql`
			fragment ProjectHealthPage_viewer on Viewer @argumentDefinitions(projectId: {type: "String"}) {
				id
				email
				company {
					isUsingProjectAllocation
				}
				project(id: $projectId) {
					id
					name
					status
					companyProjectId
					customProjectId
					projectStartYear
					projectStartMonth
					projectStartDay
					projectEndYear
					projectEndMonth
					projectEndDay
					budget
					budgetType
					useBaseline
					revenueMarginLow
					revenueMarginHigh
					rateCard {
						currency
					}
					projectHealth {
						performanceRolling
						optimisticPerformanceRolling
						conservativePerformanceRolling
						performanceRAGStatus
						performanceInfo
						performanceError
						focus
						focusRAGStatus
						focusInfo
						focusError
						predictedEndDate
						predictedEndDateRAGStatus
						predictedEndDateInfo
						predictedEndDateError
						budgetInsightInfo
						budgetInsightRAGStatus
						budgetInsightError
						optimisticPredictedEndDate
						forecastRevenue
						suggestedStatusRag
					}
					...ProjectHealthStatusSection_project
					...PredictedEndDateModal_project
					...AllocationAssistanceModal_project
					...ProjectHeader_project
					...SecondaryNavigation_project
				}
				psProject(companyProjectId: $projectId) {
					...ProjectHeader_psProject
				}
			}
		`,
	})
);
