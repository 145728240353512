/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type syncJiraProjectModal_viewer$ref = any;
export type syncJiraProjectModal_QueryVariables = {|
  projectId?: ?string,
  edit: boolean,
|};
export type syncJiraProjectModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: syncJiraProjectModal_viewer$ref,
  |}
|};
export type syncJiraProjectModal_Query = {|
  variables: syncJiraProjectModal_QueryVariables,
  response: syncJiraProjectModal_QueryResponse,
|};
*/


/*
query syncJiraProjectModal_Query(
  $projectId: ID
  $edit: Boolean!
) {
  viewer {
    actualPersonId
    component(name: "sync_jira_project_modal")
    ...syncJiraProjectModal_viewer_4r0gFt
    id
  }
}

fragment syncJiraProjectModal_viewer_4r0gFt on Viewer {
  id
  availableFeatureFlags {
    key
    id
  }
  company {
    id
    tier
    currency
    modules {
      moduleType
      id
    }
    defaultRateCard {
      id
    }
    unSynchedJiraProjects {
      id
      name
      style
    }
    unSynchedJiraServerProjects {
      id
      name
    }
    clients(first: 10000) {
      edges {
        node {
          id
          name
        }
      }
    }
    rateCards(first: 10000) {
      edges {
        node {
          id
          name
          currency
        }
      }
    }
    labels(first: 10000, labelType: PROJECT) {
      edges {
        node {
          id
          name
          color
          category {
            id
            name
          }
        }
      }
    }
  }
  project(internalId: $projectId) @include(if: $edit) {
    id
    name
    client {
      id
      name
    }
    projectStartYear
    projectStartMonth
    projectStartDay
    projectEndYear
    projectEndMonth
    projectEndDay
    projectColor
    jiraCloudProject {
      id
      name
    }
    jiraCloudProjectSettings {
      syncSprints
      syncRemaining
      jiraBoardId
      estimateInTime
      mapJiraSubTasksToToDo
      mapJiraEpicTo
      mapJiraEpicToEpic
      mapJiraEpicToPhase
      mapJiraEpicToTask
      mapJiraVersionsToPhases
      cutoffDate
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "edit",
    "type": "Boolean!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "sync_jira_project_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"sync_jira_project_modal\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
},
v8 = [
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "syncJiraProjectModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "edit",
                "variableName": "edit"
              },
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "syncJiraProjectModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "syncJiraProjectModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tier",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moduleType",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RateCard",
                "kind": "LinkedField",
                "name": "defaultRateCard",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JiraCloudProjectType",
                "kind": "LinkedField",
                "name": "unSynchedJiraProjects",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "style",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JiraServerProjectType",
                "kind": "LinkedField",
                "name": "unSynchedJiraServerProjects",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "ClientTypeConnection",
                "kind": "LinkedField",
                "name": "clients",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Client",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "clients(first:10000)"
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "RateCardTypeConnection",
                "kind": "LinkedField",
                "name": "rateCards",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RateCardTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RateCard",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "rateCards(first:10000)"
              },
              {
                "alias": null,
                "args": [
                  (v7/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "labelType",
                    "value": "PROJECT"
                  }
                ],
                "concreteType": "LabelTypeConnection",
                "kind": "LinkedField",
                "name": "labels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LabelTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Label",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LabelCategory",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "labels(first:10000,labelType:\"PROJECT\")"
              }
            ],
            "storageKey": null
          },
          {
            "condition": "edit",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "internalId",
                    "variableName": "projectId"
                  }
                ],
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Client",
                    "kind": "LinkedField",
                    "name": "client",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectStartYear",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectStartMonth",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectStartDay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectEndYear",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectEndMonth",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectEndDay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JiraCloudProjectType",
                    "kind": "LinkedField",
                    "name": "jiraCloudProject",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JiraCloudProjectSettingsType",
                    "kind": "LinkedField",
                    "name": "jiraCloudProjectSettings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "syncSprints",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "syncRemaining",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "jiraBoardId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "estimateInTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mapJiraSubTasksToToDo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mapJiraEpicTo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mapJiraEpicToEpic",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mapJiraEpicToPhase",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mapJiraEpicToTask",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mapJiraVersionsToPhases",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cutoffDate",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "syncJiraProjectModal_Query",
    "operationKind": "query",
    "text": "query syncJiraProjectModal_Query(\n  $projectId: ID\n  $edit: Boolean!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"sync_jira_project_modal\")\n    ...syncJiraProjectModal_viewer_4r0gFt\n    id\n  }\n}\n\nfragment syncJiraProjectModal_viewer_4r0gFt on Viewer {\n  id\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    id\n    tier\n    currency\n    modules {\n      moduleType\n      id\n    }\n    defaultRateCard {\n      id\n    }\n    unSynchedJiraProjects {\n      id\n      name\n      style\n    }\n    unSynchedJiraServerProjects {\n      id\n      name\n    }\n    clients(first: 10000) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    rateCards(first: 10000) {\n      edges {\n        node {\n          id\n          name\n          currency\n        }\n      }\n    }\n    labels(first: 10000, labelType: PROJECT) {\n      edges {\n        node {\n          id\n          name\n          color\n          category {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n  project(internalId: $projectId) @include(if: $edit) {\n    id\n    name\n    client {\n      id\n      name\n    }\n    projectStartYear\n    projectStartMonth\n    projectStartDay\n    projectEndYear\n    projectEndMonth\n    projectEndDay\n    projectColor\n    jiraCloudProject {\n      id\n      name\n    }\n    jiraCloudProjectSettings {\n      syncSprints\n      syncRemaining\n      jiraBoardId\n      estimateInTime\n      mapJiraSubTasksToToDo\n      mapJiraEpicTo\n      mapJiraEpicToEpic\n      mapJiraEpicToPhase\n      mapJiraEpicToTask\n      mapJiraVersionsToPhases\n      cutoffDate\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '684a990d48cac8491fc5476a57faf5ea';

module.exports = node;
