import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GenericModal from './generic_modal';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import AddTeamMemberModalContent from './add_team_member_modal_content';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {TIERS} from '../../constants';

class addTeamMemberModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			selectedRole: null,
			selectedPermission: null,
			showEmailValidationError: false,
			emailValidationErrorMessage: '',
			showNameValidationError: false,
			nameValidationErrorMessage: '',
			canSendRequest: true,
			invitedPersons: [],
		};
	}

	handleInputChange(property, value) {
		this.setState({[property]: value});
	}

	addToInvited(person) {
		this.state.invitedPersons.push(person);
		this.setState({invitedPersons: this.state.invitedPersons});
		// Person invided
	}

	done() {
		const selectedTeamMembersIds = this.state.invitedPersons.map(p => p.id);

		this.props.closeModal();

		if (this.props.projectGroupId && this.props.useManualAllocations) {
			// Do nothing yet
		} else if (this.props.projectId && this.props.useManualAllocations) {
			showModal(
				{
					type: MODAL_TYPE.ALLOCATE_TEAM_MEMBERSV2,
					projectId: this.props.projectId,
					selectedTeamMembersIds,
				},
				true
			);
		}
	}

	render() {
		const content = (
			<div>
				<AddTeamMemberModalContent
					projectId={this.props.projectId}
					projectGroupId={this.props.projectGroupId}
					buttonLocation={this.props.buttonLocation}
					viewer={this.props.viewer}
					personInvited={this.addToInvited.bind(this)}
					closeModal={this.props.closeModal}
				/>
			</div>
		);
		return (
			<GenericModal
				cy={'add-team-member'}
				closeModal={this.props.closeModal}
				buttons={[]}
				content={content}
				headerText={'Create Team Member'}
				modalWidth={this.props.viewer.company.tier === TIERS.CORE ? '1376px' : '1168px'}
			/>
		);
	}
}

addTeamMemberModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	buttonLocation: PropTypes.string.isRequired,
	projectId: PropTypes.string,
	projectGroupId: PropTypes.string,
	useManualAllocations: PropTypes.bool,
};

const addTeamMemberModalQuery = graphql`
	query addTeamMemberModal_Query {
		viewer {
			actualPersonId
			component(name: "add_team_member_modal_v2")
			...addTeamMemberModal_viewer
		}
	}
`;

export {addTeamMemberModalQuery};

export default injectIntl(
	createFragmentContainer(addTeamMemberModal, {
		viewer: graphql`
			fragment addTeamMemberModal_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				company {
					id
					userSeats
					virtualSeats
					collaboratorSeats
					isChargebeeCustomer
					oktaEnabled
					oneloginEnabled
					AADEnabled
					tier
					isCoreTier
					profiles(first: 10000) @connection(key: "Company_profiles", filters: []) {
						edges {
							node {
								id
								name
								canBeEdited
								personsCount
								permissions
								disabled
								seat
							}
						}
					}
					allPersons(first: 10000, onlyActive: true) @connection(key: "Company_modal_allPersons", filters: []) {
						edges {
							node {
								id
								firstName
								lastName
								email
								seat
								role {
									id
									name
								}
								active
								permissions
								client {
									id
								}
							}
						}
					}
					roles(first: 10000) {
						edges {
							node {
								id
								name
							}
						}
					}
				}
			}
		`,
	})
);
