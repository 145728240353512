import React from 'react';
import Moment from 'moment';
import {Redirect, Route, Switch} from 'react-router-dom';
import AppV2, {appv2Query} from './appv2';
import AccessBlocked, {accessBlockedQuery} from './access_blocked';
import CanvasScheduling, {CanvasSchedulingQuery} from '../components/canvas-scheduling/canvas_scheduling';
import CompanyStats, {companyStatsQuery} from './internal/company_stats';
import MLStats, {mlStatsQuery} from './internal/ml_stats';
import ControlPersonList, {controlPersonListQuery} from './internal/control_person_list';
import FeatureFlags, {featureFlagsQuery} from './internal/feature_flags';
import SisenseAdminPage from './internal/SisenseAdminPage';
import RestoreData, {RestoreDataQuery} from './internal/type-script-files/RestoreData';
import SageSignupControlPage, {sageSignupQuery} from './internal/type-script-files/SageSignupControlPage';
import CompanySavedReportWrapper, {
	CompanySavedReportWrapperQuery,
} from '../forecast-app/reports-tab/saved-report/CompanySavedReportWrapper';
import FinancialReportPage, {
	financialReportPageQuery,
} from '../components/insights/financial-portfolio-report/financial_report_page';
import SignupFlow, {signupFlowQuery} from './signup_flow';
import SageSignupFlow, {SageSignupFlowQuery} from './sage-signup-flow/SageSignupFlow';
import Impersonate, {impersonateQuery} from './internal/impersonate';
import InsightsBuilderV2, {insightsBuilderV2Query} from './insights/insights_builder_v2';
import InsightsSection, {insightsSectionQuery} from './insights/insights_section';
import InsightsViewer, {insightsViewerQuery} from './insights/insights_viewer';
import Login, {loginQuery} from './login';
import LoginAzureActiveDirectory, {loginAzureActiveDirectoryQuery} from './login_azure_active_directory';
import LoginOauth, {loginOauthQuery} from './login_oauth';
import LoginOkta, {loginOktaQuery} from './login_okta';
import LoginOnelogin, {loginOneloginQuery} from './login_onelogin';
import LoginSalesforce, {loginSalesforceQuery} from './login_salesforce';
import Maintenance, {maintenanceQuery} from './maintenance';
import MyExpenses, {myExpensesQuery} from './my_profile/my_expenses';
import MyWorkPageV2, {MyWorkPageV2Query} from '../forecast-app/my-work-tab/my-tasks-page/MyWorkPageV2';
import MyTaskActivities, {myTaskActivitiesQuery} from './my_profile/my_task_activities';
import NewInsightContainer, {newInsightQuery} from './insights/new_insight/new_insight';
import NoAccess from './no_access';
import NotFound from './not_found';
import BugDuty, {bugDutyQuery} from './internal/bug_duty';
import OpenTaskModal from './modal/open_task_modal';
import PublicCreateTaskPage from './PublicCreateTaskPage';
import ResetPassword, {resetPasswordQuery} from './reset_password';
import SettingsAllNotifications, {profileAllNotificationsQuery} from './my_profile/profile_all_notifications';
import SettingsProfileViewer, {settingsProfileViewerQuery} from './settings/settings_profile_viewer';
import SettingsSecurity, {profileSecurityQuery} from './my_profile/profile_security';
import SharedInsight, {sharedInsightQuery} from './shared_insight';
import Signup, {signupQuery} from './signup';
import TimesheetsPage, {timesheetsPageQuery} from '../forecast-app/my-work-tab/my-timesheets-page/timesheets_page';
import TrialEnd, {trialEndQuery} from './trial_end';
import XeroToken from './xero_token';
import ForecastQueryRenderer from '../ForecastQueryRenderer';
import BuyNowFooter from '../components/buy_now_footer';
import {VersionReloadRoute} from './version_reload_route';
import CompaniesWithModules, {companiesWithModulesQuery} from './internal/companies_with_modules/companies_with_modules';
import TrialsList, {trialsListQuery} from './internal/trials_list/trials_list';
import Util from '../forecast-app/shared/util/util';
import {MODULE_TYPES, TIERS} from '../constants';
import Unit4LogPage, {unit4LogQuery} from '../components/unit4log/Unit4LogPage';
import AdvancedAnalyticsPage, {AdvancedAnalyticsQuery} from '../components/advanced-analytics/AdvancedAnalyticsPage';
import CompaniesToBeDeleted, {companiesToBeDeletedQuery} from './internal/companies_to_be_deleted';
import XeroSignupContainer, {XeroSignupContainerQuery} from './XeroSignupContainer';
import {CanvasProjectSchedulingShare} from '../components/canvas-scheduling/projects-scheduling/share/CanvasProjectSchedulingShare';
import SharedReportPage, {SharedReportQuery} from '../forecast-app/reports-tab/shared-report/SharedReportPage';
import {getModuleAuthFunc, hasModule} from '../forecast-app/shared/util/ModuleUtil';
import {LoginWrapper} from './login_wrapper';
import ForgotPassword from './forgot_password';
import {EXTERNAL_SHARE_TYPE, INTERNAL_SHARE_TYPE} from '../forecast-app/shared/util/ReportServiceUtil';
import ProfileNotificationsPage, {ProfileNotificationsPageQuery} from './my_profile/ProfileNotificationsPage';
import ProgramListPage, {ProgramListPageQuery} from '../forecast-app/project-tab/programs/programs-list-page/ProgramListPage';
import ProgramOverviewPage from '../forecast-app/project-tab/programs/program-overview-page/ProgramOverview';
import TrialGuideV2, {TrialGuideV2Query} from './TrialGuideV2';
import DirectApi from '../directApi';
import ProgramSettingsPage from '../forecast-app/project-tab/programs/program-settings-page/ProgramSettingsPage';
import ProgramBudgetOverviewPage from '../forecast-app/project-tab/programs/program-budget-overview-page/ProgramBudgetOverview';
import ProjectListPage, {ProjectListPageQuery} from '../forecast-app/project-tab/projects/project-list-page/ProjectListPage';
import {hasFeatureFlag} from '../forecast-app/shared/util/FeatureUtil';
import Program from '../forecast-app/project-tab/programs/Program';
import ProgramBudgetEdit from '../forecast-app/project-tab/programs/program-budget-overview-page/ProgramBudgetEdit';
import PrimaryNavigation from '../forecast-app/navigation/primary/PrimaryNavigation';
import TrackingComponent, {TrackingComponentQuery} from '../forecast-app/shared/components/TrackingComponent';
import ProjectRoutes, {ProjectRoutesQuery} from '../forecast-app/project-tab/projects/ProjectRoutes';
import ProfileProjectStatusUpdatesPage, {
	ProfileProjectStatusUpdatesPageQuery,
} from './my_profile/ProfileProjectStatusUpdatesPage';
import ChatIcon from '../forecast-app/shared/icon/ChatIcon';
import {hasPermission} from '../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../Permissions';
import BambooDuplicates, {bambooDuplicatesQuery} from './internal/bamboo-duplicates';
import {ExpenseManagementPageQuery} from '../components/expense-management/ExpenseManagementPageQuery';
import ExpenseManagementPage, {getDefaultDateRange} from '../components/expense-management/ExpenseManagementPage';
import {OverviewProjectsQueryWrapper} from './overview/OverviewProjectsQueryWrapper';
import TimeApprovalPage, {TimeApprovalPageQuery} from '../components/timesheets/time-approval/TimeApprovalPage';
import TeamTimePage, {timesheetsTeamQuery} from '../components/timesheets/team-timesheet/timesheets_team';
import FinanceRoutes from '../forecast-app/finance-tab/FinanceRoutes';
import {isSageSpecificUrl} from '../forecast-app/shared/util/UrlUtil';
import ActivateFeature from './ActivateFeature';
import AdminRoutes from '../forecast-app/admin-tab/AdminRoutes';
import ReportsPage from '../forecast-app/reports-tab/reports-page/ReportsPage';
import {ReportsPageQuery} from '../forecast-app/reports-tab/reports-page/ReportsPageQuery';

export const programPrefix = end => `/:program(program|project-group)/:prefix([a-zA-z0-9]+)/${end}`;

export const projectPrefixMatcher = '/project/:projectId([^/]*)';
export const projectPrefixMatcherCompanyProjectIdOnly = '/project/P-:projectId(\\d+)';

const oldProjectPrefix = end => `${projectPrefixMatcherCompanyProjectIdOnly}/${end}`;
const projectPrefix = end => (hasFeatureFlag('custom_project_ids') ? `${projectPrefixMatcher}/${end}` : oldProjectPrefix(end));

const getVersionReloadRoute = (id, end) =>
	hasFeatureFlag('custom_project_ids') ? `/project/${id}/${end}` : `/project/P-${id}/${end}`;

const projectGroupPrefix = end => `/connected/X-:groupId(\\d+)/${end}`;

const sharedPrefix = end => `/shared/:shareUrl/${end}`;

const returnTo = url => () => {
	window.location.href = url;
};

const routerv2 = props => {
	const taskModalRegex = new RegExp('^T\\d+$');
	const appContent = (actualPersonId, availableFeatureFlags, modules, buyNowTime, tier) => {
		return (
			<div id="app-nav-switch-handle" className="app">
				{/* Header route */}
				<ForecastQueryRenderer
					key="query-render-tracking-component"
					query={TrackingComponentQuery}
					showLoader={false}
					render={relayProps => <TrackingComponent setLocale={props.setLocale} {...relayProps} />}
				/>
				<PrimaryNavigation actualPersonId={actualPersonId} />

				{/* Switch for all content to only show one page */}
				<Switch>
					{/* region Blocking Pages Section */}
					<Route path="/not-found" component={NotFound} />
					<Route path="/trial-end">
						<ForecastQueryRenderer
							key="query-render-trialEndQuery"
							query={trialEndQuery}
							render={relayProps => <TrialEnd {...relayProps} />}
						/>
					</Route>
					<Route path="/access-blocked">
						<ForecastQueryRenderer
							key="query-render-accessBlockedQuery"
							query={accessBlockedQuery}
							render={relayProps => <AccessBlocked {...relayProps} />}
						/>
					</Route>
					<Route path="/not-authorized">
						<NoAccess />
					</Route>
					{/* endregion */}
					{/* region My List Section */}
					<Route path={['/', '/T:taskId(\\d+)']} exact>
						<ForecastQueryRenderer
							key="query-render-my-work"
							query={MyWorkPageV2Query}
							variables={{
								personId: actualPersonId,
							}}
							render={(relayProps, retry) => <MyWorkPageV2 retry={retry} {...relayProps} />}
						/>
					</Route>
					<VersionReloadRoute path="/expenses">
						<ForecastQueryRenderer
							key="query-render-myExpenses"
							query={myExpensesQuery}
							authorizeModule={getModuleAuthFunc(MODULE_TYPES.FINANCE)}
							render={(relayProps, retry) => {
								return <MyExpenses setLocale={props.setLocale} {...relayProps} retry={retry} />;
							}}
						/>
					</VersionReloadRoute>
					<Redirect from="/expense-management" to="/finance/expenses" />
					<VersionReloadRoute path="/finance/expenses">
						<ForecastQueryRenderer
							key="query-render-expense-management"
							query={ExpenseManagementPageQuery}
							variables={getDefaultDateRange()}
							authorizeAccessRoute={'expense-management'}
							render={(relayProps, retry) => {
								return <ExpenseManagementPage {...relayProps} retry={retry} buyNowTime={buyNowTime} />;
							}}
						/>
					</VersionReloadRoute>
					{/* region Program Section */}
					<VersionReloadRoute path="/:programs(programs|project-groups)">
						<ForecastQueryRenderer
							key="query-render-programListPage"
							query={ProgramListPageQuery}
							authorizeAccessRoute={'program'}
							render={(relayProps, retry) => (
								<ProgramListPage {...relayProps} retry={retry} buyNowTime={buyNowTime} />
							)}
						/>
					</VersionReloadRoute>
					<VersionReloadRoute
						path={programPrefix('overview')}
						children={({match}) => {
							return (
								<Program match={match} activeTab={'overview'}>
									<ProgramOverviewPage />
								</Program>
							);
						}}
					/>
					<VersionReloadRoute path={programPrefix('settings')}>
						{({match}) => {
							return (
								<Program match={match} activeTab={'settings'}>
									<ProgramSettingsPage match={match} />
								</Program>
							);
						}}
					</VersionReloadRoute>
					<VersionReloadRoute path={[programPrefix('budget/edit'), programPrefix('financials/edit')]}>
						{({match}) => {
							return (
								<Program match={match} activeTab={'budget/edit'}>
									<ProgramBudgetEdit />
								</Program>
							);
						}}
					</VersionReloadRoute>
					<VersionReloadRoute path={[programPrefix('budget'), programPrefix('financials')]}>
						{({match}) => {
							return (
								<Program match={match} activeTab={'budget'}>
									<ProgramBudgetOverviewPage />
								</Program>
							);
						}}
					</VersionReloadRoute>
					<VersionReloadRoute path={[programPrefix('schedule'), programPrefix('timeline')]}>
						{({match}) => {
							return (
								<Program match={match} activeTab={'schedule'}>
									<ForecastQueryRenderer
										key={'query-render-program-timeline' + match.params.prefix}
										query={CanvasSchedulingQuery}
										render={(relayProps, retry) => {
											return (
												<CanvasScheduling
													key={'programPrefix' + match.params.prefix}
													isProjectTimeline={true}
													retry={retry}
													{...relayProps}
												/>
											);
										}}
									/>
								</Program>
							);
						}}
					</VersionReloadRoute>
					{/* endregion */}
					{/* region Projects Section */}
					<VersionReloadRoute path="/projects">
						{hasFeatureFlag('project_list_v2') ? (
							<ForecastQueryRenderer
								key="query-render-projectListPage"
								query={ProjectListPageQuery}
								render={(relayProps, retry) => <ProjectListPage retry={retry} {...relayProps} />}
							/>
						) : (
							<OverviewProjectsQueryWrapper />
						)}
					</VersionReloadRoute>
					{/* endregion */}
					{/* region Time Section */}
					<Route path="/time">
						<Redirect to="/timesheets" />
					</Route>
					<VersionReloadRoute path={'/timesheets'}>
						<ForecastQueryRenderer
							key="query-render-timesheetsPage"
							query={timesheetsPageQuery}
							// This is basically initialVariables
							variables={{
								personId: actualPersonId,
								googleTaskId: null,
								pageSize: 15,
								startDateString: Moment().clone().startOf('month').startOf('week').format('YYYY-MM-DD'),
								endDateString: Moment().clone().endOf('month').endOf('week').format('YYYY-MM-DD'),
								fetchCustomFields: Util.hasCustomFields(),
							}}
							render={(relayProps, retry) => {
								return <TimesheetsPage retry={retry} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					<Redirect from="/time-management/:subpage" to="/resourcing/:subpage" />
					<Redirect from="/resourcing/time-approval" to="/resourcing/timesheet-approval" />
					<Redirect from="/resourcing/company-timesheet" to="/resourcing/timesheet-entries" />
					<VersionReloadRoute
						exact
						path={['/resourcing/timesheet-approval', '/resourcing/timesheet-approval/T:taskId(\\d+)']}
					>
						<ForecastQueryRenderer
							key="query-render-timeApproval"
							query={TimeApprovalPageQuery}
							authorizeAccessRoute={'time-approval'}
							authorizeModule={getModuleAuthFunc(MODULE_TYPES.TIME_LOCKING)}
							render={(relayProps, retry) => {
								return <TimeApprovalPage retry={retry} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					<VersionReloadRoute
						exact
						path={['/resourcing/timesheet-entries', '/resourcing/timesheet-entries/T:taskId(\\d+)']}
					>
						<ForecastQueryRenderer
							key="query-render-timesheetsTeam"
							query={timesheetsTeamQuery}
							authorizeAccessRoute={'team-time'}
							render={(relayProps, retry) => {
								return <TeamTimePage retry={retry} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					{/* endregion */}
					{/* region Scheduling Section */}
					<VersionReloadRoute path="/scheduling">
						<ForecastQueryRenderer
							key="query-render-scheduling"
							query={CanvasSchedulingQuery}
							render={(relayProps, retry) => {
								return <CanvasScheduling isProjectTimeline={false} retry={retry} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					{/* endregion */}
					{/* region Insights Section */}
					<VersionReloadRoute path={[['/insights', '/reports']]} exact>
						{hasFeatureFlag('reports_redesign') ? (
							<ForecastQueryRenderer
								key="query-render-reportsPage"
								query={ReportsPageQuery}
								render={relayProps => {
									return <ReportsPage {...relayProps} />;
								}}
							/>
						) : (
							<ForecastQueryRenderer
								key="query-render-insightsSection"
								query={insightsSectionQuery}
								authorizeAccessRoute={'reports'}
								render={relayProps => {
									return <InsightsSection {...relayProps} />;
								}}
							/>
						)}
					</VersionReloadRoute>
					<Route path={['/insights/new', '/reports/new']} component={NewInsightContainer}>
						<ForecastQueryRenderer
							key="query-render-newInsight"
							query={newInsightQuery}
							authorizeAccessRoute={'new-report'}
							render={relayProps => {
								return <NewInsightContainer {...relayProps} />;
							}}
						/>
					</Route>
					<Redirect from="/insights/financial-portfolio-report" to="/reports/new/step1" />
					<Redirect from="/reports/financial-portfolio-report" to="/reports/new/step1" />
					<VersionReloadRoute path="/insights/financial-portfolio-report">
						<ForecastQueryRenderer
							key="query-render-financialReportPage"
							query={financialReportPageQuery}
							render={relayProps => {
								return <FinancialReportPage {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					<Redirect from="/insights/new-utilisation-report" to="/reports/utilization-report" />
					<Redirect from="/insights/utilization-report" to="/reports" />
					<Route
						path={['/insights/:insightId/edit', '/reports/:insightId/edit']}
						children={({match}) => {
							// Checking if valid id
							try {
								if (!atob(match.params.insightId).startsWith('Insight:')) return <Redirect to="/not-found" />;
							} catch (e) {
								return <Redirect to="/not-found" />;
							}

							return (
								<ForecastQueryRenderer
									key="query-render-insightsBuilderV2"
									query={insightsBuilderV2Query}
									variables={match.params}
									render={relayProps => {
										if (!relayProps.viewer.company.insight) return <Redirect to="/not-found" />;

										return <InsightsBuilderV2 {...relayProps} />;
									}}
								/>
							);
						}}
					/>
					<VersionReloadRoute
						path={['/insights/:insightId', '/reports/:insightId']}
						children={({match}) => {
							// Router matches to this route even if the path is for the task modal and not an insight. As such, need to check if param is a task modal path before rendering.
							if (match.params && match.params.insightId && !taskModalRegex.test(match.params.insightId)) {
								// Checking if valid id
								try {
									if (!atob(match.params.insightId).startsWith('Insight:'))
										return <Redirect to="/not-found" />;
								} catch (e) {
									return <Redirect to="/not-found" />;
								}

								return (
									<ForecastQueryRenderer
										fetchPolicy={'network-only'}
										key="query-render-insightsViewer"
										query={insightsViewerQuery}
										variables={match.params}
										render={relayProps => {
											return <InsightsViewer {...relayProps} />;
										}}
									/>
								);
							}
						}}
					/>
					<VersionReloadRoute
						path="/saved-report/:savedReportType/:savedReportId"
						children={({match}) => {
							// Router matches to this route even if the path is for the task modal and not an insight. As such, need to check if param is a task modal path before rendering.
							if (
								match.params &&
								match.params.savedReportId &&
								!taskModalRegex.test(match.params.savedReportId)
							) {
								// Checking if valid id
								try {
									if (!atob(match.params.savedReportId).startsWith('SavedReportType:'))
										return <Redirect to="/not-found" />;
								} catch (e) {
									return <Redirect to="/not-found" />;
								}
								return (
									<ForecastQueryRenderer
										key="query-render-company_saved_report_wrapper"
										query={CompanySavedReportWrapperQuery}
										variables={{
											savedReportId: match.params.savedReportId,
										}}
										authorizeAccessRoute={'reports'}
										onCloseErrorModal={returnTo('/insights')}
										render={relayProps => {
											return <CompanySavedReportWrapper {...relayProps} />;
										}}
									/>
								);
							}
						}}
					/>
					<Route exact path="/saved-report">
						<Redirect to="/reports" />
					</Route>
					{/* endregion */}
					{/* region Project routes */}
					<Redirect exact from={projectPrefix('overview')} to={projectPrefix('settings#status')} />
					<Redirect exact from="/project/P-:projectId(\d+)" to={oldProjectPrefix('task-board')} />
					{hasFeatureFlag('custom_project_ids') && (
						<Redirect exact from={projectPrefixMatcher} to={projectPrefix('task-board')} />
					)}
					<Redirect exact from={projectGroupPrefix('overview')} to={projectGroupPrefix('group-settings')} />
					<VersionReloadRoute
						path={projectPrefix('rates')}
						children={({match}) => (
							<Redirect to={getVersionReloadRoute(match.params.projectId, 'settings#financials')} />
						)}
					/>
					{/* This routing can be removed after removing all references to it */}
					<VersionReloadRoute
						path={projectPrefix('team')}
						children={({match}) => <Redirect to={getVersionReloadRoute(match.params.projectId, 'settings#team')} />}
					/>
					<VersionReloadRoute
						path={projectGroupPrefix('team')}
						children={({match}) => <Redirect to={`/connected/X-${match.params.groupId}/group-settings#team`} />}
					/>
					<Route
						path="/connected/X-:groupId(\d+)"
						exact
						render={routeProps => <Redirect to={`/connected/X-${routeProps.match.params.groupId}/task-board`} />}
					/>

					<VersionReloadRoute
						path={[projectPrefix(''), projectGroupPrefix('')]}
						children={({match}) => {
							const key = match.params.projectId
								? `p${match.params.projectId}`
								: match.params.groupId
								? `x${match.params.groupId}`
								: '';
							return (
								<ForecastQueryRenderer
									key={key}
									query={ProjectRoutesQuery}
									variables={{
										projectId: match.params.projectId,
										projectGroupId: match.params.groupId,
									}}
									requireProject={true}
									requireProjectAccess={true}
									render={relayProps => (
										<ProjectRoutes
											{...relayProps}
											availableFeatureFlags={availableFeatureFlags}
											modules={modules}
											buyNowTime={buyNowTime}
										/>
									)}
								/>
							);
						}}
					/>
					<Route
						path="/internal/sharedreport/:sharedId"
						children={({match}) => {
							return (
								<ForecastQueryRenderer
									key="query-render-sharedReport"
									query={SharedReportQuery}
									variables={{
										...match.params,
										personId: actualPersonId,
									}}
									render={relayProps => {
										return (
											<SharedReportPage
												{...relayProps}
												setLocaleFromBrowser={props.setLocaleFromBrowser}
												shareType={INTERNAL_SHARE_TYPE}
											/>
										);
									}}
								/>
							);
						}}
					/>
					<Redirect from={projectPrefix('*')} to={projectPrefix('workflow')} />
					{/* endregion */}
					{/* region Connected project routes */}
					<Redirect from={projectGroupPrefix('*')} to={projectGroupPrefix('workflow')} />
					{/* endregion */}
					{/* region portfolio */}
					<Redirect from="/invoicing/:subpage" to="/finance/:subpage" />
					<VersionReloadRoute path="/finance">
						<FinanceRoutes />
					</VersionReloadRoute>

					<VersionReloadRoute path="/unit4log">
						<ForecastQueryRenderer
							key="query-render-unit4Log"
							query={unit4LogQuery}
							authorizeAccessRoute={'unit4-log'}
							render={(relayProps, retry) => {
								return <Unit4LogPage retry={retry} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					<VersionReloadRoute path="/advanced-analytics">
						<ForecastQueryRenderer
							key="query-render-advanced-analytics"
							query={AdvancedAnalyticsQuery}
							authorizeAccessRoute={'sisense'}
							render={(relayProps, retry) => {
								return <AdvancedAnalyticsPage retry={retry} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					{/* endregion */}
					<Redirect from="/settings/:subpath" to="/admin/:subpath" />
					<VersionReloadRoute path="/admin">
						<AdminRoutes actualPersonId={actualPersonId} />
					</VersionReloadRoute>
					{/* region Profile routes */}
					<Route exact path="/my-profile">
						<Redirect to="/my-profile/account-configuration" />
					</Route>
					<VersionReloadRoute path={['/my-profile/profile', '/my-profile/account-configuration']}>
						<ForecastQueryRenderer
							key="query-render-settingsProfileViewer"
							query={settingsProfileViewerQuery}
							render={relayProps => {
								return (
									<SettingsProfileViewer
										setLocale={props.setLocale}
										queryParams={Util.getQueryParams(window.location.search)}
										{...relayProps}
									/>
								);
							}}
						/>
					</VersionReloadRoute>
					<VersionReloadRoute path="/my-profile/security">
						<ForecastQueryRenderer
							key="query-render-profileSecurity"
							query={profileSecurityQuery}
							render={relayProps => {
								return <SettingsSecurity setLocale={props.setLocale} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					<VersionReloadRoute path={['/my-profile/all-notifications', '/notifications']}>
						<ForecastQueryRenderer
							key="query-render-profileAllNotifications"
							query={profileAllNotificationsQuery}
							render={relayProps => {
								return <SettingsAllNotifications setLocale={props.setLocale} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					<VersionReloadRoute path="/my-profile/notifications">
						<ForecastQueryRenderer
							key="query-render-profileNotificationsSettings"
							query={ProfileNotificationsPageQuery}
							render={(relayProps, retry) => {
								return <ProfileNotificationsPage setLocale={props.setLocale} retry={retry} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					<VersionReloadRoute path="/my-profile/expenses">
						<ForecastQueryRenderer
							key="query-render-myExpenses"
							query={myExpensesQuery}
							authorizeModule={getModuleAuthFunc(MODULE_TYPES.FINANCE)}
							render={relayProps => {
								return <MyExpenses setLocale={props.setLocale} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					<VersionReloadRoute path={'/my-profile/task-activities'}>
						<ForecastQueryRenderer
							key="query-render-myTaskActivities"
							query={myTaskActivitiesQuery}
							render={relayProps => {
								return <MyTaskActivities setLocale={props.setLocale} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					<VersionReloadRoute path="/my-profile/project-status-updates">
						<ForecastQueryRenderer
							key="query-render-projectStatusUpdates"
							query={ProfileProjectStatusUpdatesPageQuery}
							render={relayProps => {
								return <ProfileProjectStatusUpdatesPage setLocale={props.setLocale} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					<VersionReloadRoute path="/trial-guide">
						<ForecastQueryRenderer
							key="query-render-trialGuideV2"
							query={TrialGuideV2Query}
							render={relayProps => {
								return <TrialGuideV2 setLocale={props.setLocale} {...relayProps} />;
							}}
						/>
					</VersionReloadRoute>
					{/* endregion */}
					<Route
						path={['/activate-feature/:featureFlag']}
						render={routeProps => <ActivateFeature featureFlag={routeProps.match.params.featureFlag} />}
						exact={true}
					/>
					<Redirect to="/not-found" />
				</Switch>

				{/* Task route to always be applied */}
				<Route
					path={['/:pageNo*/T:taskId(\\d+)']}
					render={routeProps => <OpenTaskModal params={routeProps.match.params} />}
					exact={true}
				/>
				{buyNowTime ? <BuyNowFooter buyNowTime={buyNowTime} /> : null}
				{!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) &&
				(process.env.CIRCLE_BRANCH !== 'production' ||
					(hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS) && tier !== TIERS.TRIAL)) ? (
					<ChatIcon />
				) : null}
			</div>
		);
	};

	const controlUrls = ['/missioncontrol', '/control'];

	// Capture datacenter from the URL (For links where the user is not logged in. Eg. invitation link, forgot password link)
	let dc = '';
	const queryParams = Util.getQueryParams(window.location.search);
	if (queryParams.dc) {
		dc = queryParams.dc[0];
	}
	DirectApi.setDatacenterBasedOnString(dc);

	return (
		<Switch>
			{/* region Routes where the user is not logged in */}
			<Route
				path="/signup_xero"
				children={() => (
					<ForecastQueryRenderer
						key="query-render-signup-xero"
						query={XeroSignupContainerQuery}
						renderIfNotLoggedIn={true}
						showLoader={false}
						render={relayProps => <XeroSignupContainer {...relayProps} />}
					/>
				)}
			/>
			<Route path="/login">
				<ForecastQueryRenderer
					key="query-render-login"
					query={loginQuery}
					showLoader={false}
					renderIfNotLoggedIn={true}
					render={relayProps => {
						return isSageSpecificUrl() ? (
							<Login {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />
						) : (
							<LoginWrapper>
								<Login {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />
							</LoginWrapper>
						);
					}}
				/>
			</Route>
			<Route
				path="/signup"
				children={routeProps => {
					if (routeProps.location.search.includes('utm_campaign=Reactivation')) {
						window.location.href = 'https://experience.forecast.it/start-a-new-forecast-trial';
					} else {
						return (
							<ForecastQueryRenderer
								key="query-render-signupQuery"
								query={signupQuery}
								showLoader={false}
								renderIfNotLoggedIn={true}
								render={relayProps => (
									<LoginWrapper>
										<Signup {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />
									</LoginWrapper>
								)}
							/>
						);
					}
				}}
			/>
			<Route path="/maintenance">
				<ForecastQueryRenderer
					key="query-render-maintenance"
					query={maintenanceQuery}
					showLoader={false}
					renderIfNotLoggedIn={true}
					render={relayProps => <Maintenance {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />}
				/>
			</Route>
			<Route path="/okta-login">
				<ForecastQueryRenderer
					key="query-render-loginOktaQuery"
					query={loginOktaQuery}
					showLoader={false}
					renderIfNotLoggedIn={true}
					render={relayProps => <LoginOkta {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />}
				/>
			</Route>
			<Route path="/one-login">
				<ForecastQueryRenderer
					key="query-render-loginOneloginQuery"
					query={loginOneloginQuery}
					showLoader={false}
					renderIfNotLoggedIn={true}
					render={relayProps => <LoginOnelogin {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />}
				/>
			</Route>
			<Route path="/azureAD">
				<ForecastQueryRenderer
					key="query-render-loginAzureActiveDirectory"
					query={loginAzureActiveDirectoryQuery}
					renderIfNotLoggedIn={true}
					showLoader={false}
					render={relayProps => (
						<LoginAzureActiveDirectory {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />
					)}
				/>
			</Route>
			<Route path="/xero-token">
				<XeroToken setLocaleFromBrowser={props.setLocaleFromBrowser} />
			</Route>
			<Route path="/salesforce-login">
				<ForecastQueryRenderer
					key="query-render-loginSalesforceQuery"
					query={loginSalesforceQuery}
					showLoader={false}
					renderIfNotLoggedIn={true}
					render={relayProps => <LoginSalesforce {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />}
				/>
			</Route>
			<Route path="/oauth/authorize">
				<ForecastQueryRenderer
					key="query-render-loginOauthQuery"
					query={loginOauthQuery}
					showLoader={false}
					renderIfNotLoggedIn={true}
					render={relayProps => <LoginOauth {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />}
				/>
			</Route>
			<Route path="/first/:key/:email">
				<ForecastQueryRenderer
					key="query-render-firstLoginQuery"
					query={signupFlowQuery}
					renderIfNotLoggedIn={true}
					render={relayProps => <SignupFlow {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />}
				/>
			</Route>
			<Route path="/first/:key">
				<ForecastQueryRenderer
					key="query-render-firstLoginQuery"
					query={signupFlowQuery}
					renderIfNotLoggedIn={true}
					render={relayProps => <SignupFlow {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />}
				/>
			</Route>
			<Route path="/registration/:key">
				<ForecastQueryRenderer
					key="query-render-firstLoginV2Query"
					query={signupFlowQuery}
					renderIfNotLoggedIn={true}
					render={relayProps => <SignupFlow {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />}
				/>
			</Route>
			<Route path="/start_signup/:key">
				<ForecastQueryRenderer
					key="query-render-signupFlowQuery"
					query={signupFlowQuery}
					renderIfNotLoggedIn={true}
					render={relayProps => <SignupFlow {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />}
				/>
			</Route>
			<Route path="/start_signup">
				<ForecastQueryRenderer
					key="query-render-signupFlowQuery"
					query={signupFlowQuery}
					renderIfNotLoggedIn={true}
					render={relayProps => <SignupFlow {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />}
				/>
			</Route>
			<Route path="/sage_signup/:key">
				<ForecastQueryRenderer
					key="query-render-sageSignupQuery"
					query={SageSignupFlowQuery}
					renderIfNotLoggedIn={true}
					render={relayProps => <SageSignupFlow {...relayProps} />}
				/>
			</Route>
			<Route path="/forgot-password">
				{isSageSpecificUrl() ? (
					<ForgotPassword setLocaleFromBrowser={props.setLocaleFromBrowser} />
				) : (
					<LoginWrapper>
						<ForgotPassword setLocaleFromBrowser={props.setLocaleFromBrowser} />
					</LoginWrapper>
				)}
			</Route>
			<Route
				path="/reset/:key"
				children={({match}) => (
					<ForecastQueryRenderer
						key="query-render-resetPasswordQuery"
						query={resetPasswordQuery}
						variables={match.params}
						renderIfNotLoggedIn={true}
						render={relayProps => {
							return isSageSpecificUrl() ? (
								<ResetPassword {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />
							) : (
								<LoginWrapper>
									<ResetPassword {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />
								</LoginWrapper>
							);
						}}
					/>
				)}
			/>
			<Route
				path={['/add-task-form/:linkToPublicCreateTaskPage', '/create-task-form/:linkToPublicCreateTaskPage']}
				children={({match}) => <PublicCreateTaskPage match={match} setLocaleFromBrowser={props.setLocaleFromBrowser} />}
			/>

			<Route
				path="/sharedinsight/:shareKey"
				children={({match}) => {
					return (
						<ForecastQueryRenderer
							key="query-render-sharedInsight"
							query={sharedInsightQuery}
							variables={match.params}
							renderIfNotLoggedIn={true}
							render={relayProps => {
								return <SharedInsight {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} />;
							}}
						/>
					);
				}}
			/>

			<Route
				path="/sharedreport/:sharedId"
				children={({match}) => {
					return (
						<ForecastQueryRenderer
							key="query-render-sharedReport"
							query={SharedReportQuery}
							variables={{
								...match.params,
							}}
							renderIfNotLoggedIn={true}
							render={relayProps => {
								return (
									<SharedReportPage
										{...relayProps}
										setLocaleFromBrowser={props.setLocaleFromBrowser}
										shareType={EXTERNAL_SHARE_TYPE}
									/>
								);
							}}
						/>
					);
				}}
			/>

			<Route path="/sisense/sso">
				<ForecastQueryRenderer
					key="query-render-login"
					query={loginQuery}
					showLoader={false}
					renderIfNotLoggedIn={true}
					render={relayProps => (
						<LoginWrapper>
							<Login {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} sisenseLogin={true} />
						</LoginWrapper>
					)}
				/>
			</Route>
			<Route path="/sisense/ssout" />

			<Route path="/zendesk/sso">
				<ForecastQueryRenderer
					key="query-render-login"
					query={loginQuery}
					showLoader={false}
					renderIfNotLoggedIn={true}
					render={relayProps => (
						<LoginWrapper>
							<Login {...relayProps} setLocaleFromBrowser={props.setLocaleFromBrowser} zendeskLogin={true} />
						</LoginWrapper>
					)}
				/>
			</Route>
			<Route path="/zendesk/ssout" />

			{/* shared timeline */}
			<Route
				path={sharedPrefix('schedule')}
				children={({match}) => <CanvasScheduling key={match.params.shareUrl} isProjectTimeline={true} />}
			/>
			{/* endregion */}
			{/* project scheduling share */}
			<Route exact path="/project-scheduling-share/:shareUrl">
				<CanvasProjectSchedulingShare />
			</Route>
			{/* project timeline share */}
			<Route exact path="/project-scheduling-share/:shareUrl/:projectId">
				<CanvasProjectSchedulingShare />
			</Route>
			{/* endregion */}
			{/* region Mission control pages */}
			<Route path={controlUrls}>
				<Route path={controlUrls} exact>
					<ForecastQueryRenderer
						isMc={true}
						key="query-render-impersonate"
						query={impersonateQuery}
						render={(relayProps, retry) => <Impersonate retry={retry} {...relayProps} />}
					/>
				</Route>
				<Route path={controlUrls.map(url => url + '/company-stats')}>
					<ForecastQueryRenderer
						isMc={true}
						key="query-render-companyStatsQuery"
						query={companyStatsQuery}
						render={relayProps => <CompanyStats {...relayProps} />}
					/>
				</Route>
				<Route path={controlUrls.map(url => url + '/trials')}>
					<ForecastQueryRenderer
						isMc={true}
						key="query-render-trialsListQuery"
						query={trialsListQuery}
						render={(relayProps, retry) => <TrialsList {...relayProps} retry={retry} />}
					/>
				</Route>
				<Route path={controlUrls.map(url => url + '/companies-with-modules')}>
					<ForecastQueryRenderer
						isMc={true}
						key="query-render-companiesWithModulesQuery"
						query={companiesWithModulesQuery}
						render={(relayProps, retry) => <CompaniesWithModules {...relayProps} retry={retry} />}
					/>
				</Route>
				<Route path={controlUrls.map(url => url + '/person-list')}>
					<ForecastQueryRenderer
						isMc={true}
						key="query-render-controlPersonList"
						query={controlPersonListQuery}
						variables={{searchString: ''}}
						render={relayProps => <ControlPersonList {...relayProps} />}
					/>
				</Route>
				<Route path={controlUrls.map(url => url + '/ml-stats')}>
					<ForecastQueryRenderer
						isMc={true}
						key="query-render-mlStatsQuery"
						query={mlStatsQuery}
						render={relayProps => <MLStats {...relayProps} />}
					/>
				</Route>
				<Route path={controlUrls.map(url => url + '/features')}>
					<ForecastQueryRenderer
						isMc={true}
						key="query-render-featureFlagsQuery"
						query={featureFlagsQuery}
						render={relayProps => <FeatureFlags {...relayProps} />}
					/>
				</Route>
				<Route path={controlUrls.map(url => url + '/sisense')}>
					<SisenseAdminPage />
				</Route>
				<Route path={controlUrls.map(url => url + '/bug-duty')}>
					<ForecastQueryRenderer
						isMc={true}
						key="query-render-bugDutyQuery"
						query={bugDutyQuery}
						render={relayProps => <BugDuty {...relayProps} />}
					/>
				</Route>
				<Route path={controlUrls.map(url => url + '/bamboo-duplicates')}>
					<ForecastQueryRenderer
						isMc={true}
						key="query-render-bambooDuplicatesQuery"
						query={bambooDuplicatesQuery}
						render={relayProps => <BambooDuplicates {...relayProps} />}
					/>
				</Route>
				<Route path={controlUrls.map(url => url + '/companies_to_be_deleted')}>
					<ForecastQueryRenderer
						key="query-render-companiesToBeDeletedQuery"
						query={companiesToBeDeletedQuery}
						isMc
						render={relayProps => <CompaniesToBeDeleted {...relayProps} />}
					/>
				</Route>
				<Route path={controlUrls.map(url => url + '/restore-data')}>
					<ForecastQueryRenderer
						key="query-render-restoreData"
						query={RestoreDataQuery}
						render={relayProps => <RestoreData {...relayProps} />}
					/>
				</Route>
				<Route path={controlUrls.map(url => url + '/sage-signup')}>
					<ForecastQueryRenderer
						key="query-render-sageSignup"
						query={sageSignupQuery}
						render={relayProps => <SageSignupControlPage {...relayProps} />}
					/>
				</Route>
			</Route>
			{/* endregion */}
			{/* Logged in routes */}
			<Route path="/">
				<ForecastQueryRenderer
					key="query-render-appv2Query"
					query={appv2Query}
					redirectIfNotLoggedIn={true}
					render={(relayProps, retry) => (
						<AppV2 {...relayProps} retry={retry} setLocale={props.setLocale} appContent={appContent} />
					)}
				/>
			</Route>
		</Switch>
	);
};

export default routerv2;
