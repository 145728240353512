import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE} from '../../constants';
import DatePicker from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import UpdateProjectMutation from '../../mutations/update_project_mutation.modern';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../forecast-app/shared/util/util';

class AddProjectDatesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: null,
			endDate: null,
		};
	}

	handleConfirmButton() {
		const {project} = this.props;
		const {formatMessage} = this.props.intl;
		const {startDate, endDate} = this.state;

		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: formatMessage({id: 'project_budget.project_dates_added'}),
			});
			this.props.datesSetCallback && this.props.datesSetCallback();
			this.props.onSuccess && this.props.onSuccess();
			this.props.closeModal();
		};

		// Mutation
		Util.CommitMutation(
			UpdateProjectMutation,
			{
				project,
				projectEndDay: endDate.date(),
				projectEndMonth: endDate.month() + 1,
				projectEndYear: endDate.year(),
				projectStartDay: startDate.date(),
				projectStartMonth: startDate.month() + 1,
				projectStartYear: startDate.year(),
			},
			onSuccess
		);
	}
	handleDateRangeChange(startDate, endDate) {
		this.setState({startDate, endDate});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {project} = this.props;
		const {projectStartDay, projectStartMonth, projectStartYear, projectEndDay, projectEndMonth, projectEndYear} = project;
		const startDate = Util.CreateNonUtcMomentDate(projectStartYear, projectStartMonth, projectStartDay);
		const endDate = Util.CreateNonUtcMomentDate(projectEndYear, projectEndMonth, projectEndDay);

		const content = (
			<div className="content add-project-dates-container">
				<p className="description">{formatMessage({id: 'add_project_dates_modal.info_text'})}</p>

				<div className="label">{formatMessage({id: 'common.date'})}</div>
				<DatePicker
					startDate={startDate}
					endDate={endDate}
					startDateEmptyText={formatMessage({id: 'common.start'})}
					endDateEmptyText={formatMessage({id: 'common.end'})}
					handleDateRangeChange={this.handleDateRangeChange.bind(this)}
					datePickerStyle={DATE_PICKER_STYLE.STANDARD}
				/>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.add'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.handleConfirmButton.bind(this),
						disabled: !this.state.startDate && !this.state.endDate,
					},
				]}
				headerText={formatMessage({id: 'add_project_dates_modal.header_title'})}
				content={content}
			/>
		);
	}
}

AddProjectDatesModal.propTypes = {
	projectId: PropTypes.string,
	closeModal: PropTypes.func.isRequired,
};

export default injectIntl(AddProjectDatesModal);
