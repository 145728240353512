import React, {useState} from 'react';
import {Button, CaretIcon, HubspotIcon} from 'web-components';
import {FakeHeaderContainer, GoBackContainer, GoBackText} from './app_salesforce_style';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {FormattedMessage, useIntl} from 'react-intl';
import {graphql} from 'graphql';
import {withRouter} from 'react-router-dom';
import {createFragmentContainer} from 'react-relay';
import {ButtonsWrapper, DescriptionWrapper, IconAndDescriptionWrapper} from './app_hubspot_style';
import Util from '../../../forecast-app/shared/util/util';
import CreateHubspotVerificationKeyMutation from '../../../mutations/CreateHubspotVerificationKeyMutation';
import {MainSectionWrapper, PageWrapper, SubSection} from '../../project/project_settings/ProjectSettings_styled';
import HubspotStagesTable from './HubspotStagesTable';
import DisableHubspotMutation from '../../../mutations/DisableHubspotMutation';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../../components/warning';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import GenericProjectTemplatesTable, {genericProjectTemplatesTableQuery} from './GenericProjectTemplatesTable';
import GenericIntegrationLinkedFields from './GenericIntegrationLinkedFields';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';

const AppHubspot = ({history, viewer}) => {
	const intl = useIntl();

	const [deactivating, setDeactivating] = useState(false);

	const openHelpCenter = () => {
		window.open('https://support.forecast.app/hc/en-us/articles/5301320577553');
	};

	const goBackToIntegrationsPage = () => {
		history.push('/admin/integrations');
	};

	const activateHubspot = () => {
		const clientId = process.env.HUBSPOT_APP_CLIENT_ID;
		const redirectUri = `${process.env.API_URL}/hubspot/oauth`;
		const scopes = [
			'tickets',
			'crm.objects.contacts.read',
			'crm.objects.contacts.write',
			'crm.objects.companies.write',
			'crm.schemas.contacts.read',
			'crm.objects.companies.read',
			'crm.objects.deals.read',
			'crm.objects.deals.write',
			'crm.schemas.companies.read',
			'crm.schemas.companies.write',
			'crm.schemas.contacts.write',
			'crm.schemas.deals.read',
			'crm.schemas.deals.write',
		];
		const joinedScopes = scopes.join('%20');
		Util.CommitMutation(CreateHubspotVerificationKeyMutation, null, ({createHubspotVerificationKey}) =>
			window.open(
				`https://app.hubspot.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${joinedScopes}&state=${createHubspotVerificationKey.key}`,
				'_self'
			)
		);
	};

	const deactivateIntegration = () => {
		const {formatMessage} = intl;

		const callbackPositive = params => {
			setDeactivating(true);
			Util.CommitMutation(DisableHubspotMutation, null, () => setDeactivating(false));
		};

		let info = 'settings.app_catalog.hubspot.disable_message_warning';

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId={info} />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			className: 'default-warning-modal',
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					callback: callbackPositive,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
				},
			],
		});
	};

	const hubspotEnabled = viewer.company.hubspotAccountId;

	const actionButton = hubspotEnabled ? (
		<Button variant={Button.VARIANT.RED_OUTLINE} onClick={deactivateIntegration}>
			<FormattedMessage id="common.deactivate" />
		</Button>
	) : (
		<Button variant={Button.VARIANT.PURPLE_OUTLINE} onClick={activateHubspot}>
			<FormattedMessage id="common.activate" />
		</Button>
	);
	return (
		<PageWrapper>
			<MainSectionWrapper width={1350}>
				<FakeHeaderContainer onClick={goBackToIntegrationsPage}>
					<GoBackContainer>
						<CaretIcon size={CaretIcon.SIZE.STANDARD} color={'#535353'} direction={CaretIcon.DIRECTION.LEFT} />
						<GoBackText>
							{intl.formatMessage({id: 'app_salesforce.salesforce_configuration.go_back_to_integrations'})}
						</GoBackText>
					</GoBackContainer>
				</FakeHeaderContainer>

				<CustomScrollDiv>
					<SubSection>
						<IconAndDescriptionWrapper>
							<HubspotIcon size={HubspotIcon.SIZE.BIG} />
							<DescriptionWrapper>
								<FormattedMessage id="settings.app_catalog.hubspot.description" />
							</DescriptionWrapper>
						</IconAndDescriptionWrapper>

						<ButtonsWrapper>
							{actionButton}
							<Button variant={Button.VARIANT.STANDARD} onClick={openHelpCenter}>
								<FormattedMessage id="settings.app_catalog.help_button" />
							</Button>
						</ButtonsWrapper>
					</SubSection>

					{viewer.company.hubspotAccountId && !deactivating && (
						<>
							<SubSection
								title={intl.formatMessage({id: 'settings.app_catalog.common.deal_stages'})}
								description={
									<FormattedMessage
										id="settings.app_catalog.hubspot.deal_description"
										values={{
											a: msg => (
												<a
													href="https://support.forecast.app/hc/en-us/articles/5301320577553"
													target="_blank"
													rel="noopener noreferrer"
												>
													{msg}
												</a>
											),
										}}
									/>
								}
							>
								<HubspotStagesTable />
							</SubSection>

							<SubSection
								title={intl.formatMessage({id: 'settings.app_catalog.hubspot.linked_fields_title'})}
								description={intl.formatMessage({
									id: 'settings.app_catalog.hubspot.linked_fields_description',
								})}
							>
								<GenericIntegrationLinkedFields integrationName={'hubspot'} />
							</SubSection>

							<SubSection
								title={intl.formatMessage({id: 'settings.app_catalog.common.project_template'})}
								description={<FormattedMessage id="settings.app_catalog.common.project_template_description" />}
							>
								<>
									<ForecastQueryRenderer
										key="query-render-GenericProjectTemplatesTable"
										query={genericProjectTemplatesTableQuery}
										render={relayProps => {
											return <GenericProjectTemplatesTable {...relayProps} integrationName={'hubspot'} />;
										}}
									/>
								</>
							</SubSection>
						</>
					)}

					{deactivating && (
						<InlineLoader
							loaderText={intl.formatMessage({id: 'settings.app_catalog.common.deactivating_integration'})}
						/>
					)}
				</CustomScrollDiv>
			</MainSectionWrapper>
		</PageWrapper>
	);
};

const appHubspotQuery = graphql`
	query appHubspot_Query {
		viewer {
			actualPersonId
			component(name: "app_hubspot")
			...appHubspot_viewer
		}
	}
`;

export {appHubspotQuery};

export default withRouter(
	createFragmentContainer(AppHubspot, {
		viewer: graphql`
			fragment appHubspot_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				company {
					id
					hubspotAccountId
				}
			}
		`,
	})
);
