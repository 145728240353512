import React, {Component} from 'react';
import Moment from 'moment';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import GenericModal from './generic_modal';
import AddTimeEntry from '../../forecast-app/shared/components/add-time-entry/add_time_entry_component';
import {
	formatIdleTimeOption,
	formatProjectOption,
	formatTaskOption,
} from '../../forecast-app/shared/components/dropdowns/task-dropdown/add_time_entry_data_format';
import Util from '../../forecast-app/shared/util/util';
import UpdateViewerMutation from '../../mutations/update_viewer_mutation.modern';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {PROJECT_STATUS} from '../../constants';
import {getLockedDate} from '../../components/timesheets/time-lock/TimeLockUtil';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';
import {getTaskTimeRegistrationNotAllowedWithReason} from '../../forecast-app/shared/util/time-registration/time-registration-settings/TimeRegistrationTaskFilter';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';

/*
 * Timer modal can be opened from the following locations and the following ways:
 * * Create new menu: Create new time registration
 * * Generic task context menu: Create time registration; start timer; stop timer
 * * Workflow board card: Create time registration; start timer on card; stop timer on card; prompt time registration; bulk delete task with running timer
 * * Scoping: Bulk delete task with running timer
 * * Sprints: Bulk delete task with running timer
 * * Timer: Start timer, stop timer
 */

const pageSize = 15;

function getFormattedPassedTime(passedTime) {
	return passedTime != null ? passedTime / 60 : null;
}

function getFormattedPassedTime_timeRegInputConversion(passedTime) {
	return passedTime != null ? `${passedTime}m` : null;
}

class timerModalV3 extends Component {
	clearTimer(shouldShowToast) {
		const onSuccess = result => {
			if (shouldShowToast) {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'timer_modal.reset_toast_text'}),
				});
			}
			this.props.closeModal();
		};
		const relaunchTimer = this.props.taskModal && this.props.isResetingAndStartingTimer && this.props.taskId;
		trackEvent('Timer Entry Modal', `${relaunchTimer ? 'Restarted' : 'Cleared'}`);
		Util.CommitMutation(
			UpdateViewerMutation,
			{
				viewer: this.props.viewer,
				timerStartDate: relaunchTimer ? Moment.utc().format() : null,
				timerEndDate: null,
				timerTaskId: relaunchTimer ? this.props.taskId : null,
			},
			onSuccess
		);
	}

	registerTimeClearTimer() {
		if (this.props.useTimer !== false) {
			const relaunchTimer = this.props.taskModal && this.props.isResetingAndStartingTimer && this.props.taskId;
			trackEvent('Timer Entry Modal', `${relaunchTimer ? 'Restarted' : 'Cleared'}`);
			Util.CommitMutation(UpdateViewerMutation, {
				viewer: this.props.viewer,
				timerStartDate: relaunchTimer ? Moment.utc().format() : null,
				timerEndDate: null,
				timerTaskId: relaunchTimer ? this.props.taskId : null,
			});
		}
	}

	render() {
		const {formatMessage} = this.props.intl;

		// Storage objects for project and task to be displayed in timer modal
		let timerProject, timerTask;

		// storage object for timer task that was invalidated by time registration settings
		let invalidatedTimerTask;

		// Timer information received from external sources
		const viewerTask = this.props.viewer.task;
		const passedTimerTask = this.props.timerTask;
		const passedTimerProject = this.props.timerProject;
		const viewerTimerTask = this.props.viewer.timerTask;
		// Time registration modal opened directly on card.
		if (viewerTask && this.props.useTimer === false) {
			timerTask = viewerTask;
			timerProject = viewerTask.project;
		} else if (
			passedTimerProject &&
			passedTimerProject.status !== PROJECT_STATUS.HALTED &&
			passedTimerProject.status !== PROJECT_STATUS.DONE
		) {
			timerProject = passedTimerProject;

			if (passedTimerTask) {
				timerTask = passedTimerTask;
			}
			// Timer was started on a task
		} else if (this.props.useTimer !== false) {
			if (viewerTimerTask) {
				//Make sure task/project are not pre selected if timer was started on a task/project and then project was set to halted or done
				if (
					viewerTimerTask.project &&
					viewerTimerTask.project.status !== PROJECT_STATUS.HALTED &&
					viewerTimerTask.project.status !== PROJECT_STATUS.DONE
				) {
					timerTask = viewerTimerTask;
					timerProject = viewerTimerTask.project;
				}
				// Timer was not started on a task
			} else if (this.props.isPreviousTimerReminder && passedTimerTask) {
				if (
					passedTimerTask.project &&
					passedTimerTask.project.status !== PROJECT_STATUS.HALTED &&
					passedTimerTask.project.status !== PROJECT_STATUS.DONE
				) {
					timerTask = passedTimerTask;
					timerProject = passedTimerTask.project;
				}
			}
		}

		if (timerTask && this.props.useTimer !== false) {
			const statusCategory = timerTask.statusColumnV2?.category;
			const assignedPersonIds = timerTask.assignedPersons ? timerTask.assignedPersons.map(person => person.id) : [];

			const timeRegistrationNotAllowedReason = getTaskTimeRegistrationNotAllowedWithReason(
				statusCategory,
				this.props.viewer.actualPersonId,
				assignedPersonIds
			);
			if (timeRegistrationNotAllowedReason) {
				invalidatedTimerTask = {taskId: timerTask.companyTaskId, reason: timeRegistrationNotAllowedReason};
				timerTask = null;
				timerProject = null;
			}
		}
		const formattedTimerProject = timerProject ? formatProjectOption(timerProject) : null;
		const formattedTimerTask = timerTask ? formatTaskOption(timerTask) : null;
		const formattedTimerIdleTask = this.props.timerIdleTime ? formatIdleTimeOption(this.props.timerIdleTime) : null;
		const formattedPassedTime = hasFeatureFlag('timereg_input_conversion')
			? getFormattedPassedTime_timeRegInputConversion(this.props.passedTime)
			: getFormattedPassedTime(this.props.passedTime);

		const {timerStartDate, timerEndDate} = this.props.viewer;
		const timerDuration =
			this.props.useTimer === false
				? 0
				: timerEndDate !== null
				? Moment.utc(timerEndDate).diff(Moment.utc(timerStartDate), 'seconds') / 3600
				: Moment.utc().diff(Moment.utc(timerStartDate), 'seconds') / 3600;
		//Round to nearest minute - if greater than 24 hours, set to 24
		const roundedTimerDuration =
			timerDuration > 24 ? 24 : timerDuration > 0 ? Math.ceil(parseFloat(timerDuration) * 60) / 60 : undefined;

		const selectedPerson = this.props.selectedPerson;
		const lockedDate = getLockedDate(this.props.viewer.company, selectedPerson || this.props.viewer);

		const content = (
			<AddTimeEntry
				showFullNotes
				viewer={this.props.viewer}
				pageSize={pageSize}
				clearTimer={this.clearTimer.bind(this)}
				registerTimeClearTimer={this.registerTimeClearTimer.bind(this)}
				closeModal={this.props.closeModal}
				updateTimeRegId={this.props.updateTimeRegId}
				timerProject={formattedTimerProject}
				timerTask={formattedTimerTask}
				timerIdleTime={formattedTimerIdleTask}
				roundedTimerDuration={roundedTimerDuration}
				passedTime={formattedPassedTime}
				initialBillableTime={this.props.passedBillableTime}
				initialRole={this.props.timeRegRole ? this.props.timeRegRole : undefined}
				passedDate={this.props.passedDate}
				passedNotes={this.props.passedNotes}
				selectedPerson={selectedPerson}
				source={'timer'}
				lockedDate={lockedDate}
				invalidatedTimerTask={invalidatedTimerTask}
				passedHarvestTask={this.props.harvestTaskSelected}
				timeOffOnly={this.props.timeOffOnly}
			/>
		);

		return (
			<GenericModal
				closeModal={this.props.closeModal}
				content={content}
				headerText={
					this.props.timeOffOnly
						? 'New Time Off'
						: this.props.updateTimeRegId
						? formatMessage({id: 'time_registration.edit.header'})
						: this.props.preventedDelete
						? formatMessage({id: 'timer_modal.delete_task_running_timer_short'})
						: this.props.isPreviousTimerReminder || this.props.isResetingAndStartingTimer
						? formatMessage({id: 'timer_modal.reminder_title'})
						: formatMessage({id: 'common.new_time_entry_popup_title'}) +
						  (selectedPerson
								? ' ' +
								  formatMessage({id: 'common.for'}) +
								  ' ' +
								  selectedPerson.firstName +
								  ' ' +
								  selectedPerson.lastName
								: '')
				}
				headerInfo={this.props.preventedDelete ? formatMessage({id: 'timer_modal.delete_task_running_timer'}) : ''}
			/>
		);
	}
}

const timerModalV3Query = graphql`
	query timerModalV3_Query($personId: ID, $taskId: ID) {
		viewer {
			actualPersonId
			component(name: "timer_modal_v3")
			...timerModalV3_viewer @arguments(personId: $personId, taskId: $taskId)
		}
	}
`;

export {timerModalV3Query};

export default injectIntl(
	createFragmentContainer(timerModalV3, {
		viewer: graphql`
			fragment timerModalV3_viewer on Viewer @argumentDefinitions(personId: {type: "ID!"}, taskId: {type: "ID"}) {
				id
				harvestUser
				language
				actualPersonId
				timerStartDate
				timerEndDate
				excludeFromCompanyLockedPeriod
				submitLockedDateYear
				submitLockedDateMonth
				submitLockedDateDay
				startDate
				endDate
				createdAt
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				task(id: $taskId) {
					id
					companyTaskId
					jiraId
					name
					timeLeft
					billable
					parentTaskId
					estimateForecastMinutes
					totalMinutesRegistered
					startDay
					startMonth
					startYear
					deadlineDay
					deadlineMonth
					deadlineYear
					project {
						id
						companyProjectId
						status
						name
						billable
						projectColor
						projectStartYear
						projectStartMonth
						projectStartDay
						projectEndYear
						projectEndMonth
						projectEndDay
						harvestProject {
							id
						}
						unit4Project {
							id
							activitiesEnabled
						}
						projectPerson(personId: $personId) {
							role {
								id
								name
							}
						}
					}
					role {
						id
						name
					}
				}
				timerTask {
					id
					companyTaskId
					startYear
					startMonth
					startDay
					deadlineDay
					deadlineMonth
					deadlineYear
					name
					timeLeft
					billable
					estimateForecastMinutes
					totalMinutesRegistered
					role {
						id
					}
					assignedPersons {
						id
					}
					statusColumnV2 {
						category
					}
					project {
						id
						companyProjectId
						status
						name
						billable
						projectColor
						projectStartYear
						projectStartMonth
						projectStartDay
						projectEndYear
						projectEndMonth
						projectEndDay
						harvestProject {
							id
						}
						projectPerson(personId: $personId) {
							role {
								id
								name
							}
						}
					}
				}
				availableFeatureFlags {
					key
				}
				company {
					id
					bambooHREnabled
					harvestEnabled
					characterLimit
					lockedPeriodYear
					lockedPeriodMonth
					lockedPeriodDay
					roles(includeDisabled: false) {
						edges {
							node {
								id
								name
							}
						}
					}
					modules {
						moduleType
					}
					idleTimes(first: 10000) {
						edges {
							node {
								id
								name
								isInternalTime
								disabled
							}
						}
					}
					person(id: $personId) {
						id
						role {
							id
						}
					}
				}
				projects(first: 10000, excludeDoneOrHalted: true, excludeReadOnly: true) {
					edges {
						...ProjectDropdown_projects
						node {
							id
							name
							companyProjectId
							isProgramRevenueLocked
							programBudgetType
							billable
							projectStartYear
							projectStartMonth
							projectStartDay
							projectEndYear
							projectEndMonth
							projectEndDay
							manualProgressOnProjectEnabled
							manualProgressOnPhasesEnabled
							manualProgressOnTasksEnabled
							status
							projectColor
							harvestProject {
								id
							}
							projectPerson(personId: $personId) {
								role {
									id
									name
								}
							}
						}
					}
				}
			}
		`,
	})
);
