import React, {useEffect, useMemo, useState} from 'react';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {Dropdown, HexagonText} from 'web-components';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import {useIntl} from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import {createFragmentContainer, graphql} from 'react-relay';
import UpdateCompanyContactMutation from '../../../mutations/UpdateCompanyContactMutation';
import InputFieldV2 from '../../../components/new-ui/input_field';
import {DropdownOption} from '../../../forecast-app/shared/components/dropdowns/preview-selector/SkillsSelections.styled';
import UpdatePersonMutation from '../../../mutations/update_person_mutation.modern';
import {personIsAdmin, personIsVirtualUser} from '../../../forecast-app/shared/util/PermissionsUtil';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {usesSso} from '../../../forecast-app/shared/util/FeatureUtil';
import {profilePicSrc} from '../../../directApi';

function splitName(s, on = ' ') {
	const [first, ...rest] = s.trim().split(on);
	return [first, rest.length > 0 ? rest.join(on) : ''];
}

const UpdateContactModal = ({contactType, company, closeModal}) => {
	const {formatMessage} = useIntl();
	const originalPersonId = company.contactPersons.edges
		.map(edge => edge.node)
		.find(contactPerson => contactPerson.contactType === contactType)?.person?.id;
	const [selectedPersonId, setSelectedPersonId] = useState(originalPersonId);
	const allPersons = company.allPersons.edges.map(edge => edge.node).filter(person => !personIsVirtualUser(person));

	const currentPerson = useMemo(() => allPersons.find(person => person.id === selectedPersonId), [selectedPersonId]);

	const [name, setName] = useState(currentPerson?.fullName);
	const [email, setEmail] = useState(currentPerson?.email);
	const [jobTitle, setJobTitle] = useState(currentPerson?.jobTitle);
	const [phone, setPhone] = useState(currentPerson?.phone);

	const [emailError, setEmailError] = useState();

	useEffect(() => {
		setName(currentPerson?.fullName || '');
		setEmail(currentPerson?.email || '');
		setJobTitle(currentPerson?.jobTitle || '');
		setPhone(currentPerson?.phone || '');
	}, [currentPerson]);

	const phoneValid = phone && phone.length > 6;
	const valid = name && email && jobTitle && phoneValid && !emailError;
	const contactTypeFormatted = formatMessage({id: 'settings_details.contact_type.' + contactType});

	const onSelectPerson = personId => {
		setSelectedPersonId(personId);
	};

	const save = () => {
		const personDataToUpdate = {};
		if (currentPerson.fullName !== name) {
			const [firstName, lastName] = splitName(name);
			personDataToUpdate.firstName = firstName;
			personDataToUpdate.lastName = lastName;
		}
		if (currentPerson.email !== email) {
			personDataToUpdate.email = email;
		}
		if (currentPerson.jobTitle !== jobTitle) {
			personDataToUpdate.jobTitle = jobTitle;
		}
		if (currentPerson.phone !== phone) {
			personDataToUpdate.phone = phone;
		}

		const updateContactPerson = () => {
			Util.CommitMutation(
				UpdateCompanyContactMutation,
				{
					contactType,
					personId: selectedPersonId,
					companyId: company.id,
				},
				() => {
					closeModal();
					createToast({
						duration: 5000,
						message: formatMessage({id: 'settings_details.contact_updated'}, {contactType: contactTypeFormatted}),
					});
				}
			);
		};

		if (Object.entries(personDataToUpdate).length > 0) {
			personDataToUpdate.id = selectedPersonId;
			if (personIsAdmin(currentPerson) && !usesSso(company)) {
				showModal({
					type: MODAL_TYPE.CONFIRM_PASSWORD_MODAL,
					mutation: UpdatePersonMutation,
					mutationName: 'updatePerson',
					data: personDataToUpdate,
					onSuccess: updateContactPerson,
				});
			} else {
				Util.CommitMutation(UpdatePersonMutation, personDataToUpdate, updateContactPerson);
			}
		} else {
			updateContactPerson();
		}
	};

	const checkEmailValidity = isValid => {
		setEmailError(isValid ? undefined : 'settings_team.invalid-email');
	};

	const nameRequired = selectedPersonId && !currentPerson?.fullName;
	const emailRequired = selectedPersonId && !currentPerson?.email;
	const jobTitleRequired = selectedPersonId && !currentPerson?.jobTitle;
	const phoneRequired = selectedPersonId;
	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({id: 'settings_details.update_contact_title'}, {contactType: contactTypeFormatted})}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.save'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					preventDefaultClose: true,
					callback: save,
					disabled: !valid,
					cy: 'button-save',
				},
			]}
			content={
				<div>
					<ModalBody>
						<Field>
							<Dropdown
								usePortal
								onSelect={ids => onSelectPerson(ids[0])}
								selectedItems={[selectedPersonId]}
								name={formatMessage({id: 'common.person'})}
								size={'large'}
								cy={'contact-select-person'}
							>
								{allPersons.map((person, index) => (
									<DropdownOption
										data-cy={`person-option-${index}`}
										key={person.id}
										value={person.id}
										searchString={person.fullName}
										cy={`person:${person.fullName}`}
									>
										<HexagonText
											text={person.fullName}
											subText={person.role?.name || ''}
											imgUrl={profilePicSrc(person.profilePictureId)}
										/>
									</DropdownOption>
								))}
							</Dropdown>
						</Field>
						<Field>
							<InputFieldV2
								value={name}
								cy={'contact-name'}
								id="contactName"
								label={formatMessage({id: 'common.name'})}
								type="text"
								onChange={setName}
								locked={!nameRequired}
								invalidInput={nameRequired && !name}
								required={nameRequired}
								maxLength={191}
							/>
						</Field>
						<Field>
							<InputFieldV2
								value={email}
								cy={'contact-email'}
								id="contactPhone"
								label={formatMessage({id: 'common.email'})}
								type="email"
								checkEmailValidity={checkEmailValidity}
								onChange={setEmail}
								locked={!emailRequired}
								invalidInput={emailRequired && (!email || emailError)}
								required={emailRequired}
								errorMessage={emailError && formatMessage({id: emailError})}
								maxLength={191}
							/>
						</Field>
						<Field>
							<InputFieldV2
								value={jobTitle}
								cy={'contact-jobTitle'}
								id="contactJobTitle"
								label={formatMessage({id: 'common.title'})}
								type="text"
								onChange={setJobTitle}
								locked={!jobTitleRequired}
								invalidInput={jobTitleRequired && !jobTitle}
								required={jobTitleRequired}
								maxLength={191}
							/>
						</Field>
						<Field>
							<InputFieldV2
								value={phone}
								cy={'contact-phone'}
								id="contactPhone"
								label={formatMessage({id: 'common.phone'})}
								type="text"
								onChange={setPhone}
								locked={!phoneRequired}
								invalidInput={phoneRequired && !phoneValid}
								required={phoneRequired}
								maxLength={45}
							/>
						</Field>
					</ModalBody>
				</div>
			}
		/>
	);
};

const updateContactModalQuery = graphql`
	query UpdateContactModal_Query {
		viewer {
			actualPersonId
			component(name: "update_contact_modal")
			company {
				...UpdateContactModal_company
			}
		}
	}
`;

export {updateContactModalQuery};

export default createFragmentContainer(UpdateContactModal, {
	company: graphql`
		fragment UpdateContactModal_company on Company {
			id
			oktaEnabled
			oneloginEnabled
			AADEnabled
			contactPersons(first: 10000) @connection(key: "Company_contactPersons") {
				edges {
					node {
						contactType
						person {
							id
						}
					}
				}
			}
			allPersons(first: 10000, excludeClientUsers: true, onlyActive: true) {
				edges {
					...PersonDropdown_persons
					node {
						id
						fullName
						email
						jobTitle
						phone
						profilePictureId
						role {
							name
						}
						permissions
					}
				}
			}
		}
	`,
});
