import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import TaskModalV3, {taskModalV3Query} from '../components/task-modal-v3/task_modal_v3';
import PropTypes from 'prop-types';
import TaskModalLoader from '../components/task-modal-v3/task_modal_v3_loader';
import ForecastQueryRenderer from '../ForecastQueryRenderer';

class taskModalWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			taskId: this.props.taskId,
		};
	}

	shouldComponentUpdate(nextProps) {
		return nextProps.taskId !== this.state.taskId;
	}

	render() {
		return (
			<ForecastQueryRenderer
				key="query-render-taskModalV3Query"
				query={taskModalV3Query}
				variables={{id: this.props.taskId}}
				modal={true}
				customLoader={() => <TaskModalLoader />}
				render={(relayProps, retry) => {
					return (
						<TaskModalV3
							{...relayProps}
							retry={retry}
							closeModal={this.props.closeModal}
							taskId={this.props.taskId}
							connectedProject={this.props.connected}
						/>
					);
				}}
			/>
		);
	}
}

taskModalWrapper.propTypes = {
	taskId: PropTypes.string.isRequired,
	shouldFocusTitle: PropTypes.bool,
	closeModal: PropTypes.func.isRequired,
};
export default injectIntl(taskModalWrapper);
