import React, {useState} from 'react';
import styled from 'styled-components';
import {injectIntl} from 'react-intl';
import Input from '../../components/new-ui/input_field';
import {CSS_CONSTANTS} from '../../css_variables';
import GenericModal, {MODAL_WIDTH} from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE, PERIOD_BUDGET_TYPE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import {DeprecatedText as Text, deprecatedTheme as theme} from '@forecast-it/design-system';
import {getLastPeriodEndDateForSelectedPeriodCount} from '../../components/new-ui/retainer_period/RetainerPeriodUtil';
import {ProgramBudgetInfo} from '../../components/new-ui/retainer_period/ProgramBudgetInfo';
import {ProgramTotalAllocation} from '../../components/new-ui/retainer_period/ProgramTotalAllocation';
import {triggerFinancialServiceCalculationAndWait} from '../../forecast-app/project-tab/programs/hooks/useFinancialServiceUpdate';
import {ProgramBudgetExceededWarning} from '../../components/new-ui/ProgramBudgetExceededWarning';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';
import {useHistory} from 'react-router-dom';
import ProgramUtil from '../../forecast-app/shared/util/ProgramUtil';

const AddPeriodsModalStyle = styled.div`
	.sub-header {
		color: ${CSS_CONSTANTS.v2_text_gray};
		font-size: 13px;
		margin-bottom: 28px;
	}
	.input-totals-container {
		display: flex;
		.input-container-v2 {
			width: 50px;
			margin-right: 66px;
			.input-value-container {
				height: 30px;
				border-color: ${props => (!props.isInputValid ? CSS_CONSTANTS.v2_border_invalid : '')};
			}
		}
	}
	.retainer-totals {
		display: flex;
		justify-content: space-between;
		width: 320px;
		height: 51px;
		.retainer-totals-container {
			display: flex;
			flex-direction: column;
			&.value-section {
				flex-grow: 1;
				text-align: right;
			}
			label {
				font-size: 9px;
				text-transform: uppercase;
				font-weight: 500;
				line-height: 1.22;
				margin-bottom: 5px;
				letter-spacing: 1px;
				color: ${CSS_CONSTANTS.v2_text_light_gray};
			}
			.retainer-bloc {
				display: flex;
				height: 30px;
				font-size: 13px;
				color: ${CSS_CONSTANTS.v2_text_gray};
				background: ${CSS_CONSTANTS.color_medium_gray};
				align-items: center;
				justify-content: space-between;
				&.dates {
					position: relative;
					padding-left: 10px;
					.start-period-date {
						position: absolute;
						height: 30px;
						width: 30px;
						left: -30px;
						top: 4px;
					}
				}
				&.value {
					text-align: right;
					padding-right: 10px;

					.inner-text {
						width: 100%;
					}
				}
			}
		}
	}
`;

const AddPeriodsModal = ({
	intl,
	intl: {formatMessage},
	handleConfirm,
	closeModal,
	defaultPeriodLength,
	defaultPeriodPeriodicity,
	defaultPeriodBudgetType,
	defaultPeriodHoursAmount,
	defaultPeriodPriceAmount,
	existingValue,
	currency,
	firstPeriodStartDate,
	latestPeriodEndDate,
	projectId,
	actualPersonId,
	getRetainerPeriodProgramBudgetInfo,
	refetch,
	project,
}) => {
	const [inputValue, setInputValue] = useState(1);
	const [isInputValid, setIsInputValid] = useState(true);
	const currencySymbol = Util.GetCurrencySymbol(currency);
	const {
		isInFixedPriceProgram,
		isInCappedRevenueProgram,
		showProgramBudgetInfo,
		totalProgramAllocationIncludingPeriodsValue,
		programBudget,
		programAllocation,
		canManageProgram,
		programPrefix,
		program,
		validateAgainstProgramBudget,
		showOverAllocatedError,
	} = getRetainerPeriodProgramBudgetInfo(defaultPeriodPriceAmount * inputValue, actualPersonId, project);

	const history = useHistory();

	const recalculateFinancials = validateAgainstProgramBudget || project.budgetType === 'RETAINER';

	const handleConfirmButton = async () => {
		handleConfirm(inputValue);
		if (recalculateFinancials) {
			await triggerFinancialServiceCalculationAndWait(projectId);
			await refetch();
			closeModal();
		} else {
			closeModal();
		}
	};

	const onInputChange = value => {
		setIsInputValid(value.length && !isNaN(value) && value > 0 && value - Math.floor(value) === 0);
		setInputValue(value);
	};

	const lastPeriodEndDate = getLastPeriodEndDateForSelectedPeriodCount(
		latestPeriodEndDate,
		inputValue,
		defaultPeriodLength,
		defaultPeriodPeriodicity
	);
	const handleEditProgramBudgetClick = () => {
		trackEvent('Edit Program Budget Link', 'Clicked', {location: 'From Add Periods Modal'});
		history.push(`${ProgramUtil.programLink()}/${programPrefix}/budget/edit`);
		closeModal();
	};

	const content = (
		<AddPeriodsModalStyle isInputValid={isInputValid}>
			{showOverAllocatedError && isInCappedRevenueProgram && (
				<ProgramBudgetExceededWarning
					canManageProgram={canManageProgram}
					program={program}
					onEditProgramBudgetClick={handleEditProgramBudgetClick}
					valueType={'period quantity'}
				></ProgramBudgetExceededWarning>
			)}
			<div style={{marginTop: theme.spacing.m}} />
			{showProgramBudgetInfo && (
				<ProgramBudgetInfo
					programBudget={programBudget}
					programAllocation={programAllocation}
					currencySymbol={currencySymbol}
					isInFixedPriceProgram={isInFixedPriceProgram}
				/>
			)}
			<div className="sub-header"></div>
			<div className="sub-header">{formatMessage({id: 'retainer.add_multiple_periods'})}</div>
			<div className="input-totals-container">
				<Input
					id="user-input-modal-input"
					label={formatMessage({id: 'retainer.periods'})}
					value={inputValue}
					minNumber={1}
					type={'number'}
					step={1}
					invalidInput={showOverAllocatedError}
					onChange={onInputChange}
					placeholder={''}
					autoFocus={true}
					onEnter={showOverAllocatedError ? undefined : handleConfirmButton.bind(this)}
					cy={'user-input-modal-input'}
				/>
				<div className="retainer-totals" data-cy={'pre-create-periods-retainer-totals'}>
					<div className={'retainer-totals-container dates-section'}>
						<label className={'label'}>{formatMessage({id: 'retainer.new_dates'})}</label>
						<div className={'retainer-bloc dates'}>
							{firstPeriodStartDate && lastPeriodEndDate
								? firstPeriodStartDate.format('ll') + ' - ' + lastPeriodEndDate.format('ll')
								: null}
						</div>
					</div>
					{defaultPeriodBudgetType !== PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS ? (
						<div className={'retainer-totals-container value-section'}>
							<label className={'label'}>{formatMessage({id: 'retainer.new_total_value'})}</label>
							<div className={'retainer-bloc value'}>
								<div className={'inner-text'}>
									<Text color={showOverAllocatedError ? 'error' : 'primary'}>
										{defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS
											? Util.convertMinutesToFullHour(
													(existingValue + defaultPeriodHoursAmount * inputValue) * 60,
													intl,
													true
											  )
											: Util.getFormattedCurrencyValue(
													Util.GetCurrencySymbol(currency),
													existingValue + defaultPeriodPriceAmount * inputValue
											  )}
									</Text>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
			{showOverAllocatedError && (
				<div style={{width: '440px'}}>
					<Text color={'error'}>
						{formatMessage({
							id: isInFixedPriceProgram
								? 'program.retainer_period_overallocation_error'
								: 'program.retainer_period_overallocation_error_capped',
						})}
					</Text>
				</div>
			)}
			{showProgramBudgetInfo && (
				<ProgramTotalAllocation
					paddingTop={theme.spacing.m}
					totalProgramAllocation={totalProgramAllocationIncludingPeriodsValue}
					showOverAllocatedError={showOverAllocatedError}
					currencySymbol={currencySymbol}
					isInFixedPriceProgram={isInFixedPriceProgram}
					showRevenueExceedInfo={true}
				/>
			)}
		</AddPeriodsModalStyle>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					callback: closeModal.bind(this),
				},
				{
					text: formatMessage({id: 'common.add'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: handleConfirmButton.bind(this),
					cy: 'user-input-confirm-btn',
					disabled: !isInputValid || showOverAllocatedError,
				},
			]}
			headerText={formatMessage({id: 'retainer.new_periods'})}
			content={content}
			modalWidth={MODAL_WIDTH.LARGE}
		/>
	);
};

export default injectIntl(AddPeriodsModal);
