import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import GenericModal from '../generic_modal';
import {APPROVAL_TYPE, BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE} from '../../../constants';
import DatePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../../forecast-app/shared/util/util';
import UpdateApprovalStatusMutation from '../../../mutations/UpdateApprovalStatusMutation';
import {ModalText, ModalWrapper} from '../modal_styled';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {triggerUserPilotOnce} from '../../../forecast-app/shared/util/UserPilotUtil';
import {USER_PILOT_FLOW_ON_TIMESHEET_SUBMIT} from '../../../forecast-app/shared/util/UserPilotConstants';

const SubmitTimeApprovalModal = ({
	selectedPersonId,
	selectedPersonName,
	currentViewingDate,
	usingTimeOffApproval,
	lockedDate,
	closeModal,
	intl: {formatMessage},
}) => {
	const startDateLimit = lockedDate && lockedDate.clone().add(1, 'd');
	const currentDateNoLock =
		lockedDate && lockedDate.isSameOrAfter(currentViewingDate, 'day') ? startDateLimit : currentViewingDate;

	const [selectedDate, setSelectedDate] = useState(currentDateNoLock);

	const handleConfirmButton = () => {
		trackEvent('Submit Timesheet Modal Save Button', 'Clicked');
		const onSuccess = res => {
			const personRes = res.updateApprovalStatus?.persons?.[0];
			if (personRes) {
				const newPersonLockDate = Util.CreateNonUtcMomentDate(
					personRes.submitLockedDateYear,
					personRes.submitLockedDateMonth,
					personRes.submitLockedDateDay
				);
				createToast({
					duration: 2500,
					message: `Timesheets submitted until ${newPersonLockDate.clone().format('LL')}`,
				});
				if (!hasFeatureFlag('timesheet_remaster')) {
					triggerUserPilotOnce(USER_PILOT_FLOW_ON_TIMESHEET_SUBMIT);
				}
				closeModal();
			} else if (Util.checkForSageErrorAndShowModal(res.updateApprovalStatus.errors)) {
				return;
			}
		};

		// Mutation
		Util.CommitMutation(
			UpdateApprovalStatusMutation,
			{
				personIds: [selectedPersonId],
				approvalType: APPROVAL_TYPE.SUBMIT,
				endYear: selectedDate.year(),
				endMonth: selectedDate.month() + 1,
				endDay: selectedDate.date(),
				includeTimeOff: hasFeatureFlag('pto_timesheet_allocation_linking'),
			},
			onSuccess
		);
	};

	const cancelButton = () => {
		trackEvent('Submit Timesheet Modal Cancel Button', 'Clicked');
	};

	const handleDateRangeChanged = newDate => {
		trackEvent('Submit Timesheet Modal Submit Until Date', 'Changed', {
			oldDate: selectedDate ? selectedDate.format('YYYY-MM-DD') : 'No date',
			newDate: newDate ? newDate.format('YYYY-MM-DD') : 'No date',
		});
		setSelectedDate(newDate);
	};

	const messageIdSuffix = (usingTimeOffApproval ? '_and_time_off' : '') + '_info';

	const content = (
		<ModalWrapper medium>
			<ModalText margin={'24px'}>
				{selectedPersonName
					? formatMessage({id: 'time_approval.submit_names_timesheets' + messageIdSuffix}, {name: selectedPersonName})
					: formatMessage({id: 'time_approval.submit_timesheets' + messageIdSuffix})}
			</ModalText>

			<ModalText margin={'8px'}>{formatMessage({id: 'time_approval.submit_timesheets_until'})}</ModalText>
			<DatePicker
				startDate={currentDateNoLock}
				handleDateRangeChange={handleDateRangeChanged.bind(this)}
				datePickerStyle={DATE_PICKER_STYLE.SLIM}
				isSingleDatePicker={true}
				startDateLimite={startDateLimit}
			/>
		</ModalWrapper>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					callback: cancelButton.bind(this),
				},
				{
					text: formatMessage({id: 'common.save'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: handleConfirmButton.bind(this),
					disabled: !selectedDate,
					cy: 'submit-time-save-button',
				},
			]}
			headerText={
				selectedPersonName
					? formatMessage({id: 'time_approval.submit_names_timesheets'}, {name: selectedPersonName})
					: formatMessage({id: 'time_approval.submit_timesheets'})
			}
			content={content}
		/>
	);
};

SubmitTimeApprovalModal.propTypes = {
	currentViewingDate: PropTypes.object,
	closeModal: PropTypes.func.isRequired,
};

export default injectIntl(SubmitTimeApprovalModal);
